import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reduxConfig';
import {
    IMenu,
    IMenuItemsState,
    IMenuLinksState,
    IMenuPhotosState,
} from 'features/dashboard/redux/menu/menu.types';

export const selectMenuState = (state: RootState): IMenu => state?.dashboard?.menu;

export const selectMenuItemsState = createSelector(
    selectMenuState,
    (state: IMenu) => state?.menuItems,
);
export const selectMenuLinksState = createSelector(
    selectMenuState,
    (state: IMenu) => state?.menuLinks,
);

export const selectMenuPhotosState = createSelector(
    selectMenuState,
    (state: IMenu) => state?.menuPhotos,
);

export const selectMenuPhotosData = createSelector(
    selectMenuPhotosState,
    (state: IMenuPhotosState) => state.photos,
);

export const selectMenuPhotosLoadingStatus = createSelector(
    selectMenuPhotosState,
    (state: IMenuPhotosState) => state?.loading,
);

export const selectMenuPhotosErrorStatus = createSelector(
    selectMenuPhotosState,
    (state: IMenuPhotosState) => state?.error,
);

export const selectMenuItemsData = createSelector(
    selectMenuItemsState,
    (state: IMenuItemsState) => state?.data,
);
export const selectMenuItemsLoadingStatus = createSelector(
    selectMenuItemsState,
    (state: IMenuItemsState) => state?.loading,
);
export const selectMenuItemsErrorStatus = createSelector(
    selectMenuItemsState,
    (state: IMenuItemsState) => state?.error,
);

export const selectMenuLinksData = createSelector(
    selectMenuLinksState,
    (state: IMenuLinksState) => state?.data,
);
export const selectMenuLinksLoadingStatus = createSelector(
    selectMenuLinksState,
    (state: IMenuLinksState) => state?.loading,
);
export const selectMenuLinksErrorStatus = createSelector(
    selectMenuLinksState,
    (state: IMenuLinksState) => state?.error,
);

export const uploadMenuPhotosLoadingStatus = createSelector(
    selectMenuPhotosState,
    (state: IMenuPhotosState) => state?.uploadLoading,
);

export const uploadMenuPhotosErrorStatus = createSelector(
    selectMenuPhotosState,
    (state: IMenuPhotosState) => state?.uploadError,
);

export const deleteMenuPhotosLoadingStatus = createSelector(
    selectMenuPhotosState,
    (state: IMenuPhotosState) => state?.deleteLoading,
);

export const deleteMenuPhotosErrorStatus = createSelector(
    selectMenuPhotosState,
    (state: IMenuPhotosState) => state?.deleteError,
);
