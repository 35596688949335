import React, { useEffect, useRef, useState } from 'react';
import { LoadingIndicator, Text } from 'spoton-lib';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import { IMediaImage, IMediaResponse } from 'features/dashboard/api/Media.types';
import { getImages } from 'features/dashboard/api/Media.data';
import { MediaFullScreen } from '../MediaFullScreen';

import styles from './MediaModalContent.module.scss';
import InfiniteScroll from 'react-infinite-scroller';
import ServerErrorImage from '../../../assets/serverError.png';
import NoMediaImage from '../../../assets/noPosts.png';
import { useBreakpoint } from '../../../../../utils/customHooks';
import { showDetailedError } from 'utils/messages';

interface IMediaModalContentProps {
    activeImageSourceTab: string;
    isMobile?: boolean;
}

export default function MediaModalContent({
    activeImageSourceTab,
}: IMediaModalContentProps): JSX.Element {
    const isMobile = useBreakpoint() !== 'desktop';
    const virtualScrollParentRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [images, setImages] = useState<any>([]);
    const [nextPageToken, setNextPageToken] = useState<string | undefined>('');
    const [totalMediaCount, setTotalMediaCount] = useState<number>(0);
    const [fullScreenImage, setFullScreenImage] = useState<any>(null);
    const [isOpenFullScreenImage, setIsOpenFullScreenImage] =
        useState<boolean>(false);
    const { t } = useTranslation();

    const getMedia = (isLazyLoad = true) => {
        setIsLoading(!isLazyLoad);
        setIsError(false);
        if (!isLazyLoad) {
            setNextPageToken('');
            setImages([]);
        }
        getImages(
            activeImageSourceTab,
            isMobile ? 20 : 10,
            isLazyLoad ? nextPageToken : null,
        )
            .then((data: AxiosResponse<IMediaResponse>) => {
                setImages((current: Array<IMediaImage>) =>
                    isLazyLoad
                        ? [...current, ...data.data.mediaItems]
                        : data.data.mediaItems,
                );
                setNextPageToken(data.data.nextPageToken);
                setTotalMediaCount(data.data.totalMediaItemCount);
            })
            .catch((error: any) => {
                showDetailedError({
                    template: 'errors.dashboard.mediaFetch',
                    error: error.response?.data,
                });
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getMedia(false);
    }, [activeImageSourceTab]);

    const openFullScreenImage = (imageData: any, imageIndex: number) => () => {
        setFullScreenImage({ ...imageData, imageIndex });
        setIsOpenFullScreenImage(true);
    };

    const closeFullScreenImage = () => {
        setFullScreenImage(null);
        setIsOpenFullScreenImage(false);
    };
    return (
        <>
            <div
                className={styles.InfiniteScrollWrapper}
                ref={virtualScrollParentRef}
            >
                {isLoading && !isError && !images.length && (
                    <LoadingIndicator className={styles.LoadingIndicator} />
                )}
                {!isLoading && (
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        loadMore={() => {
                            getMedia();
                        }}
                        loader={
                            <LoadingIndicator
                                className={styles.LoadingIndicatorScroll}
                                key={0}
                            />
                        }
                        hasMore={!!nextPageToken}
                        isReverse={false}
                        threshold={400}
                        useWindow={false}
                        className={styles.InfiniteScroll}
                        getScrollParent={() => virtualScrollParentRef.current}
                    >
                        {!isLoading && !images.length && (
                            <div className={styles.NoItems} key="noItems">
                                <img
                                    src={isError ? ServerErrorImage : NoMediaImage}
                                    alt="no media"
                                />
                                {isError && (
                                    <p className={styles.NoItemsText}>
                                        {t('errors.server')}
                                    </p>
                                )}
                                {(activeImageSourceTab === 'ALL' ||
                                    activeImageSourceTab === 'CUSTOMER') &&
                                    !isError && (
                                        <p className={styles.NoItemsText}>
                                            {t('dashboard.noMediaAll')}
                                        </p>
                                    )}
                                {activeImageSourceTab === 'OWNER' && !isError && (
                                    <>
                                        <p className={styles.NoItemsText}>
                                            {t('dashboard.noMediaOwner')}
                                        </p>
                                    </>
                                )}
                                {activeImageSourceTab === 'VIDEO' && !isError && (
                                    <>
                                        <p className={styles.NoItemsText}>
                                            {t('dashboard.noMediaVideo')}
                                        </p>
                                    </>
                                )}
                            </div>
                        )}
                        {!!images.length &&
                            images.map((image: any, i: number) => {
                                return (
                                    <div
                                        key={image.googleUrl}
                                        className={styles.ModalImageWrapper}
                                        aria-hidden="true"
                                        onClick={openFullScreenImage(image, i)}
                                    >
                                        <span
                                            style={{
                                                background:
                                                    'linear-gradient(180deg, rgba(0, 0, 0, 0) 55.21%, rgba(0, 0, 0, 0.6) 100%),' +
                                                    ` url("${image.thumbnailUrl}")`,
                                                display: 'inline-block',
                                                width: '100%',
                                                height: '100%',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center center',
                                            }}
                                        />
                                        <div
                                            className={
                                                styles.ModalImageDescriptionWrapper
                                            }
                                        >
                                            <Text
                                                className={styles.ImageDescription}
                                            >
                                                {image.hrCreateTime}
                                            </Text>
                                        </div>
                                    </div>
                                );
                            })}
                    </InfiniteScroll>
                )}
            </div>
            {isOpenFullScreenImage && (
                <MediaFullScreen
                    imageData={fullScreenImage}
                    imagesData={images}
                    totalMediaCount={totalMediaCount}
                    pageToken={nextPageToken}
                    currentFilter={activeImageSourceTab}
                    closeModal={closeFullScreenImage}
                    updateMediaList={() => getMedia(false)}
                />
            )}
        </>
    );
}
