import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import { navigation } from 'app/routes';

import i18n from 'i18n.config';
import { PreviewApi, CreateAccountApi } from 'features/account/api';
import { showDetailedError } from 'utils/messages';
import { getPathnameWithQueryParams } from 'app/components/App/App.utils';
import { paths } from 'app/routes/app.routes';

export const getPreview = createAsyncThunk(
    'preview/getPreview',
    async (_, { rejectWithValue }) => {
        try {
            const response = await PreviewApi.getPreview();
            return response.data;
        } catch (error) {
            showDetailedError({
                template: 'errors.connect.preview',
            });
            return rejectWithValue({
                data: (error as AxiosError<any, any>).response?.data,
                status: (error as AxiosError<any, any>).response?.status,
            });
        }
    },
);

export const createAccount = createAsyncThunk(
    'preview/createAccount',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await CreateAccountApi.createAccount();
            if (data.verified) {
                navigation.navigate(
                    getPathnameWithQueryParams(paths.pendingConnection),
                );
            } else {
                navigation.navigate(
                    getPathnameWithQueryParams(paths.pendingVerification),
                );
            }
            return data;
        } catch (error) {
            const response = (error as AxiosError<any, any>).response;
            if (response) {
                const error_type = response?.data?.error_type;
                showDetailedError({
                    message: error_type
                        ? i18n.t(`errors.types.${error_type}`)
                        : response?.data?.message,
                });
            }
            return rejectWithValue({
                data: response?.data,
                status: response?.status,
            });
        }
    },
);
