import React from 'react';
import { colors, Icon } from 'spoton-lib';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectBusinessInfoData } from 'features/dashboard/redux';

import styles from './MobileGoogleLinksSection.module.scss';

export default function MobileGoogleLinksSection(): JSX.Element {
    const { t } = useTranslation();
    const businessInfo = useSelector(selectBusinessInfoData);
    const mapsUrl = businessInfo?.mapsUrl;
    const searchUrl = businessInfo?.searchUrl;

    return (
        <div className={styles.Wrapper}>
            {searchUrl && (
                <div className={styles.LinkContainer}>
                    <a
                        href={searchUrl}
                        rel="noreferrer noopener"
                        target="_blank"
                        className={styles.RedirectActionButton}
                    >
                        <div className={styles.IconWrapper}>
                            <Icon
                                name="GoogleIcon"
                                alt={t('viewOnGoogleSearch')}
                                size={18}
                                color={colors.primary50}
                                className={styles.TooltipIcon}
                            />
                        </div>
                        <div
                            className={classNames(
                                styles.TextContainer,
                                styles.WithBorder,
                            )}
                        >
                            {t('viewOnGoogleSearch')}
                        </div>
                    </a>
                </div>
            )}
            {mapsUrl && (
                <div className={styles.LinkContainer}>
                    <a
                        href={mapsUrl}
                        rel="noreferrer noopener"
                        target="_blank"
                        className={styles.RedirectActionButton}
                    >
                        <div className={styles.IconWrapper}>
                            <Icon
                                name="LocationIcon"
                                alt={t('viewOnGoogleMaps')}
                                size={18}
                                color={colors.primary50}
                                className={styles.TooltipIcon}
                            />
                        </div>
                        <div className={styles.TextContainer}>
                            {t('viewOnGoogleMaps')}
                        </div>
                    </a>
                </div>
            )}
        </div>
    );
}
