import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import { IDisconnectTypes, LocationType } from './Disconnect.types';

export function getLocationStatus(key: string): Promise<AxiosResponse<any>> {
    return axios.get<LocationType>('/api/disconnect/status', {
        params: { key },
    });
}

export function disconnectAccount({
    authorizationCode,
    key,
}: {
    authorizationCode: string;
    key: string;
}): Promise<AxiosResponse<IDisconnectTypes>> {
    return axios.post<IDisconnectTypes>('/api/disconnect/google-oauth2', {
        authorizationCode,
        key,
    });
}
