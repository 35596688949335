import React, { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageWithActions, Icon, DropZone } from 'spoton-lib';
import { v4 as uuidv4 } from 'uuid';
import { IProps } from './SingleImageSelector.types';
import styles from './SingleImageSelector.module.scss';

export default function SingleImageSelector({
    onAddImage,
    image,
    className,
    text,
}: IProps): JSX.Element {
    const { t } = useTranslation();

    const inputRef = useRef<HTMLInputElement>(null);
    const focusTextInput = () => inputRef.current?.focus();

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const files = e.target?.files;
        if (files && files.length > 0) {
            onAddImage(files);
        }
    };

    const currentTimeStamp = uuidv4().toString();

    return (
        <label
            htmlFor={currentTimeStamp}
            className={`${styles.SingleImageSelector} ${className}`}
        >
            <DropZone onDrop={onAddImage} className={styles.DropZone}>
                <div className={styles.SingleImageSelector_container}>
                    <input
                        ref={inputRef}
                        type="file"
                        data-testid="file-input"
                        multiple
                        accept="image/jpeg,image/png"
                        className={styles.ImagePicker_input}
                        onChange={handleChange}
                        id={currentTimeStamp}
                    />
                    <div
                        className={styles.ImagePicker}
                        onClick={focusTextInput}
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => null}
                    >
                        <Icon
                            className={styles.ImagePicker_icon}
                            name="ImageIcon"
                            size={30}
                            alt={t('dashboard.selectMedia')}
                        />
                        <p className={styles.ImagePicker_title}>{text}</p>
                    </div>
                    {image && (
                        <ImageWithActions
                            label={''}
                            className={`
                                ${styles.SingleImageSelector_image}
                            `}
                            imgUrl={image?.imgUrl}
                            imgAlt={image?.imgAlt}
                        />
                    )}
                </div>
            </DropZone>
        </label>
    );
}
