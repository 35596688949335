import { axios, AxiosResponse } from 'features/common/utils/axios.utils';

import {
    IGetReviewsResponse,
    IGetReviewSummaryResponse,
    IPostReviewReply,
    IDeleteReviewReply,
} from './Review.types';

export function getReviews(
    orderBy = 'updateTime desc',
): Promise<AxiosResponse<IGetReviewsResponse>> {
    return axios.get<IGetReviewsResponse>(`/api/location/reviews`, {
        params: { orderBy },
    });
}

export function getReviewsInfinite(
    pageToken?: string,
): Promise<AxiosResponse<IGetReviewsResponse>> {
    return axios.get<IGetReviewsResponse>(`/api/location/reviews`, {
        params: { pageToken },
    });
}

export function getReviewSummary(): Promise<
    AxiosResponse<IGetReviewSummaryResponse>
> {
    return axios.get<IGetReviewSummaryResponse>(`/api/location/review-summary`);
}

export function postReviewReply(
    reviewId: string,
    comment: string,
): Promise<AxiosResponse<IPostReviewReply>> {
    return axios.put<IPostReviewReply>(`/api/location/reviews/${reviewId}/`, {
        comment,
        updateTime: new Date().toISOString(),
    });
}

export function deleteReviewReply({
    reviewId,
}: IDeleteReviewReply): Promise<AxiosResponse<IDeleteReviewReply>> {
    return axios.delete<IDeleteReviewReply>(`/api/location/reviews/${reviewId}/`);
}
