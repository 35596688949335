import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'reduxConfig';

import { IModalsState } from './modals.types';

export const selectModalsState = (state: RootState): IModalsState =>
    state.dashboard.modals;

export const selectMediaModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.mediaSectionModalIsOpen,
);
export const selectQuestionsModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.questionsModalIsOpen,
);
export const selectMediaSmallModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.mediaSectionSmallModalIsOpen,
);
export const selectReviewModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.reviewSectionModalIsOpen,
);
export const selectReviewModalActiveTab = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.reviewSectionModalActiveTab,
);
export const selectPostsModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.postsSectionModalIsOpen,
);

export const selectPostsModalForCreateState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.postsSectionModalIsOpenForCreate,
);

export const selectBusinessHoursModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.updateBusinessHoursModalIsOpen,
);

export const selectDeleteModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.deleteModalIsOpen,
);
export const selectUpdateBusinessInfoModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.updateBusinessInfoModalIsOpen,
);

export const selectMenuPhotosModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.menuPhotosModalIsOpen,
);

export const selectPhotoExampleModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.seePhotoExampleIsOpen,
);

export const selectViewAllModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.seeViewAllIsOpen,
);

export const selectQuickActionModalState = createSelector(
    selectModalsState,
    (state: IModalsState) => state?.quickActionModalIsOpen,
);
