// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileScreen_Wrapper__39iZY{width:100%;padding:24px 0 40px}.ProfileScreen_HalfSectionWrapper__21hf3{display:flex;flex-wrap:wrap;justify-content:space-between;width:100%}.ProfileScreen_LeftSideWrapper__1j-Sa{width:calc(51% - 10px);margin-bottom:20px}.ProfileScreen_RightSideWrapper__3b5em{width:calc(49% - 10px)}.ProfileScreen_AppointmentLinksWrapper__2QdbA{margin-top:20px}@media screen and (max-width: 1024px){.ProfileScreen_Wrapper__39iZY{margin-top:32px}.ProfileScreen_LeftSideWrapper__1j-Sa,.ProfileScreen_RightSideWrapper__3b5em{width:100%}}\n", "",{"version":3,"sources":["webpack://src/features/dashboard/profile/ProfileScreen/ProfileScreen.module.scss"],"names":[],"mappings":"AAEA,8BACI,UAAW,CACX,mBAAoB,CACvB,yCAGG,YAAa,CACb,cAAe,CACf,6BAA8B,CAC9B,UAAW,CACd,sCAGG,sBAAuB,CACvB,kBAAmB,CACtB,uCAGG,sBAAuB,CAC1B,8CAGG,eAAgB,CACnB,sCAGG,8BACI,eAAgB,CACnB,6EAIG,UAAW,CACd","sourcesContent":["@import 'src/features/common/styles/breakpoints';\n\n.Wrapper {\n    width: 100%;\n    padding: 24px 0 40px;\n}\n\n.HalfSectionWrapper {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.LeftSideWrapper {\n    width: calc(51% - 10px);\n    margin-bottom: 20px;\n}\n\n.RightSideWrapper {\n    width: calc(49% - 10px);\n}\n\n.AppointmentLinksWrapper {\n    margin-top: 20px;\n}\n\n@include notDesktop {\n    .Wrapper {\n        margin-top: 32px;\n    }\n\n    .LeftSideWrapper,\n    .RightSideWrapper {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": "ProfileScreen_Wrapper__39iZY",
	"HalfSectionWrapper": "ProfileScreen_HalfSectionWrapper__21hf3",
	"LeftSideWrapper": "ProfileScreen_LeftSideWrapper__1j-Sa",
	"RightSideWrapper": "ProfileScreen_RightSideWrapper__3b5em",
	"AppointmentLinksWrapper": "ProfileScreen_AppointmentLinksWrapper__2QdbA"
};
export default ___CSS_LOADER_EXPORT___;
