import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { CategoriesApi } from 'features/account/api';

export interface IGetCategoriesArgs {
    languageCode: string;
    searchTerm: string;
}

export const getCategories = createAsyncThunk(
    'categories/getCategories',
    async (
        { languageCode, searchTerm }: IGetCategoriesArgs,
        { rejectWithValue },
    ) => {
        try {
            const response = await CategoriesApi.getCategories({
                languageCode,
                searchTerm: searchTerm || undefined,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue({
                data: (error as AxiosError<any, any>).response?.data,
                status: (error as AxiosError<any, any>).response?.status,
            });
        }
    },
);
