import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import { IGetUserInfoResponse } from './UserInfo.types';
import { IAuthConfig } from './Auth.types';

export function getAuthConfig(): Promise<AxiosResponse<IAuthConfig>> {
    return axios.get<IAuthConfig>('/api/connect/config');
}

export function postAuthCode(
    authorizationCode: string,
): Promise<AxiosResponse<IGetUserInfoResponse>> {
    return axios.post<IGetUserInfoResponse>('/api/connect/google-oauth2', {
        authorizationCode,
    });
}
