export interface IStatusTypes {
    data: Status;
    fsIdentifiers: FsIdentifiers;
    isLoaded: boolean;
    isError: boolean;
}

export type Status = {
    email: string;
    merchantId: string;
    firstName: string;
    lastName: string;
    // whether marketing assistant is enabled
    maEnabled: boolean;
    expressEnabled: boolean;
    statuses: any;
    merchantInfo: Merchant;
    postsEnabled: boolean;
    probationPeriod: boolean;
};

export type Merchant = {
    address?: string | null;
    capitalStatus?: string | null;
    country?: string | null;
    locationName?: string | null;
    ownerName?: string | null;
    profilePhotoUrl?: string | null;
    programs?: number[] | null;
    showCapital?: boolean | null;
    type: MerchantType | null;
    sublocations: {
        merchantID: string;
        address: ISublocationAddress;
        status: LocationLinkingStatus;
    }[];
};

export const MERCHANT_TYPE = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SINGLE_LOCATION: 1,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ROOT: 2,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SUB_LOCATION: 3,
} as const;

type MerchantType = typeof MERCHANT_TYPE[keyof typeof MERCHANT_TYPE];

const LOCATION_LINKING_STATUS = [
    'NOT_REGISTERED',
    'OAUTH_COMPLETED',
    'MATCHING_CONFIRMED',
    'CLAIM_REQUESTED',
    'MANUAL_VERIFICATION_REQUIRED',
    'LINKED',
    'CANCELLED',
] as const;

export type LocationLinkingStatus = typeof LOCATION_LINKING_STATUS[number];

interface ISublocationAddress {
    address1: string;
    address2: string;
    postalCode: string;
    region: string;
    country: string;
    city: string;
}

/* eslint-disable @typescript-eslint/naming-convention */
export type FsIdentifiers =
    | Record<string, never>
    | {
          userId: Status['email'];
          Name: Status['email'];
          MerchantId: Status['merchantId'];
          NameOfBusiness: Merchant['locationName'];
          isTest: boolean;
      };
