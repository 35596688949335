import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'spoton-lib';
import { Widget } from 'features/common/components';

import {
    fetchQuestions,
    selectIsAnswerCreated,
    selectIsError,
    selectIsLoading,
    selectQuestions,
} from 'features/dashboard/redux';
import {
    IQuestionsPayload,
    SortValues,
} from 'features/dashboard/types/QuestionsSectionTypes';
import { QuestionsTabs } from './components/QuestionsTabs';
import { sortOptions } from './config';
import { useBreakpoint } from 'utils/customHooks';

import styles from './QuestionsSection.module.scss';

export default function QuestionsSection(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useBreakpoint() !== 'desktop';
    const [sortOrder, setSortOrder] = useState(sortOptions[0]);

    const questionsData = useSelector(selectQuestions);
    const isLoading = useSelector(selectIsLoading);
    const isError = useSelector(selectIsError);
    const isCreated = useSelector(selectIsAnswerCreated);

    const questionsRequest = useCallback(
        (payload: IQuestionsPayload) => {
            dispatch(fetchQuestions(payload));
        },
        [dispatch],
    );

    const handleSortQuestions = useCallback((order) => {
        setSortOrder(order);
    }, []);

    useEffect(() => {
        questionsRequest({
            answersPerQuestion: 5,
            orderBy: sortOrder.value as SortValues,
        });
    }, [sortOrder]);

    useEffect(() => {
        if (isCreated) {
            questionsRequest({
                answersPerQuestion: 5,
                orderBy: sortOrder.value as SortValues,
            });
        }
    }, [isCreated]);

    return (
        <Widget className={styles.Card}>
            <div className={styles.Header}>
                <h3 className={styles.HeaderTitle}>
                    {t('dashboard.questions.title')}
                </h3>
                {!isMobile && (
                    <Dropdown
                        className={styles.Dropdown}
                        value={sortOrder}
                        onChange={handleSortQuestions}
                        options={sortOptions}
                    />
                )}
            </div>
            <div className={styles.Content}>
                <QuestionsTabs
                    questionsData={questionsData}
                    isLoading={isLoading}
                    isError={isError}
                    sortOrder={setSortOrder}
                    order={sortOrder}
                />
            </div>
        </Widget>
    );
}
