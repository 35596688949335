import React from 'react';

import styles from './MobileTimePicker.module.scss';
import { closesPickerOptions, opensPickerOptions } from '../TimePicker';

interface ISelectProps {
    mode: string;
    onChange: any;
    value: any;
}

export default function MobileTimePicker({
    mode,
    onChange,
    value,
}: ISelectProps): JSX.Element {
    const options = mode === 'opens' ? opensPickerOptions : closesPickerOptions;
    const onSelectChange = (e: any) => {
        onChange(e.target.value);
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-onchange
        <select
            onChange={onSelectChange}
            className={styles.MobileSelect}
            name="select"
            value={value?.value}
            defaultValue=""
        >
            <option value="" disabled>
                Select
            </option>
            {options.map((option: any, index) => (
                <option key={`${option.value}-${index}`} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
}
