// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Terms_Wrapper__1jyMm{display:flex;flex-direction:column;height:100%}.Terms_Header__2BZ5F{position:relative;z-index:1}.Terms_Content__3qo65{padding:0 20px 20px 20px;overflow:auto}.Terms_Anchor__1PuEF{position:sticky;top:0;margin:5px 0;padding:10px 0;background-color:#fff}\n", "",{"version":3,"sources":["webpack://src/features/account/components/Preview/Terms/Terms.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAa,CACb,qBAAsB,CACtB,WAAY,CACf,qBAGG,iBAAkB,CAClB,SAAU,CACb,sBAGG,wBAAyB,CACzB,aAAc,CACjB,qBAGG,eAAgB,CAChB,KAAM,CACN,YAAa,CACb,cAAe,CACf,qBAAsB","sourcesContent":[".Wrapper {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.Header {\n    position: relative;\n    z-index: 1;\n}\n\n.Content {\n    padding: 0 20px 20px 20px;\n    overflow: auto;\n}\n\n.Anchor {\n    position: sticky;\n    top: 0;\n    margin: 5px 0;\n    padding: 10px 0;\n    background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": "Terms_Wrapper__1jyMm",
	"Header": "Terms_Header__2BZ5F",
	"Content": "Terms_Content__3qo65",
	"Anchor": "Terms_Anchor__1PuEF"
};
export default ___CSS_LOADER_EXPORT___;
