import React, { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown } from 'spoton-lib';
import { useNavigation } from 'react-navi';

import { axios } from 'features/common/utils/axios.utils';

import { selectSublocations } from 'features/common/redux/status/status.selectors';
import { getStatus } from 'features/common/redux/status/status.actions';
import { LocationLinkingStatus } from 'features/common/redux/status/status.types';
import SublocationOption from './components/SublocationOption/SublocationOption.component';

import styles from './SublocationSelect.module.scss';

export interface ISublocationOption {
    value: string;
    label: string;
    status: LocationLinkingStatus;
}

export default function SublocationSelect(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const sublocations = useSelector(selectSublocations);
    const options = sublocations.map((option) => ({
        value: option.merchantID,
        label: option.address.address1,
        status: option.status,
    }));
    const [option, setOption] = useState(options[0]);

    const handleSublocation = () => {
        axios.defaults.headers.common['X-SUBLOCATION-MERCHANT-ID'] = option.value;
        navigation.navigate({
            search: `?sublocation-merchant-id=${option.value}`,
        });
        dispatch(getStatus());
    };

    return (
        <>
            <Dropdown
                onChange={(option: ISublocationOption) => setOption(option)}
                options={options}
                defaultValue={option}
                primaryCondition={t(
                    'connect.sublocationSelect.chooseSublocationToConnect',
                )}
                formatOptionLabel={({
                    value,
                    label,
                    status,
                }: ISublocationOption) => (
                    <SublocationOption value={value} label={label} status={status} />
                )}
                styles={{
                    singleValue: (base: CSSProperties): CSSProperties => ({
                        ...base,
                        width: 'max-content',
                        minWidth: '100%',
                    }),
                }}
            />
            <Button className={styles.Button} onClick={handleSublocation}>
                {t('connect.continue')}
            </Button>
        </>
    );
}
