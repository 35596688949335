import React from 'react';

import { ISelectOption } from '../../MultiSelectDropdown.types';

import styles from './FormatOptionLabel.module.scss';

/* eslint-disable @typescript-eslint/naming-convention */
const FormatOptionLabel = ({ label, info }: ISelectOption): JSX.Element => (
    <div className={styles.Option}>
        <div className={styles.Option_label}>{label}</div>
        <div className={styles.Option_info}>{info}</div>
    </div>
);

export default FormatOptionLabel;
