import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AuthApi } from 'features/account/api';
import { showDetailedError } from 'utils/messages';

export const getAuthConfig = createAsyncThunk(
    'auth/getAuthConfig',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthApi.getAuthConfig();
            return response.data;
        } catch (error) {
            showDetailedError({
                template: 'errors.connect.authConfig',
            });
            return rejectWithValue({
                data: (error as AxiosError<any, any>).response?.data,
                status: (error as AxiosError<any, any>).response?.status,
            });
        }
    },
);

export interface IPostAuthCodeArgs {
    authorizationCode: string;
}

export const postAuthCode = createAsyncThunk(
    'auth/postAuthCode',
    async ({ authorizationCode }: IPostAuthCodeArgs, { rejectWithValue }) => {
        try {
            const response = await AuthApi.postAuthCode(authorizationCode);
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.connect.googleAuth',
                error: data,
            });
            return rejectWithValue({
                data,
                status,
            });
        }
    },
);
