import { createReducer, Reducer } from '@reduxjs/toolkit';

import * as userInfoActions from './userInfo.actions';
import { IUserInfoTypes } from './userInfo.types';

import * as authActions from '../auth/auth.actions';

export const initialState: IUserInfoTypes = {
    name: '',
    email: '',
    picture: '',
    isLoaded: false,
    isError: false,
};

export const userInfoReducer: Reducer<IUserInfoTypes> = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(userInfoActions.getUserInfo.fulfilled, (state, action: any) => {
                state.name = action.payload.name;
                state.email = action.payload.email;
                state.picture = action.payload.picture;
                state.isLoaded = true;
                state.isError = false;
            })
            .addCase(userInfoActions.getUserInfo.rejected, (state) => {
                state.isLoaded = true;
                state.isError = true;
            })
            .addCase(authActions.postAuthCode.fulfilled, (state, action) => {
                state.name = action.payload.name;
                state.email = action.payload.email;
                state.picture = action.payload.picture;
                state.isLoaded = true;
                state.isError = false;
            })
            .addCase(authActions.postAuthCode.rejected, (state) => {
                state.isLoaded = true;
                state.isError = true;
            })
            .addCase(userInfoActions.resetUserInfo, (state) => {
                state.name = '';
                state.email = '';
                state.picture = '';
                state.isLoaded = false;
                state.isError = false;
            })
            .addDefaultCase((state) => {
                return state;
            });
    },
);

export default userInfoReducer;
