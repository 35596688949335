import {
    IAppDefinedMetricValue,
    IMetricValue,
    APP_DEFINED_METRICS,
} from 'features/dashboard/api/BusinessActivity.types';

export const makeInsights = (metrics: IMetricValue[]): IAppDefinedMetricValue[] => {
    const filteredMetrics = metrics.filter(({ metric }) =>
        metric.includes('BUSINESS_IMPRESSIONS'),
    );

    const views: IAppDefinedMetricValue = filteredMetrics.reduce(
        (acc, { value, deviation }, index) => {
            acc.value += value;
            if (deviation !== null) {
                if (acc.deviation === null) {
                    acc.deviation = deviation;
                } else {
                    acc.deviation += deviation;
                }
            }
            if (index === filteredMetrics.length - 1) {
                // Calculate the average deviation when processing the last metric, exclude null values from average.
                acc.deviation = Math.round(
                    acc.deviation /
                        filteredMetrics.filter((m) => m.deviation !== null).length,
                );
            }
            return acc;
        },
        { metric: 'BUSINESS_IMPRESSIONS', value: 0, deviation: 0 },
    );

    const rest = metrics.filter((metric: any) =>
        APP_DEFINED_METRICS.includes(metric.metric),
    );

    return [
        ...(filteredMetrics.length ? [views] : []),
        ...rest,
    ] as IAppDefinedMetricValue[];
};
