import { Menus } from '../../types';

export const mockMenu: Menus[] = [
    {
        labels: [{ displayName: 'Menu', description: 'Main Menu' }],
        sections: [
            {
                labels: [{ displayName: 'Drinks' }],
                items: [
                    {
                        labels: [{ displayName: 'Coca Cola' }],
                        attributes: {
                            price: {
                                currencyCode: 'USD',
                                units: '1',
                                nanos: 990000000,
                            },
                        },
                    },
                ],
            },
            {
                labels: [{ displayName: 'Breakfast' }],
                items: [
                    {
                        labels: [{ displayName: 'Egg Muffin' }],
                        attributes: {
                            price: {
                                currencyCode: 'USD',
                                units: '3',
                                nanos: 990000000,
                            },
                        },
                    },
                ],
            },
            {
                labels: [{ displayName: 'Sandwiches' }],
                items: [
                    {
                        labels: [
                            {
                                displayName: 'Bagel wit cream cheese',
                                description: 'Plain Cream Cheese, Homemade Bagel',
                            },
                        ],
                        attributes: {
                            price: {
                                currencyCode: 'USD',
                                units: '4',
                                nanos: 990000000,
                            },
                        },
                    },
                    {
                        labels: [
                            {
                                displayName: 'Burrito Grilled Chicken',
                                description: 'Lettuces, tomatoes, onion, pickles...',
                            },
                        ],
                        attributes: {
                            price: {
                                currencyCode: 'USD',
                                units: '13',
                                nanos: 990000000,
                            },
                        },
                    },
                ],
            },
            {
                labels: [{ displayName: 'Burgers' }],
                items: [
                    {
                        labels: [
                            {
                                displayName: 'Double Bacon',
                                description:
                                    'Double meat, Bacon, American Cheese, Grilled...',
                            },
                        ],
                        attributes: {
                            price: {
                                currencyCode: 'USD',
                                units: '14',
                                nanos: 990000000,
                            },
                        },
                    },
                ],
            },
        ],
        sourceUrl: '',
    },
];
