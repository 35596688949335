import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import { IChartDataResponse } from './BusinessActivity.types';

export function getChartData(
    period: string,
): Promise<AxiosResponse<IChartDataResponse>> {
    return axios.get<IChartDataResponse>('/api/location/insights', {
        params: { period },
    });
}
