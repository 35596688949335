import axiosDefault from 'axios';
import { getConfigVar } from 'features/common/utils/';
import { configureAxiosInstanceForOAuth } from '@spotoninc/okta-refresh-interceptor';
// import { redirectToLogin } from 'features/common/utils/auth.utils';

const axios = axiosDefault.create({
    baseURL: getConfigVar('REACT_APP_API_BASE_URL') as string,
    withCredentials: true,
    transformResponse: [
        (data: any): any => {
            return data ? JSON.parse(data) : {};
        },
    ],
    headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        Accept: 'application/json',
        'Content-Type': 'application/json',
        /* eslint-enable @typescript-eslint/naming-convention */
    },
});

/**
 * This is a hack function that allows our API to work as expected when we operate within an ephemeral environment
 * The reason we need to use a different API base url when the app is ran under ephemeral is because dashboard ephemeral serves the app
 * under <pr-xxxx>.spoton.sh domain and our auth-middleware verifies cors using the domain attribute that is being set in the cookie as "spoton.com".
 */
export const configureEphemeralApi = (): void => {
    const ephemeralBaseUrl = getConfigVar(
        'REACT_APP_EPHEMERAL_API_BASE_URL',
    ) as string;

    axios.defaults.baseURL = ephemeralBaseUrl;
};

// Logout on 401 & Redirect on 403
// axios.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         const { status } = error.response;
//         if (status === 401) {
//             redirectToLogin();
//         }
//         if (status === 403) {
//             window.location.href = getConfigVar(
//                 'REACT_APP_EXTERNAL_APP_DASHBOARD',
//             ) as string;
//         }

//         return Promise.reject(error);
//     },
// );

const CSRF_HEADER_NAME = 'X-CSRF-Token-Dashboard';
export const configureCSRFToken = (csrfToken: string): void => {
    axios.defaults.headers[CSRF_HEADER_NAME] = csrfToken;
};

configureAxiosInstanceForOAuth(axios);

export { axios };

export type { AxiosResponse } from 'axios';
