// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PostsSection_Wrapper__3D44S{position:relative;box-sizing:border-box;margin-top:15px;margin-bottom:15px;padding-top:12px}@media screen and (max-width: 1024px){.PostsSection_Wrapper__3D44S{padding:12px 9px 15px 9px}}.PostsSection_ButtonWrapper__v43VS{margin:0 -16px 0;padding:16px 16px 0}.PostsSection_Modal__1SzE-{position:relative;width:75%;min-width:450px;max-width:717px;height:790px;padding:16px}.PostsSection_MobileModal__2zNZx{position:relative;width:100%;height:100vh;min-height:100%;padding:0}.PostsSection_Overlay__2IIwW{background-color:rgba(0,0,0,0.6)}\n", "",{"version":3,"sources":["webpack://src/features/dashboard/home/PostSection/PostsSection.module.scss"],"names":[],"mappings":"AAEA,6BACI,iBAAkB,CAClB,qBAAsB,CACtB,eAAgB,CAChB,kBAAmB,CACnB,gBAAiB,CAKpB,sCAVD,6BAQQ,yBAA0B,CAEjC,CAED,mCACI,gBAAiB,CACjB,mBAAoB,CACvB,2BAGG,iBAAkB,CAClB,SAAU,CACV,eAAgB,CAChB,eAAgB,CAChB,YAAa,CACb,YAAa,CAChB,iCAGG,iBAAkB,CAClB,UAAW,CACX,YAAa,CACb,eAAgB,CAChB,SAAU,CACb,6BAGG,gCAAoC","sourcesContent":["@import 'features/common/styles/breakpoints';\n\n.Wrapper {\n    position: relative;\n    box-sizing: border-box;\n    margin-top: 15px;\n    margin-bottom: 15px;\n    padding-top: 12px;\n\n    @include notDesktop {\n        padding: 12px 9px 15px 9px;\n    }\n}\n\n.ButtonWrapper {\n    margin: 0 -16px 0;\n    padding: 16px 16px 0;\n}\n\n.Modal {\n    position: relative;\n    width: 75%;\n    min-width: 450px;\n    max-width: 717px;\n    height: 790px;\n    padding: 16px;\n}\n\n.MobileModal {\n    position: relative;\n    width: 100%;\n    height: 100vh;\n    min-height: 100%;\n    padding: 0;\n}\n\n.Overlay {\n    background-color: rgba(0, 0, 0, 0.6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": "PostsSection_Wrapper__3D44S",
	"ButtonWrapper": "PostsSection_ButtonWrapper__v43VS",
	"Modal": "PostsSection_Modal__1SzE-",
	"MobileModal": "PostsSection_MobileModal__2zNZx",
	"Overlay": "PostsSection_Overlay__2IIwW"
};
export default ___CSS_LOADER_EXPORT___;
