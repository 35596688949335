import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton, Avatar, Text, LoadingIndicator } from 'spoton-lib';
import { format } from 'date-fns';

import { IMediaImage } from 'features/dashboard/api/Media.types';
import { IMediaFullScreenTypes } from 'features/dashboard/types/MediaFullScreenTypes';
import {
    deleteMenuPhoto,
    deleteMenuPhotosLoadingStatus,
    hideDeleteModal,
    selectDeleteModalState,
    showDeleteModal,
} from 'features/dashboard/redux';
import { DeleteModal } from 'features/common/components/DeleteModal';

import styles from './MediaFullScreen.module.scss';

export default function MediaFullScreen({
    imageData,
    imagesData,
    pageToken,
    closeModal,
    totalMediaCount,
}: IMediaFullScreenTypes): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [image, setImage] = useState<IMediaImage>({ ...imageData });
    const [isLeftArrowVisible, setIsLeftArrowVisible] = useState<boolean>(
        imageData?.imageIndex !== 0,
    );
    const [isRightArrowVisible, setIsRightArrowVisible] = useState<boolean>(true);

    const isLoadingPhoto = useSelector(deleteMenuPhotosLoadingStatus);
    const isShowingDeleteModal = useSelector(selectDeleteModalState);

    const previousImage = function () {
        const nextIndex = (image.imageIndex || 0) - 1;
        if (image.imageIndex === 1) {
            setIsLeftArrowVisible(false);
        }

        if (!imagesData[nextIndex]) {
            return;
        }

        setIsRightArrowVisible(true);
        setImage({ ...imagesData[nextIndex], imageIndex: nextIndex });
    };

    const nextImage = function () {
        if (isLoadingPhoto) {
            return;
        }

        const nextIndex = (image.imageIndex || 0) + 1;

        if (!imagesData[nextIndex]) {
            if (pageToken) {
                setIsRightArrowVisible(false);
            }

            return;
        }
        setIsLeftArrowVisible(true);
        setImage({ ...imagesData[nextIndex], imageIndex: nextIndex });
    };

    const closeFullScreenModal = () => {
        closeModal();
    };

    const openDeleteModal = useCallback(() => {
        dispatch(showDeleteModal());
    }, [dispatch]);

    const closeDeleteModal = useCallback(() => {
        dispatch(hideDeleteModal());
    }, [dispatch]);

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const deleteItem = () => {
        setIsProcessing(true);
        dispatch(deleteMenuPhoto(image.mediaId));
        if (!isLoadingPhoto) {
            nextImage();
            closeDeleteModal();
            setIsProcessing(false);
        }
    };

    return (
        <div className={styles.MediaFullScreenOverLay}>
            <div className={styles.MediaFullScreenHeader}>
                <div className={styles.MediaFullScreenHeaderUserInfoWrapper}>
                    <Avatar
                        className={styles.MediaFullScreenAvatar}
                        initials="SO"
                        photoUrl={
                            image.source === 'OWNER'
                                ? ''
                                : image.attribution?.profilePhotoUrl
                        }
                    />
                    <span
                        className={
                            image.source === 'OWNER'
                                ? styles.MediaFullScreenOwnerBadge
                                : styles.MediaFullScreenUserName
                        }
                    >
                        <Text
                            className={styles.MediaFullScreenUserNameText}
                            type="p"
                        >
                            {image.source === 'OWNER'
                                ? t('menuPhotos.owner')
                                : image.attribution?.profileName}
                        </Text>
                        <Text className={styles.MediaFullScreenCreateDateText}>
                            {format(new Date(image.createTime), 'MMM dd, yyyy')}
                        </Text>
                    </span>
                </div>
                <div className={styles.ControlIconsWrapper}>
                    <IconButton
                        className={styles.MediaFullScreenDeleteIcon}
                        name="DeleteIcon"
                        variant="secondary"
                        color="#ffffff"
                        size={80}
                        disableBorder={true}
                        alt="Delete Icon"
                        onClick={openDeleteModal}
                    />

                    <IconButton
                        className={styles.MediaFullScreenCloseIcon}
                        name="CloseIcon"
                        variant="secondary"
                        color="#ffffff"
                        size={80}
                        disableBorder={true}
                        alt="Close Icon"
                        onClick={closeFullScreenModal}
                    />
                </div>
            </div>
            <div className={styles.MediaFullScreenContent}>
                <div className={styles.MediaFullScreenLeftArrow}>
                    {isLeftArrowVisible && (
                        <IconButton
                            buttonSize="regular"
                            size={60}
                            variant="secondary"
                            disableBorder
                            alt="Left Icon"
                            name="ChevronLeftIcon"
                            color="#ffffff"
                            onClick={previousImage}
                        />
                    )}
                </div>
                <div className={styles.MediaFullScreenMedia}>
                    {isLoadingPhoto && <LoadingIndicator />}
                    {!isLoadingPhoto && image.mediaFormat === 'PHOTO' && (
                        <img
                            src={image.googleUrl}
                            alt="alt"
                            className={styles.MediaFullScreenImage}
                        />
                    )}
                </div>
                <div className={styles.MediaFullScreenRightArrow}>
                    {isRightArrowVisible && (
                        <IconButton
                            alt="Right Icon"
                            buttonSize="regular"
                            size={60}
                            variant="secondary"
                            disableBorder
                            color="#ffffff"
                            name="ChevronRightIcon"
                            onClick={nextImage}
                        />
                    )}
                </div>
            </div>
            <div className={styles.MediaFullScreenFooter}>
                <Text className={styles.MediaFullScreenFooterCopyRightText} type="p">
                    {t('menuPhotos.copyright')}
                </Text>
                <Text className={styles.MediaFullScreenFooterCountText}>
                    {(image.imageIndex || 0) + 1} / {totalMediaCount}
                </Text>
            </div>
            {isShowingDeleteModal && (
                <DeleteModal
                    closeModal={closeDeleteModal}
                    confirmDelete={deleteItem}
                    deleteMessage={t(
                        `menuPhotos.mediaDeleteMessage.${image.mediaFormat}`,
                    )}
                    showWarning={true}
                    isProcessing={isProcessing}
                />
            )}
        </div>
    );
}
