// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchValueContainer_ValueContainer_icon__1KTcC{position:absolute;top:50%;left:19px;transform:translateY(-50%)}.SearchValueContainer_ValueContainer__2mnCF .SearchValueContainer_Option_info__w829M{display:none}\n", "",{"version":3,"sources":["webpack://src/features/common/components/MultiSelectDropdown/components/SearchValueContainer/SearchValueContainer.module.scss"],"names":[],"mappings":"AACI,iDACI,iBAAkB,CAClB,OAAQ,CACR,SAAU,CACV,0BAA2B,CALnC,qFAUY,YAAa","sourcesContent":[".ValueContainer {\n    &_icon {\n        position: absolute;\n        top: 50%;\n        left: 19px;\n        transform: translateY(-50%);\n    }\n\n    .Option {\n        &_info {\n            display: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ValueContainer_icon": "SearchValueContainer_ValueContainer_icon__1KTcC",
	"ValueContainer": "SearchValueContainer_ValueContainer__2mnCF",
	"Option_info": "SearchValueContainer_Option_info__w829M"
};
export default ___CSS_LOADER_EXPORT___;
