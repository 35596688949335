import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { homeUploadMedia } from './home.actions';

const homeUploadMediaSlice = createSlice({
    name: 'homeUpload',
    initialState: {
        loading: false,
        error: false,
    },
    reducers: {
        // standard reducer logic, if local state need to be changed
    },
    extraReducers: {
        [homeUploadMedia.pending]: (state) => {
            state.loading = true;
            state.error = false;
        },
        [homeUploadMedia.fulfilled]: (state) => {
            state.loading = false;
        },
        [homeUploadMedia.rejected]: (state) => {
            state.loading = false;
            state.error = true;
        },
    },
});

export default combineReducers({
    homeUpload: homeUploadMediaSlice.reducer,
});
