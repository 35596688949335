import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { LocationsApi } from 'features/account/api';
import { showDetailedError } from 'utils/messages';

export const checkAccessStatus = createAsyncThunk(
    'locations/checkAccessStatus',
    async (_, { rejectWithValue }) => {
        try {
            const response = await LocationsApi.checkAccessStatus();
            return response.data;
        } catch (error) {
            showDetailedError({
                template: 'errors.connect.checkAccessStatus',
            });
            return rejectWithValue({
                data: (error as AxiosError<any, any>).response?.data,
                status: (error as AxiosError<any, any>).response?.status,
            });
        }
    },
);
