import React from 'react';

import { formatMoney } from 'utils/formatter';
import { Menus } from 'features/dashboard/types';

import styles from './MenuItemsList.module.scss';

interface IProps {
    menus: Menus[];
}

export default function MenuItemsList({ menus }: IProps): JSX.Element {
    return (
        <div>
            {menus.map((menu, index) => (
                <div key={`${menu?.labels[0].displayName}-${index}`}>
                    {menu.sections.map((section, sIndex) => (
                        <div
                            key={`${section.labels[0].displayName}-${sIndex}`}
                            className={styles.MenuItemsBox}
                        >
                            <div className={styles.MenuItemsBoxTitle}>
                                {section.labels.map((label) => label.displayName)}
                            </div>
                            <ul className={styles.MenuItemsBoxList}>
                                {section.items.map((item, iIndex) => (
                                    <li
                                        key={`${item.labels[0].displayName}-${iIndex}`}
                                        className={styles.MenuItemsBoxItem}
                                    >
                                        <div className={styles.MenuItemsBoxItemTop}>
                                            {item.labels.map(
                                                (label) => label.displayName,
                                            )}
                                            {item?.attributes?.price && (
                                                <span className={styles.PriceSpan}>
                                                    {formatMoney(
                                                        item.attributes.price,
                                                    )}
                                                </span>
                                            )}
                                        </div>

                                        <div
                                            className={
                                                styles.MenuItemsBoxItemDescription
                                            }
                                        >
                                            {item.labels.map(
                                                (label) => label.description,
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
