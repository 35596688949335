import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';

initializeFaro({
    url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/af5c4e591f8d884aeaecff2797d0d2e4',
    app: {
        name: 'google-my-business-web',
        version: '1.0.0',
        environment: 'staging',
    },
    isolate: true,
    instrumentations: [...getWebInstrumentations()],
});
