import React, { ChangeEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { ImageWithActions, Icon, DropZone } from 'spoton-lib';

import { propTypes } from './ImagePhotosSelector.types';

import VideoThumbnailImage from 'features/common/assets/videoThumbnail.png';

import styles from './ImagePhotosSelector.module.scss';

class ImageSelector extends React.Component<any> {
    static displayName = 'ImageSelector';
    static propTypes = propTypes;
    static defaultProps = { images: [] };

    inputRef = React.createRef<HTMLInputElement>();

    handleUploadClick = (): void => {
        this.inputRef.current?.click();
    };

    handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const { onAddImages } = this.props;
        const files = e.target?.files;
        if (files && files.length > 0) {
            onAddImages(files);
        }
    };

    renderImagePicker = (): JSX.Element => {
        const { images, t } = this.props;
        let classModifier = '';
        const pickerText = t('menuPhotos.imageFormat');
        if (images.length > 0) {
            classModifier = styles.ImagePicker___compact;
        }

        return (
            <div
                className={`
                    ${styles.ImagePicker}
                    ${classModifier}
                `}
                onClick={this.handleUploadClick}
                role="button"
                tabIndex={0}
                onKeyPress={() => null}
            >
                <input
                    ref={this.inputRef}
                    type="file"
                    data-testid="file-input"
                    multiple
                    accept="image/jpeg,image/png,video/*"
                    className={styles.ImagePicker_input}
                    onChange={this.handleChange}
                />
                <Icon
                    className={styles.ImagePicker_icon}
                    name="ImageIcon"
                    size={30}
                    alt={t('menuPhotos.selectMedia')}
                />
                <p className={styles.ImagePicker_title}>
                    {t('menuPhotos.mediaInvite')}
                </p>
                <p className={styles.ImagePicker_label}>{pickerText}</p>
                {!images.length && (
                    <p className={styles.ImagePicker_label}>
                        {t('menuPhotos.imageFormatExt')}
                    </p>
                )}
                <div className={styles.ImagePicker_video}>
                    <p className={styles.ImagePicker_label}>
                        {t('menuPhotos.photoQuality')}
                    </p>
                </div>
            </div>
        );
    };

    renderImageAtIndex = (index: number): JSX.Element => {
        const { images, onRemoveImage, t } = this.props;

        const item = images[index];
        const classModifier = '';
        const isVideo = item.imgUrl.slice(5, 10) === 'video';

        const actions = [
            {
                title: t('delete'),
                iconName: 'DeleteIcon',
                iconAlt: t('delete'),
                onClick: (): void => onRemoveImage(index),
                className: styles.ActionButton,
            },
        ];

        return (
            <ImageWithActions
                key={item.imgAlt + index}
                label={''}
                className={`
                    ${styles.ImageSelector_image}
                    ${classModifier}
                `}
                actions={actions}
                imgUrl={isVideo ? VideoThumbnailImage : item.imgUrl}
                imgAlt={item.imgAlt}
            />
        );
    };

    render(): JSX.Element {
        const { onAddImages, images, className } = this.props;
        return (
            <div
                className={`
                    ${styles.ImageSelector}
                    ${styles.ImageSelector___grid}
                    ${className}
                `}
            >
                <DropZone onDrop={onAddImages} className={styles.DropZone}>
                    <div className={`${styles.Row} ${styles.Row___fullHeight}`}>
                        {this.renderImagePicker()}
                        {images.length > 0 && (
                            <div className={styles.Row}>
                                {images.map((_: any, idx: number) =>
                                    this.renderImageAtIndex(idx),
                                )}
                            </div>
                        )}
                    </div>
                </DropZone>
            </div>
        );
    }
}

export default withTranslation()(ImageSelector);
