import i18n from 'i18n.config';
import { Money } from '../features/dashboard/types';

export function formatNumber(value: number): string {
    return new Intl.NumberFormat(i18n.language).format(value);
}

export function formatMoney(money: Money): string {
    const dollars = parseInt(money.units) || '0';
    const cents = Math.round(money.nanos / 10000000) || '00';
    return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: money.currencyCode,
    }).format(parseFloat(`${dollars}.${cents}`));
}
