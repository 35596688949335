import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { MenuItemsApi, MenuPhotosApi } from 'features/dashboard/api/index';
import i18n from 'i18n.config';
import { IPostMenuLinks } from '../../api/Post.types';
import { showDetailedError, showSuccess } from '../../../../utils/messages';

interface IFetchMenuItemsArgs {
    readMask?: string;
}

export const fetchMenuItems: any = createAsyncThunk(
    'dashboard/fetchMenuItems',
    async (args: IFetchMenuItemsArgs, thunkAPI) => {
        const { readMask } = args;
        try {
            const response = await MenuItemsApi.getMenuItems(readMask);
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.dashboard.menuItemsFetch',
                error: data,
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);

export const fetchMenuLinks: any = createAsyncThunk(
    'dashboard/fetchMenuLinks',
    async (_, thunkAPI) => {
        try {
            const response = await MenuItemsApi.getMenuLinks();
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.dashboard.menuLinksFetch',
                error: data,
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);

export const updateMenuLinks: any = createAsyncThunk(
    'dashboard/updateMenuLinks',
    async (data: IPostMenuLinks, thunkAPI) => {
        try {
            const response = await MenuItemsApi.updateMenuLinks(data);
            showSuccess({
                title: i18n.t('editsSaved'),
                message: i18n.t('timeToUpdate'),
            });
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.dashboard.menuLinksUpdate',
                error: data,
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);

export const getMenuPhotos: any = createAsyncThunk(
    'dashboard/getMenuPhoto',
    async (pageToken: string | null, thunkAPI) => {
        try {
            const response = await MenuPhotosApi.getMenuImages(100, pageToken);
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.menuPhotosFetch',
                error: data,
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);

export const addMenuPhoto: any = createAsyncThunk(
    'dashboard/addMenuPhoto',
    async (data: File, thunkAPI) => {
        try {
            const response = await MenuPhotosApi.postMenuImages(data);
            showSuccess({ title: '', message: i18n.t('success') });
            thunkAPI.dispatch(getMenuPhotos());
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.dashboard.menuPhotosUpload',
                error: data,
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);

export const deleteMenuPhoto: any = createAsyncThunk(
    'dashboard/deleteMenuPhoto',
    async (id: string, thunkAPI) => {
        try {
            const response = await MenuPhotosApi.deleteMenuImage(id);
            thunkAPI.dispatch(getMenuPhotos());
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.dashboard.menuPhotosDelete',
                error: data,
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);
