import React from 'react';
import classNames from 'classnames';
import { Icon, Tooltip } from 'spoton-lib';

import styles from './InfoTooltip.module.scss';

interface IProps {
    tooltipText: string | React.ReactElement;
    variant?: any;
    className?: string;
    tooltipClassName?: string;
    iconClassName?: string;
}

export default function InfoTooltip({
    tooltipText,
    variant = 'bottom',
    className,
    tooltipClassName,
    iconClassName,
}: IProps): JSX.Element {
    return (
        <Tooltip
            tooltipContent={tooltipText}
            variant={variant}
            className={classNames(className)}
            tooltipClassName={classNames(
                { [styles.TooltipContentBottom]: variant === 'bottom' },
                tooltipClassName,
            )}
        >
            <Icon
                name="InformativeIcon"
                alt="Information"
                size={14}
                className={classNames(styles.TooltipIcon, iconClassName)}
            />
        </Tooltip>
    );
}
