import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import { IMediaResponse } from './Media.types';

export function getImages(
    filter: string,
    pageSize: number,
    pageToken?: string | null,
): Promise<AxiosResponse<IMediaResponse>> {
    return axios.get('/api/location/media/', {
        params: { filter, pageSize, ...(!!pageToken && { pageToken }) },
    });
}

// eslint-disable-next-line
export function postImages(image: any): Promise<AxiosResponse<null>> {
    const formData = new FormData();
    formData.append('media', image, image.name);
    return axios.post('/api/location/media/', formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
}

export function deleteMedia(id: string): Promise<AxiosResponse<null>> {
    return axios.delete(`/api/location/media/${id}`);
}
