import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import {
    ICreateAnswer,
    IQuestionsPayload,
    IDeleteQuestion,
} from 'features/dashboard/types/QuestionsSectionTypes';

export function getQuestions(
    payload: IQuestionsPayload,
): Promise<AxiosResponse<IQuestionsPayload>> {
    return axios.get<any>(`/api/location/questions`, {
        params: payload,
    });
}

export function createAnswer(
    payload: ICreateAnswer,
): Promise<AxiosResponse<ICreateAnswer>> {
    const { id, text } = payload;
    return axios.post<any>(`/api/location/question/${id}/answer`, { text });
}

export function deleteAnswer({
    questionId,
}: IDeleteQuestion): Promise<AxiosResponse<any>> {
    return axios.delete(`/api/location/question/${questionId}/answer`);
}
