import React from 'react';

import styles from './QuickActionItem.module.scss';

interface IProps {
    title: string;
    onClick: () => void;
}

export default function QuickActionItem({ title, onClick }: IProps): JSX.Element {
    return (
        <div aria-hidden="true" className={styles.QuickActionItem} onClick={onClick}>
            {title}
        </div>
    );
}
