import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator, Text, Icon, Button, Modal } from 'spoton-lib';

import { Widget } from 'features/common/components';
import {
    selectMenuPhotosData,
    showPhotoExampleModal,
    showViewAllModal,
    hideMenuPhotosModal,
    hidePhotoExampleModal,
    hideViewAllModal,
    selectMenuPhotosModalState,
    selectPhotoExampleModalState,
    selectViewAllModalState,
    selectMenuPhotosErrorStatus,
    selectMenuPhotosLoadingStatus,
    getMenuPhotos,
    uploadMenuPhotosLoadingStatus,
    uploadMenuPhotosErrorStatus,
} from 'features/dashboard/redux';

import { MenuPhotosContent } from '../components/MenuPhotosContent';
import { AddImageContainer } from '../components/AddImageContainer/AddImageContainer.component';
import { MenuPhotosUpload } from '../components/MenuPhotosUpload';
import { PhotoExample } from '../components/PhotoExample';
import { ViewAllContent } from '../components/ViewAllContent';

import serverErrorImage from 'features/dashboard/assets/serverError.png';
import examplePhotos from 'features/dashboard/assets/examplePhotos.png';

import styles from './MenuPhotos.module.scss';

export default function MenuPhotos(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isError = useSelector(selectMenuPhotosErrorStatus);
    const isLoading = useSelector(selectMenuPhotosLoadingStatus);
    const menuPhotos = useSelector(selectMenuPhotosData);

    const shouldShowLoading = isLoading && !isError;
    const shouldShowContent = !isLoading && !isError;

    const isMenuPhotosModal = useSelector(selectMenuPhotosModalState);
    const isSeePhotoExampleModal = useSelector(selectPhotoExampleModalState);
    const isViewAllModal = useSelector(selectViewAllModalState);

    const isUploadPhotoLoading = useSelector(uploadMenuPhotosLoadingStatus);
    const isUploadPhotoError = useSelector(uploadMenuPhotosErrorStatus);

    useEffect(() => {
        dispatch(getMenuPhotos());
    }, [dispatch]);

    const onCloseMenuPhotosModal = useCallback(() => {
        dispatch(hideMenuPhotosModal());
    }, [dispatch]);

    useEffect(() => {
        if (!isUploadPhotoLoading && !isUploadPhotoError) {
            onCloseMenuPhotosModal();
        }
    }, [isUploadPhotoLoading, isUploadPhotoError]);

    const onClosePhotoExampleModal = useCallback(() => {
        dispatch(hidePhotoExampleModal());
    }, [dispatch]);

    const onCloseViewAllModal = useCallback(() => {
        dispatch(hideViewAllModal());
    }, [dispatch]);

    useEffect(() => {
        if (menuPhotos.mediaItems.length === 0) {
            onCloseViewAllModal();
        }
    }, [dispatch, menuPhotos.mediaItems.length]);

    const onClickSeeExamples = useCallback(() => {
        dispatch(showPhotoExampleModal());
    }, [dispatch]);

    const onClickViewAll = useCallback(() => {
        dispatch(showViewAllModal());
    }, [dispatch]);

    const onUpdateMediaList = useCallback(() => {
        dispatch(getMenuPhotos());
    }, [dispatch]);

    return (
        <>
            <Widget className={styles.MenuPhotos}>
                <div className={styles.CardHeader}>
                    <div className={styles.CardHeaderTitle}>
                        {t('menuPhotos.title')}
                    </div>
                    <Text type="p" className={styles.CardHeaderSubTitle}>
                        {t('menuPhotos.subTitle')}
                    </Text>
                </div>

                {shouldShowLoading && (
                    <div className={styles.Loader}>
                        <LoadingIndicator />
                    </div>
                )}

                {shouldShowContent && (
                    <>
                        <div className={styles.CardBody}>
                            <AddImageContainer />
                            <MenuPhotosContent
                                menuPhotos={menuPhotos}
                                onUpdateMediaList={onUpdateMediaList}
                            />
                            {!menuPhotos.mediaItems?.length && (
                                <div
                                    className={styles.ExamplesContainer}
                                    aria-hidden="true"
                                    onClick={onClickSeeExamples}
                                >
                                    <img src={examplePhotos} alt={examplePhotos} />
                                    <span>{t('menuPhotos.seeExamples')}</span>
                                </div>
                            )}
                        </div>
                        {menuPhotos.mediaItems?.length !== 0 && (
                            <Button
                                variant="tertiary"
                                onClick={onClickViewAll}
                                className={styles.ViewAllButton}
                            >
                                <>
                                    <Icon
                                        name="ListViewIcon"
                                        alt="ListViewIcon"
                                        size={25}
                                    />
                                    {t('viewAll')}
                                </>
                            </Button>
                        )}
                    </>
                )}

                {isError && (
                    <div className={styles.MenuPhotosContentCenter}>
                        <div className={styles.MenuPhotosErrorWrapper}>
                            <img src={serverErrorImage} alt="good work icon" />
                            <p className={styles.MenuPhotosErrorText}>
                                {t('errors.server')}
                            </p>
                        </div>
                    </div>
                )}
            </Widget>
            <Modal
                ariaHideApp={false}
                isOpen={isMenuPhotosModal}
                onRequestClose={onCloseMenuPhotosModal}
                className={styles.UploadModal}
                overlayClassName={styles.Overlay}
            >
                <MenuPhotosUpload closeModal={onCloseMenuPhotosModal} />
            </Modal>

            <Modal
                ariaHideApp={false}
                isOpen={isSeePhotoExampleModal}
                onRequestClose={onClosePhotoExampleModal}
                className={styles.PhotoExampleModal}
            >
                <PhotoExample closeModal={onClosePhotoExampleModal} />
            </Modal>

            {menuPhotos.mediaItems?.length !== 0 && (
                <Modal
                    ariaHideApp={false}
                    isOpen={isViewAllModal}
                    onRequestClose={onCloseViewAllModal}
                    className={styles.SmallModal}
                >
                    <ViewAllContent closeModal={onCloseViewAllModal} />
                </Modal>
            )}
        </>
    );
}
