import React from 'react';
import clsx from 'clsx';
import { Icon } from 'spoton-lib';

import { useBreakpoint } from 'utils/customHooks';

import { LocationLinkingStatus } from 'features/common/redux/status/status.types';
import { ISublocationOption } from '../../SublocationSelect.component';

import styles from './SublocationOption.module.scss';

/* eslint-disable @typescript-eslint/naming-convention */
const mapStatusToLabel: Record<
    LocationLinkingStatus,
    { label: string; icon: string }
> = {
    NOT_REGISTERED: { label: '', icon: '' },
    OAUTH_COMPLETED: { label: 'Pending', icon: 'SandClockSolidIcon' },
    MATCHING_CONFIRMED: { label: 'Pending', icon: 'SandClockSolidIcon' },
    CLAIM_REQUESTED: { label: 'Pending', icon: 'SandClockSolidIcon' },
    MANUAL_VERIFICATION_REQUIRED: { label: 'Pending', icon: 'SandClockSolidIcon' },
    LINKED: { label: 'Connected', icon: 'CheckIcon' },
    CANCELLED: { label: '', icon: '' },
};

export default function SublocationOption({
    label,
    status,
}: ISublocationOption): JSX.Element {
    const isMobile = useBreakpoint() !== 'desktop';
    const tag = mapStatusToLabel[status];

    return (
        <div className={styles.SublocationOption}>
            <div className={styles.SublocationOption_label}>{label}</div>
            {tag.label ? (
                <div className={styles.SublocationOption_tag}>
                    <div
                        className={clsx(
                            styles.SublocationOption_status,
                            styles[`SublocationOption_status___${status}`],
                        )}
                    >
                        <Icon
                            name={tag.icon}
                            alt={tag.label}
                            size={isMobile ? 10 : 14}
                        />{' '}
                        {tag.label}
                    </div>
                </div>
            ) : null}
        </div>
    );
}
