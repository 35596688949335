// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileSlider_Container__1l9U3{justify-content:center;height:-moz-fit-content;height:fit-content}.MobileSlider_Content__2lAUu{margin:0}.MobileSlider_ActivePage__19GMT{display:block}.MobileSlider_HiddenPage__1mi_b{display:none}.MobileSlider_Row__2ur-V{display:flex;justify-content:flex-end}.MobileSlider_Item__IjQ5z{height:auto}.MobileSlider_DotsContainer__23chp{display:flex;justify-content:center;margin-top:15px}.MobileSlider_ActiveDot__19Lmw{display:inline-block;width:10px;height:10px;margin:0.5rem;background-color:#2382fe;border-radius:50%}.MobileSlider_InactiveDot__2Ie32{display:inline-block;width:10px;height:10px;margin:0.5rem;background-color:#eee;border-radius:50%}\n", "",{"version":3,"sources":["webpack://src/features/common/components/MobileSlider/MobileSlider.module.scss"],"names":[],"mappings":"AAAA,+BACI,sBAAuB,CACvB,uBAAwB,CACxB,kBAAmB,CACtB,6BAGG,QAAS,CACZ,gCAGG,aAAc,CACjB,gCAGG,YAAa,CAChB,yBAGG,YAAa,CACb,wBAAyB,CAC5B,0BAGG,WAAY,CACf,mCAGG,YAAa,CACb,sBAAuB,CACvB,eAAgB,CACnB,+BAGG,oBAAqB,CACrB,UAAW,CACX,WAAY,CACZ,aAAc,CACd,wBAAyB,CACzB,iBAAkB,CACrB,iCAGG,oBAAqB,CACrB,UAAW,CACX,WAAY,CACZ,aAAc,CACd,qBAAsB,CACtB,iBAAkB","sourcesContent":[".Container {\n    justify-content: center;\n    height: -moz-fit-content;\n    height: fit-content;\n}\n\n.Content {\n    margin: 0;\n}\n\n.ActivePage {\n    display: block;\n}\n\n.HiddenPage {\n    display: none;\n}\n\n.Row {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.Item {\n    height: auto;\n}\n\n.DotsContainer {\n    display: flex;\n    justify-content: center;\n    margin-top: 15px;\n}\n\n.ActiveDot {\n    display: inline-block;\n    width: 10px;\n    height: 10px;\n    margin: 0.5rem;\n    background-color: #2382fe;\n    border-radius: 50%;\n}\n\n.InactiveDot {\n    display: inline-block;\n    width: 10px;\n    height: 10px;\n    margin: 0.5rem;\n    background-color: #eee;\n    border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "MobileSlider_Container__1l9U3",
	"Content": "MobileSlider_Content__2lAUu",
	"ActivePage": "MobileSlider_ActivePage__19GMT",
	"HiddenPage": "MobileSlider_HiddenPage__1mi_b",
	"Row": "MobileSlider_Row__2ur-V",
	"Item": "MobileSlider_Item__IjQ5z",
	"DotsContainer": "MobileSlider_DotsContainer__23chp",
	"ActiveDot": "MobileSlider_ActiveDot__19Lmw",
	"InactiveDot": "MobileSlider_InactiveDot__2Ie32"
};
export default ___CSS_LOADER_EXPORT___;
