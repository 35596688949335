import { createReducer, Reducer } from '@reduxjs/toolkit';

import * as verificationActions from './verification.actions';
import { IVerificationTypes } from './verification.types';

export const initialState: IVerificationTypes = {
    currentVerification: null,
    options: [],
    isLoaded: false,
    isError: false,
};

export const verificationReducer: Reducer<IVerificationTypes> = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(
                verificationActions.getVerification.fulfilled,
                (state, action: any) => {
                    state.currentVerification = action.payload.currentVerification;
                    state.options = action.payload.options || [];
                    state.isLoaded = true;
                    state.isError = false;
                },
            )
            .addCase(verificationActions.getVerification.rejected, (state) => {
                state.isLoaded = true;
                state.isError = true;
            })
            .addCase(
                verificationActions.postVerification.fulfilled,
                (state, action: any) => {
                    state.currentVerification = action.payload.verification;
                    state.isError = false;
                },
            )
            .addCase(verificationActions.postVerification.rejected, (state) => {
                state.isError = true;
            })
            .addDefaultCase((state) => {
                return state;
            });
    },
);

export default verificationReducer;
