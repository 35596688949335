import React, { useEffect, useState } from 'react';
import { useCurrentRoute, useNavigation, View } from 'react-navi';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOverlay } from 'spoton-lib';

import { getStatus } from 'features/common/redux/status/status.actions';
import { MERCHANT_TYPE } from 'features/common/redux/status/status.types';
import {
    selectStatus,
    selectStatusErrorState,
    selectStatusLoadedState,
} from 'features/common/redux/status/status.selectors';
import { paths } from 'app/routes/app.routes';
import { getRedirectPath } from 'app/components/App/App.redirect';
import { getPathnameWithQueryParams } from 'app/components/App/App.utils';

import { axios, getConfigVar } from './features';

// Global styles
import './index.css';

// const isSSOEnabled = getConfigFlag('REACT_APP_SSO_ENABLED');

export function App(): JSX.Element {
    const dispatch = useDispatch();

    const navigation = useNavigation();
    const currentRoute = useCurrentRoute();

    const status = useSelector(selectStatus);

    const isStatusLoaded = useSelector(selectStatusLoadedState);
    const isStatusError = useSelector(selectStatusErrorState);

    const sublocationMerchantId =
        currentRoute.url.query?.['sublocation-merchant-id'];

    useEffect(() => {
        if (sublocationMerchantId) {
            axios.defaults.headers.common['X-SUBLOCATION-MERCHANT-ID'] =
                sublocationMerchantId;
        }
        dispatch(getStatus());
    }, []);

    const [isRedirectRequired, setIsRedirectRequired] = useState<boolean>(true);

    const isRedirectEnabled = getConfigVar('REACT_APP_REDIRECT_ENABLED') === 'true';

    useEffect(() => {
        if (!isRedirectEnabled) {
            setIsRedirectRequired(false);
            return;
        }
        if (isStatusError) {
            navigation.navigate(paths.error);
            setIsRedirectRequired(false);
        }
        if (
            status.merchantInfo.type === MERCHANT_TYPE.ROOT &&
            !sublocationMerchantId
        ) {
            navigation.navigate(paths.sublocationSelect);
            setIsRedirectRequired(true);
        }
        if (!status.statuses.connectFlow || !isRedirectRequired) {
            return;
        }
        const redirectPath = getRedirectPath(status.statuses.connectFlow);
        if (redirectPath) {
            navigation.navigate(getPathnameWithQueryParams(redirectPath));
        }
        setIsRedirectRequired(false);
    }, [status]);

    return isStatusLoaded ? <View /> : <LoadingOverlay hasBackgroundBlur={true} />;
}
