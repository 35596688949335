import React, { useEffect, useState } from 'react';
import { Text, Dropdown, LoadingIndicator, Icon } from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { getChartData } from '../../api/BusinessActivity.data';
import { IMetricValue } from '../../api/BusinessActivity.types';

import styles from './BusinessActivitySection.module.scss';
import ServerErrorImage from 'features/dashboard/assets/serverError.png';
import { showDetailedError } from 'utils/messages';
import InsightsCards from './InsightsCards.component';

interface IBusinessActivityProps {
    isMobile: boolean;
}

export default function BusinessActivitySection({
    isMobile,
}: IBusinessActivityProps): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);
    const [metrics, setMetrics] = useState<Array<IMetricValue>>([]);
    const [isServerError, setIsServerError] = useState(false);
    const [selectValue, setSelectValue] = useState('Month');
    const { t } = useTranslation();

    // TODO: refactor to redux actions
    useEffect(() => {
        setIsLoading(true);
        setIsServerError(false);
        getChartData(selectValue.toLowerCase())
            .then((data) => {
                setMetrics(data.data.metricValues);
            })
            .catch(() => {
                showDetailedError({
                    template: 'errors.dashboard.insightsFetch',
                });
                setIsServerError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [selectValue]);

    const choosePeriod = (period: any): void => {
        setSelectValue(period.value);
    };

    const noData = () => (
        <div className={styles.ContentCenter}>
            <div className={styles.NoDataWrapper}>
                <img src={ServerErrorImage} alt="server error" />
                <p className={styles.NoDataText}>{t('errors.server')}</p>
            </div>
        </div>
    );

    const isNoCards =
        !isLoading && (!metrics || metrics.length === 0 || isServerError);

    return (
        <div className={styles.Wrapper}>
            <div className={isMobile ? styles.MobileContainer : styles.Container}>
                <div className={styles.Header}>
                    <div
                        className={
                            isMobile
                                ? styles.MobileTitleWrapper
                                : styles.TitleWrapper
                        }
                    >
                        <Text className={styles.Title}>
                            {t('dashboard.businessActivity')}
                        </Text>
                    </div>
                    <div className={styles.FilterWrapper}>
                        <Dropdown
                            className={
                                isMobile ? styles.MobileDropdown : styles.Dropdown
                            }
                            isSearchable={false}
                            onChange={choosePeriod}
                            trigger={
                                isMobile ? (
                                    <Icon
                                        name="CalendarIcon"
                                        className={styles.MobileSortBtn}
                                        color="#1769ff"
                                        alt="Options"
                                        size={30}
                                    />
                                ) : undefined
                            }
                            defaultValue={{
                                value: 'Month',
                                label: t('dashboard.month'),
                            }}
                            options={[
                                {
                                    value: 'Week',
                                    label: t('dashboard.week'),
                                },
                                {
                                    value: 'Month',
                                    label: t('dashboard.month'),
                                },
                                {
                                    value: 'Quarter',
                                    label: t('dashboard.quarter'),
                                },
                            ]}
                        />
                    </div>
                </div>
                <div
                    className={cn({
                        [styles.ContentCentered]: isLoading,
                        [styles.Content]: !isMobile,
                        [styles.MobileContent]: isMobile,
                    })}
                >
                    {isLoading && <LoadingIndicator />}
                    {isNoCards && noData()}
                    {!isLoading && !isNoCards && (
                        <InsightsCards
                            metrics={metrics}
                            selectValue={selectValue}
                            isMobile={isMobile}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
