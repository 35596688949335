import { IAdvisorStatus } from 'features/dashboard/types';

export interface IAdvisorState {
    data: IAdvisorStatus;
    loading: boolean;
    error: boolean;
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum PossibleActions {
    UPLOAD_MEDIA = 'UPLOAD_MEDIA',
    CREATE_POST = 'CREATE_POST',
    REPLY_REVIEW = 'REPLY_REVIEW',
    REPLY_QUESTION = 'REPLY_QUESTION',
    UPDATE_WORKING_HOURS = 'UPDATE_WORKING_HOURS',
    UPDATE_PROFILE = 'UPDATE_PROFILE',
    UPDATE_CATEGORY = 'UPDATE_CATEGORY',
}

export enum BadgeLevel {
    BRONZE,
    SILVER,
    GOLD,
}
