import React, { useState, useEffect } from 'react';
import { Modal, Button, IconButton, showToast } from 'spoton-lib';
import ImageDropbox from './ImageDropbox.component';
import InputTitle from './InputTitle.component';
import InputSummary from './InputSummary.component';
import InputAction from './InputAction.component';
import InputSchedule from './InputSchedule.component';
import { PostApi } from 'features/dashboard/api';
import { IPostEventSchedule } from 'features/dashboard/api/Post.types';

import { useTranslation } from 'react-i18next';

import styles from './SmallModal.module.scss';
import { useBreakpoint } from '../../../../../utils/customHooks';
import { showDetailedError } from 'utils/messages';

interface IPostSmallModalProps {
    post: any;
    closeModal: () => void;
    refreshTab: (arg: string) => void;
}

export default function PostSmallModal({
    post,
    closeModal,
    refreshTab,
}: IPostSmallModalProps): JSX.Element {
    const { t } = useTranslation();
    const isMobile = useBreakpoint() !== 'desktop';
    const actionType = post.postId ? 'edit' : 'create';

    const [isDisabled, setIsDisabled] = useState<boolean>(actionType === 'edit');
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const [media, setMedia] = useState<Array<any>>(post.media ?? []);
    const [imageFile, setImageFile] = useState<any>();

    const [titleText, setTitleText] = useState<string>(post.event?.title);
    const [schedule, setSchedule] = useState<IPostEventSchedule | undefined>(
        post.event?.schedule,
    );
    const [summaryText, setSummaryText] = useState<string>(post.summary);
    const [actionButton, setActionButton] = useState<string>(
        post.callToAction?.actionType ?? '',
    );
    const [actionUrl, setActionUrl] = useState<string>(post.callToAction?.url);

    const [isValidTitle, setIsValidTitle] = useState<boolean>(true);
    const [isValidSummary, setIsValidSummary] = useState<boolean>(true);
    const [isValidActionUrl, setIsValidActionUrl] = useState<boolean>(true);
    const [isValidSchedule, setIsValidSchedule] = useState<boolean>(true);

    const isValidUrl = (url: string | undefined) => {
        const check = url
            ? url.match(
                  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g,
              )
            : null;
        return check !== null;
    };
    const checkValidity = () => {
        const isTitleTextValid =
            post.topicType === 'STANDARD' || titleText ? true : false;
        const isScheduleValid =
            post.topicType === 'STANDARD' ||
            (schedule?.startDate && schedule?.endDate)
                ? true
                : false;
        const isSummaryTextValid =
            post.topicType !== 'STANDARD' || summaryText ? true : false;
        const isActionUrlValid =
            post.topicType === 'OFFER' ||
            actionButton === 'CALL' ||
            actionButton === '' ||
            isValidUrl(actionUrl);

        setIsValidTitle(isTitleTextValid);
        setIsValidSchedule(isScheduleValid);
        setIsValidSummary(isSummaryTextValid);
        setIsValidActionUrl(isActionUrlValid);
        return (
            isTitleTextValid &&
            isScheduleValid &&
            isSummaryTextValid &&
            isActionUrlValid
        );
    };

    const publishPost = () => {
        setIsProcessing(true);
        if (!checkValidity()) {
            setIsProcessing(false);
            return;
        }
        PostApi.createPost(
            post.topicType,
            titleText,
            schedule,
            summaryText,
            actionButton,
            actionUrl,
            imageFile,
        )
            .then(() => {
                showToast({
                    title: t('success'),
                    content: t('dashboard.postCreateSuccess'),
                    variant: 'success',
                    autoClose: 4000,
                });
                refreshTab(post.topicType);
                closeModal();
            })
            .catch((error) => {
                showDetailedError({
                    message: t('errors.dashboard.postCreate'),
                    error: error.response?.data,
                });
                setIsProcessing(false);
            });
    };
    const editPost = () => {
        setIsProcessing(true);
        if (!checkValidity()) {
            setIsProcessing(false);
            return;
        }
        PostApi.editPost(
            post.postId,
            titleText,
            schedule,
            summaryText,
            actionButton,
            actionUrl,
            (imageFile || !media.length) && media,
            imageFile,
        )
            .then(() => {
                showToast({
                    title: t('success'),
                    content: t('dashboard.postEditSuccess'),
                    variant: 'success',
                    autoClose: 4000,
                });
                refreshTab(post.topicType);
                closeModal();
            })
            .catch((error) => {
                showDetailedError({
                    message: t('errors.dashboard.postEdit'),
                    error: error.response?.data,
                });
                setIsProcessing(false);
            });
    };

    useEffect(() => {
        return () => {
            setIsDisabled(false);
        };
    }, [
        titleText,
        summaryText,
        schedule,
        actionButton,
        actionUrl,
        imageFile,
        media,
    ]);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={true}
            className={styles.Modal}
            overlayClassName={styles.Overlay}
        >
            <div className={styles.Heading}>
                <div className={styles.HeadingText}>
                    {t(`dashboard.postModalTitle.${actionType}.${post.topicType}`)}
                </div>
                <IconButton
                    className={styles.CloseIcon}
                    name="CloseIcon"
                    variant="secondary"
                    disableBorder={true}
                    alt="close icon"
                    onClick={closeModal}
                />
            </div>
            <div className={styles.Body}>
                {isProcessing && <div className={styles.ProcessingOverlay}></div>}

                <div className={styles.ImageWrapper}>
                    <ImageDropbox
                        stateMedia={[media, setMedia]}
                        setImageFile={setImageFile}
                    />
                </div>
                <div className={styles.InputWrapper}>
                    {post.topicType !== 'STANDARD' && (
                        <InputTitle
                            topicType={post.topicType}
                            stateTitle={[titleText, setTitleText]}
                            isValid={isValidTitle}
                        />
                    )}
                    {post.topicType !== 'STANDARD' && (
                        <InputSchedule
                            stateSchedule={[schedule, setSchedule]}
                            isValid={isValidSchedule}
                        />
                    )}
                    <InputSummary
                        topicType={post.topicType}
                        stateSummary={[summaryText, setSummaryText]}
                        isValid={isValidSummary}
                    />
                    {post.topicType !== 'OFFER' && (
                        <InputAction
                            stateActionButton={[actionButton, setActionButton]}
                            stateActionUrl={[actionUrl, setActionUrl]}
                            isValid={isValidActionUrl}
                        />
                    )}
                </div>
            </div>
            <div className={styles.Footer}>
                <div className={styles.ButtonContainer}>
                    {!isMobile && (
                        <Button
                            variant="secondary"
                            onClick={closeModal}
                            disabled={isProcessing}
                        >
                            {t(`discard`)}
                        </Button>
                    )}
                    <Button
                        className={styles.ButtonSubmit}
                        variant="primary"
                        block={isMobile}
                        onClick={actionType === 'edit' ? editPost : publishPost}
                        disabled={isProcessing || isDisabled}
                    >
                        {t(`dashboard.postModalSubmit.${actionType}`)}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
