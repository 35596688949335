import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalsState } from './modals.types';
import { ReviewTabs } from 'features/dashboard/home/ReviewsSection/ReviewsTabs.types';

const initialState: IModalsState = {
    mediaSectionModalIsOpen: false,
    mediaSectionSmallModalIsOpen: false,
    reviewSectionModalIsOpen: false,
    reviewSectionModalActiveTab: ReviewTabs.all,
    postsSectionModalIsOpen: false,
    postsSectionModalIsOpenForCreate: false,
    updateBusinessHoursModalIsOpen: false,
    deleteModalIsOpen: false,
    updateBusinessInfoModalIsOpen: false,
    menuPhotosModalIsOpen: false,
    seePhotoExampleIsOpen: false,
    seeViewAllIsOpen: false,
    quickActionModalIsOpen: false,
    questionsModalIsOpen: false,
};

const modalsSlice = createSlice({
    name: 'modals',
    initialState: initialState,
    reducers: {
        showQuestionsModal(state) {
            state.questionsModalIsOpen = true;
        },
        hideQuestionsModal(state) {
            state.questionsModalIsOpen = false;
        },
        showMediaSectionModal(state) {
            state.mediaSectionModalIsOpen = true;
        },
        hideMediaSectionModal(state) {
            state.mediaSectionModalIsOpen = false;
        },
        showMediaSectionSmallModal(state) {
            state.mediaSectionSmallModalIsOpen = true;
        },
        hideMediaSectionSmallModal(state) {
            state.mediaSectionSmallModalIsOpen = false;
        },
        showReviewSectionModal(
            state,
            action?: PayloadAction<ReviewTabs | undefined>,
        ) {
            state.reviewSectionModalIsOpen = true;
            if (action?.payload) {
                state.reviewSectionModalActiveTab = action?.payload;
            }
        },
        hideReviewSectionModal(state) {
            state.reviewSectionModalIsOpen = false;
        },
        showPostsSectionModal(state) {
            state.postsSectionModalIsOpen = true;
        },
        showPostsSectionModalForCreate(state) {
            state.postsSectionModalIsOpenForCreate = true;
        },
        hidePostsSectionModal(state) {
            state.postsSectionModalIsOpen = false;
            state.postsSectionModalIsOpenForCreate = false;
        },
        showBusinessHoursModal(state) {
            state.updateBusinessHoursModalIsOpen = true;
        },
        hideBusinessHoursModal(state) {
            state.updateBusinessHoursModalIsOpen = false;
        },
        showDeleteModal(state) {
            state.deleteModalIsOpen = true;
        },
        hideDeleteModal(state) {
            state.deleteModalIsOpen = false;
        },
        showUpdateBusinessInfoModal(state) {
            state.updateBusinessInfoModalIsOpen = true;
        },
        hideUpdateBusinessInfoModal(state) {
            state.updateBusinessInfoModalIsOpen = false;
        },
        showMenuPhotosModal(state) {
            state.menuPhotosModalIsOpen = true;
        },
        hideMenuPhotosModal(state) {
            state.menuPhotosModalIsOpen = false;
        },
        showPhotoExampleModal(state) {
            state.seePhotoExampleIsOpen = true;
        },
        hidePhotoExampleModal(state) {
            state.seePhotoExampleIsOpen = false;
        },
        showViewAllModal(state) {
            state.seeViewAllIsOpen = true;
        },
        hideViewAllModal(state) {
            state.seeViewAllIsOpen = false;
        },
        showQuickActionModal(state) {
            state.quickActionModalIsOpen = true;
        },
        hideQuickActionModal(state) {
            state.quickActionModalIsOpen = false;
        },
    },
});

export const {
    showQuestionsModal,
    hideQuestionsModal,
    showMediaSectionModal,
    hideMediaSectionModal,
    showMediaSectionSmallModal,
    hideMediaSectionSmallModal,
    showReviewSectionModal,
    hideReviewSectionModal,
    showPostsSectionModal,
    showPostsSectionModalForCreate,
    hidePostsSectionModal,
    showBusinessHoursModal,
    hideBusinessHoursModal,
    showDeleteModal,
    hideDeleteModal,
    showUpdateBusinessInfoModal,
    hideUpdateBusinessInfoModal,
    showMenuPhotosModal,
    hideMenuPhotosModal,
    showPhotoExampleModal,
    hidePhotoExampleModal,
    showViewAllModal,
    hideViewAllModal,
    showQuickActionModal,
    hideQuickActionModal,
} = modalsSlice.actions;

export default modalsSlice.reducer;
