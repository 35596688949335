import { createSlice } from '@reduxjs/toolkit';

import { getStatus, updateStatus } from './status.actions';
import { IStatusTypes } from './status.types';

import { getConfigFlag, getConfigVar } from 'features/common';

/* eslint-disable @typescript-eslint/naming-convention */
export const initialState: IStatusTypes = {
    fsIdentifiers: {
        userId: '',
        Name: '',
        MerchantId: '',
        NameOfBusiness: '',
        isTest: false,
    },
    data: {
        email: '',
        merchantId: '',
        firstName: '',
        lastName: '',
        statuses: {},
        maEnabled: true,
        expressEnabled: false,
        merchantInfo: {
            type: null,
            sublocations: [],
        },
        postsEnabled: true,
        probationPeriod: false,
    },
    isLoaded: false,
    isError: false,
};

const statusSlice = createSlice({
    name: 'status',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [getStatus.pending]: (state) => {
            state.isError = false;
            state.isLoaded = false;
        },
        [getStatus.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.data.postsEnabled =
                action.payload.postsEnabled === false ? false : true;
            state.data.probationPeriod =
                action.payload.probationPeriod === true ? true : false;
            state.fsIdentifiers = {
                userId: action.payload.email,
                Name: action.payload.email,
                MerchantId: action.payload.merchantId,
                NameOfBusiness: action.payload.merchantInfo.NameOfBusiness,
                // we want to determine if the user who is logged in is the actual merchant or a spoton user otherwise isTest
                // will not reflect accurate data when we record prod sessions
                isTest:
                    getConfigFlag('REACT_APP_IS_QA') ||
                    action.payload.email.includes('@spoton.com'),
            };

            state.isLoaded = true;
        },
        [getStatus.rejected]: (state, action) => {
            if (action.payload.status === 403) {
                window.location.href = getConfigVar(
                    'REACT_APP_EXTERNAL_APP_DASHBOARD',
                ) as string;
            } else {
                state.isError = true;
                state.isLoaded = true;
            }
        },
        [updateStatus.toString()]: (state, action) => {
            state.data = action.payload;
            state.fsIdentifiers = {
                userId: action.payload.email,
                Name: action.payload.email,
                MerchantId: action.payload.merchantId,
                NameOfBusiness: action.payload.merchantInfo.NameOfBusiness,
                isTest:
                    getConfigFlag('REACT_APP_IS_QA') ||
                    action.payload.email.includes('@spoton.com'),
            };
        },
    },
});

export default statusSlice.reducer;
