import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCurrentRoute, useNavigation } from 'react-navi';
import { Icon, Tabs, Tooltip, colors } from 'spoton-lib';

import { useBreakpoint } from '../../../utils/customHooks';
import { getPathnameWithQueryParams } from 'app/components/App/App.utils';

import { selectBusinessInfoData, showBusinessHoursModal } from '../redux';

import styles from './TabNavigationSection.module.scss';

interface ITabNavigationSection {
    tabs: Array<any>;
}

export default function TabNavigationSection(
    props: ITabNavigationSection,
): JSX.Element {
    const dispatch = useDispatch();
    const isMobile = useBreakpoint() !== 'desktop';

    const [activeTab, setActiveTab] = useState('dashboard/home');
    const navigation = useNavigation();
    const route = useCurrentRoute();
    const { t } = useTranslation();

    const businessInfo = useSelector(selectBusinessInfoData);
    const mapsUrl = businessInfo?.mapsUrl;
    const searchUrl = businessInfo?.searchUrl;

    useEffect(() => {
        setActiveTab(route.url.pathname);
    }, [route]);

    const onSelect = (name: string) => {
        setActiveTab(name);
        navigation.navigate(getPathnameWithQueryParams(name));
    };

    const showEditModal = useCallback(() => {
        dispatch(showBusinessHoursModal());
    }, [dispatch]);

    return (
        <div className={styles.Wrapper}>
            <div className={styles.TabsWrapper}>
                <Tabs className={styles.Tabs} active={activeTab} onSelect={onSelect}>
                    {props.tabs.map((tab) => (
                        <Tabs.Tab key={tab.tabName} name={tab.tabPath}>
                            <div
                                className={
                                    activeTab === tab
                                        ? styles.ActiveTabText
                                        : styles.TabText
                                }
                            >
                                {tab.tabName}
                            </div>
                        </Tabs.Tab>
                    ))}
                </Tabs>
            </div>
            {!isMobile && (
                <div className={styles.RedirectActionsWrapper}>
                    <Tooltip
                        tooltipContent={t('updateBusinessHours')}
                        variant="bottom"
                        className={styles.TooltipContainer}
                        tooltipClassName={styles.TooltipContent}
                    >
                        <span
                            className={styles.RedirectActionButton}
                            onClick={showEditModal}
                            aria-hidden="true"
                        >
                            <Icon
                                name="TimeIcon"
                                alt={t('updateBusinessHours')}
                                size={18}
                                color={colors.primary50}
                                className={styles.TooltipIcon}
                            />
                        </span>
                    </Tooltip>
                    {mapsUrl && (
                        <Tooltip
                            tooltipContent={t('viewOnGoogleMaps')}
                            variant="bottom"
                            className={styles.TooltipContainer}
                            tooltipClassName={styles.TooltipContent}
                        >
                            <a
                                href={mapsUrl}
                                rel="noreferrer noopener"
                                target="_blank"
                                className={styles.RedirectActionButton}
                            >
                                <Icon
                                    name="LocationIcon"
                                    alt={t('viewOnGoogleMaps')}
                                    size={18}
                                    color={colors.primary50}
                                    className={styles.TooltipIcon}
                                />
                            </a>
                        </Tooltip>
                    )}
                    {searchUrl && (
                        <Tooltip
                            tooltipContent={t('viewOnGoogleSearch')}
                            variant="bottom"
                            className={styles.TooltipContainer}
                            tooltipClassName={styles.TooltipContent}
                        >
                            <a
                                href={searchUrl}
                                rel="noreferrer noopener"
                                target="_blank"
                                className={styles.RedirectActionButton}
                            >
                                <Icon
                                    name="GoogleIcon"
                                    alt={t('viewOnGoogleSearch')}
                                    size={18}
                                    color={colors.primary50}
                                    className={styles.TooltipIcon}
                                />
                            </a>
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    );
}
