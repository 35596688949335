import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { selectStatus } from 'features/common/redux/status/status.selectors';

import { Icon, IconButton, showToast } from 'spoton-lib';
import Menu from 'features/common/components/Menu';

import { useTranslation } from 'react-i18next';
import styles from './PostInsights.module.scss';

interface IPostInsightsProps {
    postUrl: string;
}

export default function PostInsights({ postUrl }: IPostInsightsProps): JSX.Element {
    const { t } = useTranslation();

    const status = useSelector(selectStatus);

    const [isShowingShareMenu, setIsShowingShareMenu] = useState<boolean>(false);

    const toggleShareMenu = () => {
        setIsShowingShareMenu(!isShowingShareMenu);
    };
    const closeShareMenu = () => {
        setIsShowingShareMenu(false);
    };

    const encodedUrl = encodeURIComponent(postUrl);

    const shareViaFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`);
        closeShareMenu();
    };
    const shareViaTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?text=${encodedUrl}`);
        closeShareMenu();
    };
    const shareViaEmail = () => {
        const subject = `See the latest post from ${
            status.merchantInfo?.locationName ?? 'company'
        } on Google`;
        window.open(`mailto:?subject=${subject}&body=${encodedUrl}`);
        closeShareMenu();
    };
    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(postUrl);
        showToast({
            title: t('linkCopied'),
            content: t('dashboard.postLinkCopied'),
            variant: 'success',
            autoClose: 4000,
        });
    };

    return (
        <div className={styles.PostInsights} onMouseLeave={closeShareMenu}>
            <IconButton
                name="ShareIcon"
                alt="share icon"
                variant="secondary"
                disableBorder={true}
                size={20}
                className={styles.PostShareIcon}
                onClick={toggleShareMenu}
            />
            {isShowingShareMenu && (
                <Menu className={styles.Menu}>
                    <Menu.item onClick={shareViaFacebook}>
                        <Icon
                            name="FacebookIcon"
                            alt="facebook icon"
                            size={22}
                            className={styles.MenuItemIcon}
                        />
                        {t('facebook')}
                    </Menu.item>
                    <Menu.item onClick={shareViaTwitter}>
                        <Icon
                            name="TwitterIcon"
                            alt="twitter icon"
                            size={22}
                            className={styles.MenuItemIcon}
                        />
                        {t('twitter')}
                    </Menu.item>
                    <Menu.item onClick={shareViaEmail}>
                        <Icon
                            name="MailIcon"
                            alt="mail icon"
                            size={22}
                            className={styles.MenuItemIcon}
                        />
                        {t('email')}
                    </Menu.item>
                    <Menu.item onClick={copyLinkToClipboard}>
                        <Icon
                            name="LinkIcon"
                            alt="link icon"
                            size={22}
                            className={styles.MenuItemIcon}
                        />
                        {t('copyLink')}
                    </Menu.item>
                </Menu>
            )}
        </div>
    );
}
