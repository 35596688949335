import { createSlice } from '@reduxjs/toolkit';
import {
    fetchBusinessInfo,
    updateBusinessInfo,
} from 'features/dashboard/redux/businessInfo/businessInfo.actions';

const businessInfoSlice = createSlice({
    name: 'businessInfo',
    initialState: {
        data: {
            name: '',
            title: '',
            phoneNumbers: {
                primaryPhone: '',
                additionalPhones: [],
            },
            websiteUri: '',
            categories: {
                primaryCategory: {
                    name: '',
                    displayName: '',
                },
                additionalCategories: [],
            },
            profile: {
                description: '',
            },
            regularHours: {
                periods: [],
            },
            locationState: {
                canUpdate: false,
                canDelete: false,
                isVerified: false,
                isPublished: false,
                canModifyServiceList: false,
            },
            metadata: {
                canDelete: false,
                canHaveFoodMenus: false,
            },
            storefrontAddress: {
                regionCode: '',
                languageCode: '',
                postalCode: '',
                administrativeArea: '',
                locality: '',
                addressLines: [''],
            },
            profileImg: '',
            coverImg: '',
            mapsUrl: '',
            searchUrl: '',
        },
        isInitialLoading: true,
        loading: false,
        error: false,
        savingInfo: false,
        infoSavingError: false,
    },
    reducers: {
        // standard reducer logic, if local state need to be changed
    },
    extraReducers: {
        [fetchBusinessInfo.pending]: (state) => {
            state.loading = true;
            state.error = false;
        },
        [fetchBusinessInfo.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.isInitialLoading = false;
        },
        [fetchBusinessInfo.rejected]: (state) => {
            state.loading = false;
            state.error = true;
            state.isInitialLoading = false;
        },
        [updateBusinessInfo.pending]: (state) => {
            state.savingInfo = true;
            state.infoSavingError = false;
        },
        [updateBusinessInfo.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.savingInfo = false;
        },
        [updateBusinessInfo.rejected]: (state) => {
            state.savingInfo = false;
            state.infoSavingError = true;
        },
    },
});

export default businessInfoSlice.reducer;
