/* eslint-disable react/prop-types */
import React from 'react';
import { Text } from 'spoton-lib';
import { useTranslation } from 'react-i18next';

import {
    IAppDefinedMetricValue,
    IMetricValue,
} from 'features/dashboard/api/BusinessActivity.types';
import { InfoTooltip } from 'features/common/components';
import { MobileSlider } from 'features/common/components/MobileSlider';

import { formatNumber } from 'utils/formatter';

import styles from './BusinessActivitySection.module.scss';
import { makeInsights } from './utils/BusinessActivitySection.utils';

interface IInsightsCardsProps {
    metrics: IMetricValue[];
    isMobile: boolean;
    selectValue: string;
}

interface IInsightsCardItemProps {
    card: IAppDefinedMetricValue;
    isMobile: boolean;
    selectValue: string;
}

const InsightsCard: React.VFC<IInsightsCardItemProps> = ({
    card,
    isMobile,
    selectValue,
}) => {
    const { t } = useTranslation();
    const isDeviationNotZero = card.deviation && card.deviation > 0;

    return (
        <div
            key={card.metric}
            className={isMobile ? styles.MobileCardWrapper : styles.CardWrapper}
        >
            <div className={styles.CardContainer}>
                <Text className={styles.CardTextValue}>
                    {formatNumber(card.value)}
                </Text>
                <div className={styles.CardTextWrapper}>
                    <Text
                        className={
                            isMobile
                                ? styles.MobileCardTextName
                                : styles.CardTextName
                        }
                    >
                        {t(`dashboard.${card.metric}`)}
                    </Text>
                    <InfoTooltip
                        tooltipText={t(`dashboard.tooltips.${card.metric}`)}
                    />
                </div>

                <div className={styles.CardTextName}>
                    {!!card.deviation && (
                        <div className={styles.DeviationWrapper}>
                            <span
                                className={
                                    isDeviationNotZero
                                        ? styles.PositiveArrowUp
                                        : styles.NegativeArrowDown
                                }
                            />
                            <Text
                                className={
                                    isDeviationNotZero
                                        ? styles.PositiveDeviation
                                        : styles.NegativeDeviation
                                }
                            >
                                {isDeviationNotZero
                                    ? `${formatNumber(card.deviation)}% `
                                    : `${formatNumber(Math.abs(card.deviation))}% `}
                                {t(`dashboard.fromLast${selectValue}`)}
                            </Text>
                        </div>
                    )}
                    {card.deviation === 0 && (
                        <div className={styles.DeviationWrapper}>
                            <Text className={styles.NeutralDeviation}>
                                {`${t(
                                    'dashboard.sameAsLast',
                                )} ${selectValue.toLowerCase()}`}
                            </Text>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const InsightsCards: React.VFC<IInsightsCardsProps> = ({
    isMobile,
    metrics,
    selectValue,
}) => {
    const preparedMetrics = makeInsights(metrics).map((card) => (
        <InsightsCard
            key={card.metric}
            card={card}
            isMobile={isMobile}
            selectValue={selectValue}
        />
    ));

    return isMobile ? (
        <MobileSlider className={styles.Slider} itemsPerRow={1} itemsPerColumn={2}>
            {preparedMetrics}
        </MobileSlider>
    ) : (
        <>{preparedMetrics}</>
    );
};

export default InsightsCards;
