import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, IconButton, Modal, Switch, LoadingOverlay } from 'spoton-lib';
import { noop } from 'lodash';

import { OpenHoursEdit } from './OpenHoursEdit/OpenHoursEdit';
import { CloseHoursEdit } from './CloseHoursEdit/CloseHoursEdit';
import {
    fetchBusinessInfo,
    hideBusinessHoursModal,
    selectBusinessHoursModalState,
    selectBusinessInfoData,
    selectBusinessInfoSavingStatus,
    updateBusinessInfo,
} from 'features/dashboard/redux';

import styles from './BusinessHoursModal.module.scss';
import {
    EditableRegularHoursPeriod,
    RegularHoursPeriod,
} from 'features/dashboard/types';
import {
    FULL_DAY,
    MIDNIGHT,
    MIDNIGHT_24,
    normalizeCombineNightHours,
    normalizeSplitNightHours,
} from 'utils/periods.utils';

export default function BusinessHoursModal(): any {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [shouldShowClosedHours, setShouldShowClosedHours] = useState(false);
    const [periods, setPeriods] = useState<RegularHoursPeriod[]>([]);

    const methods = useForm();

    const isModalOpen = useSelector(selectBusinessHoursModalState);
    const info = useSelector(selectBusinessInfoData);
    const isSavingInfo = useSelector(selectBusinessInfoSavingStatus);

    const handleShowClosedHours = () => setShouldShowClosedHours((prev) => !prev);

    const handleCloseModal = () => dispatch(hideBusinessHoursModal());

    useEffect(() => {
        setPeriods(normalizeCombineNightHours(info.regularHours?.periods));
    }, [info]);

    useEffect(() => {
        if (isModalOpen && !info) {
            dispatch(fetchBusinessInfo());
        }
    }, [dispatch, isModalOpen, info]);

    const onSubmit = (data: { periods: EditableRegularHoursPeriod[] }) => {
        const openedPeriods = data.periods.filter((period) => period.open);
        const periodsPayload = normalizeSplitNightHours(
            openedPeriods.map((period) => ({
                ...period,
                combinedOpenTime:
                    period.combinedOpenTime === FULL_DAY
                        ? MIDNIGHT
                        : period.combinedOpenTime,
                combinedCloseTime:
                    period.combinedOpenTime === FULL_DAY
                        ? MIDNIGHT_24
                        : period.combinedCloseTime,
            })),
        );

        dispatch(
            updateBusinessInfo({
                formInfo: {
                    regularHours: {
                        periods: periodsPayload,
                    },
                },
            }),
        );
    };

    useEffect(() => {
        !isSavingInfo && isModalOpen && handleCloseModal();
    }, [isSavingInfo]);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            className={styles.Modal}
            overlayClassName={styles.Overlay}
        >
            <div className={styles.ModalHeader}>
                <span>{t('profile.businessHours.editBusinessHours')}</span>
                <IconButton
                    name="CloseIcon"
                    alt={t('close')}
                    buttonSize="regular"
                    size={36}
                    variant="secondary"
                    disableBorder
                    className={styles.CloseIcon}
                    onClick={handleCloseModal}
                />
            </div>
            <div className={styles.FormViewport}>
                {isSavingInfo && (
                    <LoadingOverlay
                        className={styles.Spinner}
                        greyAnimation
                        size="md"
                    />
                )}
                <form
                    className={styles.UpdateBusinessHoursModalForm}
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <div className={styles.ModalBody}>
                        <span className={styles.InfoTitle}>
                            <Icon
                                name="InformativeIcon"
                                alt="informativeIcon"
                                size={24}
                            />
                            {t('profile.businessHours.changesConfirmation')}
                        </span>

                        <div className={styles.TemporarilyClosed}>
                            <span>{t('profile.businessHours.temporaryClosed')}</span>
                            <Switch
                                checked={shouldShowClosedHours}
                                onChange={handleShowClosedHours}
                            />
                        </div>

                        {shouldShowClosedHours ? (
                            <CloseHoursEdit />
                        ) : (
                            <OpenHoursEdit periods={periods} methods={methods} />
                        )}
                    </div>
                    <div className={styles.ModalFooter}>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            {t(`discard`)}
                        </Button>
                        <Button
                            type="submit"
                            className={styles.ButtonSubmit}
                            variant="primary"
                            onClick={noop}
                        >
                            {t('saveChanges')}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}
