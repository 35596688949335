import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { event } from 'react-fullstory';

import { VerificationApi } from 'features/account/api';
import { showDetailedError } from 'utils/messages';
import { selectFsIdentifiers } from 'features/common/redux/status/status.selectors';
import { RootState } from 'reduxConfig';

import { TVerificationMethod } from './verification.types';

export const getVerification = createAsyncThunk(
    'verification/getVerification',
    async (_, { rejectWithValue }) => {
        try {
            const response = await VerificationApi.getVerification();
            return response.data;
        } catch (error) {
            showDetailedError({
                template: 'errors.connect.getVerificationMethods',
            });
            return rejectWithValue({
                data: (error as AxiosError<any, any>).response?.data,
                status: (error as AxiosError<any, any>).response?.status,
            });
        }
    },
);

export const postVerification = createAsyncThunk(
    'verification/postVerification',
    async (
        {
            input,
            method,
            showCode,
        }: { method: TVerificationMethod; input: string; showCode: () => void },
        { rejectWithValue, getState },
    ) => {
        const store = getState();
        const fsIdentifiers = selectFsIdentifiers(store as RootState);

        try {
            event('manual_verification_start', {
                ...fsIdentifiers,
                verification_option: method,
            } as { [property: string]: string });

            const response = await VerificationApi.postVerification(method, input);

            event('manual_verification_success', {
                ...fsIdentifiers,
                verification_option: method,
            } as { [property: string]: string });

            showCode();

            return response.data;
        } catch (error) {
            event(
                'manual_verification_failed',
                fsIdentifiers as { [property: string]: string },
            );
            showDetailedError({
                template: 'errors.connect.startVerification',
            });
            return rejectWithValue({
                data: (error as AxiosError<any, any>).response?.data,
                status: (error as AxiosError<any, any>).response?.status,
            });
        }
    },
);
