export interface IChartDataResponse {
    startTime: string;
    endTime: string;
    metricValues: Array<IMetricValue>;
}
export const GBP_AVAILABLE_METRICS = [
    'DAILY_METRIC_UNKNOWN',
    'BUSINESS_IMPRESSIONS_DESKTOP_MAPS',
    'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH',
    'BUSINESS_IMPRESSIONS_MOBILE_MAPS',
    'BUSINESS_IMPRESSIONS_MOBILE_SEARCH',
    'BUSINESS_CONVERSATIONS',
    'BUSINESS_DIRECTION_REQUESTS',
    'CALL_CLICKS',
    'WEBSITE_CLICKS',
    'BUSINESS_BOOKINGS',
    'BUSINESS_FOOD_MENU_CLICKS',
] as const;

export type TMetric = typeof GBP_AVAILABLE_METRICS[number];

export const APP_DEFINED_METRICS = [
    'BUSINESS_IMPRESSIONS',
    'BUSINESS_DIRECTION_REQUESTS',
    'WEBSITE_CLICKS',
    'CALL_CLICKS',
] as const;

export type TAppDefinedMetric = typeof APP_DEFINED_METRICS[number];
export interface IMetricValue {
    metric: TMetric;
    value: number;
    deviation: number | null;
    sortIndex?: number;
}

export interface IAppDefinedMetricValue {
    metric: TAppDefinedMetric;
    value: number;
    deviation: number | null;
    sortIndex?: number;
}
