import { createSlice } from '@reduxjs/toolkit';
import { fetchAdvisorStatus } from './advisor.actions';
import { IAdvisorState } from './advisor.types';

const initialLanguge = {
    todo: [],
    badges: {
        media: {
            grade: '',
            tooltip: '',
        },

        posts: {
            grade: '',
            tooltip: '',
        },
        customerService: {
            grade: '',
            tooltip: '',
        },
    },
};
const initialState: IAdvisorState = {
    data: {
        languages: {
            en: { ...initialLanguge },
            es: { ...initialLanguge },
        },
    },
    loading: false,
    error: false,
};

const advisorSlice = createSlice({
    name: 'advisor',
    initialState: initialState,
    reducers: {
        // standard reducer logic, if local state need to be changed
    },
    extraReducers: {
        [fetchAdvisorStatus.pending]: (state) => {
            state.loading = true;
            state.error = false;
        },
        [fetchAdvisorStatus.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [fetchAdvisorStatus.rejected]: (state) => {
            state.loading = false;
            state.error = true;
        },
    },
});

export default advisorSlice.reducer;
