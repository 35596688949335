import { getCookieValue } from './cookies.utils';

export const checkAuth = (): boolean => {
    const login =
        getCookieValue('CurrentLogin') ||
        getCookieValue('NewLogin') ||
        getCookieValue('NewUUID') ||
        getCookieValue('CurrentUUID');
    const refreshToken = getCookieValue('RefreshToken');
    const isSingleSpa = !!window.applicationBase;
    const usingOktaFlow = getCookieValue(
        isSingleSpa ? 'UsingOktaFlow' : 'GMBUsingOktaFlow',
    );

    return (
        // Old flow pre-okta (will be deprecated and removed once we turn okta auth on)
        (login !== undefined && refreshToken !== undefined) ||
        // support our logic while using the okta auth flow
        // this will be set by the backend coming from auth-middleware
        usingOktaFlow !== undefined
    );
};
