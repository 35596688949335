import React, { ReactNode } from 'react';
import { Card } from 'spoton-lib';
import { ErrorBoundaryGeneric } from 'features/common/components';

export interface IPropTypes {
    className?: string;
    children: ReactNode;
}

export default function Widget({ className, children }: IPropTypes): JSX.Element {
    return (
        <Card elevation={1} className={className}>
            <ErrorBoundaryGeneric>{children}</ErrorBoundaryGeneric>
        </Card>
    );
}
