import React, { useState, BaseSyntheticEvent } from 'react';
import { Input, Dropdown } from 'spoton-lib';
import { useTranslation } from 'react-i18next';

import styles from './InputAction.module.scss';

interface IInputActionProps {
    stateActionButton: any;
    stateActionUrl: any;
    isValid: boolean;
}

export default function InputAction({
    stateActionButton,
    stateActionUrl,
    isValid,
}: IInputActionProps): JSX.Element {
    const { t } = useTranslation();
    const [actionButton, setActionButton] = stateActionButton;
    const [actionUrl, setActionUrl] = stateActionUrl;

    const actionTypes = ['BOOK', 'ORDER', 'SHOP', 'LEARN_MORE', 'SIGN_UP', 'CALL'];
    const actionOptions = [
        { value: '', label: t('none') },
        ...actionTypes.map((actionType) => ({
            value: actionType,
            label: t(`dashboard.postActions.${actionType}`),
        })),
    ];

    const [isShowingActionUrl, setIsShowingActionUrl] = useState<boolean>(
        actionButton !== '' && actionButton !== 'CALL',
    );
    const captureDropdownSelect = (option: any) => {
        setIsShowingActionUrl(option.value !== 'CALL' && option.value !== '');
        setActionButton(option.value);
    };
    const collectUrlInput = (event: BaseSyntheticEvent) => {
        setActionUrl(event.target.value);
    };

    return (
        <div className={styles.ActionWrapper}>
            <Dropdown
                value={
                    actionButton && {
                        value: actionButton,
                        label: t(`dashboard.postActions.${actionButton}`),
                    }
                }
                className={styles.ActionDropdown}
                primaryCondition={t('dashboard.postActionButtonLabel')}
                options={actionOptions}
                withDividers={false}
                onChange={captureDropdownSelect}
                placeholder={t('none')}
                menuPlacement="top"
            />
            {isShowingActionUrl && (
                <Input
                    value={actionUrl ?? ''}
                    className={styles.ActionUrl}
                    label={t('dashboard.postActionUrlLabel')}
                    onChange={collectUrlInput}
                    clearable={false}
                    isValid={isValid}
                    secondaryCondition={isValid ? '' : t(`dashboard.postUrlError`)}
                />
            )}
        </div>
    );
}
