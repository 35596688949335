import React from 'react';
import { Modal, Icon, Button, IconButton } from 'spoton-lib';

import { useTranslation } from 'react-i18next';

import styles from './DeleteModal.module.scss';

interface IDeleteModalProps {
    closeModal: () => void;
    confirmDelete: () => void;
    deleteMessage: string;
    showWarning: boolean;
    isProcessing: boolean;
}

export default function PostSmallModal({
    closeModal,
    confirmDelete,
    deleteMessage,
    showWarning,
    isProcessing,
}: IDeleteModalProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Modal
            ariaHideApp={false}
            isOpen={true}
            onRequestClose={closeModal}
            className={styles.Modal}
            overlayClassName={styles.Overlay}
        >
            <div className={styles.Header}>
                <IconButton
                    className={styles.CloseIcon}
                    name="CloseIcon"
                    variant="secondary"
                    disableBorder={true}
                    alt="close icon"
                    onClick={closeModal}
                />
            </div>

            <div className={styles.Body}>
                <Icon
                    className={styles.DeleteIcon}
                    alt="delete icon"
                    name="DeleteIcon"
                    size={30}
                />
                <div className={styles.DeleteMessage}>
                    {deleteMessage}
                    {showWarning && (
                        <div className={styles.DeleteWarning}>
                            {t('deleteWarning')}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.Footer}>
                <div className={styles.ButtonContainer}>
                    <Button
                        variant="secondary"
                        onClick={closeModal}
                        disabled={isProcessing}
                    >
                        {t(`discard`)}
                    </Button>
                    <Button
                        className={styles.ButtonDelete}
                        variant="primary"
                        onClick={confirmDelete}
                        danger={true}
                        disabled={isProcessing}
                    >
                        {t(`delete`)}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
