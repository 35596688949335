import React, { useState, useEffect } from 'react';
import { ImageSelector } from 'spoton-lib';

import styles from './ImageDropbox.module.scss';

interface IImageDropboxProps {
    stateMedia: any;
    setImageFile: any;
}

export default function ImageDropbox({
    stateMedia,
    setImageFile,
}: IImageDropboxProps): JSX.Element {
    const [media, setMedia] = stateMedia;
    const [images, setImages] = useState<Array<any>>();

    useEffect(() => {
        if (media) {
            const images = media.map((image: any) => {
                return {
                    imgUrl: image.googleUrl,
                    imgAlt: image.name,
                    isCover: false,
                };
            });
            setImages(images);
        }
    }, []);

    const loadImage = (e: any, file: any) => {
        const url = e.target.result.toString();
        if (url) {
            const image = {
                imgUrl: url,
                imgAlt: file.name,
                isCover: false,
            };
            setMedia([]);
            setImageFile(file);
            setImages([image]);
        }
    };

    const addImage = (files: any) => {
        const file = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e: any) => {
            loadImage(e, file);
        };
    };

    const removeImage = () => {
        setImages([]);
        setMedia([]);
        setImageFile();
    };

    const doNothing = () => {
        null;
    };
    return (
        <ImageSelector
            className={styles.ImageSelector}
            images={images}
            onAddImages={addImage}
            onRemoveImage={removeImage}
            onCropImage={doNothing}
            onCoverImage={doNothing}
            onShowMoreImages={doNothing}
        />
    );
}
