// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuestionsSection_Card__1QGp1{margin:15px 0}.QuestionsSection_Header__19dXT{display:flex;align-items:center;justify-content:space-between;margin-bottom:11px}.QuestionsSection_HeaderTitle__27PVh{margin:0;font-weight:500;font-size:18px;line-height:27px}.QuestionsSection_Dropdown__3hY2a{width:220px;font-weight:400;font-size:15px}.QuestionsSection_Dropdown__3hY2a.QuestionsSection_Dropdown__3hY2a{padding:0}.QuestionsSection_MobileDropdown__mtpIX{right:-15px;display:flex;justify-content:flex-end;width:250px}.QuestionsSection_MobileSortBtn__2cZ3F{display:flex;justify-self:right}\n", "",{"version":3,"sources":["webpack://src/features/dashboard/home/QuestionSection/QuestionsSection.module.scss"],"names":[],"mappings":"AAGA,8BACI,aAAc,CACjB,gCAGG,YAAa,CACb,kBAAmB,CACnB,6BAA8B,CAC9B,kBAAmB,CACtB,qCAGG,QAAS,CACT,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACpB,kCAGG,WAAY,CACZ,eAAgB,CAChB,cAAe,CAHnB,mEAMQ,SAAU,CACb,wCAID,WAAY,CACZ,YAAa,CACb,wBAAyB,CACzB,WAAY,CACf,uCAGG,YAAa,CACb,kBAAmB","sourcesContent":["@import 'node_modules/spoton-lib/styles/color-variables';\n@import 'src/features/common/styles/breakpoints';\n\n.Card {\n    margin: 15px 0;\n}\n\n.Header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 11px;\n}\n\n.HeaderTitle {\n    margin: 0;\n    font-weight: 500;\n    font-size: 18px;\n    line-height: 27px;\n}\n\n.Dropdown {\n    width: 220px;\n    font-weight: 400;\n    font-size: 15px;\n\n    &#{&} {\n        padding: 0;\n    }\n}\n\n.MobileDropdown {\n    right: -15px;\n    display: flex;\n    justify-content: flex-end;\n    width: 250px;\n}\n\n.MobileSortBtn {\n    display: flex;\n    justify-self: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary10": "var(--primary10)",
	"primary20": "var(--primary20)",
	"primary30": "var(--primary30)",
	"primary50": "var(--primary50)",
	"primary70": "var(--primary70)",
	"primary80": "var(--primary80)",
	"primary90": "var(--primary90)",
	"base10": "var(--base10)",
	"base20": "var(--base20)",
	"base30": "var(--base30)",
	"base50": "var(--base50)",
	"base70": "var(--base70)",
	"base80": "var(--base80)",
	"base90": "var(--base90)",
	"highlightBase20": "var(--#f7f8f9)",
	"white": "var(--white)",
	"black": "var(--black)",
	"shadow": "var(--shadow)",
	"informative10": "var(--informative10)",
	"informative20": "var(--informative20)",
	"informative30": "var(--informative30)",
	"informative50": "var(--informative50)",
	"informative70": "var(--informative70)",
	"informative80": "var(--informative80)",
	"informative90": "var(--informative90)",
	"success10": "var(--success10)",
	"success20": "var(--success20)",
	"success30": "var(--success30)",
	"success50": "var(--success50)",
	"success70": "var(--success70)",
	"success80": "var(--success80)",
	"success90": "var(--success90)",
	"warning10": "var(--warning10)",
	"warning20": "var(--warning20)",
	"warning30": "var(--warning30)",
	"warning50": "var(--warning50)",
	"warning70": "var(--warning70)",
	"warning80": "var(--warning80)",
	"warning90": "var(--warning90)",
	"danger10": "var(--danger10)",
	"danger20": "var(--danger20)",
	"danger30": "var(--danger30)",
	"danger50": "var(--danger50)",
	"danger70": "var(--danger70)",
	"danger80": "var(--danger80)",
	"danger90": "var(--danger90)",
	"loyalty10": "var(--loyalty10)",
	"loyalty20": "var(--loyalty20)",
	"loyalty30": "var(--loyalty30)",
	"loyalty50": "var(--loyalty50)",
	"loyalty70": "var(--loyalty70)",
	"loyalty80": "var(--loyalty80)",
	"loyalty90": "var(--loyalty90)",
	"loyaltyGradient": "var(--loyaltyGradient)",
	"Card": "QuestionsSection_Card__1QGp1",
	"Header": "QuestionsSection_Header__19dXT",
	"HeaderTitle": "QuestionsSection_HeaderTitle__27PVh",
	"Dropdown": "QuestionsSection_Dropdown__3hY2a",
	"MobileDropdown": "QuestionsSection_MobileDropdown__mtpIX",
	"MobileSortBtn": "QuestionsSection_MobileSortBtn__2cZ3F"
};
export default ___CSS_LOADER_EXPORT___;
