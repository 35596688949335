import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import { IGetLocationsResponse } from './Locations.types';

export function getLocations(): Promise<AxiosResponse<IGetLocationsResponse>> {
    return axios.get<IGetLocationsResponse>('/api/connect/list-locations');
}

export function connectLocation({
    locationId,
}: {
    locationId: string;
}): Promise<AxiosResponse<any>> {
    return axios.post('/api/connect/connect-location', { locationId });
}

export function createLocation({
    name,
}: {
    name: string;
}): Promise<AxiosResponse<any>> {
    return axios.post('/api/connect/create-location', { name });
}

export function claimLocation({
    placeId,
    name,
}: {
    placeId: string;
    name: string;
}): Promise<AxiosResponse<any>> {
    return axios.post('/api/connect/claim-location', { placeId, name });
}

export function requestAccess({
    placeId,
    requestAdminRightsUrl,
}: {
    placeId: string;
    requestAdminRightsUrl: string;
}): Promise<AxiosResponse<any>> {
    return axios.post('/api/connect/claim-location-status', {
        placeId,
        requestAdminRightsUrl,
    });
}

export function checkAccessStatus(): Promise<AxiosResponse<any>> {
    return axios.get('/api/connect/claim-location-status');
}

export function deleteLocation(): Promise<AxiosResponse<any>> {
    return axios.delete('/api/connect/delete-location');
}

export function syncLocation(): Promise<AxiosResponse<any>> {
    return axios.post('/api/connect/sync-location');
}
