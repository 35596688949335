import { axios, AxiosResponse } from 'features/common/utils/axios.utils';

import { IGetCategoriesResponse } from './Categories.types';

interface IGetCategoriesParams {
    languageCode: string;
    searchTerm?: string;
    pageSize?: number;
    pageToken?: string;
    regionCode?: string;
}
export function getCategories({
    languageCode,
    searchTerm,
    pageSize = 20,
    pageToken = '',
    regionCode = 'US',
}: IGetCategoriesParams): Promise<AxiosResponse<IGetCategoriesResponse>> {
    return axios.get<IGetCategoriesResponse>('/api/profile/gmb-category', {
        params: {
            languageCode,
            searchTerm,
            pageSize,
            pageToken,
            regionCode,
        },
    });
}
