// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeScreen_Wrapper__LUfYr{width:100%;margin:24px 20px 0 0}.HomeScreen_HalfSectionWrapper__2HMGj{display:flex;justify-content:space-between;width:100%}.HomeScreen_LeftSideWrapper__3gxSM{width:49%}.HomeScreen_RightSideWrapper__2ixXI{width:49%}@media screen and (max-width: 1024px){.HomeScreen_Wrapper__LUfYr{margin:80px 20px 0 0}.HomeScreen_HalfSectionWrapper__2HMGj{display:block}.HomeScreen_LeftSideWrapper__3gxSM,.HomeScreen_RightSideWrapper__2ixXI{width:100%}}\n", "",{"version":3,"sources":["webpack://src/features/dashboard/home/HomeScreen/HomeScreen.module.scss"],"names":[],"mappings":"AAEA,2BACI,UAAW,CACX,oBAAqB,CACxB,sCAGG,YAAa,CACb,6BAA8B,CAC9B,UAAW,CACd,mCAGG,SAAU,CACb,oCAGG,SAAU,CACb,sCAGG,2BACI,oBAAqB,CACxB,sCAGG,aAAc,CACjB,uEAIG,UAAW,CACd","sourcesContent":["@import 'src/features/common/styles/breakpoints';\n\n.Wrapper {\n    width: 100%;\n    margin: 24px 20px 0 0;\n}\n\n.HalfSectionWrapper {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.LeftSideWrapper {\n    width: 49%;\n}\n\n.RightSideWrapper {\n    width: 49%;\n}\n\n@include notDesktop() {\n    .Wrapper {\n        margin: 80px 20px 0 0;\n    }\n\n    .HalfSectionWrapper {\n        display: block;\n    }\n\n    .LeftSideWrapper,\n    .RightSideWrapper {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": "HomeScreen_Wrapper__LUfYr",
	"HalfSectionWrapper": "HomeScreen_HalfSectionWrapper__2HMGj",
	"LeftSideWrapper": "HomeScreen_LeftSideWrapper__3gxSM",
	"RightSideWrapper": "HomeScreen_RightSideWrapper__2ixXI"
};
export default ___CSS_LOADER_EXPORT___;
