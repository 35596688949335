import { axios, AxiosResponse } from 'features/common/utils/axios.utils';

import { IGetPostsResponse, IPost } from './Post.types';

export function getPosts(
    filter: string,
    pageSize = 100,
    pageToken = '',
): Promise<AxiosResponse<IGetPostsResponse>> {
    return axios.get<IGetPostsResponse>(`/api/location/local-posts`, {
        params: { filter, pageSize, pageToken },
    });
}

export function createPost(
    topicType: string,
    title?: string,
    schedule?: any, // eslint-disable-line
    summary?: string,
    actionType?: string,
    url?: string,
    image?: any, // eslint-disable-line
): Promise<AxiosResponse<IPost>> {
    const localPost = {
        topicType,
        ...(title &&
            schedule && {
                event: {
                    title,
                    schedule,
                },
            }),
        ...(summary && { summary }),
        ...(actionType && {
            callToAction: {
                actionType,
                ...(url && { url }),
            },
        }),
    };
    const formData = new FormData();
    formData.append('localPost', JSON.stringify(localPost));
    if (image) {
        formData.append('media', image, image.name);
    }

    return axios.post<IPost>(`/api/location/local-posts/`, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
}

export function editPost(
    postId: string,
    title?: string,
    schedule?: any, // eslint-disable-line
    summary?: string,
    actionType?: string,
    url?: string,
    media?: Array<any>,
    image?: any, // eslint-disable-line
): Promise<AxiosResponse<IPost>> {
    const localPost = {
        ...(title &&
            schedule && {
                event: {
                    title,
                    schedule,
                },
            }),
        ...(summary !== undefined && { summary }),
        ...(actionType && {
            callToAction: {
                actionType,
                ...(url && { url }),
            },
        }),
        ...(media && { media }),
    };
    const formData = new FormData();
    formData.append('localPost', JSON.stringify(localPost));
    if (image) {
        formData.append('media', image, image.name);
    }
    return axios.put<IPost>(`/api/location/local-posts/${postId}/`, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
}

export function deletePost(postId: string): Promise<AxiosResponse<any>> {
    return axios.delete<any>(`/api/location/local-posts/${postId}`);
}
