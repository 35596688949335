import React, { useState } from 'react';

import { IconButton, Avatar, Text, LoadingIndicator } from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/lazy';
import { format } from 'date-fns';
import { getImages, deleteMedia } from 'features/dashboard/api/Media.data';
import { AxiosResponse } from 'features/common/utils/axios.utils';
import { IMediaImage, IMediaResponse } from 'features/dashboard/api/Media.types';
import { IMediaFullScreenTypes } from 'features/dashboard/types/MediaFullScreenTypes';

import { DeleteModal } from 'features/common/components/DeleteModal';
import styles from './MediaFullScreen.module.scss';
import { showDetailedError } from 'utils/messages';

export default function MediaFullScreen({
    imageData,
    imagesData,
    pageToken,
    closeModal,
    totalMediaCount,
    currentFilter,
    updateMediaList,
}: IMediaFullScreenTypes): JSX.Element {
    const { t } = useTranslation();
    const [image, setImage] = useState<IMediaImage>({ ...imageData });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLeftArrowVisible, setIsLeftArrowVisible] = useState<boolean>(
        imageData?.imageIndex !== 0,
    );

    const [isRightArrowVisible, setIsRightArrowVisible] = useState<boolean>(true);

    const [nextPageToken, setNextPageToken] = useState<string | undefined>(
        pageToken,
    );
    const [images, setImages] = useState<Array<any>>([...imagesData]);
    const [isShowingDeleteModal, setIsShowingDeleteModal] = useState<boolean>(false);

    const openDeleteModal = () => {
        setIsShowingDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setIsShowingDeleteModal(false);
    };

    const getNextMedia = (nextIndex: number) => {
        setIsLoading(true);
        getImages(currentFilter, 10, nextPageToken || null)
            .then(({ data }: AxiosResponse<IMediaResponse>) => {
                setImage({ ...data.mediaItems[0], imageIndex: nextIndex });
                setImages((current) => [...current, ...data.mediaItems]);
                setNextPageToken(data.nextPageToken);
                setIsRightArrowVisible(true);
            })
            .catch((error) => {
                showDetailedError({
                    template: 'errors.dashboard.mediaFetch',
                    error: error.response?.data,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const previousImage = function () {
        const nextIndex = (image.imageIndex || 0) - 1;
        if (image.imageIndex === 1) {
            setIsLeftArrowVisible(false);
        }

        if (!images[nextIndex]) {
            return;
        }

        setIsRightArrowVisible(true);
        setImage({ ...images[nextIndex], imageIndex: nextIndex });
    };

    const nextImage = function () {
        if (isLoading) {
            return;
        }

        const nextIndex = (image.imageIndex || 0) + 1;

        if (!images[nextIndex]) {
            if (nextPageToken) {
                setIsRightArrowVisible(false);
                getNextMedia(nextIndex);
            }

            return;
        }
        setIsLeftArrowVisible(true);
        setImage({ ...images[nextIndex], imageIndex: nextIndex });
    };

    const closeFullScreenModal = () => {
        closeModal();
    };

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const deleteItem = () => {
        setIsProcessing(true);
        deleteMedia(image.mediaId)
            .then(() => {
                closeDeleteModal();
                if (images.length === 1) {
                    closeModal();
                } else {
                    const currentIndex = image.imageIndex || 0;
                    const isLastElement = currentIndex === images.length - 1;
                    const updatedImages = images.filter(
                        ({ mediaId }) => image.mediaId !== mediaId,
                    );
                    const newIndex = isLastElement ? currentIndex - 1 : currentIndex;

                    setImage({
                        ...updatedImages[newIndex],
                        imageIndex: newIndex,
                    });
                    setImages(updatedImages);
                }

                updateMediaList && updateMediaList();
            })
            .catch((error) => {
                showDetailedError({
                    template: 'errors.dashboard.mediaDelete',
                    error: error.response?.data,
                });
            })
            .finally(() => {
                setIsProcessing(false);
            });
    };

    return (
        <div className={styles.MediaFullScreenOverLay}>
            <div className={styles.MediaFullScreenHeader}>
                <div className={styles.MediaFullScreenHeaderUserInfoWrapper}>
                    <Avatar
                        className={styles.MediaFullScreenAvatar}
                        initials="SO"
                        photoUrl={
                            image.source === 'OWNER'
                                ? ''
                                : image.attribution?.profilePhotoUrl
                        }
                    />
                    <span
                        className={
                            image.source === 'OWNER'
                                ? styles.MediaFullScreenOwnerBadge
                                : styles.MediaFullScreenUserName
                        }
                    >
                        <Text
                            className={styles.MediaFullScreenUserNameText}
                            type="p"
                        >
                            {image.source === 'OWNER'
                                ? t('dashboard.owner')
                                : image.attribution?.profileName}
                        </Text>
                        <Text className={styles.MediaFullScreenCreateDateText}>
                            {format(new Date(image.createTime), 'MMM dd, yyyy')}
                        </Text>
                    </span>
                </div>
                <div className={styles.ControlIconsWrapper}>
                    {image.source === 'OWNER' && (
                        <IconButton
                            className={styles.MediaFullScreenDeleteIcon}
                            name="DeleteIcon"
                            variant="secondary"
                            color="#ffffff"
                            size={80}
                            disableBorder={true}
                            alt="Delete Icon"
                            onClick={openDeleteModal}
                        />
                    )}
                    <IconButton
                        className={styles.MediaFullScreenCloseIcon}
                        name="CloseIcon"
                        variant="secondary"
                        color="#ffffff"
                        size={80}
                        disableBorder={true}
                        alt="Close Icon"
                        onClick={closeFullScreenModal}
                    />
                </div>
            </div>
            <div className={styles.MediaFullScreenContent}>
                <div className={styles.MediaFullScreenLeftArrow}>
                    {isLeftArrowVisible && (
                        <IconButton
                            buttonSize="regular"
                            size={60}
                            variant="secondary"
                            disableBorder
                            alt="Left Icon"
                            name="ChevronLeftIcon"
                            color="#ffffff"
                            onClick={previousImage}
                        />
                    )}
                </div>
                <div className={styles.MediaFullScreenMedia}>
                    {isLoading && <LoadingIndicator />}
                    {!isLoading && image.mediaFormat === 'PHOTO' && (
                        <img
                            src={image.googleUrl}
                            alt="alt"
                            className={styles.MediaFullScreenImage}
                        />
                    )}
                    {!isLoading && image.mediaFormat === 'VIDEO' && (
                        <ReactPlayer
                            controls={true}
                            playing={true}
                            url={image.googleUrl}
                        />
                    )}
                </div>
                <div className={styles.MediaFullScreenRightArrow}>
                    {isRightArrowVisible && (
                        <IconButton
                            alt="Right Icon"
                            buttonSize="regular"
                            size={60}
                            variant="secondary"
                            disableBorder
                            color="#ffffff"
                            name="ChevronRightIcon"
                            onClick={nextImage}
                        />
                    )}
                </div>
            </div>
            <div className={styles.MediaFullScreenFooter}>
                <Text className={styles.MediaFullScreenFooterCopyRightText} type="p">
                    {t('dashboard.copyright')}
                </Text>
                <Text className={styles.MediaFullScreenFooterCountText}>
                    {(image.imageIndex || 0) + 1} / {totalMediaCount}
                </Text>
            </div>
            {isShowingDeleteModal && (
                <DeleteModal
                    closeModal={closeDeleteModal}
                    confirmDelete={deleteItem}
                    deleteMessage={t(
                        `dashboard.mediaDeleteMessage.${image.mediaFormat}`,
                    )}
                    showWarning={true}
                    isProcessing={isProcessing}
                />
            )}
        </div>
    );
}
