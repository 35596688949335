import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import {
    IAppointmentLinksResponse,
    IAppointmentLinkResponse,
} from './AppointmentLinks.types';

export function getAppointmentLinks(
    filter: string,
    pageToken?: string,
): Promise<AxiosResponse<IAppointmentLinksResponse>> {
    return axios.get<IAppointmentLinksResponse>('/api/location/place-actions', {
        params: {
            filter: filter,
            pageSize: 10,
            ...(!!pageToken && { pageToken }),
        },
    });
}

export function createAppointmentLink(
    uri: string,
): Promise<AxiosResponse<IAppointmentLinkResponse>> {
    return axios.post<IAppointmentLinkResponse>('/api/location/place-actions/', {
        uri,
        placeActionType: 'FOOD_ORDERING',
    });
}

export function editAppointmentLink(
    linkId: string,
    uri: string,
): Promise<AxiosResponse<IAppointmentLinkResponse>> {
    return axios.put<IAppointmentLinkResponse>(
        `/api/location/place-actions/${linkId}/`,
        { uri },
    );
}

export function removeAppointmentLink(linkId: string): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/location/place-actions/${linkId}`);
}
