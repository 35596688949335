import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AuthApi } from 'features/account/api';
import { showDetailedError } from 'utils/messages';
import { disconnectApi } from 'api';

export const getAuthConfig = createAsyncThunk(
    'disconnect/getAuthConfig',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthApi.getAuthConfig();
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                error: data,
            });
            return rejectWithValue({
                data,
                status: status,
            });
        }
    },
);

export const getLocationStatus = createAsyncThunk(
    'disconnect/getLocationStatus',
    async ({ key }: { key: string }, { rejectWithValue }) => {
        try {
            const response = await disconnectApi.getLocationStatus(key);
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data?.error;
            const status = (error as AxiosError<any, any>).response?.status;

            return rejectWithValue({
                data,
                status: status,
            });
        }
    },
);

export const disconnectAccount = createAsyncThunk(
    'disconnect/disconnectAccount',
    async (
        { authorizationCode, key }: { authorizationCode: string; key: string },
        { rejectWithValue },
    ) => {
        try {
            const response = await disconnectApi.disconnectAccount({
                authorizationCode,
                key,
            });
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data?.error;
            const status = (error as AxiosError<any, any>).response?.status;

            showDetailedError({
                message: data,
            });
            return rejectWithValue({
                data,
                status: status,
            });
        }
    },
);
