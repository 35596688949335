import React, { useEffect } from 'react';
import { useNavigation } from 'react-navi';
import { getConfigVar } from 'features/common';

export function LoginRedirect(): JSX.Element {
    const next = useNavigation().getCurrentValue().url.query['next'] ?? '/';

    useEffect(() => {
        const ssoUrl = getConfigVar('REACT_APP_SSO_URL');
        const url = `${ssoUrl}?next=${window.location.origin}${next}`;

        window.location.href = url;
    });

    return <div>Redirecting...</div>;
}

export default LoginRedirect;
