// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputTitle_TitleWrapper__3zW8W{margin-bottom:15px}.InputTitle_TitleInput__uj_gW{padding-top:0}\n", "",{"version":3,"sources":["webpack://src/features/dashboard/home/PostSection/SmallModal/InputTitle.module.scss"],"names":[],"mappings":"AAAA,gCACI,kBAAmB,CACtB,8BAGG,aAAc","sourcesContent":[".TitleWrapper {\n    margin-bottom: 15px;\n}\n\n.TitleInput {\n    padding-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleWrapper": "InputTitle_TitleWrapper__3zW8W",
	"TitleInput": "InputTitle_TitleInput__uj_gW"
};
export default ___CSS_LOADER_EXPORT___;
