import React from 'react';
import { Dropdown } from 'spoton-lib';
import {
    TimePickerItem,
    closesPickerOptions,
    opensPickerOptions,
} from './TimePicker.utils';

type Props = {
    onChange: any;
    value: TimePickerItem | undefined;
    mode: 'opens' | 'closes';
    menuPlacement?: 'top' | 'bottom';
};

const styles = {
    singleValue: (provided: any) => ({
        ...provided,
        width: '100%',
        fontSize: '14px',
        maxWidth: 'calc(100% - 2px)',
    }),
    container: (provided: any) => ({
        ...provided,
        width: '120px',
    }),
};
export function TimePicker({
    onChange,
    value,
    mode,
    menuPlacement,
}: Props): JSX.Element {
    const options = mode === 'opens' ? opensPickerOptions : closesPickerOptions;
    return (
        <Dropdown
            options={options}
            onChange={onChange}
            value={value}
            styles={styles}
            menuPlacement={menuPlacement}
        />
    );
}

export default TimePicker;
