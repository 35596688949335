import React, { useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Switch } from 'spoton-lib';
import classnames from 'classnames';

import i18n from 'i18n.config';
import {
    EditableRegularHoursPeriod,
    RegularHoursPeriod,
} from 'features/dashboard/types';
import { TimePicker } from 'features/common/components';
import {
    optionByValue,
    TimePickerItem,
} from 'features/common/components/TimePicker';
import { useBreakpoint } from 'utils/customHooks';
import { MobileTimePicker } from '../../MobileTimePicker';

import styles from './OpenHoursEdit.module.scss';
import {
    daysOfWeekKeys,
    daysOfWeek,
    FULL_DAY,
    MIDNIGHT,
    MIDNIGHT_24,
} from 'utils/periods.utils';

interface IProps {
    periods: RegularHoursPeriod[];
    methods: ReturnType<typeof useForm>;
}

// Workaround to be able to create form items with dynamic names
type FormNameType = `${string}` | `${string}.${string}` | `${string}.${number}`;

const menuPlacementUp = ['THURSDAY', 'FRIDAY', 'SATURDAY'];

const createEditablePeriodByDay = (
    key: string,
    periods: RegularHoursPeriod[],
): EditableRegularHoursPeriod => {
    const day = daysOfWeek[key];
    const period = periods?.find((item) => {
        return item.openDay.toLowerCase() === day.toLowerCase();
    });

    return period
        ? {
              ...period,
              combinedOpenTime:
                  period.combinedOpenTime === MIDNIGHT &&
                  period.combinedCloseTime === MIDNIGHT_24
                      ? FULL_DAY
                      : period.combinedOpenTime,

              displayDay: i18n.t(key),
              open: true,
          }
        : {
              open: false,
              displayDay: i18n.t(key),
              openDay: day.toUpperCase(),
              closeDay: day.toUpperCase(),
              combinedOpenTime: '',
              combinedCloseTime: '',
              openTime: {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  nanos: 0,
              },
              closeTime: {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  nanos: 0,
              },
          };
};

export function OpenHoursEdit({
    periods,
    methods: { control, reset, watch },
}: IProps): JSX.Element {
    const { t } = useTranslation();
    const isMobile = useBreakpoint() !== 'desktop';
    const formValues = useMemo(
        () => ({
            periods: [
                ...daysOfWeekKeys.map((key) => {
                    return createEditablePeriodByDay(key, periods);
                }),
            ],
        }),
        [],
    );

    useEffect(() => {
        reset(formValues);
    }, [formValues]);

    return (
        <div className={styles.Schedule}>
            {formValues?.periods.map((item, index) => {
                const isOpen = watch(`periods[${index}].open` as any);
                const isFullDay =
                    watch(`periods[${index}].combinedOpenTime` as any) === FULL_DAY;
                return (
                    <div className={styles.Row} key={item.openDay}>
                        <div className={styles.WorkDayTitle}>{item.displayDay}</div>
                        <div className={styles.WorkDay}>
                            <Controller
                                control={control}
                                name={`periods[${index}].open` as FormNameType}
                                render={({ field: { onChange, value = false } }) => (
                                    <div className={styles.OpenClose}>
                                        <div className={styles.OpenCloseTitle}>
                                            {t(isOpen ? 'open' : 'close')}
                                        </div>
                                        <div className={styles.OpenCloseSwitch}>
                                            <Switch
                                                checked={value}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                )}
                            />

                            {isOpen && (
                                <>
                                    <Controller
                                        control={control}
                                        name={
                                            `periods[${index}].combinedOpenTime` as FormNameType
                                        }
                                        render={({
                                            field: { onChange, value = '' },
                                        }) => (
                                            <div className={styles.WorkTime}>
                                                <div
                                                    className={styles.WorkTimeTitle}
                                                >
                                                    {t('opens')}
                                                </div>
                                                {!isMobile && (
                                                    <TimePicker
                                                        mode="opens"
                                                        onChange={(
                                                            option: TimePickerItem,
                                                        ) => onChange(option.value)}
                                                        value={optionByValue(value)}
                                                        menuPlacement={
                                                            menuPlacementUp.includes(
                                                                item.openDay,
                                                            )
                                                                ? 'top'
                                                                : 'bottom'
                                                        }
                                                    />
                                                )}
                                                {isMobile && (
                                                    <MobileTimePicker
                                                        onChange={onChange}
                                                        mode="opens"
                                                        value={optionByValue(value)}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name={
                                            `periods[${index}].combinedCloseTime` as FormNameType
                                        }
                                        render={({
                                            field: { onChange, value = '' },
                                        }) => (
                                            <div
                                                className={classnames(
                                                    styles.WorkTime,
                                                    { [styles.Hidden]: isFullDay },
                                                )}
                                            >
                                                <div
                                                    className={styles.WorkTimeTitle}
                                                >
                                                    {t('closes')}
                                                </div>
                                                {!isMobile && (
                                                    <TimePicker
                                                        mode="closes"
                                                        onChange={(
                                                            option: TimePickerItem,
                                                        ) => onChange(option.value)}
                                                        value={optionByValue(value)}
                                                        menuPlacement={
                                                            menuPlacementUp.includes(
                                                                item.closeDay,
                                                            )
                                                                ? 'top'
                                                                : 'bottom'
                                                        }
                                                    />
                                                )}
                                                {isMobile && (
                                                    <MobileTimePicker
                                                        onChange={onChange}
                                                        mode="closes"
                                                        value={optionByValue(value)}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
