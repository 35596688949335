import React from 'react';
import { ModalHeader } from 'features/common/components/Modal/Modal.component';

import styles from './Terms.module.scss';

export default function Terms({
    onCloseModal,
}: {
    onCloseModal(): void;
}): JSX.Element {
    return (
        <div className={styles.Wrapper}>
            <ModalHeader
                title="SPOTON TRANSACT LLC GOOGLE BUSINESS PROFILE TERMS OF SERVICE"
                closeModal={onCloseModal}
                className={styles.Header}
            />
            <div className={styles.Content}>
                <div>
                    <p>Effective as of September 14th, 2021</p>
                    <p>
                        These SpotOn Transact LLC (“SpotOn”) Google Business Profile
                        (GBP) Terms of Service (GBP Terms”), outline the terms
                        regarding Client’s use of a SpotOn account for G Services.
                    </p>
                    <p>
                        These GBP Terms are incorporated into, made part of, and
                        governed by the Merchant Terms and Conditions for Services
                        Provided by SpotOn Transact, LLC:&nbsp;
                        <a
                            href="https://www.spoton.com/legal/merchant-terms/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.spoton.com/legal/merchant-terms/
                        </a>
                        (“Merchant Terms”). If there is a conflict between the
                        Merchant Terms and the GBP Terms, the GBP Terms control for
                        the GBP Services.
                    </p>
                    <p>
                        These GBP Terms are a legally binding contract between Client
                        and SpotOn. If Client does not agree with the GBP Terms, do
                        not use SpotOn for GBP Services.
                    </p>
                    <p>
                        By using SpotOn for GBP Services, Client agrees to be bound
                        by the GBP Terms.
                    </p>
                    <p>
                        If Client uses SpotOn for GBP Services on behalf of an
                        entity, Client agrees to the GBP Terms for that entity and
                        represent and warrant to SpotOn that the individual agreeing
                        for Client has the authority to bind that entity to the GBP
                        Terms.
                    </p>
                    <h3 className={styles.Anchor}>1. CHANGES TO GBP TERMS</h3>
                    <p>
                        SpotOn reserves the right to modify the GBP Terms. SpotOn
                        will post the most current version of the GBP Terms at:&nbsp;
                        <a
                            href="https://www.spoton.com/legal/gmb-terms"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.spoton.com/legal/gmb-terms
                        </a>
                        . Client’s continued use of GBP Services after SpotOn
                        publishes or notifies Client about SpotOn’s changes to the
                        GBP Terms means Client consents to the updated terms.
                    </p>
                    <h3 className={styles.Anchor}>2. GBP SERVICES</h3>
                    <p>
                        GBP allows Client to promote its Business Profile and website
                        on Google Search and Maps to connect with Client’s customers,
                        post updates to its Business Profile (e.g. address,
                        telephone, website, open hours, photos, special offers,
                        promotions), and see how its customers interact with its
                        business on Google. GBP Services FAQ:&nbsp;
                        <a
                            href="https://www.google.com/intl/en_us/ex/13100835/business/faq/?mid=13100835"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.google.com/intl/en_us/ex/13100835/business/faq/?mid=13100835
                        </a>
                        .
                    </p>
                    <h3 className={styles.Anchor}>3. SPOTON ACCOUNT</h3>
                    <p>
                        With a SpotOn account for GBP Services, Client authorizes
                        SpotOn to manage business information on GBP for Client.
                    </p>
                    <p>
                        Sign up for a SpotOn account for GBP Services on the GBP page
                        in SpotOn Marketing:{' '}
                        <a href="https://gmb-web.spoton.com">gmb-web.spoton.com</a>
                    </p>
                    <p>
                        Upon selecting the option “Create my account for me”, Client
                        consents to SpotOn managing business information on GBP for
                        Client.
                    </p>
                    <p>
                        SpotOn will create or claim Client’s GBP location on Client’s
                        behalf. SpotOn’s Gmail account (gmb@spoton.com) will be the
                        primary owner of the location. SpotOn will not share
                        passwords with Client. No Client Gmail account will be
                        associated with the location. If Client chooses to be a
                        secondary owner, SpotOn will grant secondary ownership access
                        to Client upon the Client’s request.
                    </p>
                    <h3 className={styles.Anchor}>4. GBP DATA</h3>
                    <p>
                        SpotOn will use Client’s GBP data: (a) to manage business
                        information on GBP for Client; (b) as authorized by the GBP
                        Terms; and (c) as documented in written instructions provided
                        by Client and acknowledged in writing by SpotOn as
                        constituting instructions for purposes of these GBP Terms,
                        unless required to do otherwise by Applicable Laws; in such a
                        case, SpotOn shall inform Client of the Applicable Laws,
                        unless Applicable Laws prohibit such notice on important
                        grounds of public interest. “Applicable Laws” means
                        applicable national, federal, state, and local laws, rules,
                        guidelines, court or government agency orders, and
                        regulations.
                    </p>
                    <p>
                        SpotOn will not Sell Client’s GBP data. “Sell” means selling,
                        renting, releasing, disclosing, disseminating, making
                        available, transferring, or otherwise communicating orally,
                        in writing, or by electronic or other means, a data subject’s
                        personal data for monetary or other valuable consideration.
                    </p>
                    <p>
                        Google uses different sources of data, including
                        user-generated content and other third-party data, to ensure
                        that the most accurate information is published. SpotOn will
                        consult with Client to verify data accuracy, and not take
                        automated actions to discard suggested updates.
                    </p>
                    <h3 className={styles.Anchor}>5. REVIEWS</h3>
                    <p>
                        Once SpotOn claims or creates a GBP location for Client,
                        Client will have access to a GBP dashboard to see customer
                        reviews on its business. Client may respond to business
                        reviews on Google.
                    </p>
                    <p>
                        All responses to reviews must follow Google’s Review Content
                        Policies:&nbsp;
                        <a
                            href="https://support.google.com/contributionpolicy/answer/7422880"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://support.google.com/contributionpolicy/answer/7422880
                        </a>
                        .
                    </p>
                    <h3 className={styles.Anchor}>6. INSIGHTS</h3>
                    <p>
                        Client will have access to a GBP dashboard to view insights
                        such as how many people visited its website, viewed its
                        posts, and searched for its business. The insights are pulled
                        from the GBP API:&nbsp;
                        <a
                            href="https://developers.google.com/my-business"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://developers.google.com/my-business
                        </a>
                        .
                    </p>
                    <h3 className={styles.Anchor}>7. GBP ACCOUNTS OWNERSHIP</h3>
                    <p>
                        Client owns the GBP accounts, and has the right to determine
                        SpotOn’s access rights (e.g. Co-Owner, Manager, or no
                        access). Client may terminate SpotOn for GBP Services anytime
                        with written notice. Within seven (7) business days of
                        receiving notice from Client, SpotOn will (a) stop managing
                        Client’s GBP accounts, (b) give Client exclusive control of
                        the GBP accounts, (c) transfer primary ownership of Client’s
                        Business Profile to Client, and (d) relinquish permissions to
                        manage or otherwise change Client’s GBP accounts.
                    </p>
                    <h3 className={styles.Anchor}>
                        8. SUSPENSION AND TERMINATION OF THE SERVICE
                    </h3>
                    <p>
                        SpotOn may suspend or terminate Client’s use of a SpotOn
                        account for GBP Services, at any time, in its sole
                        discretion, if SpotOn reasonably believes in good faith
                        Client is in violation of the Merchant Terms, GBP Terms, or
                        Applicable Laws.
                    </p>
                    <h3 className={styles.Anchor}>9. GBP POLICIES</h3>
                    <p>
                        Client and SpotOn agree to comply with all applicable GBP
                        policies:&nbsp;
                        <a
                            href="https://support.google.com/business/answer/7667250"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://support.google.com/business/answer/7667250
                        </a>
                        .
                    </p>
                    <h3 className={styles.Anchor}>10. MANAGEMENT FEES</h3>
                    <p>Google offers GBP as a free tool. </p>
                    <p>
                        SpotOn is not currently charging a separate fee to create or
                        manage GBP accounts, and includes GBP Services as part of
                        SpotOn Marketing. In the future, SpotOn may charge a
                        management fee for the valuable services it provides to
                        Client. SpotOn will give Client reasonable advance notice of
                        changes in its management fees, and disclose its fees in
                        Client invoices.
                    </p>
                    <h3 className={styles.Anchor}>11. DISCLOSURE NOTICE</h3>
                    <p>
                        To learn what businesses can expect when working with SpotOn,
                        read the
                        <b>
                            &quot;Working with a third party&quot; disclosure notice:
                        </b>
                        &nbsp;
                        <a
                            href="https://support.google.com/business/answer/7163406"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://support.google.com/business/answer/7163406
                        </a>
                        .
                    </p>
                    <h3 className={styles.Anchor}>12. PUBLICITY</h3>
                    <p>
                        Use of GBP does not create, suggest, or imply a partnership
                        with, sponsorship by, or endorsement by Google or SpotOn.
                        Client will not make statements regarding Client’s use of GBP
                        that suggests these types of relationships without prior
                        written approval from Google or SpotOn.
                    </p>
                    <h3 className={styles.Anchor}>
                        13. INTERFERING WITH GOOGLE ACTIVITIES
                    </h3>
                    <p>
                        Google may monitor and audit GBP activity to ensure
                        compliance with all applicable Google policies and terms.
                        Client agrees not to interfere with such monitoring or
                        auditing and should not conceal from Google its GBP activity.
                        Any interference will be considered a violation of Google
                        policies.
                    </p>
                    <h3 className={styles.Anchor}>14. DISCLAIMER OF WARRANTIES</h3>
                    <p>
                        SpotOn makes no warranties or representations as to the
                        accuracy, completeness, reliability, usefulness,
                        non-infringement, quality, originality, or truth of Client’s
                        business information on GBP or GBP Services, regardless of
                        whether SpotOn reviewed, monitored, or moderated such
                        information or GBP Services. SpotOn has no obligation to
                        review, monitor, or moderate Client’s business information or
                        GBP Services.
                    </p>
                    <p>
                        SPOTON MAKES NO (AND SPOTON SPECIFICALLY DISCLAIMS ALL)
                        REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND,
                        WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING,
                        WITHOUT LIMITATION, REPRESENTATIONS, WARRANTIES OR
                        CONDITIONS: (A) ARISING OUT OF ANY COURSE OF PERFORMANCE,
                        COURSE OF DEALING OR USAGE OF TRADE; (B) OF: (I) SATISFACTORY
                        QUALITY; (II) FITNESS FOR A PARTICULAR PURPOSE; (III)
                        NON-INFRINGEMENT; OR (IV) INTEROPERABILITY WITH THIRD-PARTY
                        PRODUCTS OR SERVICES; AND (C) THAT SPOTON FOR GBP SERVICES
                        WILL BE UNINTERRUPTED, ERROR-FREE, OR FREE OF HARM. SOME
                        JURISDICTIONS DO NOT ALLOW THE ABOVE EXCLUSIONS. THE ABOVE
                        EXCLUSIONS WILL NOT APPLY SOLELY TO THE EXTENT PROHIBITED BY
                        LAW.
                    </p>
                    <p>
                        IF CLIENT IS DISSATISFIED WITH SPOTON FOR GBP SERVICES OR THE
                        GBP TERMS, CLIENT’S SOLE AND EXCLUSIVE REMEDY IS TO
                        DISCONTINUE USE OF SPOTON FOR GBP SERVICES.
                    </p>
                    <p>
                        This Section 14 (DISCLAIMER OF WARRANTIES) survives
                        expiration or termination of the GBP Terms.
                    </p>
                    <h3 className={styles.Anchor}>15. LIMITATION OF LIABILITY</h3>
                    <p>
                        Client’s sole and exclusive remedy hereunder shall be to
                        discontinue use of SpotOn for GBP Services. THE LIMITATIONS
                        AND EXCLUSIONS APPLY IF THIS REMEDY DOES NOT FULLY COMPENSATE
                        CLIENT FOR ANY LOSSES OR FAILS OF ITS ESSENTIAL PURPOSE.
                    </p>
                    <p>
                        IN NO EVENT WILL SPOTON BE LIABLE FOR INDIRECT, INCIDENTAL,
                        SPECIAL, PUNITIVE, COVER, LOSS OF PROFITS OR REVENUE, OR
                        CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF
                        GOODWILL, LOSS OR USE OF DATA) HOWEVER CAUSED, WHETHER BASED
                        IN CONTRACT, TORT, WARRANTY, NEGLIGENCE, OR OTHER LIABILITY
                        THEORY, EVEN IF SPOTON HAS BEEN ADVISED AS TO THE POSSIBILITY
                        OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE ABOVE
                        EXCLUSIONS. THE ABOVE EXCLUSIONS WILL NOT APPLY SOLELY TO THE
                        EXTENT PROHIBITED BY LAW.
                    </p>
                    <p>
                        This Section 15 (LIMITATION OF LIABILITY) survives expiration
                        or termination of the GBP Terms.
                    </p>
                    <h3 className={styles.Anchor}>16. INDEMNIFICATION</h3>
                    <p>
                        Client will defend, indemnify, and hold harmless SpotOn, its
                        Affiliates, and their officers, employees, independent
                        contractors (“Representatives”), directors, successors, and
                        assigns (“SpotOn Indemnified Parties”), against any third
                        party claim, demand, lawsuit, dispute, or proceeding
                        (“Claim”): (a) that Client’s, Client Affiliates’, Client
                        Representatives’ use of SpotOn for GBP Services in breach of
                        these GBP Terms, infringes or misappropriates a trademark,
                        copyright, trade secret, or patent (to the extent such
                        infringement or misappropriation is not the result of
                        SpotOn’s actions); (b) relating to Client’s, Client
                        Affiliates’, Client Representatives’ use of SpotOn for GBP
                        Services in violation of the GBP Terms; (c) relating to
                        Client’s business information; or (d) relating to the acts
                        and omissions of Client Affiliates’ or Client
                        Representatives’ use of SpotOn for GBP Services. Client will,
                        with respect to any Claim against SpotOn Indemnified Parties
                        that is subject to this Section 16 (Indemnification),
                        indemnify SpotOn Indemnified Parties for damages, attorney
                        fees, expert witness fees, and litigation costs finally
                        awarded against SpotOn Indemnified Parties to such third
                        party by a court of competent jurisdiction or agreed in
                        settlement.
                    </p>
                    <p>
                        This Section 16 (Indemnification) survives expiration or
                        termination of the GBP Terms.
                    </p>
                    <h3 className={styles.Anchor}>17. INTEGRATION</h3>
                    <p>
                        These GBP Terms: (a) constitute the entire agreement between
                        the parties and supersedes all prior agreements or
                        communications between the parties with regard to the subject
                        matter; (b) may not be amended or modified except by a
                        writing signed by each party; and (c) supersedes and controls
                        over conflicting or additional terms and conditions of any
                        purchase order, acknowledgement, confirmation, or other
                        document Client issues.
                    </p>
                </div>
            </div>
        </div>
    );
}
