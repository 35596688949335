import { createReducer, Reducer } from '@reduxjs/toolkit';

import * as categoriesActions from './categories.actions';
import { ICategoriesTypes } from './categories.types';

export const initialState: ICategoriesTypes = {
    categories: [],
    isLoading: false,
    isError: false,
};

export const categoriesReducer: Reducer<ICategoriesTypes> = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(
                categoriesActions.getCategories.fulfilled,
                (state, action: any) => {
                    state.categories = action.payload.categories;
                    state.isLoading = false;
                    state.isError = false;
                },
            )
            .addCase(categoriesActions.getCategories.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(categoriesActions.getCategories.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addDefaultCase((state) => {
                return state;
            });
    },
);

export default categoriesReducer;
