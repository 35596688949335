import { getConfigVar } from './config.utils';
import { ELogLevels } from '../types/logLevels.type';

/**
 * Find out if we want to enable logging or not. If not set, show logs
 */
const isLoggingEnabled = getConfigVar('REACT_APP_ENABLE_LOGGING') || false;

/**
 * Grab the log level value from the 'LOG_LEVEL' env variable. If none is found
 * default to verbose.
 */
const logLevel = getConfigVar('REACT_APP_LOG_LEVEL') || ELogLevels.verbose;

/**
 * Logging helper
 *
 * Just a simple wrapper that allows us to turn on and off logging levels
 * depending on ENV Vars.
 *
 * @example
 *  Logger.warn('hello warn');
 *  Logger.error('hello error');
 *  Logger.info('hello info');
 *  Logger.debug('hello debug');
 */
export class Logger {
    /** Print a warning message */
    static warn(...message: Array<any>): void {
        switch (logLevel) {
            case ELogLevels.warn:
            case ELogLevels.verbose:
            case ELogLevels.debug:
                if (isLoggingEnabled) {
                    console.warn(message);
                }
                break;
            default:
                break;
        }
    }

    /** Print an error message */
    static error(...message: Array<any>): void {
        switch (logLevel) {
            case ELogLevels.error:
            case ELogLevels.verbose:
            case ELogLevels.debug:
                if (isLoggingEnabled) {
                    console.error(message);
                }
                break;
            default:
                break;
        }
    }

    /** Print an info message */
    static info(...message: Array<any>): void {
        switch (logLevel) {
            case ELogLevels.info:
            case ELogLevels.debug:
                if (isLoggingEnabled) {
                    // eslint-disable-next-line no-console
                    console.info(message);
                }
                break;
            default:
                break;
        }
    }

    /** Print a debug message */
    static debug(...message: Array<any>): void {
        switch (logLevel) {
            case ELogLevels.debug:
            case ELogLevels.verbose:
                if (isLoggingEnabled) {
                    // eslint-disable-next-line no-console
                    console.log(message);
                }
                break;
            default:
                break;
        }
    }
}
