import { createReducer, Reducer } from '@reduxjs/toolkit';

import * as locationsActions from './accessStatus.actions';
import { IAccessStatusTypes } from './accessStatus.types';

export const initialState: IAccessStatusTypes = {
    status: '',
    requestAdminRightsUrl: '',
    isLoaded: false,
    isError: false,
};

export const accessStatusReducer: Reducer<IAccessStatusTypes> = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(
                locationsActions.checkAccessStatus.fulfilled,
                (state, action: any) => {
                    state.status = action.payload.status;
                    state.requestAdminRightsUrl =
                        action.payload.requestAdminRightsUrl;
                    state.isLoaded = true;
                    state.isError = false;
                },
            )
            .addCase(locationsActions.checkAccessStatus.rejected, (state) => {
                state.isLoaded = true;
                state.isError = true;
            })
            .addDefaultCase((state) => {
                return state;
            });
    },
);

export default accessStatusReducer;
