import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reduxConfig';
import { IHome, IHomeUploadState } from './home.types';

export const selectHomeState = (state: RootState): IHome => state?.dashboard?.home;

export const selectHomeUploadMediaState = createSelector(
    selectHomeState,
    (state: IHome) => state?.homeUpload,
);

export const homeUploadMediaLoadingStatus = createSelector(
    selectHomeUploadMediaState,
    (state: IHomeUploadState) => state?.loading,
);

export const homeUploadMediaErrorStatus = createSelector(
    selectHomeUploadMediaState,
    (state: IHomeUploadState) => state?.error,
);
