import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { RootState } from 'reduxConfig';
import { AxiosError } from 'axios';
import { event } from 'react-fullstory';

import { selectFsIdentifiers } from 'features/common/redux/status/status.selectors';
import { StatusApi } from 'features/common/api';
import { IStatus } from '../../../common/api/Status.types';

const updateStatus = createAction<IStatus>('status/updateStatus');

export const checkStatus: any = createAsyncThunk<IStatus, any, { state: RootState }>(
    'status/checkStatus',
    async (_, { rejectWithValue, dispatch, getState }) => {
        try {
            const { data } = await StatusApi.getStatus();
            // TODO: important! Check flow on /pending-connection and probably remove next lines
            if (data?.statuses?.connectFlow !== 'LINKED') {
                dispatch(checkStatus());
            }

            dispatch(updateStatus(data));

            if (
                data?.statuses.connectFlow === 'LINKED' &&
                data?.statuses.connectWay !== 'REQUESTED_ACCESS'
            ) {
                const fsIdentifiers = selectFsIdentifiers(getState());
                event('success', {
                    ...fsIdentifiers,
                    connect_source: data?.statuses.connectFlow,
                } as { [property: string]: string });
            }
            return data;
        } catch (error) {
            return rejectWithValue({
                status: (error as AxiosError<any, any>).response?.status,
            });
        }
    },
);
