import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import * as features from 'features';

import 'moment/locale/es';

export interface ITranslation {
    namespace: string;
    translations: { [key: string]: any };
}

// we will use the `common` namespace as the default in this case, althought
// that may change.
const defaultNamespace = 'common';

// extract translations from features
// TODO Find keys with `^*Translations$` in the string
const translations: ITranslation[] = Object.keys(features)
    .filter((key) => /^\w+Translations$/.test(key))
    .map((key) => (features as { [key: string]: any })[key]);

// combine all those keys into a resources object that we know how to read
const resources = translations.reduce(
    (acc, current: ITranslation) => {
        return current
            ? {
                  en: {
                      ...acc.en,
                      [current.namespace]: current.translations.en,
                  },
                  es: {
                      ...acc.es,
                      [current.namespace]: current.translations.es,
                  },
              }
            : { en: { ...acc.en }, es: { ...acc.es } };
    },
    { en: {}, es: {} },
);

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        defaultNS: defaultNamespace,
        // lng: 'es-MX', // default lang
        fallbackLng: 'en',
        debug: typeof jest === undefined, // false for testing environment
        keySeparator: '.',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        detection: {
            // order and from where user language should be detected
            order: [
                'navigator',
                'querystring',
                'cookie',
                'localStorage',
                'sessionStorage',
                'htmlTag',
                'path',
                'subdomain',
            ],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language
            caches: [],
            excludeCacheFor: [],
        },
    });

export default i18n;

moment.locale(i18n.language);
