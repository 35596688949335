import React, { BaseSyntheticEvent } from 'react';
import { Input } from 'spoton-lib';
import { useTranslation } from 'react-i18next';

import styles from './InputTitle.module.scss';

interface IInputTitleProps {
    topicType: string;
    stateTitle: any;
    isValid: boolean;
}

export default function InputTitle({
    topicType,
    stateTitle,
    isValid,
}: IInputTitleProps): JSX.Element {
    const { t } = useTranslation();
    const [titleText, setTitleText] = stateTitle;
    const collectInput = (event: BaseSyntheticEvent) => {
        setTitleText(event.target.value);
    };
    return (
        <div className={styles.TitleWrapper}>
            <Input
                className={styles.TitleInput}
                label={t(`dashboard.postCreateTitle.${topicType}`)}
                value={titleText ?? ''}
                onChange={collectInput}
                isValid={isValid}
                secondaryCondition={
                    isValid ? '' : t(`dashboard.postTitleError.${topicType}`)
                }
            />
        </div>
    );
}
