import { showToast } from 'spoton-lib';
import { getConfigFlag } from 'features/common';
import i18n from 'i18n.config';

interface ISuccessMessageProps {
    title: string;
    message: string;
}

export function showSuccess(props: ISuccessMessageProps): void {
    const { title, message } = props;

    showToast({
        variant: 'success',
        title,
        content: message.split('.').length > 1 ? i18n.t(message) : message,
        autoClose: 4000,
    });
}

export function showError(error: string): void {
    showToast({
        variant: 'danger',
        title: i18n.t('error'),
        content: error,
        autoClose: 4000,
    });
}

interface IDetailedErrorProps {
    title?: string;
    template?: string;
    message?: string;
    error?: any;
}

export function showDetailedError(props: IDetailedErrorProps): void {
    const { title, template, message, error } = props;

    const isErrorActionalble =
        error?.error_type &&
        error?.error_type !== 'INTERNAL_SERVER_ERROR' &&
        error?.error_type !== 'PROFILE_MEDIA';

    const errorVariant =
        isErrorActionalble && getConfigFlag('REACT_APP_IS_QA')
            ? 'warning'
            : 'danger';

    const errorTitle: string = title ?? i18n.t('error');

    const defaultError = i18n.t(`errors.default`);

    const errorContent: string = isErrorActionalble
        ? i18n.t(`errors.types.${error?.error_type}`, i18n.t(`errors.default`))
        : template
        ? i18n.t(template, defaultError)
        : message ?? defaultError;

    showToast({
        variant: errorVariant,
        title: errorTitle,
        content: errorContent,
        autoClose: 4000,
    });
}
