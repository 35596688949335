import React from 'react';

import MediaSelectorMobile from 'features/common/components/MediaSelectorMobile';
import styles from './QuickActionItem.module.scss';

interface IProps {
    title: string;
    onClick: () => void;
}

export default function QuickActionItemUpload({
    title,
    onClick,
}: IProps): JSX.Element {
    return (
        <MediaSelectorMobile className={styles.QuickActionItem} onClick={onClick}>
            {title}
        </MediaSelectorMobile>
    );
}
