import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reduxConfig';

export const selectAppointmentLinksState = (state: RootState): any =>
    state?.dashboard?.appointmentLinks;

export const selectAppointmentLinksData = createSelector(
    selectAppointmentLinksState,
    (state: any) => state?.data,
);

export const selectAppointmentLinksIsLoading = createSelector(
    selectAppointmentLinksState,
    (state: any) => state?.loading,
);

export const selectAppointmentLinksNextPageToken = createSelector(
    selectAppointmentLinksState,
    (state: any) => state?.pageToken,
);

export const selectAppointmentLinksIsError = createSelector(
    selectAppointmentLinksState,
    (state: any) => state?.error,
);
