import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, colors, Button, TextArea } from 'spoton-lib';

import { IQuestion } from 'features/dashboard/types/QuestionsSectionTypes';
import { selectStatus } from 'features/common/redux/status/status.selectors';
import {
    createAnswer,
    hideDeleteModal,
    deleteAnswer,
} from 'features/dashboard/redux';
import { formatDateDistance } from 'utils/periods.utils';
import { DeleteModal } from 'features/common/components/DeleteModal';

import styles from './Question.module.scss';

interface IProps {
    question: IQuestion | null;
    openModal?: any;
    sortOrder: string;
    isModal?: boolean;
}

export default function Question({ question, sortOrder }: IProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(selectStatus);
    const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false);
    const textAreaLineHeight = 24;
    const [textAreaRows, setTextAreaRows] = useState(4);
    const [isAnswering, setIsAnswering] = useState(false);
    const [answerText, setAnswerText] = useState('');
    const [isAnswersExpanded, expandAnswers] = useState<boolean>(false);
    const [isValid, setIsValid] = useState(true);

    const showConfirmModal = (): any => {
        setIsShowingDeleteModal(true);
    };

    const removeAnswer = () => {
        return () => {
            setIsShowingDeleteModal(false);
            dispatch(
                deleteAnswer({ questionId: question?.questionId, order: sortOrder }),
            );
        };
    };

    const showAnswers = () => {
        expandAnswers(true);
    };

    const handleChangeAnswer = useCallback((e) => {
        const areaHeight = e.target.scrollHeight;
        const textRows = Math.floor(areaHeight / textAreaLineHeight);
        if (textRows > textAreaRows) {
            setTextAreaRows(Math.floor(textRows));
        }
        setAnswerText(e.target.value);
        setIsValid(true);
    }, []);

    const showAnswerForm = useCallback(() => {
        setIsAnswering(true);
    }, []);

    const hideAnswerForm = useCallback(() => {
        setIsAnswering(false);
        setAnswerText('');
        setIsValid(true);
    }, []);

    const leaveAnswer = useCallback(() => {
        //TODO propper validation
        if (!answerText || !answerText.trim().length) {
            setIsValid(false);
            return;
        }
        hideAnswerForm();
        dispatch(
            createAnswer({
                id: question?.questionId,
                text: answerText,
                sortOrder,
            }),
        );
    }, [answerText, dispatch]);

    const closeDeleteModal = () => {
        dispatch(hideDeleteModal());
    };

    return (
        <div className={styles.Question} key={question?.questionId}>
            <div className={styles.Header}>
                <div className={styles.AvatarBox}>
                    <img src={question?.author.profilePhotoUrl} alt="Avatar" />
                </div>
                <div>
                    <div className={styles.Author}>
                        {question?.author.displayName}
                    </div>
                    <div className={styles.AuthorInfo} />
                </div>
            </div>
            <div className={styles.QuestionBody}>
                <div className={styles.QuestionText}>{question?.text}</div>
                <div className={styles.QuestionLikeWrapper}>
                    <div className={styles.AnswerLikesBox}>
                        <Icon
                            name="ThumbsUpIcon"
                            alt="Thumbs up icon"
                            size={24}
                            color={colors.base90}
                        />
                        <span className={styles.LikesCount}>
                            {question?.upvoteCount || 0}
                        </span>
                    </div>
                    {question && !question.topAnswers && !isAnswering && (
                        <Button variant="tertiary" onClick={showAnswerForm}>
                            {t('dashboard.questions.answer')}
                        </Button>
                    )}
                </div>
                {question?.topAnswers && question?.topAnswers.length && (
                    <>
                        <div className={styles.Answer}>
                            <div className={styles.AnswerContainer}>
                                <div className={styles.AnswerHeader}>
                                    <span className={styles.AnswerAuthor}>
                                        {question?.topAnswers[0].author.displayName}
                                    </span>{' '}
                                    <span className={styles.AnswerDate}>
                                        -{' '}
                                        {formatDateDistance(
                                            question?.topAnswers[0].updateTime,
                                        )}
                                    </span>
                                </div>
                                <div className={styles.AnswerText}>
                                    {question?.topAnswers[0].text}
                                </div>
                                <div className={styles.LikesBox}>
                                    <Icon
                                        name="ThumbsUpIcon"
                                        alt="Thumbs up icon"
                                        size={24}
                                        color={colors.base90}
                                    />
                                    <span className={styles.LikesCount}>
                                        {question?.topAnswers[0].upvoteCount || 0}
                                    </span>
                                </div>
                                {question?.totalAnswerCount > 1 &&
                                    !isAnswersExpanded && (
                                        <button
                                            onClick={showAnswers}
                                            className={styles.MoreAnswers}
                                        >
                                            {question?.totalAnswerCount - 1} more
                                            answers
                                        </button>
                                    )}
                            </div>
                        </div>
                        {isAnswersExpanded &&
                            question?.topAnswers
                                ?.filter((elem, i) => i !== 0)
                                .map((answer, index) => (
                                    <div key={index} className={styles.Answer}>
                                        <div className={styles.AnswerHeader}>
                                            <div className={styles.AvatarBox}>
                                                <img
                                                    src={
                                                        answer.author.profilePhotoUrl
                                                    }
                                                    alt="Avatar"
                                                />
                                            </div>
                                            <span className={styles.AnswerAuthor}>
                                                {answer.author.displayName}
                                            </span>{' '}
                                            <span className={styles.AnswerDate}>
                                                -{' '}
                                                {formatDateDistance(
                                                    answer.updateTime,
                                                )}
                                            </span>
                                        </div>
                                        <div className={styles.AnswerText}>
                                            {answer.text}
                                        </div>
                                        <div className={styles.LikesBox}>
                                            <Icon
                                                name="ThumbsUpIcon"
                                                alt="Thumbs up icon"
                                                size={24}
                                                color={colors.base90}
                                            />
                                            <span className={styles.LikesCount}>
                                                {answer.upvoteCount || 0}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                    </>
                )}
                {isAnswering && (
                    <div className={styles.AnswerForm}>
                        <div className={styles.Header}>
                            <div className={styles.AvatarBox}>
                                <img
                                    src={status.merchantInfo.profilePhotoUrl ?? ''}
                                    alt="Avatar"
                                />
                            </div>
                            <div>
                                <div className={styles.Author}>
                                    {`${status?.firstName} ${status?.lastName}`}
                                </div>
                                <div className={styles.AuthorInfo}>{t('owner')}</div>
                            </div>
                        </div>
                        <TextArea
                            placeholder={t('dashboard.replyPlaceholder')}
                            onChange={handleChangeAnswer}
                            isValid={isValid}
                            rows={textAreaRows}
                            style={{
                                lineHeight: `${textAreaLineHeight}px`,
                            }}
                        />
                    </div>
                )}
                <div className={styles.QuestionFooter}>
                    <div className={styles.ActionsGroup}>
                        {isAnswering && (
                            <>
                                <Button
                                    variant="tertiary"
                                    onClick={hideAnswerForm}
                                    className={styles.DiscardButton}
                                >
                                    {t('discard')}
                                </Button>
                                <Button variant="tertiary" onClick={leaveAnswer}>
                                    {t('dashboard.questions.answer')}
                                </Button>
                            </>
                        )}
                        {question &&
                            question.topAnswers &&
                            question?.topAnswers[0]?.author.current_user && (
                                <Button
                                    danger
                                    onClick={showConfirmModal}
                                    variant="tertiary"
                                >
                                    {t('dashboard.questions.removeAnswer')}
                                </Button>
                            )}
                    </div>
                </div>
            </div>
            {isShowingDeleteModal && (
                <DeleteModal
                    closeModal={closeDeleteModal}
                    confirmDelete={removeAnswer()}
                    deleteMessage={t('dashboard.questions.deleteConfirm')}
                    showWarning={true}
                    isProcessing={false}
                />
            )}
        </div>
    );
}
