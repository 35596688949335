import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import styles from './MobileSlider.module.scss';

interface IProps {
    className?: string;
    itemsPerRow: number;
    itemsPerColumn: number;
    children: React.ReactNode | React.ReactNode[];
    spaceBetween?: number;
}
export default function MobileSlider({
    itemsPerRow = 1,
    itemsPerColumn = 1,
    children = [],
    spaceBetween = 5,
}: IProps): JSX.Element {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<Array<any>>([]);

    const slide = (direction: string) => {
        const nextPage = direction === 'NEXT' ? currentPage + 1 : currentPage - 1;
        if (pages[nextPage - 1]) {
            setCurrentPage(nextPage);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => slide('NEXT'),
        onSwipedRight: () => slide('PREV'),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    useEffect(() => {
        const items = React.Children.toArray(children) || [];
        const pagePredefine = new Array(
            Math.floor(
                items.length
                    ? Math.ceil(items.length / (itemsPerRow * itemsPerColumn))
                    : 1,
            ),
        );
        const rowCount = Math.floor(items.length ? items.length / itemsPerRow : 0);
        let stashedResult = new Array(rowCount || 1);
        let pageData = [];

        for (let i = 0; i <= rowCount; i++) {
            const rowEndIndex = (i + 1) * itemsPerRow;
            const rowStartIndex = rowEndIndex - itemsPerRow;
            const itemsRow = items.slice(rowStartIndex, rowEndIndex);
            stashedResult[i] = itemsRow;
        }

        for (let i = 0; i <= pagePredefine.length - 1; i++) {
            const pageStartIndex = i * itemsPerColumn;
            const pageEndIndex = pageStartIndex + itemsPerColumn;
            pageData[i] = [stashedResult.slice(pageStartIndex, pageEndIndex)];
        }
        setPages(pageData || []);
        stashedResult = [];
        pageData = [];
    }, [children]);

    return (
        <div {...handlers} className={styles.Container}>
            <div className={styles.Content}>
                {pages.map((page: any, pageIndex: number) => (
                    <div
                        key={`MobileSliderPage_${pageIndex}`}
                        className={
                            currentPage === pageIndex + 1
                                ? styles.ActivePage
                                : styles.HiddenPage
                        }
                        style={{
                            height: `${100 / itemsPerColumn}%`,
                        }}
                    >
                        {page[0].map((row: any, rowIndex: number) => {
                            return (
                                <div
                                    key={`MobileSliderRow_${rowIndex}`}
                                    className={styles.Row}
                                >
                                    {row.map((item: any, itemIndex: number) => (
                                        <div
                                            key={`MobileSliderItem_${itemIndex}`}
                                            className={styles.Item}
                                            style={{
                                                width: `${100 / itemsPerRow}%`,
                                                margin: `${spaceBetween}px`,
                                            }}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
            <div className={styles.PaginationWrapper}>
                <div className={styles.DotsContainer}>
                    {pages.map((el, i) => (
                        <span
                            key={`MobileSliderDot_${i}`}
                            className={
                                currentPage === i + 1
                                    ? styles.ActiveDot
                                    : styles.InactiveDot
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
