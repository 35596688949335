import i18n from 'i18n.config';
import { getConfigVar } from 'features/common';
import { Merchant } from 'features/common/redux/status/status.types';

function isLink(link: Link, ids: string | string[]) {
    if (!link) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Array.isArray(ids) ? ids.includes(link.id) : ids === link.id;
}

enum Programs {
    loyalty = 1,
    marketing = 2,
    hasTablet = 3,
    spotsByVisit = 4,
    spotsByDollar = 5,
    reviews = 6,
    cpayData = 7,
    imobile3 = 8,
    merchantTablet = 9,
    pairedTablet = 10,
    poynt = 11,
    salons = 12,
    gusto = 13,
    nmi = 14,
    spotonTerminal = 15,
    website = 16,
    emaginePoyntKiosk = 17,
    giftCards = 18,
    restaurants = 19,
    otherLoyalty = 20,
    transitIntegration = 21,
    spotonReserve = 22,
    omnichannel = 23,
    eGiftCards = 24,
    dineIn = 25,
    enhancedRewards = 26,
}

type Link = { id: string } | string | number | boolean | undefined;

type SettingItem = {
    id: string;
    displayName: string;
    link: Link;
};

type SettingLinkItem = Omit<SettingItem, 'link'> & {
    link?: Link;
    hasDivider: boolean;
};

const settingLinks: Array<SettingLinkItem> = [
    {
        id: 'account',
        displayName: i18n.t('settings.account'),
        link: getConfigVar('REACT_APP_SETTINGS_ACCOUNT'),
        hasDivider: false,
    },
    {
        id: 'admin',
        displayName: i18n.t('settings.admin'),
        link: getConfigVar('REACT_APP_SETTINGS_ADMIN'),
        hasDivider: false,
    },
    {
        id: 'statements',
        displayName: i18n.t('settings.statements'),
        link: getConfigVar('REACT_APP_SETTINGS_STATEMENTS'),
        hasDivider: true,
    },
    {
        id: 'orderGiftCards',
        displayName: i18n.t('settings.orderGiftCards'),
        link: getConfigVar('REACT_APP_SETTINGS_ORDER_GIFT_CARDS'),
        hasDivider: false,
    },
    {
        id: 'orderLoyaltyCards',
        displayName: i18n.t('settings.orderLoyaltyCards'),
        link: getConfigVar('REACT_APP_SETTINGS_ORDER_LOYALTY_CARDS'),
        hasDivider: false,
    },
    {
        id: 'referBusiness',
        displayName: i18n.t('settings.referBusiness'),
        link: getConfigVar('REACT_APP_SETTINGS_REFER_BUSINESS'),
        hasDivider: false,
    },
    {
        id: 'addServices',
        displayName: i18n.t('settings.addServices'),
        link: getConfigVar('REACT_APP_SETTINGS_ADD_SERVICES'),
        hasDivider: false,
    },
    {
        id: 'supportLegal',
        displayName: i18n.t('settings.supportLegal'),
        link: getConfigVar('REACT_APP_SETTINGS_SUPPORT_LEGAL'),
        hasDivider: true,
    },
    {
        id: 'logout',
        displayName: i18n.t('settings.logout'),
        hasDivider: false,
    },
];

type ExternalAppItem = SettingItem & {
    isActive: boolean;
};

const externalApps: Array<ExternalAppItem> = [
    {
        id: 'catalog',
        displayName: i18n.t('externalApps.catalog'),
        link: getConfigVar('REACT_APP_EXTERNAL_APP_CATALOG'),
        isActive: false,
    },
    {
        id: 'appointments',
        displayName: i18n.t('externalApps.appointments'),
        link: getConfigVar('REACT_APP_EXTERNAL_APP_APPOINTMENTS'),
        isActive: false,
    },
    {
        id: 'dashboard',
        displayName: i18n.t('externalApps.dashboard'),
        link: getConfigVar('REACT_APP_EXTERNAL_APP_DASHBOARD'),
        isActive: true,
    },
    {
        id: 'payroll',
        displayName: i18n.t('externalApps.payroll'),
        link: getConfigVar('REACT_APP_EXTERNAL_APP_PAYROLL'),
        isActive: false,
    },
    {
        id: 'terminal',
        displayName: i18n.t('externalApps.terminal'),
        link: getConfigVar('REACT_APP_EXTERNAL_APP_TERMINAL'),
        isActive: false,
    },
    {
        id: 'website',
        displayName: i18n.t('externalApps.website'),
        link: getConfigVar('REACT_APP_EXTERNAL_APP_WEBSITES'),
        isActive: false,
    },
];

type NavigationItem = SettingItem & {
    icon: string;
    isActive?: boolean;
    subLinks: Array<
        SettingItem & {
            isActive?: boolean;
        }
    >;
};

const navigationItems = (isMaEnabled: boolean): Array<NavigationItem> => [
    {
        id: 'dashboard',
        displayName: i18n.t('navigationApps.home'),
        link: getConfigVar('REACT_APP_EXTERNAL_APP_DASHBOARD'),
        icon: 'HomeIcon',
        subLinks: [],
    },
    {
        id: 'campaigns',
        displayName: i18n.t('navigationApps.campaigns.parent'),
        icon: 'CampaignIcon',
        link: '',
        isActive: true,
        subLinks: [
            ...(isMaEnabled
                ? [
                      {
                          id: 'marketingService',
                          displayName: i18n.t(
                              'navigationApps.campaigns.doneForYouMarketing',
                          ),
                          link: getConfigVar('REACT_APP_DASHBOARD_MAAS'),
                      },
                  ]
                : []),
            {
                id: 'myCampaigns',
                displayName: i18n.t('navigationApps.campaigns.campaignStudio'),
                link: getConfigVar('REACT_APP_DASHBOARD_MY_CAMPAIGNS'),
            },
            {
                id: 'campaignResults',
                displayName: i18n.t('navigationApps.campaigns.marketingDashboard'),
                link: getConfigVar('REACT_APP_DASHBOARD_CAMPAIGN_RESULTS'),
            },
            {
                id: 'googleMyBusiness',
                displayName: i18n.t('navigationApps.campaigns.googleMyBusiness'),
                link: '',
                isActive: true,
            },
        ].filter((item) => item) as NavigationItem['subLinks'],
    },
    {
        id: 'loyalty',
        displayName: i18n.t('navigationApps.loyalty'),
        link: getConfigVar('REACT_APP_DASHBOARD_LOYALTY'),
        icon: 'LoyaltyGenericIcon',
        subLinks: [],
    },
    {
        id: 'reviews',
        displayName: i18n.t('navigationApps.reviews'),
        link: getConfigVar('REACT_APP_DASHBOARD_REVIEWS'),
        icon: 'InstructionsIcon',
        subLinks: [],
    },
    {
        id: 'customers',
        displayName: i18n.t('navigationApps.customers'),
        link: getConfigVar('REACT_APP_DASHBOARD_CUSTOMERS'),
        icon: 'CustomersIcon',
        subLinks: [],
    },
    {
        id: 'sales',
        displayName: i18n.t('navigationApps.sales.parent'),
        link: getConfigVar('REACT_APP_DASHBOARD_SALES'),
        icon: 'SalesIcon',
        isActive: false,
        subLinks: [
            {
                id: 'trends',
                displayName: i18n.t('navigationApps.sales.trends'),
                link: getConfigVar('REACT_APP_DASHBOARD_SALES_TRENDS'),
            },
            {
                id: 'summary',
                displayName: i18n.t('navigationApps.sales.summary'),
                link: getConfigVar('REACT_APP_DASHBOARD_SALES_SUMMARY'),
            },
            {
                id: 'items',
                displayName: i18n.t('navigationApps.sales.items'),
                link: getConfigVar('REACT_APP_DASHBOARD_SALES_ITEMS'),
            },
            {
                id: 'categories',
                displayName: i18n.t('navigationApps.sales.categories'),
                link: getConfigVar('REACT_APP_DASHBOARD_SALES_CATEGORIES'),
            },
        ],
    },
    {
        id: 'orders',
        displayName: i18n.t('navigationApps.orders'),
        link: getConfigVar('REACT_APP_DASHBOARD_ORDERS'),
        icon: 'ReceiptIcon',
        subLinks: [],
    },
    {
        id: 'deposits',
        displayName: i18n.t('navigationApps.deposits.parent'),
        link: getConfigVar('REACT_APP_DASHBOARD_DEPOSITS'),
        icon: 'DepositsIcon',
        subLinks: [
            {
                id: 'summary',
                displayName: i18n.t('navigationApps.deposits.summary'),
                link: getConfigVar('REACT_APP_DASHBOARD_DEPOSITS_SUMMARY'),
            },
            {
                id: 'batches',
                displayName: i18n.t('navigationApps.deposits.batches'),
                link: getConfigVar('REACT_APP_DASHBOARD_DEPOSITS_BATCHES'),
            },
        ],
    },
    {
        id: 'spotOnCapital',
        displayName: i18n.t('navigationApps.spotOnCapital'),
        link: getConfigVar('REACT_APP_DASHBOARD_CAPITAL'),
        icon: 'AdvanceIcon',
        subLinks: [],
    },
    {
        id: 'giftCards',
        displayName: i18n.t('navigationApps.giftCards'),
        link: getConfigVar('REACT_APP_DASHBOARD_GIFT_CARDS'),
        icon: 'GiftCardIcon',
        subLinks: [],
    },
];

export const settingsRules = (
    link: Link,
    country: Merchant['country'],
    isRootGroup: boolean,
    programs: Merchant['programs'],
    isExpressEnabled: boolean,
): boolean => {
    if (isExpressEnabled) {
        return (
            isLink(link, 'admin') ||
            isLink(link, 'statements') ||
            isLink(link, 'referBusiness') ||
            isLink(link, 'supportLegal') ||
            isLink(link, 'logout')
        );
    }

    if (isLink(link, 'admin') && !(country === 'US' && !isRootGroup)) {
        return false;
    }

    if (isLink(link, 'orderGiftCards') && !programs?.includes(Programs.cpayData)) {
        return false;
    }

    if (isLink(link, 'orderLoyaltyCards') && !programs?.includes(Programs.loyalty)) {
        return false;
    }
    return true;
};

export const externalAppsRules = (
    link: Link,
    programs: Merchant['programs'],
): boolean => {
    if (!programs?.includes(Programs.omnichannel) && isLink(link, 'catalog')) {
        return false;
    }
    return true;
};

export const dashboardRules = (
    link: Link,
    country: Merchant['country'],
    programs: Merchant['programs'],
    posTabState: string,
    isRootGroup: boolean,
    isShowCapital: Merchant['showCapital'],
    expressEnabled: boolean,
): boolean => {
    if (expressEnabled) {
        return isLink(link, 'dashboard') || isLink(link, 'campaigns');
    }
    const isHideDashboardOrSales =
        programs?.includes(Programs.restaurants) ||
        programs?.includes(Programs.emaginePoyntKiosk);
    const isHideCampaignsOrCustomers =
        programs?.includes(Programs.restaurants) &&
        programs?.includes(Programs.otherLoyalty);

    if (isHideDashboardOrSales && isLink(link, 'dashboard')) {
        return false;
    }

    if (
        isHideCampaignsOrCustomers &&
        isLink(link, ['campaigns', 'loyalty', 'customers', 'sales'])
    ) {
        return false;
    }

    if (
        (isHideDashboardOrSales || posTabState === 'upsell') &&
        isLink(link, 'sales')
    ) {
        return false;
    }

    if (!programs?.includes(Programs.omnichannel) && isLink(link, 'orders')) {
        return false;
    }

    if (isRootGroup && isLink(link, 'deposits')) {
        return false;
    }

    if (!(country === 'US' && isShowCapital) && isLink(link, 'spotOnCapital')) {
        return false;
    }

    return true;
};

export const dashboardSalesRules = (
    link: Link,
    programs: Merchant['programs'],
    isRootGroup: boolean,
): boolean => {
    if (!isRootGroup && isLink(link, ['summary', 'items', 'categories'])) {
        return false;
    }
    if (
        !(
            programs?.includes(Programs.poynt) ||
            programs?.includes(Programs.imobile3) ||
            programs?.includes(Programs.nmi) ||
            programs?.includes(Programs.spotonTerminal)
        ) &&
        isLink(link, 'summary')
    ) {
        return false;
    }

    if (
        !programs?.includes(Programs.imobile3) &&
        isLink(link, ['items', 'categories'])
    ) {
        return false;
    }

    return true;
};

export const dashboardDepositsRules = (link: Link, posTabState: string): boolean => {
    if (posTabState === 'upsell') {
        return false;
    }
    return true;
};

export const getSettingsLinks = (
    country: Merchant['country'],
    programs: Merchant['programs'],
    type: Merchant['type'],
    isExpressEnabled: boolean,
): any => {
    if (!programs || !country) {
        return [];
    }
    const isRootGroup = type === 2;

    return settingLinks.filter((link) =>
        settingsRules(link, country, isRootGroup, programs, isExpressEnabled),
    );
};

export const getExternalApps = (programs: Merchant['programs']): any => {
    if (!programs) {
        return [];
    }
    return externalApps.filter((link) => externalAppsRules(link, programs));
};

export const getNavigationItems = (
    country: Merchant['country'],
    programs: Merchant['programs'],
    type: Merchant['type'],
    isShowCapital: Merchant['showCapital'],
    maEnabled: boolean,
    expressEnabled: boolean,
): any => {
    if (!programs) {
        return [];
    }

    const isRootGroup = type === 2;

    const posTabState =
        programs.includes(Programs.cpayData) ||
        programs.includes(Programs.emaginePoyntKiosk)
            ? 'true'
            : 'upsell';

    const filteredItems = navigationItems(maEnabled).filter((link) =>
        dashboardRules(
            link,
            country,
            programs,
            posTabState,
            isRootGroup,
            isShowCapital,
            expressEnabled,
        ),
    );

    const filteredSubItems = filteredItems.map((item) => {
        if (item.id === 'sales') {
            item.subLinks = item.subLinks.filter((link: any) =>
                dashboardSalesRules(link, programs, isRootGroup),
            );
        }
        if (item.id === 'deposits') {
            item.subLinks = item.subLinks.filter((link: any) =>
                dashboardDepositsRules(link, posTabState),
            );
        }
        if (item.id === 'spotOnCapital') {
            item.subLinks = [];
        }
        if (item.subLinks.length > 0) {
            item.link = '';
        }
        return item;
    });

    return filteredSubItems;
};

/**
 * If the merchant is a root level (type 2), then we add
 * X-SUBLOCATION-MERCHANT-ID=sublocationId to all request headers
 * and to the query parameter ?sublocation-merchant-id=sublocationId so as
 * not to lose the sublocation ID when reloading the page and changing routing
 */
export const getPathnameWithQueryParams = (
    pathname: string,
): { pathname: string; search: string } => ({
    pathname,
    search: new URLSearchParams(document.location.search).toString(),
});
