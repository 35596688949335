import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from 'react-navi';
import { useTranslation } from 'react-i18next';
import { event } from 'react-fullstory';
import { Button, LoadingIndicator } from 'spoton-lib';
import { showDetailedError } from 'utils/messages';

import SelectCategory from './SelectCategory.component';
import LocationList from './LocationList.component';
import SmallModal from './SmallModal.component';

import { RootState } from 'reduxConfig';

import { getPathnameWithQueryParams } from 'app/components/App/App.utils';
import { getLocations } from 'features/account/redux/locations/locations.actions';
import {
    selectLocationsGMB,
    selectLocationSpotOn,
    selectLocationsStatus,
    selectLocationsCategory,
} from 'features/account/redux/locations/locations.selectors';
import { selectUserInfo } from 'features/account/redux/userInfo/userInfo.selectors';
import { selectFsIdentifiers } from 'features/common/redux/status/status.selectors';

import { LocationsApi } from 'features/account/api';

import styles from './SelectLocation.module.scss';
import { paths } from 'app/routes/app.routes';

export default function SelectLocaiton(): JSX.Element {
    const { t } = useTranslation();
    const navigation = useNavigation();
    const dispatch = useDispatch();

    const locationsGMB = useSelector((state: RootState) =>
        selectLocationsGMB(state),
    );
    const locationsGMBCount = locationsGMB.length;
    const locationSpotOn = useSelector((state: RootState) =>
        selectLocationSpotOn(state),
    );
    const locationsCategory = useSelector((state: RootState) =>
        selectLocationsCategory(state),
    );
    const locationsStatus = useSelector((state: RootState) =>
        selectLocationsStatus(state),
    );

    const [category, setCategory] = useState<any>();

    const userInfo = useSelector((state: RootState) => selectUserInfo(state));
    const fsIdentifiers = useSelector(selectFsIdentifiers);

    useEffect(() => {
        if (!userInfo.isError) {
            dispatch(getLocations());
        }
    }, [userInfo]);

    const [locationsState, setLocationsState] = useState<string>('');

    useEffect(() => {
        setLocationsState(
            locationsGMBCount === 0
                ? 'CREATE'
                : locationsGMBCount === 1
                ? 'SINGLE'
                : 'MULTIPLE',
        );
    }, [locationSpotOn]);

    const createLocation = () => {
        setLocationsState('CREATE');
    };

    const [selectedLocationId, setSelectedLocationId] = useState<any>(
        locationsState === 'CREATE'
            ? locationSpotOn.uuid4
            : locationsState === 'SINGLE' && locationsGMB.length
            ? locationsGMB[0].uuid4
            : null,
    );

    const selectedLocation =
        locationsState === 'CREATE'
            ? locationSpotOn
            : locationsState === 'SINGLE'
            ? locationsGMB[0]
            : selectedLocationId
            ? locationsGMB.find((el: any) => el.uuid4 === selectedLocationId)
            : null;

    const [isConnecting, setIsConnecting] = useState<boolean>(false);

    const apiList: any = {
        CONNECT: LocationsApi.connectLocation, // eslint-disable-line
        CREATE: LocationsApi.createLocation, // eslint-disable-line
        CLAIM: LocationsApi.claimLocation, // eslint-disable-line
        REQUEST: LocationsApi.requestAccess, // eslint-disable-line
    };
    const navigationList: any = {
        CONNECT: paths.pendingConnection, // eslint-disable-line
        CREATE: paths.pendingConnection, // eslint-disable-line
        CLAIM: paths.pendingConnection, // eslint-disable-line
        REQUEST: paths.pendingAccess, // eslint-disable-line
    };

    const isCategoryDisabled =
        ['CONNECT', 'REQUEST'].includes(selectedLocation?.action) ||
        !selectedLocation;

    const [isCategoryValid, setIsCategoryValid] = useState<boolean>(false);

    useEffect(() => {
        setIsCategoryValid(true);
    }, [category]);

    useEffect(() => {
        setCategory({
            value: locationsCategory.name,
            label: locationsCategory.displayName,
        });
        setIsCategoryValid(true);
    }, [selectedLocation]);

    const processSelectedLocation = () => {
        if (!isCategoryDisabled && !category?.value) {
            setIsCategoryValid(false);
            return;
        }

        event('connect_started', {
            ...fsIdentifiers,
            connect_source: selectedLocation?.action,
            category: category.value,
            locations_number: locationsGMBCount,
        } as { [property: string]: string });

        const locationAPI = apiList[selectedLocation.action];
        const args = {
            locationId: selectedLocation.locationId,
            placeId: selectedLocation.placeId,
            requestAdminRightsUrl: selectedLocation.requestAdminRightsUrl,
            name: category?.value,
        };

        setIsConnecting(true);
        locationAPI(args)
            .then(() => {
                if (selectedLocation.action === 'REQUEST') {
                    window.open(selectedLocation.requestAdminRightsUrl, '_blank');
                }
                navigation.navigate(
                    getPathnameWithQueryParams(
                        navigationList[selectedLocation.action],
                    ),
                );
            })
            .catch((error: any) => {
                if (error.response.status === 403) {
                    setTimeout(() => {
                        navigation.navigate(
                            getPathnameWithQueryParams(paths.pendingVerification),
                        );
                    }, 10000);
                } else {
                    // TODO: Do we need to change titles? Do we need titles at all?
                    showDetailedError({
                        template: `errors.connect.selectLocation.${selectedLocation.action}`,
                        error: error.response?.data,
                    });
                    setIsConnecting(false);
                }
            });
    };

    const [isSmallModalOpen, setIsSmallModalOpen] = useState<boolean>(false);

    const openSmallModal = () => {
        event('connect_help', fsIdentifiers as { [property: string]: string });
        setIsSmallModalOpen(true);
    };
    const closeSmallModal = () => {
        setIsSmallModalOpen(false);
    };

    return (
        <>
            <div className={styles.Content}>
                {locationsStatus.isLoading ? (
                    <LoadingIndicator />
                ) : locationsStatus.isError ? (
                    <div className={styles.ServerError}>{t('errors.server')}</div>
                ) : (
                    <>
                        <div className={styles.Header}>
                            {isConnecting
                                ? t(`connect.locationsHeaderConnecting`)
                                : t(`connect.locationsHeader.${locationsState}`)}
                        </div>

                        {!isConnecting ? (
                            <>
                                <div className={styles.Body}>
                                    <LocationList
                                        stateSelectedLocationId={[
                                            selectedLocationId,
                                            setSelectedLocationId,
                                        ]}
                                        storeLocations={
                                            locationsState === 'CREATE'
                                                ? [locationSpotOn]
                                                : locationsGMB
                                        }
                                    />
                                    {!(
                                        locationsState === 'SINGLE' &&
                                        isCategoryDisabled
                                    ) && (
                                        <SelectCategory
                                            categoryState={[category, setCategory]}
                                            isDisabled={isCategoryDisabled}
                                            isValid={isCategoryValid}
                                        />
                                    )}
                                </div>
                                <div className={styles.ButtonWrapper}>
                                    <Button
                                        className={styles.Button}
                                        disabled={!selectedLocation}
                                        onClick={processSelectedLocation}
                                    >
                                        {selectedLocation
                                            ? t(
                                                  `connect.locationsButton.${selectedLocation.action}`,
                                              )
                                            : t('connect.locationsButton.CREATE')}
                                    </Button>
                                    <span
                                        aria-hidden="true"
                                        onClick={openSmallModal}
                                    >
                                        <div className={styles.Help}>
                                            {t(
                                                `connect.locationsHelp.${locationsState}`,
                                            )}
                                        </div>
                                    </span>
                                </div>
                            </>
                        ) : (
                            <LoadingIndicator />
                        )}
                    </>
                )}
            </div>
            {isSmallModalOpen && (
                <SmallModal
                    title={t(`connect.locationsHelp.${locationsState}`)}
                    closeModal={closeSmallModal}
                    locationsState={locationsState}
                    createLocation={createLocation}
                />
            )}
        </>
    );
}
