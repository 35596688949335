import React from 'react';

import { MenuPhotos } from '../MenuPhotos';
import { UserMenuItems } from '../UserMenuItems';
import { WebsiteMenus } from '../WebsiteMenus';

import styles from './MenuScreen.module.scss';

export default function MenuScreen(): JSX.Element {
    return (
        <>
            <div className={styles.Wrapper}>
                <div className={styles.HalfSectionWrapper}>
                    <div className={styles.LeftSideWrapper}>
                        <MenuPhotos />
                        <WebsiteMenus />
                    </div>
                    <div className={styles.RightSideWrapper}>
                        <UserMenuItems />
                    </div>
                </div>
            </div>
        </>
    );
}
