// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoTooltip_TooltipIcon__2s2yK{display:block;margin-left:2px}.InfoTooltip_TooltipContentBottom__2q1kJ{margin-top:10px}\n", "",{"version":3,"sources":["webpack://src/features/common/components/InfoTooltip/InfoTooltip.module.scss"],"names":[],"mappings":"AAAA,gCACI,aAAc,CACd,eAAgB,CACnB,yCAGG,eAAgB","sourcesContent":[".TooltipIcon {\n    display: block;\n    margin-left: 2px;\n}\n\n.TooltipContentBottom {\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TooltipIcon": "InfoTooltip_TooltipIcon__2s2yK",
	"TooltipContentBottom": "InfoTooltip_TooltipContentBottom__2q1kJ"
};
export default ___CSS_LOADER_EXPORT___;
