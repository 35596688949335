import React from 'react';
import { Avatar } from 'spoton-lib';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';

import Google from 'assets/images/Google.svg';

import styles from './SignInButton.module.scss';

interface ISignInButton {
    type: 'primary' | 'secondary';
    scope: string;
    onSuccess: (googleResponse: CodeResponse) => void;
    picture?: string;
    onError?: (
        error: Pick<CodeResponse, 'error' | 'error_description' | 'error_uri'>,
    ) => void;
}

export default function SignInButton({
    type,
    scope,
    picture,
    onSuccess,
    onError,
}: ISignInButton): JSX.Element {
    const { t } = useTranslation();

    const login = useGoogleLogin({
        onSuccess: onSuccess,
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
        scope: scope,
        flow: 'auth-code',
    });

    if (type === 'secondary') {
        return (
            <div className={styles.Avatar} aria-hidden="true" onClick={login}>
                <Avatar photoUrl={picture} />
            </div>
        );
    }

    return (
        <button className={styles.SignInButton} onClick={login}>
            <img className={styles.Logo} src={Google} alt="Google logo" />
            {t('connect.signInButton')}
        </button>
    );
}
