import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { colors, Icon, Tooltip, Text } from 'spoton-lib';

import SearchValueContainer from './components/SearchValueContainer/SearchValueContainer';
import FormatOptionLabel from './components/FormatOptionLabel/FormatOptionLabel';
import Tag from './components/Tag/Tag';
import { IMultiSelectPropTypes } from './MultiSelectDropdown.types';

import styles from './MultiSelectDropdown.module.scss';

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/display-name */
const MultiSelectDropdown: React.FC<IMultiSelectPropTypes> = ({
    label,
    value,
    onChange,
    className,
    options,
    onInputChange,
    inputValue,
    isMobile = false,
    isLoading,
}) => {
    const { t } = useTranslation();

    return (
        <div>
            {label && (
                <Text type="label" className={styles.Label}>
                    {label}
                </Text>
            )}
            <Select
                components={{
                    /* eslint-disable react/prop-types */
                    ClearIndicator: ({ innerProps: { ref, ...restInnerProps } }) => {
                        if (isMobile) {
                            return null;
                        }

                        return (
                            <div
                                className={styles.ClearIndicator}
                                {...restInnerProps}
                                ref={ref}
                            >
                                <Icon
                                    size={24}
                                    className={styles.Block}
                                    color={colors.base50}
                                    name="ClearIcon"
                                    alt={t('CLEAR')}
                                    data-testid="clear-all-tags-icon"
                                />
                            </div>
                        );
                    },
                    DropdownIndicator: (props) => {
                        const {
                            selectProps: { menuIsOpen },
                            innerProps: { ref, ...restInnerProps },
                        } = props;

                        return (
                            <div
                                className={styles.DropdownIcon}
                                {...restInnerProps}
                                ref={ref}
                            >
                                <Icon
                                    size={26}
                                    className={styles.Block}
                                    color={colors.black}
                                    name={menuIsOpen ? 'CollapseIcon' : 'ExpandIcon'}
                                    alt="ExpandIcon"
                                />
                            </div>
                        );
                    },
                    MultiValue: (props) => {
                        const {
                            data,
                            children,
                            removeProps: { onClick },
                        } = props;

                        return (
                            <Tooltip
                                tooltipContent={children as string}
                                portalTarget={document.body}
                                tooltipClassName={styles.TagTooltip}
                            >
                                <Tag onClick={onClick}>{data.label}</Tag>
                            </Tooltip>
                        );
                    },
                    ValueContainer: SearchValueContainer,
                }}
                isSearchable={true}
                isMulti={true}
                value={value}
                onChange={onChange}
                closeMenuOnSelect={true}
                blurInputOnSelect={true}
                className={clsx(styles.MultiSelect, className)}
                options={options}
                formatOptionLabel={FormatOptionLabel}
                onInputChange={onInputChange}
                inputValue={inputValue}
                isLoading={isLoading}
                styles={{
                    valueContainer: (base) => ({
                        ...base,
                        position: 'relative',
                        display: 'flex',
                        flex: '1',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                        maxWidth: '770px',
                        padding: '0.4rem 0.4rem 0.4rem 47px',
                        overflowX: 'auto',
                        flexWrap: 'nowrap',
                    }),
                    control: (base) => ({
                        ...base,
                        borderColor: colors.base50,
                    }),
                }}
            />
        </div>
    );
};

export default MultiSelectDropdown;
