import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { showSuccess, showError } from 'utils/messages';
import { AppointmentLinksApi } from 'features/dashboard/api';
import i18n from 'i18n.config';

export const fetchAppointmentLinks: any = createAsyncThunk(
    'dashboard/fetchAppointmentLinks',
    async (payload: any, thunkAPI) => {
        try {
            const response = await AppointmentLinksApi.getAppointmentLinks(
                payload?.filter,
                payload?.pageToken,
            );

            return response.data;
        } catch (error) {
            const { message, response } = error as AxiosError<any, any>;
            //TODO redo error handling
            showError(`${message} while fetching appointment links`);
            return thunkAPI.rejectWithValue(response?.data);
        }
    },
);

export const createAppointmentLink: any = createAsyncThunk(
    'dashboard/createAppointmentLink',
    async (payload: any, thunkAPI) => {
        try {
            const response = await AppointmentLinksApi.createAppointmentLink(
                payload?.uri,
            );
            showSuccess({
                title: i18n.t('editsSaved'),
                message: i18n.t('profile.appointmentLinks.appointmentLinkEdited'),
            });
            thunkAPI.dispatch(fetchAppointmentLinks());
            return response.data;
        } catch (error) {
            const { message, response } = error as AxiosError<any, any>;
            showError(`${message} while creating appointment link`);
            return thunkAPI.rejectWithValue(response?.data);
        }
    },
);

export const editAppointmentLink: any = createAsyncThunk(
    'dashboard/editAppointmentLink',
    async (payload: any, thunkAPI) => {
        try {
            const response = await AppointmentLinksApi.editAppointmentLink(
                payload?.id,
                payload?.uri,
            );
            showSuccess({
                title: i18n.t('editsSaved'),
                message: i18n.t('profile.appointmentLinks.appointmentLinkEdited'),
            });
            thunkAPI.dispatch(fetchAppointmentLinks());
            return response.data;
        } catch (error) {
            const { message, response } = error as AxiosError<any, any>;
            showError(`${message} while editing appointment link`);
            return thunkAPI.rejectWithValue(response?.data);
        }
    },
);

export const removeAppointmentLink: any = createAsyncThunk(
    'dashboard/removeAppointmentLink',
    async (payload: any, thunkAPI) => {
        try {
            const response = await AppointmentLinksApi.removeAppointmentLink(
                payload?.id,
            );
            showSuccess({
                title: i18n.t('editsSaved'),
                message: i18n.t('profile.appointmentLinks.appointmentLinkEdited'),
            });
            thunkAPI.dispatch(fetchAppointmentLinks());

            return response.data;
        } catch (error) {
            const { message, response } = error as AxiosError<any, any>;
            showError(`${message} while removing appointment link`);
            return thunkAPI.rejectWithValue({
                data: response?.data,
            });
        }
    },
);
