import React from 'react';
import { ReviewsSection } from '../ReviewsSection';
import { QuestionsSection } from '../QuestionSection';
import { BusinessActivitySection } from '../BusinessActivitySection';
import { MediaSection } from '../MediaSection';
import { PostSection } from '../PostSection';
import { MobileGoogleLinksSection } from '../MobileGoogleLinksSection';
import { GoogleAdvisor } from '../GoogleAdvisor';
import { useBreakpoint } from 'utils/customHooks';

import styles from './HomeScreen.module.scss';

export default function Homescreen(): JSX.Element {
    const isMobile = useBreakpoint() !== 'desktop';

    return (
        <div className={styles.Wrapper}>
            <BusinessActivitySection isMobile={isMobile} />
            {isMobile ? (
                <div className={styles.HalfSectionWrapper}>
                    <GoogleAdvisor />
                    <ReviewsSection />
                    <QuestionsSection />
                    <MediaSection isMobile={isMobile} />
                    <PostSection />
                    <MobileGoogleLinksSection />
                </div>
            ) : (
                <div className={styles.HalfSectionWrapper}>
                    <div className={styles.LeftSideWrapper}>
                        <ReviewsSection />
                        <QuestionsSection />
                    </div>
                    <div className={styles.RightSideWrapper}>
                        <GoogleAdvisor />
                        <MediaSection />
                        <PostSection />
                    </div>
                </div>
            )}
        </div>
    );
}
