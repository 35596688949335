import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, Icon, IconButton, LoadingIndicator, Tabs } from 'spoton-lib';
import { useTranslation } from 'react-i18next';

import styles from './QuestionsModal.module.scss';
import { IQuestions, QuestionTabs } from '../../../../types/QuestionsSectionTypes';
import serverErrorImage from '../../../../assets/serverError.png';
import NoItemsImageSorted from '../../../../../common/assets/noItems.png';
import { Question } from '../Question';
import { sortOptions } from '../../config';
import { useBreakpoint } from '../../../../../../utils/customHooks';

interface IProps {
    questionsData: IQuestions | undefined;
    isLoading: boolean;
    hasQuestions?: boolean;
    isError: boolean;
    closeModal: any;
    sortOrder: any;
    order: string;
}
export default function QuestionsModal({
    closeModal,
    isLoading,
    isError,
    questionsData,
    sortOrder,
    order,
}: IProps): JSX.Element {
    const { t } = useTranslation();
    const isMobile = useBreakpoint() !== 'desktop';
    const [activeTab, setActiveTab] = useState(QuestionTabs.all);
    const hasQuestions =
        questionsData?.questions && questionsData?.questions.length > 0;
    const handleChangeTab = useCallback((name) => {
        setActiveTab(name);
    }, []);

    const repliedQuestions = useMemo(
        () => questionsData?.questions?.filter((item: any) => item?.topAnswers),
        [questionsData],
    );

    const notRepliedQuestions = useMemo(
        () => questionsData?.questions?.filter((item) => !item?.topAnswers),
        [questionsData],
    );

    const choosePeriod = (period: any): void => {
        sortOrder(period);
    };

    const questions = useMemo(() => {
        switch (activeTab) {
            case QuestionTabs.replied:
                return repliedQuestions;
            case QuestionTabs.notReplied:
                return notRepliedQuestions;
            default:
                return questionsData?.questions;
        }
    }, [questionsData, repliedQuestions, notRepliedQuestions, activeTab]);

    const mobileHeader = () => (
        <div className={styles.PopupHeadingWrapper}>
            <div className={styles.ModalControlsWrapper}>
                <IconButton
                    name="ArrowLeftIcon"
                    alt={t('close')}
                    buttonSize="large"
                    size={60}
                    variant="secondary"
                    disableBorder
                    className={styles.BackIcon}
                    onClick={closeModal}
                />
                <h3 className={styles.MobileHeading}>
                    {t('dashboard.questions.mobileTitle')}
                </h3>
            </div>
            <Dropdown
                className={styles.MobileDropdown}
                isSearchable={false}
                onChange={choosePeriod}
                trigger={
                    <Icon
                        name="DefaultViewIcon"
                        className={styles.MobileSortBtn}
                        color="#1769ff"
                        alt="Options"
                        size={30}
                    />
                }
                defaultValue={{
                    value: 'updateTime desc',
                    label: t('dashboard.questions.sortDateDesc'),
                }}
                options={sortOptions}
            />
        </div>
    );

    const desktopHeader = () => (
        <div className={styles.Header}>
            <h3 className={styles.HeaderTitle}>{t('dashboard.questions.title')}</h3>
            <Dropdown
                className={styles.Dropdown}
                value={order}
                onChange={choosePeriod}
                options={sortOptions}
            />
            <IconButton
                name="CloseIcon"
                alt={t('close')}
                buttonSize="regular"
                size={36}
                variant="secondary"
                disableBorder
                className={styles.CloseIcon}
                onClick={closeModal}
            />
        </div>
    );

    return (
        <>
            {isMobile && mobileHeader()}
            {!isMobile && desktopHeader()}
            <Tabs
                active={activeTab}
                onSelect={handleChangeTab}
                className={styles.Tabs}
            >
                <Tabs.Tab name={QuestionTabs.all}>{t('all')}</Tabs.Tab>
                <Tabs.Tab name={QuestionTabs.replied}>
                    {t('dashboard.replied')}
                </Tabs.Tab>
                <Tabs.Tab name={QuestionTabs.notReplied}>
                    {t('dashboard.notReplied')}
                </Tabs.Tab>
            </Tabs>
            <div className={styles.TabsContent}>
                {isError && (
                    <div className={styles.CenterContainer}>
                        <img src={serverErrorImage} alt="Server error" />
                        <p className={styles.serverError}>{t('errors.server')}</p>
                    </div>
                )}
                {isLoading && (
                    <div className={styles.CenterContainer}>
                        <LoadingIndicator />
                    </div>
                )}
                {!isLoading && questions && !questions.length && (
                    <div className={styles.CenterContainer}>
                        <img src={NoItemsImageSorted} alt="No items" />
                        <p className={styles.serverError}>
                            {activeTab === QuestionTabs.all &&
                                t('dashboard.questions.noQuestions')}

                            {activeTab === QuestionTabs.replied &&
                                (hasQuestions
                                    ? t('dashboard.questions.noQuestionsReplied')
                                    : t('dashboard.questions.noQuestions'))}

                            {activeTab === QuestionTabs.notReplied &&
                                (hasQuestions
                                    ? t('dashboard.questions.noQuestionsNotReplied')
                                    : t('dashboard.questions.noQuestions'))}
                        </p>
                    </div>
                )}
                {!isLoading &&
                    questions?.map((question) => (
                        <Question
                            isModal={true}
                            sortOrder={order}
                            key={question.questionId}
                            question={question}
                        />
                    ))}
            </div>
        </>
    );
}
