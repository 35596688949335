import i18n from 'i18next';

export const sortOptions = [
    {
        value: 'updateTime desc',
        label: i18n.t('dashboard.questions.sortDateDesc'),
    },
    {
        value: 'upvoteCount desc',
        label: i18n.t('dashboard.questions.sortRatingDesc'),
    },
];
