import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { showSuccess, showDetailedError } from 'utils/messages';
import { BusinessInfoApi } from 'features/dashboard/api';
import { IPostBusinessInfoPayload } from 'features/dashboard/api/Post.types';
import i18n from 'i18n.config';

export const fetchBusinessInfo: any = createAsyncThunk(
    'dashboard/fetchBusinessInfo',
    async (_, thunkAPI) => {
        try {
            const response = await BusinessInfoApi.getBusinessInfo();
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.dashboard.profileFetch',
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);

export const updateBusinessInfo: any = createAsyncThunk(
    'dashboard/updateBusinessInfo',
    async (payload: IPostBusinessInfoPayload, thunkAPI) => {
        const { formInfo, coverImg, profileImg } = payload;
        try {
            const response = await BusinessInfoApi.updateBusinessInfo(
                formInfo,
                coverImg,
                profileImg,
            );
            showSuccess({
                title: i18n.t('editsSaved'),
                message: i18n.t('timeToUpdate'),
            });
            return response.data;
        } catch (error: any) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                error: data,
                message:
                    data.error_type === 'PROFILE_MEDIA' ? data.message : undefined,
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);
