import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { event } from 'react-fullstory';
import { Card, LoadingIndicator } from 'spoton-lib';

import { RootState } from 'reduxConfig';
import { useSelector, useDispatch } from 'react-redux';

import { getVerification } from 'features/account/redux/verification/verification.actions';
import { selectVerification } from 'features/account/redux/verification/verification.selectors';
import {
    selectFsIdentifiers,
    selectStatus,
} from 'features/common/redux/status/status.selectors';

import styles from './Verification.module.scss';

import VerificationOptions from './VerificationOptions.component';
import VerificationCode from './VerificationCode.component';
import VerificationError from './VerificationError.component';

import GoogleKiosk from 'features/account/assets/images/googleKiosk.png';

export default function PendingVerification(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const connectStatus = useSelector(selectStatus);
    const fsIdentifiers = useSelector(selectFsIdentifiers);

    useEffect(() => {
        dispatch(getVerification());

        if (connectStatus.statuses.connectFlow === 'LINKED') {
            event('success', {
                ...fsIdentifiers,
                connect_source: connectStatus.statuses.connectWay,
            } as { [property: string]: string });
        }
    }, []);

    const verification = useSelector((state: RootState) =>
        selectVerification(state),
    );

    const [status, setStatus] = useState<string>(
        verification.currentVerification?.state === 'PENDING' ? 'code' : 'options',
    );

    const showOptions = () => {
        setStatus('options');
    };

    const showCode = () => {
        setStatus('code');
    };

    const showCodeError = () => {
        setStatus('error');
    };

    return (
        <div className={styles.Container}>
            <div className={styles.Title}>
                {t('connect.manualVerificationTitle')}
            </div>
            <Card elevation={1} className={styles.Card}>
                <div className={styles.Content}>
                    <img
                        className={styles.ImageKiosk}
                        src={GoogleKiosk}
                        alt="google kiosk"
                    />
                    {!verification.isLoaded ? (
                        <LoadingIndicator />
                    ) : status === 'options' ? (
                        <VerificationOptions
                            showCode={showCode}
                            isError={verification.isError}
                        />
                    ) : status === 'code' ? (
                        <VerificationCode
                            showOptions={showOptions}
                            showCodeError={showCodeError}
                        />
                    ) : (
                        status === 'error' && (
                            <VerificationError showOptions={showOptions} />
                        )
                    )}
                </div>
            </Card>
        </div>
    );
}
