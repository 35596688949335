import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { showDetailedError } from 'utils/messages';
import { AdvisorApi } from '../../../dashboard/api';

export const fetchAdvisorStatus: any = createAsyncThunk(
    'dashboard/fetchAdvisorStatus',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AdvisorApi.getAdvisorStatus();
            return response.data;
        } catch (error) {
            showDetailedError({
                template: 'errors.dashboard.advisorFetch',
            });
            return rejectWithValue({
                data: (error as AxiosError<any, any>).response?.data,
                status: (error as AxiosError<any, any>).response?.status,
            });
        }
    },
);
