import React, { useCallback, useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { RootState } from 'reduxConfig';
import { useSelector, useDispatch } from 'react-redux';

import { getUserInfo } from 'features/account/redux/userInfo/userInfo.actions';
import { selectUserInfo } from 'features/account/redux/userInfo/userInfo.selectors';

import { getAuthConfig } from 'features/account/redux/auth/auth.actions';
import { postAuthCode } from 'features/account/redux/auth/auth.actions';
import { selectAuthConfig } from 'features/account/redux/auth/auth.selectors';
import { selectFsIdentifiers } from 'features/common/redux/status/status.selectors';
import { selectLocations } from 'features/account/redux/locations/locations.selectors';

import { LoadingIndicator, Card } from 'spoton-lib';

import SelectLocation from './SelectLocation.component';
import SignInButton from './components/SignInButton/SignInButton.component';

import { useTranslation } from 'react-i18next';
import styles from './Connect.module.scss';

import ImageKiosk from 'features/account/assets/images/googleKiosk.png';

import { event } from 'react-fullstory';

export default function Connect(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserInfo());
        dispatch(getAuthConfig());
    }, []);

    const fsIdentifiers = useSelector(selectFsIdentifiers);
    const userInfo = useSelector((state: RootState) => selectUserInfo(state));
    const authConfig = useSelector((state: RootState) => selectAuthConfig(state));
    const locations = useSelector((state: RootState) => selectLocations(state));
    const dataLoaded = userInfo.isLoaded && authConfig.isLoaded;

    const postAuthCodeFromGoogle = useCallback(
        (type: 'primary' | 'secondary') => (googleResponse: any) => {
            event('sign_in', {
                ...fsIdentifiers,
                type: type,
            } as { [property: string]: string });
            dispatch(postAuthCode({ authorizationCode: googleResponse.code }));
        },
        [fsIdentifiers],
    );

    const title = !(userInfo.isLoaded && authConfig.isLoaded)
        ? ''
        : !userInfo.email
        ? t(`connect.locationsTitle.SIGNIN`)
        : locations.isLoading || !locations.spoton?.locationName
        ? t(`connect.locationsTitle.SEARCH`)
        : locations.locations.length === 0
        ? t(`connect.locationsTitle.CREATE`)
        : locations.locations.length === 1
        ? t(`connect.locationsTitle.${locations.locations[0].action}`)
        : t(`connect.locationsTitle.MULTIPLE`);

    return (
        <GoogleOAuthProvider clientId={authConfig.clientId}>
            <div className={styles.Container}>
                <div className={styles.Title}>{title}</div>
                <Card elevation={1} className={styles.Card}>
                    {dataLoaded && userInfo.email && (
                        <div className={styles.Account}>
                            <div className={styles.AccountInfo}>
                                <div className={styles.AccountName}>
                                    {userInfo.name}
                                </div>
                                <div className={styles.AccountEmail}>
                                    {userInfo.email}
                                </div>
                            </div>
                            <SignInButton
                                type="secondary"
                                scope={authConfig.scope}
                                onSuccess={postAuthCodeFromGoogle('secondary')}
                                picture={userInfo.picture}
                            />
                        </div>
                    )}
                    <img
                        className={styles.ImageKiosk}
                        src={ImageKiosk}
                        alt="google kiosk"
                    />
                    {!dataLoaded && <LoadingIndicator />}
                    {dataLoaded && authConfig.isError && (
                        <div className={styles.ServerError}>
                            {t('errors.server')}
                        </div>
                    )}
                    {dataLoaded && !authConfig.isError && userInfo.email && (
                        <SelectLocation />
                    )}
                    {dataLoaded && !authConfig.isError && !userInfo.email && (
                        <>
                            <div className={styles.Header}>
                                {t('connect.signInTitle')}
                            </div>
                            <div className={styles.ButtonContainer}>
                                <div className={styles.ButtonWrapper}>
                                    <SignInButton
                                        type="primary"
                                        scope={authConfig.scope}
                                        onSuccess={postAuthCodeFromGoogle('primary')}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </Card>
            </div>
        </GoogleOAuthProvider>
    );
}
