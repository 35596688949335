import { useState, useEffect } from 'react';

export default function useDebounce(value: string, delay: number): any {
    const [debouncedValue, setDebouncedValue] = useState<any>(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value]);
    return debouncedValue;
}
