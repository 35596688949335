import { combineReducers, createSlice } from '@reduxjs/toolkit';
import {
    fetchMenuItems,
    fetchMenuLinks,
    updateMenuLinks,
    addMenuPhoto,
    getMenuPhotos,
    deleteMenuPhoto,
} from './menu.actions';

const menuItemsSlice = createSlice({
    name: 'menuItems',
    initialState: {
        data: {
            name: '',
            menus: [],
        },
        loading: false,
        error: false,
    },
    reducers: {
        // standard reducer logic, if local state need to be changed
    },
    extraReducers: {
        [fetchMenuItems.pending]: (state) => {
            state.loading = true;
            state.error = false;
        },
        [fetchMenuItems.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [fetchMenuItems.rejected]: (state) => {
            state.loading = false;
            state.error = true;
        },
    },
});

const menuLinksSlice = createSlice({
    name: 'menuLinks',
    initialState: {
        data: {
            url_menu: [],
            url_order_ahead: [],
        },
        loading: false,
        error: false,
    },
    reducers: {
        // standard reducer logic, if local state need to be changed
    },
    extraReducers: {
        [fetchMenuLinks.pending]: (state) => {
            state.loading = true;
            state.error = false;
        },
        [fetchMenuLinks.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [fetchMenuLinks.rejected]: (state) => {
            state.loading = false;
            state.error = true;
        },
        [updateMenuLinks.pending]: (state) => {
            state.loading = true;
            state.error = false;
        },
        [updateMenuLinks.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [updateMenuLinks.rejected]: (state) => {
            state.loading = false;
            state.error = true;
        },
    },
});

const menuPhotosSlice = createSlice({
    name: 'menuPhotos',
    initialState: {
        photos: {
            mediaItems: [],
            nextPageToken: '',
        },
        loading: false,
        error: false,
        uploadLoading: false,
        uploadError: false,
        deleteLoading: false,
        deleteError: false,
    },
    reducers: {
        // standard reducer logic, if local state need to be changed
    },
    extraReducers: {
        [addMenuPhoto.pending]: (state) => {
            state.uploadLoading = true;
            state.uploadError = false;
        },
        [addMenuPhoto.fulfilled]: (state) => {
            state.uploadLoading = false;
        },
        [addMenuPhoto.rejected]: (state) => {
            state.uploadLoading = false;
            state.uploadError = true;
        },
        [getMenuPhotos.pending]: (state) => {
            state.loading = true;
            state.error = false;
        },
        [getMenuPhotos.fulfilled]: (state: any, action) => {
            state.photos = action.payload;
            state.loading = false;
        },
        [getMenuPhotos.rejected]: (state) => {
            state.loading = false;
            state.error = true;
        },
        [deleteMenuPhoto.pending]: (state) => {
            state.deleteLoading = true;
            state.deleteError = false;
        },
        [deleteMenuPhoto.fulfilled]: (state) => {
            state.deleteLoading = false;
        },
        [deleteMenuPhoto.rejected]: (state) => {
            state.deleteLoading = false;
            state.deleteError = true;
        },
    },
});

export default combineReducers({
    menuItems: menuItemsSlice.reducer,
    menuLinks: menuLinksSlice.reducer,
    menuPhotos: menuPhotosSlice.reducer,
});
