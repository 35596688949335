import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'reduxConfig';

import { IBusinessInfoState } from 'features/dashboard/redux/businessInfo/businessInfo.types';

export const selectBusinessInfoState = (state: RootState): IBusinessInfoState =>
    state?.dashboard?.businessInfo;

export const selectBusinessInfoData = createSelector(
    selectBusinessInfoState,
    (state: IBusinessInfoState) => state?.data,
);
export const selectServiceAreaData = createSelector(
    selectBusinessInfoData,
    (state: any) => state?.serviceArea?.places?.placeInfos,
);
export const selectBusinessInfoLoadingStatus = createSelector(
    selectBusinessInfoState,
    (state: IBusinessInfoState) => state?.loading,
);
export const selectBusinessInfoInitialLoading = createSelector(
    selectBusinessInfoState,
    (state: IBusinessInfoState) => state?.isInitialLoading,
);
export const selectBusinessInfoErrorStatus = createSelector(
    selectBusinessInfoState,
    (state: IBusinessInfoState) => state?.error,
);
export const selectBusinessInfoSavingStatus = createSelector(
    selectBusinessInfoState,
    (state: IBusinessInfoState) => state?.savingInfo,
);

export const selectBusinessInfoSavingErrorStatus = createSelector(
    selectBusinessInfoState,
    (state: IBusinessInfoState) => state?.infoSavingError,
);
