import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'reduxConfig';

import { IAuthState } from './auth.types';

export const selectAuthState = (state: RootState): IAuthState => state.auth;

export const selectIsAuthenticated = createSelector(
    selectAuthState,
    (state) => state.isAuthenticated,
);

export const selectMerchantLocationId = createSelector(
    selectAuthState,
    (state) => state.merchantLocationId,
);

export const selectRetrieveMerchantLocationIdRequestState = createSelector(
    selectAuthState,
    (state) => state.retrieveMerchantLocationIdRequestState,
);
