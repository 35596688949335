import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './Modal.module.scss';
import { Button, IconButton } from 'spoton-lib';

interface IModalTypes {
    children: React.ReactNode;
}

export default function Modal(props: IModalTypes): JSX.Element {
    const { children } = props;

    return (
        <>
            <ModalHeader
                className={styles.Modal}
                title="viewAll"
                closeModal={() => null}
            />
            <div className={styles.ModalBody}>{children}</div>
            <ModalFooter
                primaryBtn={['submit', () => null]}
                secondaryBtn={['discard', () => null]}
            />
        </>
    );
}

type ModalHeaderType = {
    title: string;
    closeModal(): void;
    className?: string;
};

export function ModalHeader(props: ModalHeaderType): JSX.Element {
    const { t } = useTranslation();
    const { title, closeModal, className } = props;

    return (
        <div className={classNames(styles.ModalHeader, className)}>
            <div className={styles.ModalHeaderText}>{t(title)}</div>
            <IconButton
                name="CloseIcon"
                alt={t('close')}
                buttonSize="regular"
                size={36}
                variant="secondary"
                disableBorder
                className={styles.ModalHeaderIcon}
                onClick={closeModal}
            />
        </div>
    );
}
// eslint-disable-next-line
export function ModalFooter(props: any): JSX.Element {
    const { t } = useTranslation();
    const { primaryBtn, secondaryBtn, tertiaryBtn } = props;

    return (
        <footer className={styles.Footer}>
            {tertiaryBtn.length && (
                <IconButton
                    name="DeleteIcon"
                    alt={t(tertiaryBtn[0])}
                    buttonSize="regular"
                    size={36}
                    variant="secondary"
                    disableBorder
                    className={styles.ButtonTertiary}
                    onClick={tertiaryBtn[1]}
                />
            )}
            <div className={styles.ButtonContainer}>
                <Button variant="secondary" onClick={secondaryBtn[1]}>
                    {t(secondaryBtn[0])}
                </Button>
                <Button
                    className={styles.ButtonPrimary}
                    variant="primary"
                    onClick={primaryBtn[1]}
                >
                    {t(primaryBtn[0])}
                </Button>
            </div>
        </footer>
    );
}
