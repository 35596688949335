import { axios, AxiosResponse } from 'features/common/utils/axios.utils';

export function getMenuImages(
    pageSize: number,
    pageToken?: string | null,
): Promise<AxiosResponse<any>> {
    return axios.get('/api/menu/media', {
        params: {
            pageSize,
            ...(!!pageToken && { pageToken }),
        },
    });
}

export function postMenuImages(image: File): Promise<AxiosResponse<null>> {
    const formData = new FormData();
    formData.append('media', image, image.name);
    return axios.post('/api/menu/media', formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
}

export function deleteMenuImage(id: string): Promise<AxiosResponse<null>> {
    return axios.delete(`/api/menu/media${id}/`);
}
