import React, { useState } from 'react';
import moment from 'moment';
import { IconButton } from 'spoton-lib';
import Menu from 'features/common/components/Menu';

import { useTranslation } from 'react-i18next';
import styles from './PostHeader.module.scss';

interface IPostHeaderProps {
    searchUrl: string;
    title?: string;
    updateDate: string;
    imageUrl?: string;
    contentHeight: number;
    openSmallModal: () => void;
    openDeleteModal: () => void;
}

export default function PostHeader({
    searchUrl,
    title,
    updateDate,
    imageUrl,
    contentHeight,
    openSmallModal,
    openDeleteModal,
}: IPostHeaderProps): JSX.Element {
    const { t } = useTranslation();

    const [isShowingPostMenu, setIsShowingPostMenu] = useState<boolean>(false);
    const togglePostMenu = () => {
        setIsShowingPostMenu(!isShowingPostMenu);
    };
    const closePostMenu = () => {
        setIsShowingPostMenu(false);
    };

    const date = moment(new Date(updateDate)).format('ll');

    const editPost = () => {
        openSmallModal();
        closePostMenu();
    };
    const deletePost = () => {
        openDeleteModal();
        closePostMenu();
    };
    const viewPost = () => {
        window.open(searchUrl);
        closePostMenu();
    };

    return (
        <div
            className={styles.PostHeader}
            style={
                imageUrl
                    ? {
                          background:
                              'linear-gradient(180deg, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.6) 100%),' +
                              ` url("${imageUrl}")`,
                          height: `calc(100% - ${contentHeight.toString()}px)`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          color: '#F7F7FA',
                          stroke: '#F7F7FA',
                          fill: '#F7F7FA',
                      }
                    : {}
            }
            onMouseLeave={closePostMenu}
        >
            {title && <div className={styles.PostTitle}>{title}</div>}
            <div className={styles.PostDate}>
                {t('dashboard.postDateCreated')} {date}
            </div>
            <div className={styles.PostMenuContainer}>
                <IconButton
                    name="MoreVerticalIcon"
                    alt="more icon"
                    disableBorder={true}
                    variant="secondary"
                    size={24}
                    className={styles.PostMenuIcon}
                    onClick={togglePostMenu}
                />
                {isShowingPostMenu && (
                    <Menu className={styles.Menu}>
                        <Menu.item onClick={editPost}>{t('edit')}</Menu.item>
                        <Menu.item onClick={deletePost}>{t('delete')}</Menu.item>
                        <Menu.item onClick={viewPost}>{t('viewOnGoogle')}</Menu.item>
                    </Menu>
                )}
            </div>
        </div>
    );
}
