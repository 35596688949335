import { RootState } from 'reduxConfig';
import { createSelector } from '@reduxjs/toolkit';
import { IStatusTypes, FsIdentifiers, Status } from './status.types';

export const selectStatusState = (state: RootState): IStatusTypes => state.status;

export const selectStatus = createSelector<RootState, IStatusTypes, Status>(
    selectStatusState,
    (state) => state.data,
);

export const selectFsIdentifiers = createSelector<
    RootState,
    IStatusTypes,
    FsIdentifiers
>(selectStatusState, (state) => state?.fsIdentifiers);

export const selectMerchantType = createSelector(
    selectStatusState,
    (state) => state.data.merchantInfo.type,
);

export const selectSublocations = createSelector(
    selectStatusState,
    (state) => state.data.merchantInfo.sublocations,
);

export const selectStatusLoadedState = createSelector(
    selectStatusState,
    (state) => state.isLoaded,
);

export const selectStatusErrorState = createSelector(
    selectStatusState,
    (state) => state.isError,
);
