import React from 'react';
import { mount, withView, route, compose, map, redirect } from 'navi';

import i18n from 'i18n.config';
import { DashboardScreen } from '../DashboardScreen';
import { HomeScreen } from '../home/HomeScreen';
import { ProfileScreen } from '../profile/ProfileScreen';
import { MenuScreen } from '../menu/MenuScreen';
import { paths } from 'app/routes/app.routes';

export const dashboardPaths = {
    home: '/',
    profile: '/profile',
    menu: '/menu',
};

export const routes = compose(
    withView(<DashboardScreen />),
    mount({
        '*': map(() => redirect(paths.dashboard, { exact: false })),
        [dashboardPaths.home]: route({
            title: `${i18n.t('applicationShortName')} ${i18n.t('home')}`,
            view: <HomeScreen />,
        }),
        [dashboardPaths.profile]: route({
            title: `${i18n.t('applicationShortName')} ${i18n.t('profileMenu')}`,
            view: <ProfileScreen />,
        }),
        [dashboardPaths.menu]: route({
            title: `${i18n.t('applicationShortName')} ${i18n.t('menu')}`,
            view: <MenuScreen />,
        }),
    }),
);

export default routes;
