import React, { useEffect, useCallback, useMemo } from 'react';
import { useNavigation, View } from 'react-navi';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { LoadingOverlay, IconButton } from 'spoton-lib';

import i18n from 'i18n.config';
import { BusinessHoursModal } from 'features/common/components';
import TabNavigationSection from '../TabNavigationSection/TabNavigationSection.component';
import { UpdateBusinessInfoModal } from 'features/common/components/UpdateBusinessInfoModal';
import { QuickActionsModal } from './components';
import {
    homeUploadMedia,
    showMediaSectionModal,
    addMenuPhoto,
} from 'features/dashboard/redux';

import { useBreakpoint } from 'utils/customHooks';
import { getPathnameWithQueryParams } from 'app/components/App/App.utils';
import useNoEnabledPost from 'features/dashboard/hooks/useNoEnabledPost';

import { QuickAction } from './components/utils';

import {
    fetchBusinessInfo,
    hideQuickActionModal,
    selectBusinessInfoData,
    selectBusinessInfoInitialLoading,
    selectQuickActionModalState,
    showBusinessHoursModal,
    showPostsSectionModalForCreate,
    showQuickActionModal,
    showUpdateBusinessInfoModal,
} from '../redux';

import styles from './DashboardScreen.module.scss';
import { paths } from 'app/routes/app.routes';
import { dashboardPaths } from '../routes/dashboard.routes';

export default function DashboardScreen(): JSX.Element {
    const generalTabs = [
        {
            tabName: i18n.t('home'),
            tabPath: paths.dashboard,
            rules: [],
        },
        {
            tabName: i18n.t('profileMenu'),
            tabPath: paths.dashboard + dashboardPaths.profile,
            rules: [],
        },
    ];

    const menuTabs = [
        {
            tabName: i18n.t('menu'),
            tabPath: paths.dashboard + dashboardPaths.menu,
            rules: ['canHaveFoodMenus'],
        },
    ];

    const nonMeTabs = [
        {
            tabName: i18n.t('products'),
            tabPath: '/dashboard/products',
            rules: ['canNotHaveFoodMenus'],
        },
        {
            tabName: i18n.t('services'),
            tabPath: '/dashboard/services',
            rules: ['canNotHaveFoodMenus'],
        },
    ];
    const dispatch = useDispatch();

    const isMobile = useBreakpoint() !== 'desktop';

    const navigation = useNavigation();

    const businessInfoData = useSelector(selectBusinessInfoData);
    const isInitialLoading = useSelector(selectBusinessInfoInitialLoading);
    const isActionModalOpen = useSelector(selectQuickActionModalState);

    const canHaveFoodMenus = get(businessInfoData, 'metadata.canHaveFoodMenus');
    const canHaveFoodProductsServices = false;

    const { isPostsEnabled, notifyNoPost } = useNoEnabledPost();

    const customTabs = useMemo(() => {
        if (!isInitialLoading && canHaveFoodMenus) {
            return menuTabs;
        }
        if (!isInitialLoading && canHaveFoodProductsServices) {
            return nonMeTabs;
        }
        return [];
    }, [canHaveFoodMenus, canHaveFoodProductsServices, isInitialLoading]);

    useEffect(() => {
        dispatch(fetchBusinessInfo());
    }, [dispatch]);

    const onOpenQuickActionModal = useCallback(() => {
        dispatch(showQuickActionModal());
    }, [dispatch]);

    const onCloseQuickActionModal = useCallback(() => {
        dispatch(hideQuickActionModal());
    }, [dispatch]);

    const handleQuickAction = useCallback(
        (actionType) => (files?: File[]) => {
            onCloseQuickActionModal();
            switch (actionType) {
                case QuickAction.UPLOAD_HOURS:
                    dispatch(showBusinessHoursModal());
                    break;

                case QuickAction.UPLOAD_MEDIA:
                    files?.map((item: File) => dispatch(homeUploadMedia(item)));
                    navigation.navigate(getPathnameWithQueryParams(paths.dashboard));
                    dispatch(showMediaSectionModal());
                    break;

                case QuickAction.UPLOAD_MENU_PHOTO:
                    files?.map((item: File) => dispatch(addMenuPhoto(item)));
                    navigation.navigate(
                        getPathnameWithQueryParams(
                            paths.dashboard + dashboardPaths.menu,
                        ),
                    );
                    break;

                case QuickAction.CREATE_POST:
                    if (isPostsEnabled) {
                        navigation.navigate(
                            getPathnameWithQueryParams(paths.dashboard),
                        );
                        dispatch(showPostsSectionModalForCreate());
                    } else {
                        notifyNoPost();
                    }
                    break;

                case QuickAction.UPDATE_PROFILE:
                    dispatch(showUpdateBusinessInfoModal());
                    break;
                default:
                    break;
            }
        },
        [dispatch],
    );

    const tabsForCurrentUser = [generalTabs[0], ...customTabs, generalTabs[1]];

    useEffect(() => {
        // https://spotonteam.atlassian.net/browse/MC-2776
        const redirectTo = sessionStorage.getItem('redirectTo');
        if (redirectTo) {
            sessionStorage.setItem('redirectTo', '');
            location.href = redirectTo;
        }
    }, []);

    return (
        <div className={styles.Wrapper}>
            {isInitialLoading ? (
                <LoadingOverlay hasBackgroundBlur={true} />
            ) : (
                <>
                    <BusinessHoursModal />
                    <UpdateBusinessInfoModal />
                    <TabNavigationSection tabs={tabsForCurrentUser} />
                    <View />

                    {isMobile && (
                        <>
                            <IconButton
                                className={styles.QuickActionButton}
                                name="AddIcon"
                                variant="secondary"
                                disableBorder={true}
                                alt="AddIcon"
                                onClick={onOpenQuickActionModal}
                            />
                            <QuickActionsModal
                                isOpen={isActionModalOpen}
                                onAction={handleQuickAction}
                                closeModal={onCloseQuickActionModal}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
}
