import React, { ChangeEvent, forwardRef } from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

import styles from './CustomInputMask.module.scss';

interface IProps {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    mask: string;
    error?: string;
    label?: string;
}

const customInputMask = forwardRef<HTMLDivElement, IProps>(
    ({ value, onChange, mask, label, error }: IProps, ref): JSX.Element => {
        return (
            <div className={styles.InputMaskWrapper} ref={ref}>
                {label && <div className={styles.InputMaskLabel}>{label}</div>}
                <InputMask
                    mask={mask}
                    value={value}
                    onChange={onChange}
                    className={classNames(styles.InputMask, {
                        [styles.InputMaskWithError]: error,
                    })}
                />
                {error && <div className={styles.InputMaskError}>{error}</div>}
            </div>
        );
    },
);
customInputMask.displayName = 'CustomInputMask';

export default customInputMask;
