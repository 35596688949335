import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-navi';
import { event } from 'react-fullstory';

import { Card, LoadingIndicator, Button } from 'spoton-lib';

import { RootState } from 'reduxConfig';

import { showDetailedError } from 'utils/messages';
import { useBreakpoint } from 'utils/customHooks';

import { getPathnameWithQueryParams } from 'app/components/App/App.utils';
import {
    getUserInfo,
    resetUserInfo,
} from 'features/account/redux/userInfo/userInfo.actions';
import { selectUserInfo } from 'features/account/redux/userInfo/userInfo.selectors';
import {
    selectFsIdentifiers,
    selectStatus,
} from 'features/common/redux/status/status.selectors';
import { checkAccessStatus } from 'features/account/redux/accessStatus/accessStatus.actions';
import { selectAccessStatus } from 'features/account/redux/accessStatus/accessStatus.selectors';
import ImageEmail from 'features/account/assets/images/checkEmail.png';

import { getConfigVar } from 'features/common/utils/config.utils';

import { LocationsApi } from 'features/account/api';

import styles from './Access.module.scss';
import { paths } from 'app/routes/app.routes';

export default function PendingAccess(): JSX.Element {
    const { t } = useTranslation();
    const navigation = useNavigation();
    const dispatch = useDispatch();

    const isMobile = useBreakpoint() !== 'desktop';

    const status = useSelector(selectStatus);
    const fsIdentifiers = useSelector(selectFsIdentifiers);
    const userInfo = useSelector((state: RootState) => selectUserInfo(state));
    const accessStatus = useSelector((state: RootState) =>
        selectAccessStatus(state),
    );

    useEffect(() => {
        dispatch(getUserInfo());
        dispatch(checkAccessStatus());

        if (status.statuses.connectFlow === 'LINKED') {
            event('success', {
                ...fsIdentifiers,
                connect_source: 'REQUESTED_ACCESS',
            } as { [property: string]: string });
        } else {
            event(
                'check_access_status',
                fsIdentifiers as { [property: string]: string },
            );
        }
    }, []);

    useEffect(() => {
        if (accessStatus.status === 'LOCATION_ADDED') {
            navigation.navigate(getPathnameWithQueryParams(paths.dashboard));
        }
    }, [accessStatus]);

    const requestAdminRights = () => {
        window.open(accessStatus.requestAdminRightsUrl, '_blank');
    };

    // TODO: refactor to redux actions
    const changeAccount = () => {
        LocationsApi.deleteLocation()
            .then(() => {
                dispatch(resetUserInfo());
            })
            .then(() => {
                navigation.navigate(getPathnameWithQueryParams(paths.connect));
            })
            .catch((error) => {
                showDetailedError({
                    message: t('errors.connect.deleteLocation'),
                    error: error.response?.data,
                });
            });
    };

    const googleSupportUrl = getConfigVar('REACT_APP_GOOGLE_SUPPORT') as string;

    return (
        <div className={styles.Container}>
            <div className={styles.Title}>{t('connect.pendingAccessTitle')}</div>
            <Card elevation={1} className={styles.Card}>
                <div className={styles.Content}>
                    <img
                        className={styles.ImageEmail}
                        src={ImageEmail}
                        alt="check email"
                    />
                    {!(userInfo.isLoaded && accessStatus.isLoaded) ? (
                        <LoadingIndicator />
                    ) : userInfo.isError || accessStatus.isError ? (
                        <div className={styles.ServerError}>{t('serverError')}</div>
                    ) : (
                        <>
                            <div className={styles.Header}>
                                {t('connect.pendingAccessHeader')}
                            </div>
                            <div className={styles.Instructions}>
                                {t('connect.pendingAccessInstuctions')}
                                &nbsp;
                                <b>{userInfo.email}</b>
                            </div>
                            <div className={styles.Actions}>
                                <Button
                                    variant={isMobile ? 'primary' : 'secondary'}
                                    className={styles.AdminRightsButton}
                                    onClick={requestAdminRights}
                                >
                                    {isMobile
                                        ? t('connect.continue')
                                        : t('connect.requestAdminRights')}
                                </Button>
                                <Button
                                    variant={'tertiary'}
                                    danger={true}
                                    className={styles.ChangeAccountButton}
                                    onClick={changeAccount}
                                >
                                    {t('connect.changeAccount')}
                                </Button>
                            </div>
                            <div className={styles.Help}>
                                {t('connect.needHelp')}
                                &nbsp;
                                <a
                                    className={styles.HelpUrl}
                                    href={googleSupportUrl}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {t('connect.googleHelpCenter')}
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
}
