import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { StatusApi } from 'features/common/api';
import { IStatus } from '../../api/Status.types';

export const updateStatus = createAction<IStatus>('status/updateStatus');

export const getStatus: any = createAsyncThunk(
    'status/getStatus',
    async (_, { rejectWithValue }) => {
        try {
            const response = await StatusApi.getStatus();
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: (error as AxiosError<any, any>).response?.status,
            });
        }
    },
);
