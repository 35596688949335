import { createSlice } from '@reduxjs/toolkit';
import { createAnswer, fetchQuestions } from './questionsSection.actions';
import { IQuestionsState } from './questionsSection.types';

const initialState: IQuestionsState = {
    data: undefined,
    loading: false,
    error: false,
    answerLoading: false,
    answerError: false,
    answerCreated: false,
};

const questionsSlice = createSlice({
    name: 'questions',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuestions.pending, (state) => {
                state.answerCreated = false;
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchQuestions.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(fetchQuestions.rejected, (state) => {
                state.loading = false;
                state.error = true;
                state.data = undefined;
            })
            .addCase(createAnswer.pending, (state) => {
                state.answerLoading = true;
                state.answerError = false;
            })
            .addCase(createAnswer.fulfilled, (state) => {
                state.answerCreated = true;
                state.answerLoading = false;
            })
            .addCase(createAnswer.rejected, (state) => {
                state.answerLoading = false;
                state.answerError = true;
            });
    },
});

export default questionsSlice.reducer;
