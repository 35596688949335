import React from 'react';
import { NotFoundBoundary } from 'react-navi';
import { AxiosError } from 'axios';
import { Text, TextTypes } from 'spoton-lib';

import { IPropTypes, IState } from './ErrorBoundaryRequest.types';

// NOTE: using class based component ONLY because we need support for error boundaries
export class ErrorBoundaryRequest extends React.Component<IPropTypes> {
    state: IState = { errorStatus: null };

    componentDidCatch(error: Error): void {
        if (this.isAxiosError(error) && error.response) {
            this.setState({ errorStatus: error.response.status });
        } else {
            throw error;
        }
    }

    isAxiosError = (error: Error): error is AxiosError => 'isAxiosError' in error;

    renderNotFound = (): JSX.Element => {
        return (
            <Text type={TextTypes.H2} className={this.props.className}>
                Oops! We couldn&apos;t find that page
            </Text>
        );
    };

    renderForbidden = (): JSX.Element => {
        return (
            <Text type={TextTypes.H2} className={this.props.className}>
                Forbidden. You don&apos;t have permission to access this resource.
            </Text>
        );
    };

    renderServerError = (): JSX.Element => {
        return (
            <Text type={TextTypes.H2} className={this.props.className}>
                Oops! Something went wrong. Please try again later.
            </Text>
        );
    };

    render(): JSX.Element {
        const { errorStatus } = this.state;

        if (errorStatus && errorStatus >= 500) {
            return this.renderServerError();
        } else if (errorStatus === 403) {
            return this.renderForbidden();
        } else if (errorStatus === 404) {
            return this.renderNotFound();
        } else {
            return (
                <NotFoundBoundary render={this.renderNotFound}>
                    {this.props.children}
                </NotFoundBoundary>
            );
        }
    }
}

export default ErrorBoundaryRequest;
