import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Icon } from 'spoton-lib';
import MobilePreview from '../Preview/MobilePreview.component';

import SublocationSelect from './components/SublocationSelect/SublocationSelect.component';

import styles from './Sublocation.module.scss';

const businessPreview = {
    name: 'Martin’s Burgers',
    category: 'Restaurants',
    address: '330 Hamilton Row, , Birmingham, MI, 48009',
    showMenu: true,
    about: 'Your business-specific description will appear here. Think of a good description to attract new customers.',
    rating: 4.8,
    userRatingsTotal: 36,
    map: 'https://maps.googleapis.com/maps/api/staticmap?center=42.5473338,-83.21307879999999&zoom=15&markers=color:red|42.5473338,-83.21307879999999&size=150x150&key=AIzaSyA9KvmLCYpa2hz-1x5cbV2SmdcD7AiiRNE',
    image: 'https://spoton-staging-google-my-business-service-assets.s3.amazonaws.com/static/business_preview/restaurants.jpeg',
    imageMobile:
        'https://spoton-staging-google-my-business-service-assets.s3.amazonaws.com/static/business_preview/restaurants_m.jpeg',
    imageSmall:
        'https://spoton-staging-google-my-business-service-assets.s3.amazonaws.com/static/business_preview/restaurants_small.jpeg',
    reviews: [
        {
            authorName: 'Lincoln Philips',
            authorAvatar:
                'https://spoton-staging-google-my-business-service-assets.s3.amazonaws.com/static/business_preview/avatars/linkoln.jpeg',
            rating: 5,
            relativeTimeDescription: '2 days ago',
            text: 'I refuse to go anywhere else. This place is unmatchable',
        },
        {
            authorName: 'Anastasia',
            authorAvatar:
                'https://spoton-staging-google-my-business-service-assets.s3.amazonaws.com/static/business_preview/avatars/owner.jpeg',
            rating: 5,
            relativeTimeDescription: '2 days ago',
            text: 'The whole team here is great!',
        },
        {
            authorName: 'Luke',
            authorAvatar:
                'https://spoton-staging-google-my-business-service-assets.s3.amazonaws.com/static/business_preview/avatars/luke.jpeg',
            rating: 5,
            relativeTimeDescription: '2 days ago',
            text: "Don't think to go anywhere else! This is literally the best place ever!",
        },
        {
            authorName: 'Pedro',
            authorAvatar:
                'https://spoton-staging-google-my-business-service-assets.s3.amazonaws.com/static/business_preview/avatars/pedro.jpeg',
            rating: 5,
            relativeTimeDescription: '2 days ago',
            text: 'Excellent service!',
        },
    ],
};

export default function Sublocation(): JSX.Element {
    const { t } = useTranslation();

    const advantages: Array<any> = t('connect.advantages.CREATE', {
        returnObjects: true,
    });

    return (
        <Card className={styles.Wrapper}>
            <div className={styles.LeftWrapper}>
                <div className={styles.Title}>
                    {t('connect.previewTitles.NOT_CLAIMED')}
                </div>
                <div className={styles.Advantages}>
                    {advantages.map((advantage, index) => (
                        <div key={index}>
                            <div className={styles.Advantage}>
                                <div className={styles.AdvantageIcon}>
                                    <Icon
                                        name={advantage.icon}
                                        alt="advantage icon"
                                        size={22}
                                    />
                                </div>
                                {advantage.text}
                            </div>
                            {advantage.details && (
                                <ul className={styles.DetailList}>
                                    {advantage.details.map(
                                        (item: string, index: number) => (
                                            <li
                                                key={index}
                                                className={styles.Detail}
                                            >
                                                {item}
                                            </li>
                                        ),
                                    )}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
                <SublocationSelect />
            </div>
            <div className={styles.RightWrapper}>
                <MobilePreview
                    businessPreview={businessPreview}
                    isExampleProfile={true}
                />
            </div>
        </Card>
    );
}
