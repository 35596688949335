import { createReducer, Reducer, AnyAction } from '@reduxjs/toolkit';

import {
    // readCookieData,
    //setMerchantLocationIdHeader,
    // logout,
    setCookie,
} from 'features/common/utils';
//import { emptyRequestState } from 'features/common/utils/request.utils';

import { IAuthState } from './auth.types';
import { checkAuth } from '../../utils/auth.utils';
import * as authActions from './auth.actions';

// const cookieData = readCookieData();

export const initialState: IAuthState = {
    isAuthenticated: checkAuth(),
    //merchantLocationId: cookieData?.merchantLocationId,
    //retrieveMerchantLocationIdRequestState: emptyRequestState(),
};

export const authReducer: Reducer<IAuthState, AnyAction> = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(authActions.logoutUserAction, (state) => {
                state.isAuthenticated = false;
                state.merchantLocationId = undefined;

                // clear actual auth cookies
                // logout();
            })
            .addCase(authActions.setMerchantLocationId, (state, action) => {
                state.merchantLocationId = String(action.payload);

                // TODO: probably change to a proper name of a cookie
                setCookie('MerchantLocationId', String(action.payload), 60);
                //setMerchantLocationIdHeader(action.payload);
            })
            .addDefaultCase((state) => {
                return state;
            });
    },
);

export default authReducer;
