import React, { useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'spoton-lib';
import { useTranslation } from 'react-i18next';

import { IMediaImage } from 'features/dashboard/api/Media.types';
import { Widget } from 'features/common/components';
import { MediaContent } from './MediaContent';
import { MediaModalUpload } from './MediaModalUpload';

import {
    selectMediaModalState,
    selectMediaSmallModalState,
    hideMediaSectionModal,
    showMediaSectionModal,
    showMediaSectionSmallModal,
    hideMediaSectionSmallModal,
} from 'features/dashboard/redux';

import styles from './MediaSection.module.scss';

interface IMediaSectionProps {
    isMobile?: boolean;
}

export default function MediaSection({ isMobile }: IMediaSectionProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [overviewImages, setOverviewImages] = useState<Array<IMediaImage>>([]);
    const [allImages, setAllImages] = useState<Array<IMediaImage>>([]);
    const isModalOpen = useSelector(selectMediaModalState);
    const isSmallModalOpen = useSelector(selectMediaSmallModalState);
    const refContent = useRef(null);

    const openAllImagesModal = useCallback(() => {
        dispatch(showMediaSectionModal());
    }, [dispatch]);

    const closeModal = useCallback(() => {
        dispatch(hideMediaSectionModal());
    }, [dispatch]);

    const openSmallModal = useCallback(() => {
        dispatch(showMediaSectionSmallModal());
    }, [dispatch]);

    const closeSmallModal = useCallback(() => {
        dispatch(hideMediaSectionSmallModal());
    }, [dispatch]);

    const uploadMedia = () => {
        closeModal();
        openSmallModal();
    };

    const finishUploadingMedia = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        refContent?.current?.refreshOwnMedia();
    };

    return (
        <>
            <Widget className={styles.MediaCard}>
                <MediaContent
                    images={overviewImages}
                    setImages={setOverviewImages}
                    uploadMedia={uploadMedia}
                    ref={refContent}
                />
                <Button
                    variant={isMobile ? 'tertiary' : 'primary'}
                    block
                    onClick={openAllImagesModal}
                >
                    {t('viewAll')}
                </Button>
            </Widget>
            <Modal
                ariaHideApp={false}
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                className={isMobile ? styles.MobileModal : styles.Modal}
                overlayClassName={styles.Overlay}
            >
                <MediaContent
                    closeModal={closeModal}
                    isModal
                    isMobile
                    images={allImages}
                    setImages={setAllImages}
                    uploadMedia={uploadMedia}
                />
            </Modal>
            <Modal
                ariaHideApp={false}
                isOpen={isSmallModalOpen}
                onRequestClose={closeSmallModal}
                className={isMobile ? styles.MobileModal : styles.SmallModal}
                overlayClassName={styles.Overlay}
            >
                <MediaModalUpload
                    isMobile
                    closeModal={closeSmallModal}
                    finishUploadingMedia={finishUploadingMedia}
                />
            </Modal>
        </>
    );
}
