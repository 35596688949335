import React from 'react';

import { useTranslation } from 'react-i18next';
import styles from './ServiceUnavailable.module.scss';

export default function ServiceUnavailable(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className={styles.Container}>
            <div className={styles.Title}>{t('connect.errorTitle')}</div>
        </div>
    );
}
