import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
    Button,
    Icon,
    IconButton,
    LoadingIndicator,
    Modal,
    Tooltip,
} from 'spoton-lib';

import { useBreakpoint } from 'utils/customHooks';
import { Widget } from 'features/common/components';

import { InfoTooltip } from 'features/common/components';
import Badge from './Components/Badge';

import {
    fetchAdvisorStatus,
    selectAdvisorErrorStatus,
    selectAdvisorLoadingStatus,
    selectAdvisorStatusData,
    showBusinessHoursModal,
    showMediaSectionSmallModal,
    showPostsSectionModal,
    showQuestionsModal,
    showReviewSectionModal,
    showUpdateBusinessInfoModal,
} from 'features/dashboard/redux';
import { PossibleActions } from 'features/dashboard/redux/advisor/advisor.types';
import { ReviewTabs } from 'features/dashboard/home/ReviewsSection/ReviewsTabs.types';

import goodWorkIcon from '../../assets/gift-box-icon.svg';
import serverErrorImage from 'features/dashboard/assets/serverError.png';

import styles from './GoogleAdvisor.module.scss';
import { Todo } from 'features/dashboard/types';

enum BadgeTypes {
    media = 'media',
    customerService = 'customerService',
    posts = 'posts',
}

export default function GoogleAdvisor(): JSX.Element {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useBreakpoint() !== 'desktop';
    const currentLanguage = i18n.language.includes('es') ? 'es' : 'en';

    useEffect(() => {
        dispatch(fetchAdvisorStatus());
    }, []);

    const [isShowModal, setShowModal] = useState(false);
    const advisorData = useSelector(selectAdvisorStatusData)?.languages[
        currentLanguage
    ];
    const isLoading = useSelector(selectAdvisorLoadingStatus);
    const isError = useSelector(selectAdvisorErrorStatus);
    const todos = advisorData?.todo;
    const badgesInfo = advisorData?.badges;
    const isNoTodos = !isLoading && !todos?.length;
    const todosToRender = useMemo(
        () => (isMobile ? todos?.slice(0, 2) : todos),
        [todos, isMobile],
    );
    const mediaTooltipContent = (tooltipContent: any) => (
        <div
            dangerouslySetInnerHTML={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __html: tooltipContent,
            }}
            className={styles.GoogleAdvisorTooltipContainer}
        />
    );

    const handleShowModal = useCallback(() => {
        setShowModal(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const handleAction = useCallback(
        (actionType) => () => {
            if (isShowModal) {
                handleCloseModal();
            }
            switch (actionType) {
                case PossibleActions.CREATE_POST:
                    dispatch(showPostsSectionModal());
                    break;
                case PossibleActions.UPLOAD_MEDIA:
                    dispatch(showMediaSectionSmallModal());
                    break;
                case PossibleActions.REPLY_REVIEW:
                    dispatch(showReviewSectionModal(ReviewTabs.omitted));
                    break;
                case PossibleActions.REPLY_QUESTION:
                    dispatch(showQuestionsModal());
                    break;
                case PossibleActions.UPDATE_WORKING_HOURS:
                    dispatch(showBusinessHoursModal());
                    break;
                case PossibleActions.UPDATE_PROFILE:
                    dispatch(showUpdateBusinessInfoModal());
                    break;
                case PossibleActions.UPDATE_CATEGORY:
                    dispatch(showUpdateBusinessInfoModal());
                    break;
                default:
                    break;
            }
        },
        [isShowModal],
    );

    const getActionText = (actionType: PossibleActions) => {
        switch (actionType) {
            case PossibleActions.CREATE_POST: {
                return t('post');
            }
            case PossibleActions.UPLOAD_MEDIA: {
                return t('upload');
            }
            case PossibleActions.REPLY_REVIEW:
            case PossibleActions.REPLY_QUESTION: {
                return t('reply');
            }
            case PossibleActions.UPDATE_WORKING_HOURS:
            case PossibleActions.UPDATE_CATEGORY:
            case PossibleActions.UPDATE_PROFILE: {
                return t('update');
            }
        }
    };

    const renderBadges = () => (
        <div className={styles.AdvisorHeaderIcons}>
            {Object.keys(BadgeTypes).map((badge: string) => (
                <div key={badge}>
                    <Tooltip
                        tooltipContent={
                            badgesInfo
                                ? mediaTooltipContent(
                                      badgesInfo[badge as BadgeTypes]?.tooltip,
                                  )
                                : ''
                        }
                        variant="bottomRight"
                        className={styles.TooltipContainer}
                        tooltipClassName={styles.TooltipContent}
                        disabled={
                            !badgesInfo ||
                            badgesInfo[badge as BadgeTypes]?.tooltip === ''
                        }
                    >
                        <Badge
                            type={badge}
                            level={badgesInfo[badge as BadgeTypes]?.grade}
                        />
                    </Tooltip>
                </div>
            ))}
        </div>
    );

    const renderTodoList = (todoList: Array<Todo>) => {
        return todoList.map((todo: Todo) => (
            <div className={styles.AdvisorTodoWrapper} key={todo.name}>
                <div className={styles.AdvisorTodoIconText}>
                    <div className={styles.AdvisorTodoIcon}>
                        <img
                            src={todo.iconUrl}
                            alt="todoIcon"
                            className={styles.AdvisorTodoIconImg}
                        />
                    </div>
                    <div
                        className={cn(styles.AdvisorTodoText, {
                            [styles.highlighted]:
                                todo.action === PossibleActions.UPDATE_CATEGORY,
                        })}
                    >
                        {todo.name}
                    </div>
                </div>

                <button
                    className={styles.AdvisorTodoAction}
                    onClick={handleAction(todo.action as PossibleActions)}
                >
                    <span className={styles.AdvisorTodoActionText}>
                        {getActionText(todo.action as PossibleActions)}
                    </span>
                    <span className={styles.AdvisorTodoActionIcon}>
                        <Icon
                            name="ChevronRightIcon"
                            size={26}
                            alt={t('dashboard.selectMedia')}
                        />
                    </span>
                </button>
            </div>
        ));
    };

    return (
        <>
            <Widget className={styles.GoogleAdvisor}>
                <div className={styles.AdvisorHeader}>
                    <div className={styles.AdvisorHeaderTitles}>
                        <div className={styles.AdvisorHeaderTitle}>
                            {t('dashboard.advisor.title')}
                            {!isLoading && (
                                <InfoTooltip
                                    tooltipText={mediaTooltipContent(
                                        t('dashboard.advisor.titleTooltip'),
                                    )}
                                    className={styles.TooltipContainer}
                                    tooltipClassName={styles.AdvisorTooltip}
                                />
                            )}
                        </div>
                        <div className={styles.AdvisorHeaderSubTitle}>
                            {t('dashboard.advisor.subTitle')}
                        </div>
                    </div>
                    {renderBadges()}
                </div>

                {!isNoTodos && (
                    <div className={styles.AdvisorContent}>
                        {isLoading && <LoadingIndicator />}
                        {!isLoading && renderTodoList(todosToRender)}
                        {isMobile && !isLoading && (
                            <div className={styles.MobileButtonWrapper}>
                                <Button
                                    variant="tertiary"
                                    className={styles.Button}
                                    onClick={handleShowModal}
                                >
                                    {t('dashboard.viewAllTodos')}
                                </Button>
                            </div>
                        )}
                    </div>
                )}

                {isNoTodos && (
                    <div className={styles.AdvisorContentCenter}>
                        <div className={styles.AdvisorNoDataWrapper}>
                            <img
                                src={isError ? serverErrorImage : goodWorkIcon}
                                alt="good work icon"
                            />
                            <p className={styles.AdvisorNoDataText}>
                                {isError
                                    ? t('errors.server')
                                    : t('dashboard.advisor.goodWork')}
                            </p>
                        </div>
                    </div>
                )}
            </Widget>
            <Modal
                ariaHideApp={false}
                isOpen={isShowModal}
                onRequestClose={handleCloseModal}
                className={styles.MobileModal}
                overlayClassName={styles.Overlay}
            >
                <div className={styles.MobileHeader}>
                    <IconButton
                        name="ArrowLeftIcon"
                        alt={t('close')}
                        buttonSize="large"
                        size={60}
                        variant="secondary"
                        disableBorder
                        className={styles.BackIcon}
                        onClick={handleCloseModal}
                    />
                    <h3 className={styles.MobileHeading}>
                        {t('dashboard.advisor.title')}
                    </h3>
                </div>
                <div className={styles.MobileBadgeWrapper}>{renderBadges()}</div>
                <p className={styles.ListHeading}>{t('dashboard.viewAllTodos')}</p>
                <div className={styles.AdvisorContent}>
                    {isLoading && <LoadingIndicator />}
                    {!isLoading && renderTodoList(todos)}
                </div>
            </Modal>
        </>
    );
}
