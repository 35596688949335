import React, { useState, useRef } from 'react';
import { SingleDatePicker } from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import moment from 'moment';

import styles from './InputSchedule.module.scss';

const scrollOptions: ScrollIntoViewOptions = {
    block: 'start',
    behavior: 'smooth',
};

interface IInputScheduleProps {
    stateSchedule: any;
    isValid: boolean;
}

export default function InputSchedule({
    stateSchedule,
    isValid,
}: IInputScheduleProps): JSX.Element {
    const { t } = useTranslation();
    const [isFocusedStart, setIsFocusedStart] = useState<boolean>(false);
    const [isFocusedEnd, setIsFocusedEnd] = useState<boolean>(false);
    const startDatePicker = useRef<HTMLDivElement>(null);
    const endDatePicker = useRef<HTMLDivElement>(null);

    const changeFocusStart = () => {
        if (startDatePicker?.current?.scrollIntoView) {
            startDatePicker.current.scrollIntoView(scrollOptions);
        }
        setIsFocusedStart(!isFocusedStart);
        setIsFocusedEnd(false);
    };
    const changeFocusEnd = () => {
        if (endDatePicker?.current?.scrollIntoView) {
            endDatePicker.current.scrollIntoView(scrollOptions);
        }
        setIsFocusedEnd(!isFocusedEnd);
        setIsFocusedStart(false);
    };

    const [schedule, setSchedule] = stateSchedule;
    const [startDate, setStartDate] = useState<any>(
        schedule?.startDate.year
            ? moment(
                  `${schedule.startDate.year}-${schedule.startDate.month}-${schedule.startDate.day}`,
                  'YYYY-MM-DD',
              )
            : moment(),
    );
    const [endDate, setEndDate] = useState<any>(
        schedule?.endDate.year
            ? moment(
                  `${schedule.endDate.year}-${schedule.endDate.month}-${schedule.endDate.day}`,
                  'YYYY-MM-DD',
              )
            : null,
    );

    const collectStartDate = (date: any) => {
        setIsFocusedStart(false);
        setStartDate(date);
        setEndDate(null);
        setSchedule();
    };
    const collectEndDate = (date: any) => {
        setIsFocusedEnd(false);
        setEndDate(date);

        if (startDate && date) {
            setSchedule({
                startDate: {
                    year: startDate.year(),
                    month: startDate.month() + 1,
                    day: startDate.date(),
                },
                endDate: {
                    year: date.year(),
                    month: date.month() + 1,
                    day: date.date(),
                },
            });
        }
    };

    const validateEndDate = (day: any) => {
        return day.isBefore(startDate ?? moment(), 'day');
    };

    const isValidStart = isValid || startDate;
    const isValidEnd = isValid || endDate;

    return (
        <div className={styles.PeriodWrapper}>
            <div className={styles.Period}>
                <div
                    className={cn(
                        styles.PeriodDatePicker_left,
                        isValidStart
                            ? styles.PeriodDatePicker
                            : styles.PeriodDatePickerError,
                    )}
                    ref={startDatePicker}
                >
                    <SingleDatePicker
                        id={'startDate'}
                        label={t('startDate')}
                        date={startDate}
                        focused={isFocusedStart}
                        onFocusChange={changeFocusStart}
                        onDateChange={collectStartDate}
                        placeholder={t('selectDate')}
                        showClearDate
                    />
                    <div className={styles.PeriodError}>
                        {!isValidStart && t('dashboard.postPeriodError')}
                    </div>
                </div>
                <div
                    className={cn(
                        styles.PeriodDatePicker_right,
                        isValidEnd
                            ? styles.PeriodDatePicker
                            : styles.PeriodDatePickerError,
                    )}
                    ref={endDatePicker}
                >
                    <SingleDatePicker
                        id={'endDate'}
                        label={t('endDate')}
                        date={endDate}
                        focused={isFocusedEnd}
                        onFocusChange={changeFocusEnd}
                        onDateChange={collectEndDate}
                        placeholder={t('selectDate')}
                        showClearDate
                        isOutsideRange={validateEndDate}
                    />
                    <div className={styles.PeriodError}>
                        {!isValidEnd && t('dashboard.postPeriodError')}
                    </div>
                </div>
            </div>
        </div>
    );
}
