// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MediaSelector_Input__3icuS{display:none}\n", "",{"version":3,"sources":["webpack://src/features/common/components/MediaSelectorMobile/MediaSelector.module.scss"],"names":[],"mappings":"AAAA,4BACI,YAAa","sourcesContent":[".Input {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Input": "MediaSelector_Input__3icuS"
};
export default ___CSS_LOADER_EXPORT___;
