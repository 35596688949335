import React, { useEffect, useState, useRef } from 'react';
import { Button, LoadingIndicator, Text } from 'spoton-lib';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import withWidget from 'features/common/hoc/withWidget/withWidget';
import { AutoComplete } from 'features/common/components/AutoComplete';
import TransparentButton from 'features/common/components/Button/TransparentButton';
import unifyData from 'utils/unifyData';
import { DeleteModal } from 'features/common/components/DeleteModal';

import styles from './ServiceArea.module.scss';
import {
    fetchBusinessInfo,
    selectBusinessInfoSavingStatus,
    selectServiceAreaData,
    updateBusinessInfo,
} from '../../redux';
import { BusinessInfoApi } from '../../api';

interface IPlaceInfo {
    placeName: string;
    placeId: string;
}

interface IServiceAreaItem extends IPlaceInfo {
    removingItemId?: string;
    removeArea(placeId: string): void;
}

function ServiceAreaItem({
    placeName,
    placeId,
    removeArea,
    removingItemId,
}: IServiceAreaItem) {
    const deleteArea = () => {
        removeArea(placeId);
    };

    return (
        <div
            className={cn(styles.ActiveLinkWrapper, {
                [styles.Active]: placeId === removingItemId,
            })}
        >
            <Text className={styles.AddLinkInputText}>{placeName}</Text>
            <TransparentButton
                name="DeleteIcon"
                alt="Delete Icon"
                onClick={deleteArea}
            />
        </div>
    );
}

function ServiceArea() {
    const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isLoading = useSelector(selectBusinessInfoSavingStatus);
    const isError = false;
    const [inputValue, setInputValue] = useState<IPlaceInfo | null>(null);
    const serviceAreas = useSelector(selectServiceAreaData) || [];
    const childRef = useRef();
    const removingAreaRef = useRef<{
        placeId: string;
    }>();

    useEffect(() => {
        dispatch(fetchBusinessInfo());
    }, [dispatch]);

    const searchPlace = async (name: string) => {
        try {
            const response = await BusinessInfoApi.searchPlaces(name);

            const result = unifyData(response?.data?.predictions, {
                place_id: 'placeId',
                description: 'placeName',
            }).filter(
                ({ placeId }) =>
                    !serviceAreas.some(
                        (addedArea: { placeId: string }) =>
                            addedArea.placeId === placeId,
                    ),
            );
            return result;
        } catch (e) {
            console.error('Error while searching Places');
            return null;
        }
    };

    const selectPlace = (item: any) => {
        setInputValue(item);
    };

    const onConfirmRemoveArea = (placeId: string) => {
        setIsShowingDeleteModal(true);
        removingAreaRef.current = {
            placeId,
        };
    };

    const removeArea = () => {
        dispatch(
            updateBusinessInfo({
                formInfo: {
                    serviceArea: {
                        places: {
                            placeInfos: [
                                ...serviceAreas.filter(
                                    (item: any) =>
                                        item.placeId !==
                                        removingAreaRef.current?.placeId,
                                ),
                            ],
                        },
                    },
                },
            }),
        ).then(() => {
            removingAreaRef.current = { placeId: '' };
            setIsShowingDeleteModal(false);
        });
    };

    const addArea = () => {
        dispatch(
            updateBusinessInfo({
                formInfo: {
                    serviceArea: {
                        places: {
                            placeInfos: [...serviceAreas, inputValue],
                        },
                    },
                },
            }),
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        childRef.current.setInputValue('');
    };

    const closeModal = () => {
        removingAreaRef.current = { placeId: '' };
        setIsShowingDeleteModal(false);
    };

    return (
        <>
            <div className={styles.Header}>
                <div className={styles.HeaderText}>
                    {t('profile.serviceArea.header')}
                </div>
            </div>
            <div className={styles.Content}>
                <div className={styles.SmallText}>
                    {t('profile.serviceArea.description')}
                </div>
                <div className={styles.InputWrapper}>
                    <div className={styles.AddLinkInput}>
                        <AutoComplete<IPlaceInfo>
                            searchMethod={searchPlace}
                            suggestionModel={{
                                key: 'placeId',
                                value: 'placeName',
                            }}
                            onSelect={selectPlace}
                            childRef={childRef}
                        />
                    </div>
                    <Button
                        disabled={!inputValue}
                        className={styles.AddLinkButton}
                        onClick={addArea}
                    >
                        {t('profile.serviceArea.addArea')}
                    </Button>
                </div>
                <div className={styles.LinksWrapper}>
                    {!!serviceAreas.length && !isLoading && (
                        <Text className={styles.ActiveLinksHeader}>
                            {t('profile.serviceArea.activeLinksHeader')}
                        </Text>
                    )}
                    <div className={styles.LinksContainer}>
                        {!!serviceAreas.length &&
                            !isLoading &&
                            !isError &&
                            serviceAreas.map((area: any, i: number) => (
                                <ServiceAreaItem
                                    key={`${area.placeId}_${i}`}
                                    placeName={area.placeName}
                                    placeId={area.placeId}
                                    removeArea={onConfirmRemoveArea}
                                    removingItemId={removingAreaRef.current?.placeId}
                                />
                            ))}
                        {isLoading && !isError && (
                            <LoadingIndicator className={styles.Loader} />
                        )}
                    </div>
                </div>
            </div>
            {isShowingDeleteModal && (
                <DeleteModal
                    closeModal={closeModal}
                    confirmDelete={removeArea}
                    deleteMessage={`${t('profile.serviceArea.removeArea')}`}
                    showWarning={false}
                    isProcessing={isLoading}
                />
            )}
        </>
    );
}

export default withWidget()(ServiceArea);
