import React, { useCallback, useState } from 'react';
import { IMediaImage } from 'features/dashboard/api/Media.types';

import { MediaFullScreen } from '../MediaFullScreen';
import { ImageContainer } from '../ImageContainer/ImageContainer.component';

interface IProps {
    menuPhotos: {
        mediaItems: IMediaImage[];
        nextPageToken: string;
    };
    onUpdateMediaList(): void;
}

export default function MenuPhotosContent({
    menuPhotos,
    onUpdateMediaList,
}: IProps): JSX.Element {
    const [fullScreenImage, setFullScreenImage] = useState<any>(null);
    const [isOpenFullScreenImage, setIsOpenFullScreenImage] =
        useState<boolean>(false);

    const openFullScreenImage = (data: any, index: number) =>
        useCallback(() => {
            setFullScreenImage({ ...data, index });
            setIsOpenFullScreenImage(true);
        }, [data, index]);

    const closeFullScreenImage = () => {
        setFullScreenImage(null);
        setIsOpenFullScreenImage(false);
    };

    return (
        <>
            {menuPhotos?.mediaItems?.length > 0 &&
                menuPhotos.mediaItems.map((item, index: number) => (
                    <ImageContainer
                        key={item?.name}
                        image={item?.thumbnailUrl}
                        days={item?.hrCreateTime}
                        onClick={openFullScreenImage(item, index)}
                    />
                ))}

            {isOpenFullScreenImage && (
                <MediaFullScreen
                    imageData={fullScreenImage}
                    imagesData={menuPhotos?.mediaItems}
                    totalMediaCount={menuPhotos?.mediaItems?.length}
                    pageToken={menuPhotos?.nextPageToken}
                    currentFilter={'ALL'}
                    closeModal={closeFullScreenImage}
                    updateMediaList={onUpdateMediaList}
                />
            )}
        </>
    );
}
