import React, {
    useState,
    useEffect,
    useRef,
    BaseSyntheticEvent,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { Input, Icon, Dropdown } from 'spoton-lib';
import classNames from 'classnames';

import { RootState } from 'reduxConfig';
import { useSelector, useDispatch } from 'react-redux';

import { getCategories } from 'features/account/redux/categories/categories.actions';
import {
    selectCategories,
    selectCategoriesLoading,
} from 'features/account/redux/categories/categories.selectors';

import { useTranslation } from 'react-i18next';
import styles from './SelectCategory.module.scss';

interface ICategorySelectorProps {
    categoryState: any;
    isDisabled: boolean;
    isValid?: boolean;
    inputClassName?: string;
    clearable?: boolean;
    isRequired?: boolean;
    label?: string;
}

export default forwardRef(function CategorySelector(
    {
        categoryState,
        isDisabled,
        isValid,
        inputClassName,
        clearable = false,
        isRequired = false,
        label = '',
    }: ICategorySelectorProps,
    ref,
): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [category, setCategory] = categoryState;

    const [categorySearch, setCategorySearch] = useState<string>('');

    const debouncedCategory = debounce(categorySearch, 500);

    const storeCategories = useSelector((state: RootState) =>
        selectCategories(state),
    );
    const isLoading = useSelector((state: RootState) =>
        selectCategoriesLoading(state),
    );

    const categories = storeCategories?.map((category: any) => ({
        value: category.name,
        label: category.displayName,
    }));

    const [isShowingOptions, setIsShowingOptions] = useState<boolean>(false);

    const collectCategoryInput = (event: BaseSyntheticEvent) => {
        const value = event.target.value;
        if (!value) {
            setIsShowingOptions(false);
        }
        setCategorySearch(value);
        setCategory(undefined);
    };

    const collectCategorySelect = (option: any) => {
        setCategorySearch(option.label);
        setIsShowingOptions(false);
        setCategory(option);
    };

    useEffect(() => {
        if (category) return;
        if (debouncedCategory) {
            dispatch(
                getCategories({
                    languageCode: t('languageCode'),
                    searchTerm: debouncedCategory,
                }),
            );
            setIsShowingOptions(true);
        } else {
            setIsShowingOptions(false);
        }
    }, [debouncedCategory]);

    const categoryRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
        getRef: () => {
            return categoryRef;
        },
    }));

    useEffect(() => {
        const resetCategory = (event: any) => {
            if (categoryRef.current && !categoryRef.current.contains(event.target)) {
                setCategorySearch('');
                setIsShowingOptions(false);
            }
        };

        if (isShowingOptions) document.addEventListener('mousedown', resetCategory);
        return () => {
            document.removeEventListener('mousedown', resetCategory);
        };
    }, [isShowingOptions]);

    return (
        <div ref={categoryRef} className={styles.CategoryContainer}>
            <Input
                className={classNames(styles.CategoryInput, inputClassName)}
                label={label ? label : t('connect.categoryLabel')}
                value={isDisabled ? '' : category?.label ?? categorySearch}
                onChange={collectCategoryInput}
                disabled={isDisabled}
                icon={<Icon name="SearchIcon" alt="search icon" size={16} />}
                clearable={clearable}
                required={isRequired}
                isValid={isValid}
                secondaryCondition={!isValid ? t('connect.categoryError') : ''}
            />

            {isShowingOptions && !category && (
                <Dropdown
                    onChange={collectCategorySelect}
                    className={styles.CategoryDropdown}
                    styles={{
                        control: () => ({ display: 'none' }),
                        menu: (base: any) => ({
                            ...base,
                            marginTop: -15,
                        }),
                    }}
                    options={categories}
                    placeholder={''}
                    menuIsOpen={true}
                    isLoading={isLoading}
                    withDividers
                />
            )}
        </div>
    );
});

export function debounce(value: string, delay: number): any {
    const [debouncedValue, setDebouncedValue] = useState<any>(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value]);
    return debouncedValue;
}
