import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import PostHeading from './PostsHeading.component';
import PostTabs from './PostsTabs.component';
import { Widget } from 'features/common/components';

import {
    hidePostsSectionModal,
    selectPostsModalForCreateState,
    selectPostsModalState,
    showPostsSectionModal,
} from '../../redux';
import { useBreakpoint } from 'utils/customHooks';
import { selectStatus } from 'features/common/redux/status/status.selectors';

import styles from './PostsSection.module.scss';

export default function PostSection(): JSX.Element {
    const isMobile = useBreakpoint() !== 'desktop';
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('all');
    const [posts, setPosts] = useState<any>({});
    const isModalOpen = useSelector(selectPostsModalState);
    const isModalOpenForCreate = useSelector(selectPostsModalForCreateState);
    const { postsEnabled: isPostsEnabled } = useSelector(selectStatus);

    const showModal = useCallback(() => {
        dispatch(showPostsSectionModal());
    }, [dispatch]);

    const closeModal = useCallback(() => {
        dispatch(hidePostsSectionModal());
    }, [dispatch]);

    const [refreshTab, setRefreshTab] = useState<string>();

    return (
        <Widget className={styles.Wrapper}>
            <PostHeading isModal={false} refreshTab={setRefreshTab} />
            {
                <PostTabs
                    isFullView={false}
                    stateTabs={[activeTab, setActiveTab]}
                    statePosts={[posts, setPosts]}
                    stateRefresh={[refreshTab, setRefreshTab]}
                />
            }
            {
                <div
                    className={cn(styles.ButtonWrapper, {
                        hidden: !isPostsEnabled,
                    })}
                >
                    <Button
                        variant={isMobile ? 'tertiary' : 'primary'}
                        className={styles.Button}
                        onClick={showModal}
                        block={true}
                    >
                        {t('dashboard.postViewAll')}
                    </Button>
                </div>
            }
            <Modal
                ariaHideApp={false}
                isOpen={isModalOpen || isModalOpenForCreate}
                onRequestClose={closeModal}
                className={isMobile ? styles.MobileModal : styles.Modal}
                overlayClassName={styles.Overlay}
            >
                <PostHeading
                    isModal={true}
                    isCreateMode={isModalOpenForCreate}
                    closeModal={closeModal}
                    refreshTab={setRefreshTab}
                />
                <PostTabs
                    isFullView={true}
                    initialTab={activeTab}
                    initialPosts={posts}
                    stateRefresh={[refreshTab, setRefreshTab]}
                />
            </Modal>
        </Widget>
    );
}
