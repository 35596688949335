/* eslint-disable no-console, no-undef, @typescript-eslint/explicit-module-boundary-types */
/**
 * Display information related to state change
 *
 * Prints a grouped message based on *action type*
 *  - action dispatched
 *  - next state
 *
 *  @param {Object} store - the redux store
 *  @param {Func} next - the next action
 *  @param {Object} action - the action
 *  @returns {Object} the next redux state
 */

import { getConfigFlag } from 'features/common';

export const logger = (store: any) => (next: any) => (action: any) => {
    const result = next(action);

    if (getConfigFlag('REACT_APP_REDUX_LOGGING') && action.type) {
        console.group(action.type);
        console.log(store.getState());
        console.groupEnd();
    }

    return result;
};

export default logger;
