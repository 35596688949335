import React from 'react';
import { useSelector } from 'react-redux';
import { LoadingIndicator } from 'spoton-lib';
import { useTranslation } from 'react-i18next';

import { Widget } from 'features/common/components';
import serverErrorImage from 'features/dashboard/assets/serverError.png';

import styles from './withWidget.module.scss';

const withWidget =
    (isErrorSelector?: () => any, isLoadingSelector?: () => any): any =>
    (
        // eslint-disable-next-line @typescript-eslint/naming-convention
        WrappedComponent: React.ComponentType<any>,
    ): any =>
        function withWidgetComponent(...props: any): JSX.Element {
            const { t } = useTranslation();
            const isError = isErrorSelector ? useSelector(isErrorSelector) : false;
            const isLoading = isLoadingSelector
                ? useSelector(isLoadingSelector)
                : false;
            const shouldShowLoading = isLoading && !isError;
            const shouldShowContent = !isLoading && !isError;

            return (
                <Widget className={styles.Card}>
                    {shouldShowContent && (
                        <div className={styles.WidgetBox}>
                            <WrappedComponent
                                isError={isError}
                                isLoading={isLoading}
                                {...props}
                            />
                        </div>
                    )}
                    {shouldShowLoading && (
                        <div className={styles.Loader}>
                            <LoadingIndicator />
                        </div>
                    )}
                    {isError && (
                        <div className={styles.WidgetContentCenter}>
                            <div className={styles.WidgetErrorWrapper}>
                                <img src={serverErrorImage} alt="good work icon" />
                                <p className={styles.WidgetErrorText}>
                                    {t('serverError')}
                                </p>
                            </div>
                        </div>
                    )}
                </Widget>
            );
        };

export default withWidget;
