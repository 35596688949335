import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Icon } from 'spoton-lib';

import { showMenuPhotosModal, addMenuPhoto } from 'features/dashboard/redux';
import MediaSelector from 'features/common/components/MediaSelectorMobile';
import { useBreakpoint } from 'utils/customHooks';

import styles from './AddImageContainer.module.scss';
import { useDispatch } from 'react-redux';

export function AddImageContainer(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useBreakpoint() !== 'desktop';
    const onClickAddPhotos = (files: File[] | null) => {
        if (!files) {
            return;
        }
        files.map((item: File) => dispatch(addMenuPhoto(item)));
    };
    const onOpenModalAddPhotos = useCallback(() => {
        dispatch(showMenuPhotosModal());
    }, [dispatch]);

    const content = (
        <>
            <Icon name="ImageUploadIcon" alt="ImageUploadIcon" size={18} />
            <Text type="p">{t('menuPhotos.addPhoto')}</Text>
        </>
    );

    return isMobile ? (
        <MediaSelector
            className={styles.AddImageContainer}
            onClick={onClickAddPhotos}
        >
            {content}
        </MediaSelector>
    ) : (
        <div
            className={styles.AddImageContainer}
            onClick={onOpenModalAddPhotos}
            aria-hidden="true"
        >
            {content}
        </div>
    );
}
