import React, { useState, useRef } from 'react';
import { Input } from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import cn from 'classnames';

import useOuterClick from 'features/common/utils/useOuterClick';

import styles from './AutoComplete.module.scss';
import { useEffect } from 'react';

interface ISuggestion<T> {
    key: keyof T;
    value: keyof T;
}

type Suggestion<T> = {
    [P in keyof T]: T[P];
} & {
    view: Array<{
        isRest: boolean;
        value: string;
    }>;
};

interface IAutoComplete<T> {
    searchMethod: any;
    suggestionModel: ISuggestion<T>;
    onSelect: any;
    childRef: any;
}

export default function AutoComplete<T>({
    searchMethod,
    suggestionModel,
    onSelect,
    childRef,
}: IAutoComplete<T>): JSX.Element {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState<Suggestion<T>[]>([]);
    const [isShowSuggestions, setShowSuggestions] = useState(false);
    const inputRef = useRef(null);
    const suggestionsRef = useOuterClick<HTMLDivElement>((e) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const isNotInput = e.target !== inputRef.current?.inputElement.current;
        isNotInput && setShowSuggestions(false);
    });

    useEffect(() => {
        childRef.current = {
            setInputValue,
        };
    }, []);

    const searchPlaces = useDebouncedCallback(
        async (value) => {
            try {
                const result = await searchMethod(value);
                setSuggestions(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    result.map((item) => {
                        const value = item[suggestionModel.value];
                        const main = value.substring(0, value.length);
                        const rest = value.substring(value.length);
                        const view = [
                            {
                                value: main,
                                isRest: false,
                            },
                            {
                                value: rest,
                                isRest: true,
                            },
                        ];
                        return {
                            [suggestionModel.value]: value,
                            [suggestionModel.key]: item[suggestionModel.key],
                            view,
                        };
                    }) || [],
                );

                setShowSuggestions(!!result.length);
            } catch (e) {
                console.error(e);
            }
        },
        1000,
        { leading: true },
    );

    const search = async (value: any) => {
        setInputValue(value);
        if (!value.trim()) {
            setShowSuggestions(false);
            setSuggestions([]);
            return;
        }
        searchPlaces(value);
    };

    const select = (suggestion: any) => () => {
        setInputValue(suggestion[suggestionModel.value]);
        setShowSuggestions(false);
        onSelect({
            [suggestionModel.value]: suggestion[suggestionModel.value],
            [suggestionModel.key]: suggestion[suggestionModel.key],
        });
        setSuggestions(
            suggestions.filter(
                (s) => s[suggestionModel.key] !== suggestion[suggestionModel.key],
            ),
        );
    };

    const onFocus = () => {
        if (suggestions.length) {
            setShowSuggestions(true);
        }
    };

    return (
        <div className={styles.Wrapper}>
            <div className={styles.InputWrapper}>
                <Input
                    className={styles.Input}
                    label={t('profile.serviceArea.inputLabel')}
                    placeholder={t('profile.serviceArea.search')}
                    value={inputValue}
                    clearable={false}
                    ref={inputRef}
                    onChange={(e) => search(e.target.value)}
                    onFocus={onFocus}
                />
            </div>
            {isShowSuggestions && (
                <div className={styles.ListWrapper}>
                    <div className={styles.SuggestionsList} ref={suggestionsRef}>
                        <div>
                            {suggestions.map((suggestion, i) => {
                                return (
                                    <div
                                        className={styles.Suggestion}
                                        aria-hidden="true"
                                        onClick={select(suggestion)}
                                        key={`${
                                            suggestion[suggestionModel.key]
                                        }_${i}`}
                                    >
                                        <div>
                                            <span>{suggestion.view[0].value}</span>
                                            <span
                                                className={cn({
                                                    [styles.Rest]:
                                                        suggestion.view[1].isRest,
                                                })}
                                            >
                                                {suggestion.view[1]?.value}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
