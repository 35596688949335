import React, { useEffect, useState } from 'react';
import { View } from 'react-navi';
import { useSelector } from 'react-redux';
import { identify, setUserVars } from 'react-fullstory';
import { omit } from 'lodash';
import {
    LeftNavHeader,
    LeftNavVerticalsMenu,
    LeftNavAppMenu,
    LeftNavSettings,
} from 'spoton-lib';

import { getSettingsLinks, getExternalApps, getNavigationItems } from './App.utils';
import { IAppPropTypes, connector } from './App.types';

import { getConfigVar } from 'features/common';
import {
    selectFsIdentifiers,
    selectStatus,
} from 'features/common/redux/status/status.selectors';

import styles from './App.module.scss';

export function App(props: IAppPropTypes): JSX.Element {
    const { logout } = props;
    const env = process.env.NODE_ENV;

    const appName = getConfigVar('REACT_APP_NAME') as string;

    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

    const [isVerticalsMenuOpen, setIsVerticalsMenuOpen] = useState(false);
    const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
    const [isFullStoryInitialized, setIsFullStoryInitialized] = useState(false);

    const status = useSelector(selectStatus);
    const fsIdentifiers = useSelector(selectFsIdentifiers);

    const separator = status.firstName && status.lastName ? ', ' : ' ';
    const userName = `${status.firstName}${separator}${status.lastName}`;
    const userNameInitials = `${status.firstName[0]}${status.lastName[0]}`;

    // TODO This should be replaced in the future via extending default NODE_ENV
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const shouldEnableFs = env === 'production' || env === 'staging';

    useEffect(() => {
        // FullStory initialize and set user vars
        if (
            !isFullStoryInitialized &&
            shouldEnableFs &&
            fsIdentifiers &&
            fsIdentifiers.userId
        ) {
            identify(fsIdentifiers.userId);
            setUserVars(
                omit(fsIdentifiers, ['userId']) as { [property: string]: any },
            );
            setIsFullStoryInitialized(true);
        }
    }, [
        isFullStoryInitialized,
        shouldEnableFs,
        JSON.stringify(fsIdentifiers),
        fsIdentifiers,
    ]);

    const settingsLinks = getSettingsLinks(
        status.merchantInfo.country,
        status.merchantInfo.programs,
        status.merchantInfo.type,
        status.expressEnabled,
    );
    const externalApps = getExternalApps(status.merchantInfo.programs);
    const navigationItems = getNavigationItems(
        status.merchantInfo.country,
        status.merchantInfo.programs,
        status.merchantInfo.type,
        status.merchantInfo.showCapital,
        status.maEnabled,
        status.expressEnabled,
    );

    function toggleSettingsMenu(): void {
        setIsSettingsMenuOpen(!isSettingsMenuOpen);
    }

    function closeSettingsMenu(): void {
        setIsSettingsMenuOpen(false);
    }

    function toggleVerticalsMenu(): void {
        setIsVerticalsMenuOpen(!isVerticalsMenuOpen);
    }

    function goToSettingsLink(item: any): void {
        if (item.id === 'logout') {
            logout();
        } else if (item.link) {
            window.location.href = item.link;
        }
        setIsSettingsMenuOpen(false);
    }

    function openVerticalsMenu(): void {
        setIsVerticalsMenuOpen(true);
    }

    function closeVerticalsMenu(): void {
        setIsVerticalsMenuOpen(false);
    }

    function goToExternalApp(item: any): void {
        if (item.link) {
            window.location.href = item.link;
        }
    }

    function toggleMainMenu(): void {
        setIsMainMenuOpen(!isMainMenuOpen);
    }

    function handleAppMenuClose(): void {
        setIsMainMenuOpen(false);
    }

    function goToApplication(item: any): void {
        if (item.link) {
            window.location.href = item.link;
        }
    }

    return (
        <div className={styles.App}>
            <LeftNavHeader
                className={styles.AppHeader}
                applicationName={appName}
                currentPageName={appName}
                userName={userName || 'Spoton Merchant'}
                userNameInitials={userNameInitials || 'SM'}
                userAddress={status.merchantInfo.address ?? ''}
                photoUrl={status.merchantInfo.profilePhotoUrl ?? ''}
                avatarColor={'#black'}
                toggleMainMenu={toggleMainMenu}
                toggleSettingsMenu={toggleSettingsMenu}
                toggleVerticalsMenu={toggleVerticalsMenu}
            />

            <div className={styles.AppWrapper}>
                <LeftNavAppMenu
                    isOpen={isMainMenuOpen}
                    applicationName={appName}
                    applicationLinks={navigationItems}
                    onSelectLink={goToApplication}
                    onClose={handleAppMenuClose} // mobile only
                    onOpenVerticalsMenu={openVerticalsMenu} // mobile only
                    className={styles.AppMenu}
                />

                <LeftNavVerticalsMenu
                    isOpen={isVerticalsMenuOpen}
                    applicationName={''}
                    links={externalApps}
                    onSelectLink={goToExternalApp}
                    onClose={closeVerticalsMenu}
                />

                <LeftNavSettings
                    className={styles.AppSettings}
                    isOpen={isSettingsMenuOpen}
                    closeSettingsMenu={closeSettingsMenu}
                    settingsLinks={settingsLinks}
                    onSelectLink={goToSettingsLink}
                    userName={userName || 'Spoton Merchant'}
                    userNameInitials={userNameInitials || 'SM'}
                    userAddress={status.merchantInfo.address ?? ''}
                    photoUrl={status.merchantInfo.profilePhotoUrl ?? ''}
                />

                <div className={styles.AppContent}>
                    <View />
                </div>
            </div>
        </div>
    );
}

export default connector(App);
