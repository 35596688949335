import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reduxConfig/rootReducer';
import { IQuestionsState } from './questionsSection.types';

export const selectQuestionsState = (state: RootState): IQuestionsState =>
    state?.dashboard?.questions;

export const selectQuestions = createSelector(
    selectQuestionsState,
    (state: IQuestionsState) => state?.data,
);

export const selectIsLoading = createSelector(
    selectQuestionsState,
    (state: IQuestionsState) => state?.loading,
);

export const selectIsError = createSelector(
    selectQuestionsState,
    (state: IQuestionsState) => state?.error,
);

export const selectIsAnswerCreated = createSelector(
    selectQuestionsState,
    (state: IQuestionsState) => state?.answerCreated,
);
