import { createSlice } from '@reduxjs/toolkit';
import {
    createAppointmentLink,
    removeAppointmentLink,
    editAppointmentLink,
    fetchAppointmentLinks,
} from './appointmentLinks.actions';

const appointmentLinksSlice = createSlice({
    name: 'appointmentLinks',
    initialState: {
        data: [],
        pageToken: '',
        loading: false,
        error: false,
    },
    reducers: {
        // standard reducer logic, if local state need to be changed
    },
    extraReducers: {
        [fetchAppointmentLinks.pending]: (state) => {
            state.loading = true;
            state.error = false;
            state.data = [];
        },
        [fetchAppointmentLinks.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = false;
            state.data = action.payload.placeActionLinks;
            state.pageToken = action.payload.pageToken;
        },
        [fetchAppointmentLinks.rejected]: (state) => {
            state.loading = false;
            state.error = true;
        },
        [createAppointmentLink.pending]: (state) => {
            state.loading = true;
            state.error = false;
        },
        [editAppointmentLink.pending]: (state) => {
            state.loading = true;
            state.error = false;
        },
        [removeAppointmentLink.pending]: (state) => {
            state.loading = true;
            state.error = false;
        },
    },
});

export default appointmentLinksSlice.reducer;
