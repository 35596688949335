import React, { BaseSyntheticEvent } from 'react';
import { Radio } from 'spoton-lib';
import styles from './LocationList.module.scss';

interface ILocationListProps {
    stateSelectedLocationId: any;
    storeLocations: Array<any>;
}

export default function LocationList({
    stateSelectedLocationId,
    storeLocations,
}: ILocationListProps): JSX.Element {
    const [selectedLocationId, setSelectedLocationId] = stateSelectedLocationId;

    const selectLocation = (event: BaseSyntheticEvent) => {
        setSelectedLocationId(event.target.value);
    };

    let locations: Array<any>;

    const locationLabel = (
        title: string,
        category?: string,
        phoneNumber?: string,
        address?: string,
    ) => {
        return (
            <div key={1} className={styles.LocationLabel}>
                <div className={styles.LocationName}>{title}</div>
                <div className={styles.LocationData}>
                    {!!category && `${category} - `}
                    {!!phoneNumber && `${phoneNumber} - `}
                    {address ?? ''}
                </div>
            </div>
        );
    };

    if (storeLocations?.length === 1) {
        locations = [
            locationLabel(
                storeLocations[0].locationName,
                storeLocations[0].googleCategory.displayName,
                storeLocations[0].phoneNumber,
                storeLocations[0].address,
            ),
        ];
    } else {
        locations = storeLocations.map((location: any) => {
            const value = location.uuid4;

            const label: any = locationLabel(
                location.locationName,
                location.googleCategory.displayName,
                location.phoneNumber,
                location.address,
            );
            return (
                <Radio
                    className={styles.Radio}
                    key={value}
                    value={value}
                    label={label}
                    checked={value === selectedLocationId}
                    onChange={selectLocation}
                />
            );
        });
    }

    return (
        <div className={styles.LocationContainer}>
            {locations.length && <div className={styles.RadioList}>{locations}</div>}
        </div>
    );
}
