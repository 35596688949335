import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { authReducer, authActions } from 'features/common/redux';
import { statusReducer } from 'features/common/redux/status';

import { userInfoReducer } from 'features/account/redux/userInfo';
import { locationsReducer } from 'features/account/redux/locations';
import { categoriesReducer } from 'features/account/redux/categories';
import { authConfigReducer } from 'features/account/redux/auth';
import { previewReducer } from 'features/account/redux/preview';
import { accessStatusReducer } from 'features/account/redux/accessStatus';
import { verificationReducer } from 'features/account/redux/verification';
import { disconnectReducer } from 'features/disconnect/reducer';

import {
    advisorReducer,
    modalsReducer,
    businessInfoReducer,
    menuReducer,
    homeReducer,
    questionsReducer,
    appointmentLinksReducer,
} from 'features/dashboard/redux';

const appReducer = combineReducers({
    auth: authReducer,
    status: statusReducer,
    userInfo: userInfoReducer,
    preview: previewReducer,
    authConfig: authConfigReducer,
    dashboard: combineReducers({
        advisor: advisorReducer,
        modals: modalsReducer,
        businessInfo: businessInfoReducer,
        home: homeReducer,
        menu: menuReducer,
        questions: questionsReducer,
        appointmentLinks: appointmentLinksReducer,
    }),
    locations: locationsReducer,
    categories: categoriesReducer,
    accessStatus: accessStatusReducer,
    verification: verificationReducer,
    disconnect: disconnectReducer,
});

const rootReducer = (
    state: ReturnType<typeof appReducer> | undefined,
    action: AnyAction,
): ReturnType<typeof appReducer> => {
    // reset redux state on the authActions.resetStore action or logout
    if (
        action.type ===
        (authActions.resetStore.type || authActions.logoutUserAction.type)
    ) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
