/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars */
import {
    configureStore,
    getDefaultMiddleware,
    EnhancedStore,
    AnyAction,
} from '@reduxjs/toolkit';

import rootReducer from './rootReducer';
// import * as enhancers from './enhancers';
import * as middleware from './middleware';

/**
 * REDUX Store configuration
 *
 * Here we apply the reducers, set middleware, apply state and add enhancers.
 * You probably won't need to edit this file unless you need to add middleware.
 */
export default function configureAppStore(
    preloadedState: any,
): EnhancedStore<any, AnyAction> {
    const store = configureStore({
        // here we define our reducers
        reducer: rootReducer,

        // we use the callback version of the middlware as per redux-toolkits
        // recommendations with typescript. we can 'prepend' and 'concat'
        // middlwares provided they are properly typed, no need to specify
        // generics by hand.
        middleware: (getDefaultMiddlware) =>
            getDefaultMiddleware().prepend(middleware.logger),

        // initial state
        preloadedState,

        // any redux enhancers we want to use
        //enhancers: [enhancers.monitorReducerEnhancer],
    });

    // hot reload on any non production ENV
    if (process.env.NODE_ENV === 'development' && module.hot) {
        module.hot.accept('./rootReducer', () => {
            import('./rootReducer')
                .then((reducer) => {
                    store.replaceReducer(reducer.default);
                })
                .catch((e: Error) => {
                    console.error(
                        `[Error] Could not load reducers for hot reloading`,
                        e,
                    );
                });
        });
    }

    return store;
}
