import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from 'react-navi';
import { useTranslation } from 'react-i18next';

import { Card, Button, LoadingIndicator } from 'spoton-lib';

import { useBreakpoint } from 'utils/customHooks';
import { getPathnameWithQueryParams } from 'app/components/App/App.utils';

import { checkStatus } from './actions';
import { syncLocation } from 'features/account/redux/locations/locations.actions';
import { selectSyncLocationsStatus } from 'features/account/redux/locations/locations.selectors';
import { selectStatus } from 'features/common/redux/status/status.selectors';
import { selectAccountCreatingStatus } from '../../redux/preview/preview.selectors';

import GoogleKiosk from 'features/account/assets/images/googleKiosk.png';

import styles from './Connection.module.scss';
import { paths } from 'app/routes/app.routes';

export default function PendingConnection(): JSX.Element {
    const { t } = useTranslation();
    const navigation = useNavigation();
    const dispatch = useDispatch();

    const isMobile = useBreakpoint() !== 'desktop';

    const actions: Array<any> = t(`connect.actions`, { returnObjects: true });

    const actionsElement: Array<any> = [];

    actions.forEach((action: string, id: number) => {
        actionsElement.push(
            <div key={id} className={styles.Action}>{`${id + 1}. ${action}`}</div>,
        );
    });

    const { statuses } = useSelector(selectStatus);
    const isLoadingSyncLocation = useSelector(selectSyncLocationsStatus);
    const isAccountAutoCreated = useSelector(selectAccountCreatingStatus);

    useEffect(() => {
        dispatch(checkStatus());
        if (isAccountAutoCreated) {
            dispatch(syncLocation());
        }
    }, []);

    const isConnected = statuses.connectFlow === 'LINKED';

    const goToDashboard = () => {
        navigation.navigate(getPathnameWithQueryParams(paths.dashboard));
    };

    return (
        <div className={styles.Container}>
            <div className={styles.Title}>
                {statuses.connectWay
                    ? t(`connect.pendingConnectionTitle.${statuses.connectWay}`)
                    : t('connect.pendingConnectionTitle.PENDING')}
            </div>
            <Card elevation={1} className={styles.Card}>
                <div className={styles.Content}>
                    <img
                        className={styles.ImageKiosk}
                        src={GoogleKiosk}
                        alt="google kiosk"
                    />
                    <div className={styles.Header}>
                        {/* {isConnected
                            ? t(`connect.connectedHeader.${statuses.connectWay}`)
                            : t('connect.connectingHeader')} */}
                        {t('connect.connectingHeader')}
                    </div>
                    {(isAccountAutoCreated && isLoadingSyncLocation) ||
                    !isConnected ? (
                        <LoadingIndicator />
                    ) : (
                        <>
                            <div className={styles.Actions}>
                                <div className={styles.Heading}>
                                    {t('connect.youCanNow')}
                                </div>
                                {actionsElement}
                                {t('connect.andMore')}
                            </div>
                            <div className={styles.ButtonWrapper}>
                                <Button
                                    onClick={goToDashboard}
                                    className={styles.Button}
                                >
                                    {isMobile
                                        ? t('connect.continue')
                                        : t('connect.goToDashboard')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
}
