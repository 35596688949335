import React, { ChangeEvent, useRef, ReactNode } from 'react';
import styles from './MediaSelector.module.scss';

type ImageSelectorProps = {
    children: ReactNode;
    onClick(files: File[] | null): void;
    className?: string;
};

export default function MediaSelector({
    children,
    onClick,
    className,
}: ImageSelectorProps): JSX.Element {
    const inputRef = useRef<HTMLInputElement>(null);

    const runInput = () => {
        inputRef.current?.click();
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target?.files && Array.from(e.target.files);
        if (files && files.length > 0) {
            onClick(files);
        }
    };

    return (
        <div className={className} onClick={runInput} aria-hidden="true">
            {children}
            <input
                ref={inputRef}
                type="file"
                data-testid="file-input"
                multiple
                accept="image/jpeg,image/png,video/*"
                className={styles.Input}
                onChange={handleChange}
            />
        </div>
    );
}
