import { useSelector } from 'react-redux';
import { showToast } from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import throttle from 'lodash/throttle';

import { selectStatus } from 'features/common/redux/status/status.selectors';

const useNoEnabledPost = (): {
    isPostsEnabled: boolean;
    notifyNoPost: () => void;
} => {
    const { t } = useTranslation();
    const { postsEnabled: isPostsEnabled } = useSelector(selectStatus);

    const notifyNoPost = throttle(
        () => {
            showToast({
                content: t('dashboard.noEnablePost'),
                variant: 'informative',
            });
        },
        5000,
        { trailing: false },
    );

    return {
        isPostsEnabled,
        notifyNoPost,
    };
};

export default useNoEnabledPost;
