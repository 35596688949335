// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServiceUnavailable_Container__3xnKp{display:flex;flex-direction:column;align-items:center}.ServiceUnavailable_Title__2D7_c{margin-top:46px}\n", "",{"version":3,"sources":["webpack://src/features/account/components/ServiceUnavailable/ServiceUnavailable.module.scss"],"names":[],"mappings":"AAAA,qCACI,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACtB,iCAGG,eAAgB","sourcesContent":[".Container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.Title {\n    margin-top: 46px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "ServiceUnavailable_Container__3xnKp",
	"Title": "ServiceUnavailable_Title__2D7_c"
};
export default ___CSS_LOADER_EXPORT___;
