import moment from 'moment';
import { omit } from 'lodash';
import i18n from 'i18n.config';
import formatDistance from 'date-fns/formatDistance';

import {
    EditableRegularHoursPeriod,
    RegularHoursPeriod,
    TGoogleTimeOfDay,
    TGoogleTimePeriod,
} from 'features/dashboard/types';

export const FULL_DAY = '24';
export const MIDNIGHT = '00:00';
export const MIDNIGHT_24 = '24:00';

export const daysOfWeekKeys = [
    'daysOfWeek.sunday',
    'daysOfWeek.monday',
    'daysOfWeek.tuesday',
    'daysOfWeek.wednesday',
    'daysOfWeek.thursday',
    'daysOfWeek.friday',
    'daysOfWeek.saturday',
];

export const daysOfWeek: any = {
    'daysOfWeek.sunday': 'sunday',
    'daysOfWeek.monday': 'monday',
    'daysOfWeek.tuesday': 'tuesday',
    'daysOfWeek.wednesday': 'wednesday',
    'daysOfWeek.thursday': 'thursday',
    'daysOfWeek.friday': 'friday',
    'daysOfWeek.saturday': 'saturday',
};

const getGoogleTime = (time: string): TGoogleTimeOfDay => {
    const timeNumber = time.split(':').map((text) => Number(text));
    return {
        hours: timeNumber[0],
        minutes: timeNumber[1],
        seconds: 0,
        nanos: 0,
    };
};

const convertGoogleTimeTo24 = (time: TGoogleTimeOfDay): string => {
    const fullTimeObject = { ...time };
    fullTimeObject.hours = time.hours || 0;
    fullTimeObject.minutes = time.minutes || 0;
    fullTimeObject.seconds = time.seconds || 0;
    fullTimeObject.nanos = time.nanos || 0;
    return moment().set(fullTimeObject).format('HH:mm');
};

const getPreviousDayOfWeek = (day: string) => {
    const daysOfWeeksValues: string[] = daysOfWeekKeys.map((d) =>
        i18n.t(d).toUpperCase(),
    );

    const dayIndex = daysOfWeeksValues.findIndex((item) => item === day);

    if (dayIndex === 0) {
        return daysOfWeeksValues[daysOfWeeksValues.length - 1];
    } else {
        return daysOfWeeksValues[dayIndex - 1];
    }
};

const getNextDayOfWeek = (day: string) => {
    const daysOfWeeksValues: string[] = daysOfWeekKeys.map((d) =>
        i18n.t(d).toUpperCase(),
    );

    const dayIndex = daysOfWeeksValues.findIndex((item) => item === day);

    if (dayIndex === daysOfWeeksValues.length - 1) {
        return daysOfWeeksValues[0];
    } else {
        return daysOfWeeksValues[dayIndex + 1];
    }
};

export const normalizeCombineNightHours = (
    periods: RegularHoursPeriod[],
): RegularHoursPeriod[] => {
    const accumulator = {
        periods: [],
        mergedIndexes: [],
    };
    return periods
        ?.map((period) => ({
            ...period,
            combinedOpenTime: convertGoogleTimeTo24(period.openTime),
            combinedCloseTime: convertGoogleTimeTo24(period.closeTime),
        }))
        .reduce(
            (
                result: {
                    periods: RegularHoursPeriod[];
                    mergedIndexes: number[];
                },
                period,
                index,
            ) => {
                if (result.mergedIndexes.includes(index)) {
                    return result;
                }

                const lastIndex = periods.length - 1;
                const previousIndex = index === 0 ? lastIndex : index - 1;
                const previousDay = periods[previousIndex];

                const nextIndex = index === lastIndex ? 0 : index + 1;
                const nextDay = periods[nextIndex];

                const previousDayOfWeek = getPreviousDayOfWeek(period.openDay);
                const nextDayOfWeek = getNextDayOfWeek(period.openDay);

                const shouldMergeWithPrevious =
                    previousDay.openDay === previousDayOfWeek &&
                    period.combinedOpenTime === MIDNIGHT &&
                    period.combinedCloseTime !== MIDNIGHT_24 &&
                    previousDay.combinedOpenTime !== MIDNIGHT &&
                    previousDay.combinedCloseTime === MIDNIGHT_24;

                const shouldMergeWithNext =
                    nextDay.openDay === nextDayOfWeek &&
                    period.combinedOpenTime !== MIDNIGHT &&
                    period.combinedCloseTime === MIDNIGHT_24 &&
                    nextDay.combinedOpenTime === MIDNIGHT &&
                    nextDay.combinedCloseTime !== MIDNIGHT_24;

                if (shouldMergeWithPrevious) {
                    const mergedPeriod = {
                        ...previousDay,
                        combinedCloseTime: period.combinedCloseTime,
                    };

                    return {
                        periods: [...result.periods, mergedPeriod],
                        mergedIndexes: [...result.mergedIndexes, previousIndex],
                    };
                } else if (shouldMergeWithNext) {
                    const mergedPeriod = {
                        ...period,
                        combinedCloseTime: nextDay.combinedCloseTime,
                    };

                    return {
                        periods: [...result.periods, mergedPeriod],
                        mergedIndexes: [...result.mergedIndexes, nextIndex],
                    };
                }

                result.periods.push(period);
                return result;
            },
            accumulator,
        ).periods;
};

export const normalizeSplitNightHours = (
    periods: EditableRegularHoursPeriod[],
): TGoogleTimePeriod[] => {
    return periods.reduce((result: TGoogleTimePeriod[], item) => {
        const period = omit(
            item,
            'open',
            'displayDay',
            'combinedOpenTime',
            'combinedCloseTime',
        );

        const startMoment = moment().set({
            hours: Number(item.combinedOpenTime.split(':')[0]),
            minutes: Number(item.combinedOpenTime.split(':')[1]),
            seconds: 0,
        });

        const endMoment = moment().set({
            hours: Number(item.combinedCloseTime.split(':')[0]),
            minutes: Number(item.combinedCloseTime.split(':')[1]),
            seconds: 0,
        });

        if (startMoment.isAfter(endMoment)) {
            const firstPeriod = {
                ...period,
                openTime: getGoogleTime(item.combinedOpenTime),
                closeTime: getGoogleTime(MIDNIGHT_24),
            };

            const nextDayOfWeek = getNextDayOfWeek(period.openDay);
            const secondPeriod = {
                openDay: nextDayOfWeek,
                closeDay: nextDayOfWeek,
                openTime: getGoogleTime(MIDNIGHT),
                closeTime: getGoogleTime(item.combinedCloseTime),
            };

            result = [...result, firstPeriod, secondPeriod];
        } else {
            result.push({
                ...period,
                openTime: getGoogleTime(item.combinedOpenTime),
                closeTime: getGoogleTime(item.combinedCloseTime),
            });
        }

        return result;
    }, []);
};

export const formatDateDistance = (date: string): string =>
    formatDistance(new Date(date), new Date(), {
        addSuffix: true,
    });
