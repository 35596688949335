import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { UserInfoApi } from 'features/account/api';
import { showDetailedError } from 'utils/messages';

export const getUserInfo = createAsyncThunk(
    'userInfo/getUserInfo',
    async (_, { rejectWithValue }) => {
        try {
            const response = await UserInfoApi.getUserInfo();
            return response.data;
        } catch (error) {
            showDetailedError({
                template: 'errors.connect.userInfo',
            });
            return rejectWithValue({
                data: (error as AxiosError<any, any>).response?.data,
                status: (error as AxiosError<any, any>).response?.status,
            });
        }
    },
);

export const resetUserInfo = createAction('userInfo/reset');
