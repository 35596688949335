import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'reduxConfig';

import { IAdvisorState } from './advisor.types';

export const selectAdvisorStatusState = (state: RootState): IAdvisorState =>
    state?.dashboard?.advisor;

export const selectAdvisorStatusData = createSelector(
    selectAdvisorStatusState,
    (state: IAdvisorState) => state?.data,
);
export const selectAdvisorLoadingStatus = createSelector(
    selectAdvisorStatusState,
    (state: IAdvisorState) => state?.loading,
);
export const selectAdvisorErrorStatus = createSelector(
    selectAdvisorStatusState,
    (state: IAdvisorState) => state?.error,
);
