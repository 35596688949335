import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import { IPostBusinessInfo } from './Post.types';

export function getBusinessInfo(): Promise<AxiosResponse<any>> {
    return axios.get<any>(`/api/profile/info`);
}

export function updateBusinessInfo(
    data?: IPostBusinessInfo | undefined,
    coverImg?: File,
    profileImg?: File,
): Promise<AxiosResponse<IPostBusinessInfo>> {
    const formData = new FormData();
    if (data) {
        Object.entries(data).forEach((dataKey) => {
            formData.append(dataKey[0], JSON.stringify(dataKey[1]));
        });
    }

    if (coverImg) {
        formData.append('coverImg', coverImg, coverImg.name);
    }

    if (profileImg) {
        formData.append('profileImg', profileImg, profileImg.name);
    }

    return axios.patch<IPostBusinessInfo>(`/api/profile/info`, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
}

export function searchPlacesV1(name: string): Promise<AxiosResponse<any>> {
    return axios.get('/api/profile/places-search', { params: { name } });
}

export function searchPlaces(name: string): Promise<AxiosResponse<any>> {
    return axios.get('/api/profile/places-autocomplete', { params: { name } });
}
