import React, { useState } from 'react';
import { Button, IconButton } from 'spoton-lib';
import { useTranslation } from 'react-i18next';

import { IImage } from 'features/common/components/MediaSelector/ImageSelector.types';
import MediaPhotosSelector from 'features/common/components/MediaPhotosSelector';

import styles from './MenuPhotosUpload.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    addMenuPhoto,
    uploadMenuPhotosLoadingStatus,
} from 'features/dashboard/redux';

export function MenuPhotosUpload({
    closeModal,
}: {
    closeModal: () => void;
}): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isUploadPhotoLoading = useSelector(uploadMenuPhotosLoadingStatus);

    const [images, setImages] = useState<any>([]);
    const [imageFiles, setImageFiles] = useState<any>([]);

    const uploadPhotos = () => {
        imageFiles.map((item: File) => dispatch(addMenuPhoto(item)));
    };

    const loadImage = (e: any, file: File) => {
        setImageFiles([...imageFiles, file]);
        const url = e.target.result.toString();
        if (url) {
            const image = {
                imgUrl: url,
                imgAlt: file.name,
            };
            setImages([...images, image]);
        }
    };

    const addImage = (files: FileList) => {
        Array.from(files).forEach((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                loadImage(e, file);
            };
        });
    };

    const removeImage = (index: number) => {
        const imagesCopy = images.filter((image: IImage, i: number) => i !== index);
        const imageFileCopy = imageFiles.filter(
            (image: IImage, i: number) => i !== index,
        );
        setImages([...imagesCopy]);
        setImageFiles([...imageFileCopy]);
    };

    //TODO: Replace this header to src/features/common/components/Modal/Modal.component.tsx -> ModalHeader
    return (
        <>
            <IconButton
                name="CloseIcon"
                alt={t('close')}
                buttonSize="regular"
                size={36}
                variant="secondary"
                disableBorder
                className={styles.CloseIcon}
                onClick={closeModal}
            />
            <h3 className={styles.Heading}>{t('menuPhotos.modalTitle')}</h3>
            <div className={styles.Body}>
                <div className={styles.ImageSelectorWrapper}>
                    <MediaPhotosSelector
                        className={styles.ImageSelector}
                        images={images}
                        onAddImages={addImage}
                        onRemoveImage={removeImage}
                    />
                </div>
            </div>
            <footer className={styles.Footer}>
                <Button
                    variant="secondary"
                    onClick={closeModal}
                    className={styles.ButtonDiscard}
                >
                    {t(`discard`)}
                </Button>
                <Button
                    className={styles.ButtonSubmit}
                    variant="primary"
                    onClick={uploadPhotos}
                    disabled={!images.length || isUploadPhotoLoading}
                >
                    {t('menuPhotos.uploadPhotos')}
                </Button>
            </footer>
        </>
    );
}
