// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SublocationSelect_Button__1yN2G{margin:8px 0}\n", "",{"version":3,"sources":["webpack://src/features/account/components/Sublocation/components/SublocationSelect/SublocationSelect.module.scss"],"names":[],"mappings":"AAAA,iCACI,YAAa","sourcesContent":[".Button {\n    margin: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": "SublocationSelect_Button__1yN2G"
};
export default ___CSS_LOADER_EXPORT___;
