// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuScreen_Wrapper__2ZQQx{width:100%;padding:38px 0 40px;text-align:left}.MenuScreen_PageTitle__TxwmF{margin-bottom:30px}.MenuScreen_HalfSectionWrapper__3jqUb{display:flex;flex-wrap:wrap;justify-content:space-between;width:100%}.MenuScreen_LeftSideWrapper__dTi_A{width:calc(54% - 10px)}.MenuScreen_RightSideWrapper__2Mqfr{width:calc(46% - 10px);max-height:calc(100vh - 240px)}@media screen and (max-width: 1024px){.MenuScreen_Wrapper__2ZQQx{margin-top:70px;padding:18px 0 20px}.MenuScreen_LeftSideWrapper__dTi_A,.MenuScreen_RightSideWrapper__2Mqfr{width:100%}.MenuScreen_PageTitle__TxwmF{display:none}}\n", "",{"version":3,"sources":["webpack://src/features/dashboard/menu/MenuScreen/MenuScreen.module.scss"],"names":[],"mappings":"AAEA,2BACI,UAAW,CACX,mBAAoB,CACpB,eAAgB,CACnB,6BAGG,kBAAmB,CACtB,sCAGG,YAAa,CACb,cAAe,CACf,6BAA8B,CAC9B,UAAW,CACd,mCAGG,sBAAuB,CAC1B,oCAGG,sBAAuB,CACvB,8BAA+B,CAClC,sCAGG,2BACI,eAAgB,CAChB,mBAAoB,CACvB,uEAIG,UAAW,CACd,6BAGG,YAAa,CAChB","sourcesContent":["@import 'src/features/common/styles/breakpoints';\n\n.Wrapper {\n    width: 100%;\n    padding: 38px 0 40px;\n    text-align: left;\n}\n\n.PageTitle {\n    margin-bottom: 30px;\n}\n\n.HalfSectionWrapper {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.LeftSideWrapper {\n    width: calc(54% - 10px);\n}\n\n.RightSideWrapper {\n    width: calc(46% - 10px);\n    max-height: calc(100vh - 240px);\n}\n\n@include notDesktop {\n    .Wrapper {\n        margin-top: 70px;\n        padding: 18px 0 20px;\n    }\n\n    .LeftSideWrapper,\n    .RightSideWrapper {\n        width: 100%;\n    }\n\n    .PageTitle {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Wrapper": "MenuScreen_Wrapper__2ZQQx",
	"PageTitle": "MenuScreen_PageTitle__TxwmF",
	"HalfSectionWrapper": "MenuScreen_HalfSectionWrapper__3jqUb",
	"LeftSideWrapper": "MenuScreen_LeftSideWrapper__dTi_A",
	"RightSideWrapper": "MenuScreen_RightSideWrapper__2Mqfr"
};
export default ___CSS_LOADER_EXPORT___;
