import { getRoutePrefix } from '../../../utils/router';

const allowedUrls: any = {
    // eslint-disable-next-line
    NOT_REGISTERED: ['/', '/connect'],
    // eslint-disable-next-line
    OAUTH_COMPLETED: ['/connect'],
    // eslint-disable-next-line
    MATCHING_CONFIRMED: ['/pending-connection'],
    // eslint-disable-next-line
    CLAIM_REQUESTED: ['/pending-access'],
    // eslint-disable-next-line
    MANUAL_VERIFICATION_REQUIRED: ['/pending-verification'],
    // eslint-disable-next-line
    LINKED: [
        '/pending-connection',
        '/dashboard',
        '/dashboard/profile',
        '/dashboard/menu',
    ],
};

const redirectUrls: any = {
    NOT_REGISTERED: '/', // eslint-disable-line
    OAUTH_COMPLETED: '/connect', // eslint-disable-line
    MATCHING_CONFIRMED: '/pending-connection', // eslint-disable-line
    CLAIM_REQUESTED: '/pending-access', // eslint-disable-line
    MANUAL_VERIFICATION_REQUIRED: '/pending-verification', // eslint-disable-line
    LINKED: '/dashboard', // eslint-disable-line
};

export const getRedirectPath = (status: string): any => {
    const currentPath = window.location.pathname;
    if (allowedUrls[status].includes(currentPath)) {
        return;
    } else {
        return getRoutePrefix() + redirectUrls[status];
    }
};
