import { createReducer, Reducer } from '@reduxjs/toolkit';

import * as actions from './actions';
import { IDisconnectTypes } from './types';

export const initialState: IDisconnectTypes = {
    locationName: '',
    locationAddress: '',
    scope: '',
    clientId: '',
    isLoading: true,
    isError: false,
    isStatusError: false,
    isLocationStatus: false,
    isLocationDisconnected: false,
};

export const disconnectReducer: Reducer<IDisconnectTypes> = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(actions.getAuthConfig.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(actions.getAuthConfig.fulfilled, (state, action: any) => {
                state.scope = action.payload.config.scope.join(',');
                state.clientId = action.payload.config.clientId;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(actions.getAuthConfig.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(actions.getLocationStatus.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                actions.getLocationStatus.fulfilled,
                (state, { payload: { location } }) => {
                    state.locationName = location.location_name;
                    state.locationAddress = location.address;
                    state.isLoading = false;
                    state.isLoading = false;
                    state.isLocationStatus = true;
                },
            )
            .addCase(actions.getLocationStatus.rejected, (state) => {
                state.isLoading = false;
                state.isStatusError = true;
            })
            .addCase(actions.disconnectAccount.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(actions.disconnectAccount.fulfilled, (state) => {
                state.isLoading = false;
                state.isLocationDisconnected = true;
            })
            .addCase(actions.disconnectAccount.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addDefaultCase((state) => {
                return state;
            });
    },
);

export default disconnectReducer;
