import React, { useState, useEffect } from 'react';
import { Tabs, LoadingIndicator } from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import Post from './Post/Post.component';
import styles from './PostsTabs.module.scss';

import { IPost } from 'features/dashboard/api/Post.types';
import { PostApi } from 'features/dashboard/api';
import NoPostsImage from 'features/dashboard/assets/noPosts.png';
import ServerErrorImage from 'features/dashboard/assets/serverError.png';
import { useBreakpoint } from '../../../../utils/customHooks';
import classNames from 'classnames';
import { showDetailedError } from 'utils/messages';

interface IPostTabsProps {
    isFullView: boolean;
    initialTab?: string;
    stateTabs?: any;
    initialPosts?: Array<IPost>;
    statePosts?: any;
    stateRefresh: any;
}

export default function PostTabs({
    isFullView,
    initialTab,
    stateTabs,
    initialPosts,
    statePosts,
    stateRefresh,
}: IPostTabsProps): JSX.Element {
    const isMobile = useBreakpoint() !== 'desktop';
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = initialTab
        ? useState<string>(initialTab)
        : stateTabs;
    const [posts, setPosts] = initialPosts
        ? useState<any>(initialPosts)
        : statePosts;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const fetchPosts = (filter: string) => {
        setIsLoading(true);
        PostApi.getPosts(filter)
            .then((response) => {
                setPosts(response.data);
            })
            .catch((error) => {
                showDetailedError({
                    template: 'errors.dashboard.postsFetch',
                    error: error.response?.data,
                });
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onTabSelect = (tabName: string) => {
        fetchPosts(tabName.toUpperCase());
        setActiveTab(tabName);
    };

    useEffect(() => {
        if (!posts.localPosts?.length) {
            fetchPosts(activeTab.toUpperCase());
        }
    }, []);

    const [refreshTab, setRefreshTab] = stateRefresh;

    useEffect(() => {
        const currentTab = activeTab.toUpperCase();
        if (refreshTab && (currentTab === refreshTab || currentTab === 'ALL')) {
            fetchPosts(currentTab);
            setRefreshTab();
        }
    });

    let content;
    if (isLoading) {
        content = (
            <div className={styles.Loader}>
                <LoadingIndicator />
            </div>
        );
    } else if (posts.localPosts?.length) {
        content = posts.localPosts.map((post: IPost) => (
            <Post key={post.name} post={post} refreshTab={setRefreshTab} />
        ));
        if (!isFullView) {
            content.length = 2;
        }
    } else {
        content = (
            <div className={styles.NoItems}>
                <img
                    src={isError ? ServerErrorImage : NoPostsImage}
                    alt="no posts"
                />
                <div className={styles.NoItemsText}>
                    {isError && t('serverError')}
                    {!isError &&
                        (activeTab === 'all' || activeTab === 'standard') &&
                        t('dashboard.postsNoItems')}
                    {!isError &&
                        activeTab === 'offer' &&
                        t('dashboard.postsNoItemsOffers')}
                    {!isError &&
                        activeTab === 'event' &&
                        t('dashboard.postsNoItemsEvents')}
                </div>
            </div>
        );
    }

    return (
        <>
            {(!isMobile || isFullView) && (
                <Tabs
                    active={activeTab}
                    onSelect={onTabSelect}
                    className={styles.Tabs}
                >
                    <Tabs.Tab className={styles.Tab} name="all">
                        {t('all')}
                    </Tabs.Tab>
                    <Tabs.Tab className={styles.Tab} name="offer">
                        {t('offers')}
                    </Tabs.Tab>
                    <Tabs.Tab className={styles.Tab} name="standard">
                        {t('whatsNew')}
                    </Tabs.Tab>
                    <Tabs.Tab className={styles.Tab} name="event">
                        {t('events')}
                    </Tabs.Tab>
                </Tabs>
            )}
            <div
                className={classNames(styles.TabContent, {
                    [styles.MobileTabContent]: isFullView && isMobile,
                })}
                style={!isFullView ? { overflow: 'visible' } : {}}
            >
                {content}
            </div>
        </>
    );
}
