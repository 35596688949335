import React from 'react';
import { ValueContainerProps, GroupTypeBase, components } from 'react-select';
import { Icon, colors } from 'spoton-lib';

import { ISelectOption } from '../../MultiSelectDropdown.types';

import styles from './SearchValueContainer.module.scss';

/**
 * SearchValueContainer customer override of the ValueContainer used by MultiSelectDropdown
 * @see MultiSelectDropdown.component.tsx
 */
/* eslint-disable @typescript-eslint/naming-convention */
const SearchValueContainer: React.FC<
    ValueContainerProps<ISelectOption, boolean, GroupTypeBase<ISelectOption>>
> = ({ children, ...props }) => (
    <components.ValueContainer {...props} className={styles.ValueContainer}>
        <Icon
            size={16}
            name="SearchIcon"
            alt="ClearIcon"
            className={styles.ValueContainer_icon}
            color={colors.base50}
        />
        {children}
    </components.ValueContainer>
);

export default SearchValueContainer;
