import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LoadingIndicator, Modal, Tabs } from 'spoton-lib';

import {
    IQuestions,
    QuestionTabs,
} from 'features/dashboard/types/QuestionsSectionTypes';
import serverErrorImage from 'features/dashboard/assets/serverError.png';
import NoItemsImageSorted from 'features/common/assets/noItems.png';
import { Question } from '../Question';
import { QuestionsModal } from '../QuestionsModal';
import { useBreakpoint } from 'utils/customHooks';

import styles from './QuestionsTabs.module.scss';
import ServerErrorImage from 'features/dashboard/assets/serverError.png';
import NoItemsImage from 'features/dashboard/assets/noPosts.png';
import {
    showQuestionsModal,
    hideQuestionsModal,
} from 'features/dashboard/redux/modals';
import { selectQuestionsModalState } from 'features/dashboard/redux';

interface IProps {
    questionsData: IQuestions | undefined;
    isLoading: boolean;
    hasQuestions?: boolean;
    isError: boolean;
    sortOrder: any;
    order: any;
}

export default function QuestionsTabs({
    questionsData,
    isLoading,
    isError,
    sortOrder,
    order,
}: IProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useBreakpoint() !== 'desktop';
    const [activeTab, setActiveTab] = useState(QuestionTabs.all);
    const isModalOpen = useSelector(selectQuestionsModalState);
    const hasQuestions =
        questionsData?.questions && questionsData?.questions.length > 0;
    const repliedQuestions = useMemo(
        () => questionsData?.questions?.filter((item) => item?.topAnswers),
        [questionsData],
    );

    const notRepliedQuestions = useMemo(
        () => questionsData?.questions?.filter((item) => !item?.topAnswers),
        [questionsData],
    );

    const questions = useMemo(() => {
        switch (activeTab) {
            case QuestionTabs.replied:
                return repliedQuestions || [];
            case QuestionTabs.notReplied:
                return notRepliedQuestions || [];
            default:
                return questionsData?.questions || [];
        }
    }, [questionsData, repliedQuestions, notRepliedQuestions, activeTab]);

    const lastQuestion = useMemo(() => {
        const reversedQuestions =
            questionsData?.questions?.filter((item) => item) || [];
        return reversedQuestions[0];
    }, [questionsData]);

    const handleChangeTab = useCallback((name) => {
        setActiveTab(name);
    }, []);

    const showModal = () => {
        dispatch(showQuestionsModal());
    };

    const hideModal = () => {
        dispatch(hideQuestionsModal());
    };

    if (isMobile) {
        if (isError) {
            return (
                <div className={styles.ErrorWrapper}>
                    <img
                        className={styles.ErrorImage}
                        src={ServerErrorImage}
                        alt="Server error"
                    />
                    {t('errors.server')}
                </div>
            );
        }

        if (!lastQuestion) {
            return (
                <div className={styles.ErrorWrapper}>
                    <img
                        className={styles.ErrorImage}
                        src={NoItemsImage}
                        alt="No Items"
                    />
                    {/*//TODO simplify to JSON with text*/}
                    {activeTab === QuestionTabs.all &&
                        t('dashboard.questions.noQuestions')}

                    {activeTab === QuestionTabs.replied &&
                        (hasQuestions
                            ? t('dashboard.questions.noQuestionsReplied')
                            : t('dashboard.questions.noQuestions'))}

                    {activeTab === QuestionTabs.notReplied &&
                        (hasQuestions
                            ? t('dashboard.questions.noQuestionsNotReplied')
                            : t('dashboard.questions.noQuestions'))}
                </div>
            );
        }

        return (
            <>
                <Question
                    key={lastQuestion?.questionId}
                    question={lastQuestion}
                    sortOrder={order}
                />
                <Button
                    variant={'tertiary'}
                    onClick={showModal}
                    block
                    className={styles.ViewAll}
                >
                    {t('viewAll')}
                </Button>
                <Modal
                    ariaHideApp={false}
                    isOpen={isModalOpen}
                    onRequestClose={hideModal}
                    className={styles.Modal}
                    overlayClassName={styles.Overlay}
                >
                    <QuestionsModal
                        sortOrder={sortOrder}
                        questionsData={questionsData}
                        closeModal={hideModal}
                        order={order}
                        isError={isError}
                        isLoading={isLoading}
                    />
                </Modal>
            </>
        );
    }

    return (
        <>
            <Tabs
                active={activeTab}
                onSelect={handleChangeTab}
                className={styles.Tabs}
            >
                <Tabs.Tab name={QuestionTabs.all}>{t('all')}</Tabs.Tab>
                <Tabs.Tab name={QuestionTabs.replied}>
                    {t('dashboard.replied')}
                </Tabs.Tab>
                <Tabs.Tab name={QuestionTabs.notReplied}>
                    {t('dashboard.notReplied')}
                </Tabs.Tab>
            </Tabs>
            <div className={styles.TabsContent}>
                {isError && (
                    <div className={styles.CenterContainer}>
                        <img src={serverErrorImage} alt="Server error" />
                        <p className={styles.serverError}>{t('errors.server')}</p>
                    </div>
                )}
                {isLoading && (
                    <div className={styles.CenterContainer}>
                        <LoadingIndicator />
                    </div>
                )}
                {!isLoading && questions && !questions.length && (
                    <div className={styles.CenterContainer}>
                        <img src={NoItemsImageSorted} alt="No items" />
                        <p className={styles.serverError}>
                            {' '}
                            {activeTab === QuestionTabs.all &&
                                t('dashboard.questions.noQuestions')}
                            {activeTab === QuestionTabs.replied &&
                                (hasQuestions
                                    ? t('dashboard.questions.noQuestionsReplied')
                                    : t('dashboard.questions.noQuestions'))}
                            {activeTab === QuestionTabs.notReplied &&
                                (hasQuestions
                                    ? t('dashboard.questions.noQuestionsNotReplied')
                                    : t('dashboard.questions.noQuestions'))}
                        </p>
                    </div>
                )}
                {!isLoading &&
                    questions?.map((question: any) => {
                        return (
                            <Question
                                sortOrder={order}
                                key={question.questionId}
                                question={question}
                            />
                        );
                    })}
            </div>
            <Modal
                ariaHideApp={false}
                isOpen={isModalOpen}
                onRequestClose={hideModal}
                className={styles.Modal}
                overlayClassName={styles.Overlay}
            >
                <QuestionsModal
                    sortOrder={sortOrder}
                    questionsData={questionsData}
                    closeModal={hideModal}
                    order={order}
                    isError={isError}
                    isLoading={isLoading}
                />
            </Modal>
        </>
    );
}
