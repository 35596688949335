import React, { SyntheticEvent, ReactElement } from 'react';
import styles from './Menu.module.scss';

interface IMenuProps {
    className?: string;
    children: ReactElement<IItemProps>[];
}

interface IItemProps {
    onClick?: (event: SyntheticEvent) => void;
    className?: string;
}

export class Item extends React.Component<IItemProps> {
    render(): JSX.Element {
        const { className, onClick, children } = this.props;
        return (
            <div
                className={`${styles.MenuItem} ${className ? className : ''}`}
                role="button"
                tabIndex={0}
                onClick={onClick}
                onKeyPress={() => {
                    null;
                }}
            >
                {children}
            </div>
        );
    }
}
export default class Menu extends React.Component<IMenuProps> {
    static item = Item;
    render(): JSX.Element {
        const { className, children } = this.props;

        return (
            <div className={`${styles.Menu} ${className ? className : ''}`}>
                {children}
            </div>
        );
    }
}
