import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PostHeader from './PostHeader.component';
import PostContent from './PostContent.component';
import PostInsights from './PostInsights.component';
import PostSmallModal from '../SmallModal/SmallModal.component';
import { DeleteModal } from 'features/common/components/DeleteModal';
import { showToast } from 'spoton-lib';

import { PostApi } from 'features/dashboard/api';
import { IPost } from 'features/dashboard/api/Post.types';

import styles from './Post.module.scss';
import { showDetailedError } from 'utils/messages';

interface IPostProps {
    post: IPost;
    refreshTab: (arg: string) => void;
}

export default function Post({ post, refreshTab }: IPostProps): JSX.Element {
    const { t } = useTranslation();
    const [contentHeight, setContentHeight] = useState<number>(80);

    const [isShowingSmallModal, setIsShowingSmallModal] = useState<boolean>(false);

    const [isShowingDeleteModal, setIsShowingDeleteModal] = useState<boolean>(false);

    const openSmallModal = () => {
        setIsShowingSmallModal(true);
    };

    const closeSmallModal = () => {
        setIsShowingSmallModal(false);
    };

    const openDeleteModal = () => {
        setIsShowingDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setIsShowingDeleteModal(false);
    };

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const deletePost = () => {
        setIsProcessing(true);
        PostApi.deletePost(post.postId)
            .then(() => {
                showToast({
                    title: t('success'),
                    content: t('dashboard.postDeleteSuccess'),
                    variant: 'success',
                    autoClose: 4000,
                });
                refreshTab(post.topicType);
                closeDeleteModal();
            })
            .catch((error) => {
                showDetailedError({
                    message: t('errors.dashboard.postDelete'),
                    error: error.response?.data,
                });
                setIsProcessing(false);
            });
    };

    return (
        <div className={styles.PostCard}>
            <div className={styles.PostData}>
                <PostHeader
                    searchUrl={post.searchUrl}
                    title={post.event?.title}
                    updateDate={post.updateTime}
                    imageUrl={post.media[0]?.googleUrl}
                    contentHeight={contentHeight}
                    openSmallModal={openSmallModal}
                    openDeleteModal={openDeleteModal}
                />
                <PostContent
                    summary={post.summary}
                    schedule={post.event?.schedule}
                    callToAction={post.callToAction}
                    setHeight={setContentHeight}
                />
            </div>
            <PostInsights postUrl={post.searchUrl} />
            {isShowingSmallModal && (
                <PostSmallModal
                    post={post}
                    closeModal={closeSmallModal}
                    refreshTab={refreshTab}
                />
            )}
            {isShowingDeleteModal && (
                <DeleteModal
                    closeModal={closeDeleteModal}
                    confirmDelete={deletePost}
                    deleteMessage={t('dashboard.postDeleteMessage')}
                    showWarning={true}
                    isProcessing={isProcessing}
                />
            )}
        </div>
    );
}
