import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from 'spoton-lib';

import { IImage } from 'features/common/components/MediaSelector/ImageSelector.types';
import {
    homeUploadMedia,
    homeUploadMediaLoadingStatus,
} from 'features/dashboard/redux';

import MediaSelector from 'features/common/components/MediaSelector';

import styles from './MediaModalUpload.module.scss';

export default function MediaModalUpload({
    closeModal,
    isMobile,
    finishUploadingMedia,
}: {
    closeModal: () => void;
    finishUploadingMedia: () => void;
    isMobile?: boolean;
}): JSX.Element {
    const dispatch = useDispatch();

    const isLoading = useSelector(homeUploadMediaLoadingStatus);

    const { t } = useTranslation();
    const [images, setImages] = useState<any>([]);
    const [imageFiles, setImageFiles] = useState<any>([]);

    const uploadMedia = () => {
        Promise.all(
            imageFiles.map((item: File) => dispatch(homeUploadMedia(item))),
        ).then(() => {
            finishUploadingMedia();
        });
    };

    const loadImage = (e: any, file: File) => {
        setImageFiles((imgFiles: any) => [...imgFiles, file]);
        const url = e.target.result.toString();
        if (url) {
            const image = {
                imgUrl: url,
                imgAlt: file.name,
            };
            setImages((img: any) => [...img, image]);
        }
    };

    const addImage = (files: FileList) => {
        Array.from(files).forEach((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                loadImage(e, file);
            };
        });
    };

    const removeImage = (index: number) => {
        const imagesCopy = images.filter((image: IImage, i: number) => i !== index);
        const imageFileCopy = imageFiles.filter(
            (image: IImage, i: number) => i !== index,
        );
        setImages([...imagesCopy]);
        setImageFiles([...imageFileCopy]);
    };

    const desktopHeader = () => (
        <>
            <IconButton
                name="CloseIcon"
                alt={t('close')}
                buttonSize="regular"
                size={36}
                variant="secondary"
                disableBorder
                className={styles.CloseIcon}
                onClick={closeModal}
            />
            <h3 className={styles.Heading}>{t('dashboard.uploadMedia')}</h3>
        </>
    );

    const mobileHeader = () => (
        <div className={styles.MobileHeadingWrapper}>
            <IconButton
                name="CloseIcon"
                alt={t('close')}
                buttonSize="regular"
                size={36}
                variant="secondary"
                disableBorder
                className={styles.MobileCloseIcon}
                onClick={closeModal}
            />
            <h3 className={styles.MobileHeader}>{t('dashboard.uploadMedia')}</h3>
        </div>
    );

    return (
        <>
            {!isMobile && desktopHeader()}
            {isMobile && mobileHeader()}
            <div className={styles.Body}>
                <div className={styles.ImageSelectorWrapper}>
                    <MediaSelector
                        className={styles.ImageSelector}
                        images={images}
                        onAddImages={addImage}
                        onRemoveImage={removeImage}
                    />
                </div>
            </div>
            <footer className={styles.Footer}>
                <div className={styles.ButtonContainer}>
                    {!isMobile && (
                        <Button variant="secondary" onClick={closeModal}>
                            {t(`discard`)}
                        </Button>
                    )}
                    <Button
                        className={styles.ButtonSubmit}
                        variant="primary"
                        block={isMobile}
                        onClick={uploadMedia}
                        disabled={!images.length || isLoading}
                    >
                        {t('dashboard.uploadMedia')}
                    </Button>
                </div>
            </footer>
        </>
    );
}
