import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReviewsTabs from './ReviewsTabs.component';
import {
    Button,
    Card,
    Dropdown,
    Modal,
    Icon,
    IconButton,
    StarRating,
    LoadingIndicator,
} from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import { ReviewApi } from 'features/dashboard/api';

import {
    showReviewSectionModal,
    hideReviewSectionModal,
    selectReviewModalState,
} from '../../redux';
import { useBreakpoint } from 'utils/customHooks';

import styles from './ReviewsSection.module.scss';
import classNames from 'classnames';
import { showDetailedError } from 'utils/messages';

function cutDecimalToOnePlace(number: number) {
    return Math.round(number * 10) / 10;
}

export default function ReviewsSection(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [sortOrder, setSortOrder] = useState<string>('updateTime desc');
    const [isLoading, setIsloading] = useState<boolean>(false);
    const [isLoadingSummary, setIsLoadingSummary] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [reviews, setReviews] = useState<any>({});
    const [reviewSummary, setReviewSummary] = useState<any>(null);
    const isModalOpen = useSelector(selectReviewModalState);
    const isMobile = useBreakpoint() !== 'desktop';
    const rates = ['five', 'four', 'three', 'two', 'one'];
    const sortLabels: any = {
        'updateTime desc': 'sortDate',
        'rating desc': 'sortRatingDesc',
        rating: 'sortRatingAsc',
    };

    const showModal = useCallback(() => {
        dispatch(showReviewSectionModal());
    }, [dispatch]);

    const closeModal = useCallback(() => {
        dispatch(hideReviewSectionModal());
    }, [dispatch]);

    const fetchReviews = async (isIgnore = false, orderBy?: string) => {
        try {
            setIsloading(true);
            const result = await ReviewApi.getReviews(orderBy);

            setIsloading(false);
            if (!isIgnore) setReviews(result.data);
        } catch (error) {
            showDetailedError({
                template: 'errors.dashboard.reviewsFetch',
            });
            setIsError(true);
            setIsloading(false);
        }
    };

    const fetchSummary = async (isIgnore = false) => {
        try {
            setIsLoadingSummary(true);
            const result = await ReviewApi.getReviewSummary();

            setIsLoadingSummary(false);
            if (!isIgnore) setReviewSummary(result.data);
        } catch (error) {
            showDetailedError({
                template: 'errors.dashboard.reviewsSummaryFetch',
            });
            setIsLoadingSummary(false);
        }
    };
    useEffect(() => {
        let isIgnore = false;

        fetchSummary(isIgnore);

        fetchReviews(isIgnore);

        return () => {
            isIgnore = true;
        };
    }, []);

    const sortReviews = (order: any) => {
        setSortOrder(order.value);
        setReviews({});
        fetchReviews(false, order.value);
    };

    const getMoreReviews = (page: number) => {
        if (page > 10) {
            return;
        }
        ReviewApi.getReviewsInfinite(reviews.nextPageToken)
            .then((result) => {
                setReviews({
                    ...reviews,
                    ...result.data,
                    nextPageToken: result.data.nextPageToken,
                    reviews: [...reviews.reviews, ...result.data.reviews],
                });
            })
            .catch(() => {
                showDetailedError({
                    template: 'errors.dashboard.reviewsFetch',
                });
            });
    };

    const desktopModalHeader = () => (
        <>
            <IconButton
                name="CloseIcon"
                alt={t('close')}
                buttonSize="regular"
                size={36}
                variant="secondary"
                disableBorder
                className={styles.CloseIcon}
                onClick={closeModal}
            />
            <h3 className={styles.Heading}>{t('dashboard.reviews')}</h3>
            <Dropdown
                className={styles.Dropdown}
                value={{
                    value: sortOrder,
                    label: t(`dashboard.${sortLabels[sortOrder]}`),
                }}
                onChange={sortReviews}
                options={[
                    {
                        value: 'updateTime desc',
                        label: t('dashboard.sortDate'),
                    },
                    {
                        value: 'rating desc',
                        label: t('dashboard.sortRatingDesc'),
                    },
                    {
                        value: 'rating',
                        label: t('dashboard.sortRatingAsc'),
                    },
                ]}
            />
        </>
    );

    const mobileModalHeader = () => (
        <div className={styles.MobileHeader}>
            <span className={styles.MobileHeaderControls}>
                <IconButton
                    name="ArrowLeftIcon"
                    alt={t('close')}
                    buttonSize="large"
                    size={60}
                    variant="secondary"
                    disableBorder
                    className={styles.BackIcon}
                    onClick={closeModal}
                />
                <h3 className={styles.MobileHeading}>{t('dashboard.reviews')}</h3>
            </span>
            <Dropdown
                className={styles.MobileDropDown}
                value={{
                    value: sortOrder,
                    label: t(`dashboard.${sortLabels[sortOrder]}`),
                }}
                isSearchable={false}
                onChange={sortReviews}
                trigger={
                    <Icon
                        name="DefaultViewIcon"
                        className={styles.MobileSortBtn}
                        color="#1769ff"
                        alt="Options"
                        size={30}
                    />
                }
                options={[
                    {
                        value: 'updateTime desc',
                        label: t('dashboard.sortDate'),
                    },
                    {
                        value: 'rating desc',
                        label: t('dashboard.sortRatingDesc'),
                    },
                    {
                        value: 'rating',
                        label: t('dashboard.sortRatingAsc'),
                    },
                ]}
            />
        </div>
    );

    return (
        <>
            <Card elevation={1} className={styles.Wrapper}>
                {!isMobile && (
                    <>
                        <h3 className={styles.Heading}>{t('dashboard.reviews')}</h3>
                        <Dropdown
                            className={styles.Dropdown}
                            value={{
                                value: sortOrder,
                                label: t(`dashboard.${sortLabels[sortOrder]}`),
                            }}
                            onChange={sortReviews}
                            options={[
                                {
                                    value: 'updateTime desc',
                                    label: t('dashboard.sortDate'),
                                },
                                {
                                    value: 'rating desc',
                                    label: t('dashboard.sortRatingDesc'),
                                },
                                {
                                    value: 'rating',
                                    label: t('dashboard.sortRatingAsc'),
                                },
                            ]}
                        />
                    </>
                )}
                <div className={styles.ReviewSummary}>
                    {!isLoadingSummary ? (
                        <>
                            <div className={styles.RatingBlock}>
                                <div>
                                    <span className={styles.Rating}>
                                        {reviews.averageRating
                                            ? cutDecimalToOnePlace(
                                                  reviews.averageRating,
                                              )
                                            : 0}
                                    </span>
                                    <StarRating stars={reviews.averageRating || 0} />
                                    <div className={styles.ReviewsNumber}>
                                        {reviews.totalReviewCount || 0}{' '}
                                        {t('dashboard.reviews')}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    isMobile
                                        ? styles.MobileRatingChart
                                        : styles.RatingChart
                                }
                            >
                                {rates.map((rate, idx) => {
                                    const percent = reviewSummary
                                        ? (
                                              (reviewSummary[rate] * 100) /
                                              reviewSummary.total
                                          ).toFixed(1)
                                        : '0';
                                    return (
                                        <RatingItem
                                            key={rate}
                                            isMobile={isMobile}
                                            rate={5 - idx}
                                            percentage={percent}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        <div className={styles.Loader}>
                            <LoadingIndicator />
                        </div>
                    )}
                </div>
                {!isMobile && (
                    <ReviewsTabs
                        reviews={reviews.reviews}
                        isReviewFetchInProgress={isLoading}
                        nextPageToken={reviews.nextPageToken}
                        isError={isError}
                        isFullView={!isMobile}
                        getReviews={getMoreReviews}
                    />
                )}
                {isMobile && (
                    <div
                        className={
                            isMobile
                                ? styles.MobileButtonWrapper
                                : styles.ButtonWrapper
                        }
                    >
                        <Button
                            variant="tertiary"
                            className={styles.Button}
                            onClick={showModal}
                        >
                            {t('dashboard.viewAllReviews')}
                        </Button>
                    </div>
                )}
            </Card>
            <Modal
                ariaHideApp={false}
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                className={classNames(
                    { [styles.MobileModal]: isMobile },
                    { [styles.Modal]: !isMobile },
                )}
                overlayClassName={styles.Overlay}
            >
                {!isMobile && desktopModalHeader()}
                {isMobile && mobileModalHeader()}
                <ReviewsTabs
                    reviews={reviews.reviews}
                    isReviewFetchInProgress={isLoading}
                    nextPageToken={reviews.nextPageToken}
                    isError={isError}
                    isModal={true}
                    isFullView={true}
                    getReviews={getMoreReviews}
                />
            </Modal>
        </>
    );
}

interface IRatingItemProps {
    rate: number;
    percentage: string;
    isMobile?: boolean;
}

export function RatingItem({
    rate,
    percentage,
    isMobile,
}: IRatingItemProps): JSX.Element {
    return (
        <div className={styles.RatingItem}>
            <span className={styles.RatingNum}>{rate}</span>
            <Icon alt="star icon" color="#e6e8ee" name="RatingSolidIcon" size={11} />
            <div className={isMobile ? styles.MobileRatingFill : styles.RatingFill}>
                <div
                    className={styles.RatingPercent}
                    style={{
                        width: `${percentage}%`,
                    }}
                />
            </div>
        </div>
    );
}
