import React, { BaseSyntheticEvent } from 'react';
import { TextArea } from 'spoton-lib';
import { useTranslation } from 'react-i18next';

import styles from './InputSummary.module.scss';

interface IInputSummaryProps {
    topicType: string;
    stateSummary: any;
    isValid: boolean;
}

export default function InputSummary({
    topicType,
    stateSummary,
    isValid,
}: IInputSummaryProps): JSX.Element {
    const { t } = useTranslation();
    const [summaryText, setSummaryText] = stateSummary;
    const collectInput = (event: BaseSyntheticEvent) => {
        setSummaryText(event.target.value);
    };

    return (
        <div className={styles.SummaryWrapper}>
            <TextArea
                className={styles.SummaryInput}
                value={summaryText}
                label={t(`dashboard.postSummaryLabel.${topicType}`)}
                onChange={collectInput}
                primaryCondition={topicType !== 'STANDARD' ? t('optional') : ''}
                secondaryCondition={isValid ? '' : t('dashboard.postSummaryError')}
                isValid={isValid}
            />
        </div>
    );
}
