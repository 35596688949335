import React, { useCallback } from 'react';
import { RootState } from 'reduxConfig';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import styles from './PostContent.module.scss';
import {
    IPostEventSchedule,
    IPostCallToAction,
    IPostDate,
} from 'features/dashboard/api/Post.types';
import { selectBusinessInfoData } from 'features/dashboard/redux';

interface IPostContentProps {
    summary?: string;
    schedule?: IPostEventSchedule;
    callToAction?: IPostCallToAction;
    setHeight: (arg: number) => void;
}

function getLocalDate(date: IPostDate): string {
    return moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format(
        'L',
    );
}

export default function PostContent({
    summary,
    schedule,
    callToAction,
    setHeight,
}: IPostContentProps): JSX.Element {
    const { t } = useTranslation();
    const businessInfo = useSelector((state: RootState) =>
        selectBusinessInfoData(state),
    );

    const measureContentHeight = useCallback((node) => {
        if (node !== null) {
            setHeight(node.getBoundingClientRect().height);
        }
    }, []);

    const executeAction = () => {
        if (callToAction) {
            if (callToAction.actionType === 'CALL') {
                window.location.href = `tel:${businessInfo.phoneNumbers.primaryPhone}`;
            } else {
                window.open(callToAction.url);
            }
        }
    };

    const doNothing = () => {
        null;
    };

    return (
        <div ref={measureContentHeight} className={styles.PostContent}>
            {summary && <div className={styles.PostSummary}>{summary}</div>}
            {schedule && (
                <div className={styles.PostSchedule}>
                    {t('valid')}&nbsp;&nbsp;
                    {getLocalDate(schedule.startDate)}
                    &nbsp;-&nbsp;
                    {getLocalDate(schedule.endDate)}
                </div>
            )}
            {callToAction?.actionType && (
                <div
                    role="button"
                    tabIndex={0}
                    className={styles.PostAction}
                    onClick={executeAction}
                    onKeyPress={doNothing}
                >
                    {t(`dashboard.postActions.${callToAction?.actionType}`)}
                </div>
            )}
        </div>
    );
}
