import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useDebounce from 'features/common/utils/hooks/useDebounce';
import MultiSelectDropdown from 'features/common/components/MultiSelectDropdown';
import { getCategories } from 'features/account/redux/categories/categories.actions';
import {
    selectCategories,
    selectCategoriesLoading,
} from 'features/account/redux/categories/categories.selectors';

interface ICategorySelectorProps {
    categoryState: any;
    label: string;
}

export default function SelectSubCategory({
    categoryState,
    label,
}: ICategorySelectorProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [categories, setCategories] = categoryState;

    const [categorySearch, setCategorySearch] = useState<string>('');

    const debouncedCategory = useDebounce(categorySearch, 500);

    const storeCategories = useSelector(selectCategories);

    const isLoading = useSelector(selectCategoriesLoading);

    const handleOnSelect = (items: any) =>
        items.length <= 10 ? setCategories(items) : null;

    useEffect(() => {
        dispatch(
            getCategories({
                languageCode: t('languageCode'),
                searchTerm: debouncedCategory,
            }),
        );
    }, [debouncedCategory]);

    const categoriesOptions = storeCategories?.map((category: any) => ({
        value: category.name,
        label: category.displayName,
    }));
    return (
        <MultiSelectDropdown
            label={label}
            value={categories}
            onChange={handleOnSelect}
            options={categoriesOptions}
            onInputChange={setCategorySearch}
            inputValue={categorySearch}
            isLoading={isLoading}
            className=""
        />
    );
}
