import { connect, ConnectedProps } from 'react-redux';
import { authActions } from 'features/common/redux/';
import { RootState } from 'reduxConfig';

export interface IStatePropTypes {
    merchantLocationId?: string;
}

// eslint-disable-next-line
export function mapStateToProps(state: RootState): IStatePropTypes {
    // const merchantLocationId = authSelectors.selectMerchantLocationId(state);

    return {
        //merchantLocationId,
    };
}

export const mapDispatchToProps = {
    logout: authActions.logoutUserAction,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type IAppPropTypes = ConnectedProps<typeof connector> & IStatePropTypes;
