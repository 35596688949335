import React from 'react';
import { colors, Icon } from 'spoton-lib';

import { IProps } from './Tag.types';

import styles from './Tag.module.scss';

/* eslint-disable @typescript-eslint/naming-convention */
const Tag: React.FC<IProps> = ({ children, onClick }) => (
    <div
        className={styles.Tag}
        data-testid="tag-wrapper"
        // avoid opening dropdown
        onMouseDown={(e) => e.stopPropagation()}
        onTouchEnd={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
    >
        <div className={styles.TagLabel}>{children}</div>
        <div
            onClick={onClick}
            onKeyDown={() => null}
            className={styles.Tag_clearIndicator}
            role="button"
            tabIndex={0}
        >
            <Icon
                size={18}
                color={colors.base50}
                name="ClearIcon"
                alt="ClearIcon"
                data-testid="clear-tag-icon"
            />
        </div>
    </div>
);

export default Tag;
