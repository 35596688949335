import React, { useCallback, useState } from 'react';
import { DateRangePicker, Radio, SingleDatePicker } from 'spoton-lib';
import cn from 'classnames';
import { noop } from 'lodash';

import styles from './CloseHoursEdit.module.scss';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

type FocusedInput = 'startDate' | 'endDate' | null;

export function CloseHoursEdit(): JSX.Element {
    const { t } = useTranslation();
    const [periodRange, setPeriodRange] = useState('today');

    const [todayDate, setTodayDate] = useState(moment());
    const [startDate, setStartDate] = useState<Moment | null>(moment());
    const [endDate, setEndDate] = useState<Moment | null>(moment());

    const [isStartDateFocused, setStartDateFocused] = useState(true);
    const [isEndDateFocused, setEndDateFocused] = useState(false);
    const [focusedInput, setFocusedInput] = useState<FocusedInput>('startDate');

    const [weekStartDate, weekEndDate] = [
        moment().startOf('week'),
        moment().endOf('week'),
    ];

    const onChangePeriodRange = useCallback(
        (event: string) => () => {
            setPeriodRange(event);
        },
        [],
    );

    const hanldeChangeTodayDate = useCallback((event) => {
        setTodayDate(event);
    }, []);

    const handleRangeDateChange = useCallback(
        (start: Moment | null, end: Moment | null) => {
            setEndDate(end);
            setStartDate(startDate);
        },
        [],
    );

    return (
        <div className={styles.Calendar}>
            <div className={styles.RadioGroup}>
                <Radio
                    label={t('profile.businessHours.today')}
                    className={styles.RadioButton}
                    checked={periodRange === 'today'}
                    onChange={onChangePeriodRange('today')}
                />
                <Radio
                    label={t('profile.businessHours.thisWeek')}
                    className={styles.RadioButton}
                    checked={periodRange === 'week'}
                    onChange={onChangePeriodRange('week')}
                />
                <Radio
                    label={t('profile.businessHours.custom')}
                    className={styles.RadioButton}
                    checked={periodRange === 'custom'}
                    onChange={onChangePeriodRange('custom')}
                />
            </div>
            <div className={styles.DatePicker}>
                {periodRange === 'today' && (
                    <>
                        <div className={styles.DatePickerItem}>
                            <div className={styles.DatePickerItemLabel}>
                                {t('startDate')}
                            </div>
                            <SingleDatePicker
                                id="startDatePicker"
                                placeholder={t('businessHours.enterDate')}
                                date={todayDate}
                                focused={isStartDateFocused}
                                showClearDate
                                onFocusChange={({ focused }) => {
                                    setStartDateFocused(focused);
                                }}
                                onDateChange={(date) => hanldeChangeTodayDate(date)}
                            />
                        </div>
                        <div className={styles.DatePickerItem}>
                            <div
                                className={cn(
                                    styles.DatePickerItemLabel,
                                    styles.DatePickerItemLabelDisabled,
                                )}
                            >
                                {t('endDate')}
                            </div>
                            <SingleDatePicker
                                id="endDatePicker"
                                placeholder={t('businessHours.enterDate')}
                                date={null}
                                focused={isEndDateFocused}
                                disabled={true}
                                onFocusChange={({ focused }) =>
                                    setEndDateFocused(focused)
                                }
                                onDateChange={(date) => hanldeChangeTodayDate(date)}
                            />
                        </div>
                    </>
                )}
                {periodRange === 'week' && (
                    <DateRangePicker
                        onDatesChange={noop}
                        startDate={weekStartDate}
                        endDate={weekEndDate}
                        startDateId="weekStartDateId"
                        endDateId="weeekEndDateId"
                        focusedInput="startDate"
                        onFocusChange={(focusedInput) =>
                            setFocusedInput(focusedInput as FocusedInput)
                        }
                    />
                )}

                {periodRange === 'custom' && (
                    <DateRangePicker
                        onDatesChange={({ startDate, endDate }) =>
                            handleRangeDateChange(startDate, endDate)
                        }
                        startDate={startDate}
                        endDate={endDate}
                        startDateId="startDateId"
                        endDateId="endDateId"
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) =>
                            setFocusedInput(focusedInput as FocusedInput)
                        }
                    />
                )}
            </div>
        </div>
    );
}
