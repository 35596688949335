import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isValidNumber } from 'libphonenumber-js';
import { pick, assign, isEqual, isEmpty, isString } from 'lodash';
import {
    Modal,
    Button,
    Input,
    TextArea,
    Container,
    Row,
    Col,
    Text,
    LoadingOverlay,
} from 'spoton-lib';

import { ModalHeader } from '../Modal';
import SingleImageSelector from '../SingleImageSelector/SingleImageSelector.component';
import SelectCategory from 'features/account/components/Connect/SelectCategory.component';
import CustomInputMask from 'features/common/components/CustomInputMask/CustomInputMask.component';

import {
    hideUpdateBusinessInfoModal,
    updateBusinessInfo,
    selectBusinessInfoData,
    selectUpdateBusinessInfoModalState,
    selectBusinessInfoSavingStatus,
    selectBusinessInfoSavingErrorStatus,
} from 'features/dashboard/redux';
import SelectSubCategory from 'features/account/components/Connect/SelectSubCategory.component';

import styles from './UpdateBusinessInfoModal.module.scss';

const scrollOptions = {
    block: 'start',
    behavior: 'smooth',
};

const convertCategory = (category: any) => ({
    name: category.value,
    displayName: category.label,
});

export default function UpdateBusinessInfoModal(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [category, setCategory] = useState<any>();
    const [subCategories, setSubCategories] = useState<any>();
    const [initialCategory, setInitialCategory] = useState<any>();
    const [initialSubCategory, setInitialSubCategory] = useState<any>();

    const [coverImageFile, setCoverImageFile] = useState<any>();
    const [coverImage, setCoverImage] = useState<any>();

    const [logoImageFile, setLogoImageFile] = useState<any>();
    const [logoImage, setLogoImage] = useState<any>();
    const businessInfo = useSelector(selectBusinessInfoData);

    const isSavingInfo = useSelector(selectBusinessInfoSavingStatus);

    const isInfoSavingError = useSelector(selectBusinessInfoSavingErrorStatus);

    const fieldRefs = [
        'phoneNumbers.primaryPhone',
        'title',
        'categories.primaryCategory',
        'websiteUri',
        'profile.description',
    ].reduce((acc, field: string) => {
        acc[field] = useRef(null);
        return acc;
    }, {} as Record<string, any>);

    const initialValues: { [index: string]: any } = useMemo(() => {
        return businessInfo && businessInfo.profile === null
            ? {
                  ...pick(businessInfo, [
                      'title',
                      'phoneNumbers.primaryPhone',
                      'phoneNumbers.additionalPhones',
                      'websiteUri',
                  ]),
                  profile: {
                      description: '',
                  },
              }
            : pick(businessInfo, [
                  'title',
                  'phoneNumbers.primaryPhone',
                  'phoneNumbers.additionalPhones',
                  'websiteUri',
                  'profile',
              ]);
    }, [businessInfo]);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm();

    const isModalOpen = useSelector(selectUpdateBusinessInfoModalState);

    const closeModal = useCallback(() => {
        setCategory(undefined);
        setSubCategories(undefined);
        setCoverImageFile(undefined);
        setCoverImage(undefined);
        setLogoImageFile(undefined);
        setLogoImage(undefined);
        reset();
        dispatch(hideUpdateBusinessInfoModal());
    }, [dispatch]);

    const onSubmit = useCallback(
        (data: any) => {
            const dataToSave: any = {};
            Object.keys(data).map((fieldName) => {
                if (!isEqual(data[fieldName], initialValues[fieldName])) {
                    if (fieldName === 'phoneNumbers.primaryPhone') {
                        assign(dataToSave, {
                            [fieldName]: `+1${data[fieldName].replace(/\D/g, '')}`,
                        });
                        dataToSave.phoneNumbers.additionalPhones =
                            data.phoneNumbers.additionalPhones;
                    } else {
                        assign(dataToSave, { [fieldName]: data[fieldName] });
                    }
                }
            });

            if (!category) {
                fieldRefs['categories.primaryCategory']?.current
                    ?.getRef()
                    .current.scrollIntoView(scrollOptions);
            } else if (!isEqual(category, initialCategory)) {
                assign(dataToSave, {
                    categories: {
                        additionalCategories:
                            businessInfo?.categories?.additionalCategories || null,
                        primaryCategory: convertCategory(category),
                    },
                });
            }

            if (!isEqual(subCategories, initialSubCategory)) {
                assign(dataToSave, {
                    categories: {
                        primaryCategory:
                            dataToSave?.categories?.primaryCategory ||
                            businessInfo?.categories?.primaryCategory,
                        additionalCategories: subCategories.map((item: any) =>
                            convertCategory(item),
                        ),
                    },
                });
            }

            if (!isEmpty(dataToSave) || coverImageFile || logoImageFile) {
                dispatch(
                    updateBusinessInfo({
                        formInfo: isEmpty(dataToSave) ? undefined : dataToSave,
                        coverImg: coverImageFile,
                        profileImg: logoImageFile,
                    }),
                );
            } else {
                closeModal();
            }
        },
        [coverImageFile, logoImageFile, category, subCategories],
    );

    useEffect(() => {
        if (isModalOpen && !isInfoSavingError && !isSavingInfo) {
            closeModal();
        }
    }, [isInfoSavingError, isSavingInfo]);

    useEffect(() => {
        if (isModalOpen && initialValues) {
            reset(initialValues);
            const { categories, coverImg, profileImg } = businessInfo;

            if (categories.primaryCategory) {
                const categoryObj = {
                    value: categories.primaryCategory.name,
                    label: categories.primaryCategory.displayName,
                };
                setInitialCategory(categoryObj);
                setCategory(categoryObj);
            }

            if (
                categories.additionalCategories &&
                categories.additionalCategories.length
            ) {
                const subCategories = categories.additionalCategories.map(
                    ({ name, displayName }) => ({
                        value: name,
                        label: displayName,
                    }),
                );
                setInitialSubCategory(subCategories);
                setSubCategories(subCategories);
            }

            if (coverImg)
                setCoverImage({
                    imgUrl: coverImg,
                    imgAlt: t('profile.businessInfo.coverImage'),
                });
            if (profileImg)
                setLogoImage({
                    imgUrl: profileImg,
                    imgAlt: t('profile.businessInfo.logoImage'),
                });
        }
    }, [initialValues, isModalOpen]);

    const addImageHandler = useCallback(
        (actionType) => (files: FileList) => {
            const isCover = actionType === 'cover';
            Array.from(files).forEach((file) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    isCover ? setCoverImageFile(file) : setLogoImageFile(file);
                    if (e.target?.result) {
                        const url = e.target.result.toString();
                        if (url) {
                            const image = {
                                imgUrl: url,
                                imgAlt: file.name,
                            };
                            isCover ? setCoverImage(image) : setLogoImage(image);
                        }
                    }
                };
            });
        },
        [],
    );

    const addressString = useMemo(() => {
        let str = '';
        if (businessInfo.storefrontAddress?.locality.length)
            str += `${businessInfo.storefrontAddress?.locality}, `;
        if (businessInfo.storefrontAddress?.addressLines[0].length)
            str += `${businessInfo.storefrontAddress.addressLines[0]}, `;
        if (businessInfo.storefrontAddress?.postalCode.length)
            str += `${businessInfo.storefrontAddress.postalCode}`;
        return str;
    }, [businessInfo]);

    const onInvalidForm = useCallback(() => {
        const fieldFirstError = Object.keys(errors)[0];
        if (!fieldRefs[fieldFirstError]) {
            return;
        }

        if (fieldRefs[fieldFirstError]?.current.scrollIntoView) {
            fieldRefs[fieldFirstError]?.current?.scrollIntoView(scrollOptions);
        } else {
            fieldRefs[fieldFirstError]?.current?.inputElement.current.scrollIntoView(
                scrollOptions,
            );
        }
    }, [errors]);

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            className={styles.UpdateBusinessInfoModal}
            overlayClassName={styles.Overlay}
        >
            <ModalHeader
                title="profile.businessInfo.editBusinessInfo"
                closeModal={closeModal}
                className={styles.ModalHeader}
            />
            <div className={styles.FormViewport}>
                {isSavingInfo && (
                    <LoadingOverlay
                        className={styles.Spinner}
                        greyAnimation
                        size="md"
                    />
                )}
                <form
                    onSubmit={handleSubmit(onSubmit, onInvalidForm)}
                    className={styles.UpdateBusinessInfoModalForm}
                    noValidate
                >
                    <div className={styles.UpdateBusinessInfoModalContainer}>
                        <Container>
                            <Row>
                                <Col lg={12} xl={4} className={styles.Column}>
                                    <SingleImageSelector
                                        image={logoImage}
                                        onAddImage={addImageHandler('logo')}
                                        onRemoveImage={() => setLogoImage(undefined)}
                                        text={
                                            logoImage
                                                ? t(
                                                      'profile.businessInfo.changeLogo',
                                                  )
                                                : t('profile.businessInfo.addLogo')
                                        }
                                    />
                                </Col>
                                <Col lg={12} xl={8} className={styles.Column}>
                                    <SingleImageSelector
                                        image={coverImage}
                                        onAddImage={addImageHandler('cover')}
                                        onRemoveImage={() =>
                                            setCoverImage(undefined)
                                        }
                                        text={
                                            coverImage
                                                ? t(
                                                      'profile.businessInfo.changeCover',
                                                  )
                                                : t('profile.businessInfo.addCover')
                                        }
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} className={styles.Column}>
                                    <Controller
                                        control={control}
                                        name="title"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: t(
                                                    'profile.businessInfo.isRequired',
                                                ),
                                            },
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Input
                                                onChange={onChange}
                                                value={value}
                                                className={styles.ZeroSpaces}
                                                secondaryCondition={
                                                    errors?.title?.message
                                                }
                                                isValid={!errors?.title?.message}
                                                label={`${t(
                                                    'profile.businessInfo.businessName',
                                                )} *`}
                                                clearable={false}
                                                ref={fieldRefs['title']}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <SelectCategory
                                        categoryState={[category, setCategory]}
                                        isDisabled={false}
                                        inputClassName={styles.ZeroSpaces}
                                        clearable={false}
                                        isRequired={true}
                                        isValid={!!category}
                                        label={`${t(
                                            'profile.businessInfo.businessCategory',
                                        )} *`}
                                        ref={fieldRefs['categories.primaryCategory']}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className={styles.Column}>
                                    <SelectSubCategory
                                        categoryState={[
                                            subCategories,
                                            setSubCategories,
                                        ]}
                                        label={t(
                                            'profile.businessInfo.businessSubCategory',
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} xl={6} className={styles.Column}>
                                    <Controller
                                        control={control}
                                        name="phoneNumbers.primaryPhone"
                                        rules={{
                                            validate: (value) =>
                                                isValidNumber(value, 'US') ||
                                                (t(
                                                    'profile.businessInfo.phoneValidationMessage',
                                                ) as string),
                                            required: {
                                                value: true,
                                                message: t(
                                                    'profile.businessInfo.isRequired',
                                                ),
                                            },
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <CustomInputMask
                                                value={value}
                                                onChange={onChange}
                                                mask="(999) 999-9999"
                                                label={`${t(
                                                    `profile.businessInfo.businessPhoneNumber`,
                                                )} *`}
                                                error={
                                                    errors?.phoneNumbers
                                                        ?.primaryPhone?.message
                                                }
                                                ref={
                                                    fieldRefs[
                                                        'phoneNumbers.primaryPhone'
                                                    ]
                                                }
                                            />
                                        )}
                                    />
                                </Col>
                                <Col lg={12} xl={6} className={styles.Column}>
                                    <Controller
                                        control={control}
                                        name="websiteUri"
                                        rules={{
                                            pattern: {
                                                value: /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi,
                                                message: t(
                                                    'profile.businessInfo.urlValidationMessage',
                                                ),
                                            },
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Input
                                                onChange={onChange}
                                                value={value}
                                                className={styles.ZeroSpaces}
                                                label={t(
                                                    `profile.businessInfo.businessWebsite`,
                                                )}
                                                secondaryCondition={
                                                    errors?.websiteUri?.message
                                                }
                                                isValid={
                                                    !errors?.websiteUri?.message
                                                }
                                                clearable={false}
                                                ref={fieldRefs['websiteUri']}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className={styles.Column}>
                                    <Controller
                                        control={control}
                                        name="profile.description"
                                        rules={{
                                            maxLength: 750,
                                        }}
                                        render={({ field: { onChange, value } }) => {
                                            const descriptionLength = isString(value)
                                                ? value.length
                                                : 0;
                                            return (
                                                <TextArea
                                                    onChange={onChange}
                                                    value={value}
                                                    maxLength={750}
                                                    primaryCondition={`${descriptionLength} of 750`}
                                                    placeholder={t(
                                                        `profile.businessInfo.textareaPlaceholder`,
                                                    )}
                                                    label={t(
                                                        `profile.businessInfo.businessDescription`,
                                                    )}
                                                    ref={
                                                        fieldRefs[
                                                            'profile.description'
                                                        ]
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} xl={6} className={styles.Column}>
                                    <Text type="label">
                                        {t('profile.businessInfo.businessAddress')}
                                    </Text>
                                    <Text
                                        type="p"
                                        className={classNames(
                                            styles.ViewOnlyBoxText,
                                            styles.ViewOnlyBoxAddress,
                                        )}
                                    >
                                        {addressString}
                                    </Text>
                                </Col>
                                {businessInfo.locationState &&
                                    businessInfo.locationState.isVerified && (
                                        <Col
                                            lg={12}
                                            xl={6}
                                            className={styles.Column}
                                        >
                                            <Text type="label">
                                                {t(
                                                    'profile.businessInfo.businessStatus',
                                                )}
                                            </Text>
                                            <Text
                                                type="p"
                                                className={styles.ViewOnlyBoxText}
                                            >
                                                <span
                                                    className={
                                                        styles.ViewOnlyBoxVerify
                                                    }
                                                >
                                                    {t(
                                                        'profile.businessInfo.verified',
                                                    )}
                                                </span>
                                            </Text>
                                        </Col>
                                    )}
                            </Row>
                        </Container>
                    </div>
                    <div className={styles.UpdateBusinessInfoModalFooter}>
                        <Button
                            className={styles.DiscardButton}
                            onClick={closeModal}
                            variant="secondary"
                        >
                            {t(`discard`)}
                        </Button>
                        <Button
                            className={styles.SaveButton}
                            type="submit"
                            onClick={() => null}
                            variant="primary"
                        >
                            {t(`modals.save`)}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}
