// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Badge_Badge__34NAs{display:block}\n", "",{"version":3,"sources":["webpack://src/features/dashboard/home/GoogleAdvisor/Components/Badge.module.scss"],"names":[],"mappings":"AAAA,oBACI,aAAc","sourcesContent":[".Badge {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Badge": "Badge_Badge__34NAs"
};
export default ___CSS_LOADER_EXPORT___;
