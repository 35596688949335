import { createReducer, Reducer } from '@reduxjs/toolkit';

import * as userInfoActions from './auth.actions';
import { IAuthTypes } from './auth.types';

export const initialState: IAuthTypes = {
    scope: '',
    clientId: '',
    isLoaded: false,
    isError: false,
};

export const authConfigReducer: Reducer<IAuthTypes> = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(
                userInfoActions.getAuthConfig.fulfilled,
                (state, action: any) => {
                    state.scope = action.payload.config.scope.join(',');
                    state.clientId = action.payload.config.clientId;
                    state.isLoaded = true;
                    state.isError = false;
                },
            )
            .addCase(userInfoActions.getAuthConfig.rejected, (state) => {
                state.isLoaded = true;
                state.isError = true;
            })
            .addDefaultCase((state) => {
                return state;
            });
    },
);

export default authConfigReducer;
