import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Icon, Row, Col, LoadingIndicator, Button, Tooltip } from 'spoton-lib';
import { useTranslation } from 'react-i18next';

import { Widget } from 'features/common/components';
import {
    selectBusinessInfoData,
    selectBusinessInfoErrorStatus,
    selectBusinessInfoLoadingStatus,
    showUpdateBusinessInfoModal,
} from 'features/dashboard/redux';
import { InfoTooltip } from 'features/common/components';

import serverErrorImage from 'features/dashboard/assets/serverError.png';

import styles from './BusinessInfo.module.scss';

export default function BusinessInfo(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const businessInfo = useSelector(selectBusinessInfoData);
    const isLoading = useSelector(selectBusinessInfoLoadingStatus);
    const isError = useSelector(selectBusinessInfoErrorStatus);

    const {
        title,
        phoneNumbers: { primaryPhone = '' },
        websiteUri,
        categories: {
            primaryCategory = {
                name: '',
                displayName: '',
            },
            additionalCategories = [],
        },
        profile,
        profileImg,
        coverImg,
        storefrontAddress,
        locationState: { isVerified = false },
    } = businessInfo;

    const addressString = useMemo(() => {
        if (!storefrontAddress) {
            return '';
        } else {
            const { locality, addressLines, postalCode } = storefrontAddress;
            return `${locality}, ${addressLines[0]}, ${postalCode}`;
        }
    }, [businessInfo]);

    const showEditModal = useCallback(() => {
        dispatch(showUpdateBusinessInfoModal());
    }, [dispatch]);

    return (
        <Widget className={styles.BusinessInfo}>
            {isLoading && !isError && (
                <div className={styles.Loader}>
                    <LoadingIndicator />
                </div>
            )}
            {!isLoading && !isError && (
                <>
                    {coverImg ? (
                        <img
                            className={styles.BusinessImage}
                            src={coverImg}
                            alt={t('profile.businessInfo.coverImage')}
                        />
                    ) : (
                        <button
                            className={styles.BusinessImageIsEmpty}
                            onClick={showEditModal}
                            type="button"
                        >
                            <Icon name="ImageUploadIcon" alt="" size={23} />
                            <div className={styles.AddCoverText}>
                                {t('profile.businessInfo.addCover')}
                            </div>
                        </button>
                    )}

                    <div className={styles.BusinessInfoBox}>
                        <div className={styles.BusinessInfoHeader}>
                            <div className={styles.BusinessInfoLogoWrapper}>
                                {profileImg ? (
                                    <img
                                        className={styles.BusinessInfoLogo}
                                        src={profileImg}
                                        alt={t('profile.businessInfo.logoImage')}
                                    />
                                ) : (
                                    <div
                                        className={styles.BusinessInfoLogoIsEmpty}
                                        onClick={showEditModal}
                                        aria-hidden="true"
                                    >
                                        <Icon
                                            name="ImageUploadIcon"
                                            alt=""
                                            size={23}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.BusinessInfoName}>
                                <div className={styles.BusinessInfoNameLabel}>
                                    <div>
                                        {t('profile.businessInfo.businessName')}
                                    </div>
                                    {isVerified && (
                                        <Tooltip
                                            tooltipContent={t(
                                                'profile.businessInfo.verified',
                                            )}
                                        >
                                            <Icon
                                                className={styles.CheckIcon}
                                                name="CheckInIcon"
                                                alt=""
                                                size={18}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                                <Text type="sub1">{title}</Text>
                            </div>
                            <button
                                onClick={showEditModal}
                                className={styles.EditButton}
                            >
                                <Icon name="PencilOutline" alt="" size={20} />
                            </button>
                        </div>
                        <div>
                            <Row>
                                <Col lg={4} xs={6} className={styles.Col}>
                                    <div className={styles.InfoBoxLabel}>
                                        {t('profile.businessInfo.businessAddress')}
                                    </div>
                                    <div className={styles.InfoBoxContent}>
                                        {addressString}
                                    </div>
                                </Col>
                                <Col lg={4} xs={6} className={styles.Col}>
                                    <div className={styles.InfoBoxLabel}>
                                        {t('profile.businessInfo.businessCategory')}
                                        <InfoTooltip
                                            tooltipText={t(
                                                `profile.businessInfo.categoryTooltip`,
                                            )}
                                        />
                                    </div>
                                    <div className={styles.InfoBoxContent}>
                                        {primaryCategory?.displayName ? (
                                            primaryCategory.displayName
                                        ) : (
                                            <Button
                                                className={styles.AddButton}
                                                variant="tertiary"
                                                onClick={showEditModal}
                                            >
                                                <>
                                                    <Icon
                                                        name="AddIcon"
                                                        alt=""
                                                        size={18}
                                                    />
                                                    {t(
                                                        'profile.businessInfo.addCategory',
                                                    )}
                                                </>
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={4} xs={6} className={styles.Col}>
                                    <div className={styles.InfoBoxLabel}>
                                        {t(
                                            'profile.businessInfo.businessSubCategory',
                                        )}
                                    </div>
                                    <div className={styles.InfoBoxContent}>
                                        {additionalCategories ? (
                                            additionalCategories
                                                .map(
                                                    ({ displayName }) => displayName,
                                                )
                                                .join(', ')
                                        ) : (
                                            <Button
                                                className={styles.AddButton}
                                                variant="tertiary"
                                                onClick={showEditModal}
                                            >
                                                <>
                                                    <Icon
                                                        name="AddIcon"
                                                        alt=""
                                                        size={18}
                                                    />
                                                    {t(
                                                        'profile.businessInfo.addSubCategory',
                                                    )}
                                                </>
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={4} xs={6} className={styles.Col}>
                                    <div className={styles.InfoBoxLabel}>
                                        {t('profile.businessInfo.phoneNumber')}
                                    </div>
                                    <div className={styles.InfoBoxContent}>
                                        {primaryPhone ? (
                                            primaryPhone
                                        ) : (
                                            <Button
                                                className={styles.AddButton}
                                                variant="tertiary"
                                                onClick={showEditModal}
                                            >
                                                <>
                                                    <Icon
                                                        name="AddIcon"
                                                        alt=""
                                                        size={18}
                                                    />
                                                    {t(
                                                        'profile.businessInfo.addPhoneNumber',
                                                    )}
                                                </>
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={8} xs={12} className={styles.Col}>
                                    <div className={styles.InfoBoxLabel}>
                                        {t('profile.businessInfo.businessWebsite')}
                                    </div>
                                    <div className={styles.InfoBoxContent}>
                                        {websiteUri ? (
                                            <div className={styles.InfoBoxLink}>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={websiteUri}
                                                    className={styles.Link}
                                                    title={websiteUri}
                                                >
                                                    {websiteUri}
                                                </a>
                                            </div>
                                        ) : (
                                            <Button
                                                className={styles.AddButton}
                                                variant="tertiary"
                                                onClick={showEditModal}
                                            >
                                                <>
                                                    <Icon
                                                        name="AddIcon"
                                                        alt=""
                                                        size={18}
                                                    />
                                                    {t(
                                                        'profile.businessInfo.addWebsite',
                                                    )}
                                                </>
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className={styles.InfoBoxLabel}>
                                        {t('profile.businessInfo.description')}
                                        <InfoTooltip
                                            tooltipText={t(
                                                `profile.businessInfo.descriptionTooltip`,
                                            )}
                                        />
                                    </div>
                                    <div className={styles.InfoBoxDescription}>
                                        {profile && profile?.description ? (
                                            profile.description
                                        ) : (
                                            <Button
                                                className={styles.AddButton}
                                                variant="tertiary"
                                                onClick={showEditModal}
                                            >
                                                <>
                                                    <Icon
                                                        name="AddIcon"
                                                        alt=""
                                                        size={18}
                                                    />
                                                    {t(
                                                        'profile.businessInfo.addDescription',
                                                    )}
                                                </>
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
            )}
            {isError && (
                <div className={styles.BusinessInfoContentCenter}>
                    <div className={styles.BusinessInfoErrorWrapper}>
                        <img src={serverErrorImage} alt="good work icon" />
                        <p className={styles.BusinessInfoErrorText}>
                            {t('errors.server')}
                        </p>
                    </div>
                </div>
            )}
        </Widget>
    );
}
