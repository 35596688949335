import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { showDetailedError, showSuccess } from 'utils/messages';
import i18n from 'i18n.config';
import { QuestionsApi } from 'features/dashboard/api/index';
import {
    ICreateAnswer,
    IDeleteQuestion,
    IQuestionsPayload,
} from 'features/dashboard/types/QuestionsSectionTypes';

export const fetchQuestions: any = createAsyncThunk(
    'dashboard/fetchQuestions',
    async (payload: IQuestionsPayload, thunkAPI) => {
        try {
            const response = await QuestionsApi.getQuestions(payload);
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.dashboard.questionsFetch',
                error: data,
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);

export const createAnswer: any = createAsyncThunk(
    'dashboard/createAnswer',
    async (payload: ICreateAnswer, thunkAPI) => {
        try {
            const response = await QuestionsApi.createAnswer(payload);
            showSuccess({
                title: i18n.t('success'),
                message: i18n.t('dashboard.questions.answerSaved'),
            });

            setTimeout(() => {
                thunkAPI.dispatch(
                    fetchQuestions({
                        answersPerQuestion: 5,
                        orderBy: payload.sortOrder?.value,
                    }),
                );
            }, 15000);

            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.dashboard.questionsAnswer',
                error: data,
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);

export const deleteAnswer: any = createAsyncThunk(
    'dashboard/deleteQuestion',
    async (payload: IDeleteQuestion, thunkAPI) => {
        try {
            await QuestionsApi.deleteAnswer(payload);
            showSuccess({
                title: i18n.t('success'),
                message: i18n.t('dashboard.questions.answerDeleted'),
            });
            setTimeout(() => {
                thunkAPI.dispatch(
                    fetchQuestions({
                        answersPerQuestion: 5,
                        orderBy: payload?.order?.value,
                    }),
                );
            }, 1000);
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.dashboard.questionsDelete',
                error: data,
            });
            return thunkAPI.rejectWithValue({ data, status });
        }
    },
);
