// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileTimePicker_MobileSelect__Oxcjr{display:inline-block;box-sizing:border-box;width:120px;height:44px;margin:0;padding:0.5em 0.5em 0.5em 1em;font:inherit;font-size:14px;line-height:1.5em;background-color:white;background-image:linear-gradient(45deg, transparent 50%, gray 50%),linear-gradient(135deg, gray 50%, transparent 50%),radial-gradient(#ddd 70%, transparent 72%);background-repeat:no-repeat;background-position:calc(100% - 17px) calc(1.3em + 2px),calc(100% - 12px) calc(1.3em + 2px),calc(100% - 0.5em) 0.8em;background-size:4px 4px, 5px 5px, 1.5em 1.5em;border:thin solid grey;border-radius:4px;-webkit-appearance:none;-moz-appearance:none}\n", "",{"version":3,"sources":["webpack://src/features/common/components/MobileTimePicker/MobileTimePicker.module.scss"],"names":[],"mappings":"AAAA,sCACI,oBAAqB,CAGrB,qBAAsB,CACtB,WAAY,CACZ,WAAY,CACZ,QAAS,CACT,6BAA8B,CAC9B,YAAa,CACb,cAAe,CACf,iBAAkB,CAClB,sBAAuB,CACvB,gKAE8C,CAC9C,2BAA4B,CAC5B,oHACiE,CACjE,6CAA8C,CAC9C,sBAAuB,CACvB,iBAAkB,CAClB,uBAAwB,CACxB,oBAAqB","sourcesContent":[".MobileSelect {\n    display: inline-block;\n    -webkit-box-sizing: border-box;\n    -moz-box-sizing: border-box;\n    box-sizing: border-box;\n    width: 120px;\n    height: 44px;\n    margin: 0;\n    padding: 0.5em 0.5em 0.5em 1em;\n    font: inherit;\n    font-size: 14px;\n    line-height: 1.5em;\n    background-color: white;\n    background-image: linear-gradient(45deg, transparent 50%, gray 50%),\n        linear-gradient(135deg, gray 50%, transparent 50%),\n        radial-gradient(#ddd 70%, transparent 72%);\n    background-repeat: no-repeat;\n    background-position: calc(100% - 17px) calc(1.3em + 2px),\n        calc(100% - 12px) calc(1.3em + 2px), calc(100% - 0.5em) 0.8em;\n    background-size: 4px 4px, 5px 5px, 1.5em 1.5em;\n    border: thin solid grey;\n    border-radius: 4px;\n    -webkit-appearance: none;\n    -moz-appearance: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MobileSelect": "MobileTimePicker_MobileSelect__Oxcjr"
};
export default ___CSS_LOADER_EXPORT___;
