import { RootState } from 'reduxConfig';

export const selectPreview = (state: RootState): any => state.preview;

export const selectPreviewInfo = (state: RootState): any =>
    state.preview.businessPreview;

export const selectLoadingStatus = (state: RootState): boolean =>
    state.preview.isLoaded;

export const selectAccountCreatingStatus = (state: RootState): boolean =>
    state.preview.isAccountAutoCreated;
