import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Button, Icon, IconButton, LoadingIndicator, Tabs, Text } from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { showDetailedError } from 'utils/messages';
import { IMediaImage, IMediaResponse } from 'features/dashboard/api/Media.types';
import { getImages } from 'features/dashboard/api/Media.data';
import { MediaModalContent } from '../MediaModalContent';
import { IMediaContentTypes } from 'features/dashboard/types/MediaContentTypes';
import { AxiosResponse } from 'features/common/utils/axios.utils';
import { InfoTooltip } from 'features/common';
import { useBreakpoint } from 'utils/customHooks';
import MediaSelector from 'features/common/components/MediaSelectorMobile';
import { homeUploadMedia } from 'features/dashboard/redux';

import ServerErrorImage from '../../../assets/serverError.png';
import NoMediaImage from '../../../assets/noPosts.png';
import styles from './MediaContent.module.scss';
import { MediaFullScreen } from '../MediaFullScreen';

export default forwardRef(function MediaContent(
    { setImages, images, isModal, closeModal, uploadMedia }: IMediaContentTypes,
    ref,
): JSX.Element {
    const isMobile = useBreakpoint() !== 'desktop';
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [totalMediaCount, setTotalMediaCount] = useState<number>(0);
    const [fullScreenImage, setFullScreenImage] = useState<any>({});
    const [nextPageToken, setNextPageToken] = useState<string | undefined>('');
    const [isOpenFullScreenImage, setIsOpenFullScreenImage] =
        useState<boolean>(false);
    const { t } = useTranslation();
    const [activeImageSourceTab, setActiveImageSourceTab] = useState<string>('ALL');
    const dispatch = useDispatch();

    const updateMediaList = () => {
        setIsLoading(true);
        getImages(activeImageSourceTab, isMobile ? 2 : 3, '')
            .then(({ data }: AxiosResponse<IMediaResponse>) => {
                setIsError(false);
                setImages(data.mediaItems);
                setNextPageToken(data.nextPageToken);
                setTotalMediaCount(data.totalMediaItemCount);
            })
            .catch(() => {
                setIsError(true);
                showDetailedError({
                    template: 'errors.dashboard.mediaFetch',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useImperativeHandle(ref, () => ({
        refreshOwnMedia: () => {
            setActiveImageSourceTab('OWNER');
            updateMediaList();
        },
    }));

    useEffect(() => {
        if (isModal) {
            return;
        }
        updateMediaList();
    }, [activeImageSourceTab]);

    const selectImageSource = (source: string): void => {
        setActiveImageSourceTab(source);
    };

    const openFullScreenImage =
        (imageData: IMediaImage, imageIndex: number) => () => {
            setFullScreenImage({ ...imageData, imageIndex });
            setIsOpenFullScreenImage(true);
        };

    const closeFullScreenImage = () => {
        setFullScreenImage(null);
        setIsOpenFullScreenImage(false);
    };

    const onUploadMedia = (files: File[]) => {
        Promise.all(
            files?.map((item: File) => dispatch(homeUploadMedia(item))),
        ).then(() => {
            updateMediaList();
            setActiveImageSourceTab('OWNER');
        });
    };

    const desktopHeader = () => (
        <div
            className={isModal ? styles.PopupHeadingWrapper : styles.HeadingWrapper}
        >
            <div className={styles.HeadingWrapperChild}>
                <div className={styles.HeadingTextWrapper}>
                    {t('dashboard.mediaManagement')}
                    <InfoTooltip
                        tooltipText={t(`dashboard.tooltips.mediaWidgetTitle`)}
                        className={styles.TooltipContainer}
                        tooltipClassName={styles.TooltipContent}
                        iconClassName={styles.ToolTipIcon}
                    />
                </div>
                <Button
                    className={styles.UploadMediaButton}
                    onClick={uploadMedia}
                    variant="secondary"
                >
                    <>
                        <Icon name="AddIcon" alt="" size={24} />
                        {t('dashboard.uploadMedia')}
                    </>
                </Button>
            </div>

            {isModal && (
                <IconButton
                    name="CloseIcon"
                    alt={t('close')}
                    buttonSize="regular"
                    size={36}
                    variant="secondary"
                    disableBorder
                    className={styles.CloseIcon}
                    onClick={closeModal}
                />
            )}
        </div>
    );

    const mobileHeader = () => (
        <div
            className={isModal ? styles.PopupHeadingWrapper : styles.HeadingWrapper}
        >
            <div className={styles.ModalControlsWrapper}>
                {isMobile && isModal && (
                    <IconButton
                        name="ArrowLeftIcon"
                        alt={t('close')}
                        buttonSize="large"
                        size={60}
                        variant="secondary"
                        disableBorder
                        className={styles.BackIcon}
                        onClick={closeModal}
                    />
                )}
                <h3 className={isMobile ? styles.MobileHeading : styles.Heading}>
                    {t('dashboard.mediaManagement')}
                </h3>
            </div>
            {isModal && (
                <MediaSelector onClick={onUploadMedia}>
                    <IconButton
                        name="ImageUploadIcon"
                        alt={t('upload')}
                        buttonSize="large"
                        size={60}
                        color="#1769ff"
                        variant="secondary"
                        disableBorder
                        className={styles.BackIcon}
                        onClick={isMobile ? () => null : uploadMedia}
                    />
                </MediaSelector>
            )}
        </div>
    );

    return (
        <>
            {!isMobile && desktopHeader()}
            {isMobile && mobileHeader()}
            {(!isMobile || isModal) && (
                <Tabs
                    active={activeImageSourceTab}
                    className={styles.MediaSelectionMenu}
                    onSelect={selectImageSource}
                >
                    <Tabs.Tab name="ALL">{t('all')}</Tabs.Tab>
                    <Tabs.Tab name="OWNER">{t('dashboard.owner')}</Tabs.Tab>
                    <Tabs.Tab name="CUSTOMER">{t('dashboard.customers')}</Tabs.Tab>
                    <Tabs.Tab name="VIDEO">{t('dashboard.videos')}</Tabs.Tab>
                </Tabs>
            )}
            <div
                className={
                    isModal ? styles.PopupImagesWrapper : styles.ImagesWrapper
                }
            >
                {isLoading && (
                    <div className={styles.LoadingIndicatorWrapper}>
                        <LoadingIndicator />
                    </div>
                )}
                {!isLoading && !isModal && !images.length && (
                    <div className={styles.NoItems}>
                        <img
                            src={isError ? ServerErrorImage : NoMediaImage}
                            alt="no media"
                        />
                        {isError && (
                            <p className={styles.NoItemsText}>
                                {t('errors.server')}
                            </p>
                        )}
                        {(activeImageSourceTab === 'ALL' ||
                            activeImageSourceTab === 'CUSTOMER') &&
                            !isError && (
                                <p className={styles.NoItemsText}>
                                    {t('dashboard.noMediaAll')}
                                </p>
                            )}
                        {activeImageSourceTab === 'OWNER' && !isError && (
                            <>
                                <p className={styles.NoItemsText}>
                                    {t('dashboard.noMediaOwner')}
                                </p>
                            </>
                        )}
                        {activeImageSourceTab === 'VIDEO' && !isError && (
                            <>
                                <p className={styles.NoItemsText}>
                                    {t('dashboard.noMediaVideo')}
                                </p>
                            </>
                        )}
                    </div>
                )}
                {!isLoading &&
                    !isModal &&
                    !!images.length &&
                    images.map((image: IMediaImage, index: number) => (
                        <div
                            key={image.name}
                            aria-hidden="true"
                            className={styles.ImageWrapper}
                            onClick={openFullScreenImage(image, index)}
                        >
                            <span
                                style={{
                                    background:
                                        'linear-gradient(180deg, rgba(0, 0, 0, 0) 55.21%, rgba(0, 0, 0, 0.6) 100%),' +
                                        ` url("${image.thumbnailUrl}")`,
                                    display: 'inline-block',
                                    width: '100%',
                                    height: '100%',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                }}
                            />
                            <div className={styles.ImageDescriptionWrapper}>
                                <Text className={styles.ImageDescription}>
                                    {image.hrCreateTime}
                                </Text>
                            </div>
                        </div>
                    ))}
                {!isLoading && isModal && (
                    <MediaModalContent
                        isMobile={isMobile}
                        activeImageSourceTab={activeImageSourceTab}
                    />
                )}
            </div>
            {isOpenFullScreenImage && (
                <MediaFullScreen
                    imageData={fullScreenImage}
                    imagesData={images}
                    totalMediaCount={totalMediaCount}
                    pageToken={nextPageToken}
                    currentFilter={activeImageSourceTab}
                    closeModal={closeFullScreenImage}
                    updateMediaList={updateMediaList}
                />
            )}
        </>
    );
});
