import React, { SyntheticEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { QuickAction } from '../utils';

import QuickActionItem from '../QuickActionItem/QuickActionItem.component';
import QuickActionItemUpload from '../QuickActionItem/QuickActionItemUpload.component';

import styles from './QuickActionsModal.module.scss';

interface IPropsModal {
    isOpen: boolean;
    onAction: (value: string) => (data?: any) => void;
    closeModal(): void;
}

export default function QuickActionsModal({
    isOpen,
    onAction,
    closeModal,
}: IPropsModal): JSX.Element {
    const { t } = useTranslation();
    const refWrapper = useRef<HTMLDivElement>(null);

    function onHandleWrapper(e: SyntheticEvent) {
        if (e.target === refWrapper.current) {
            closeModal();
        }
    }

    return (
        <>
            {isOpen && (
                <div
                    aria-hidden
                    className={styles.ModalBackdrop}
                    onClick={onHandleWrapper}
                    ref={refWrapper}
                >
                    <div className={styles.Modal}>
                        <div className={styles.Body}>
                            <QuickActionItem
                                title={t('dashboard.updateHours')}
                                onClick={onAction(QuickAction.UPLOAD_HOURS)}
                            />
                            <QuickActionItemUpload
                                title={t('dashboard.mediaUpload')}
                                onClick={onAction(QuickAction.UPLOAD_MEDIA)}
                            />
                            <QuickActionItemUpload
                                title={t('dashboard.menuPhotoUpload')}
                                onClick={onAction(QuickAction.UPLOAD_MENU_PHOTO)}
                            />
                            <QuickActionItem
                                title={t('dashboard.createPost')}
                                onClick={onAction(QuickAction.CREATE_POST)}
                            />
                            <QuickActionItem
                                title={t('dashboard.updateProfile')}
                                onClick={onAction(QuickAction.UPDATE_PROFILE)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
