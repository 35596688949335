import moment from 'moment';

import i18n from 'i18n.config';
import { FULL_DAY, MIDNIGHT_24 } from 'utils/periods.utils';

export type TimePickerItem = {
    value: string;
    label: string;
};

const normalizeHour = (hour: number) =>
    i18n &&
    hour.toLocaleString(i18n.language, {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });

const minutes = ['00', '30'];
const hours: number[] = Array.from(Array(24).keys());

function generateTime(hour: number, minute: string): TimePickerItem {
    const value = `${normalizeHour(hour)}:${minute}`;
    return {
        value,
        label: i18n && moment(value, 'HH:mm').locale(i18n.language).format('LT'),
    };
}

const hoursSource = hours.reduce((items: TimePickerItem[], hour: number) => {
    return [...items, ...minutes.map((minute) => generateTime(hour, minute))];
}, []);

export const opensPickerOptions = [
    {
        label: i18n && i18n.t('profile.businessInfo.fullDay'),
        value: FULL_DAY,
    },
    ...hoursSource,
];

export const closesPickerOptions = (() => {
    const result = [
        ...hoursSource,
        {
            label:
                i18n && moment('00:00', 'HH:mm').locale(i18n.language).format('LT'),
            value: MIDNIGHT_24,
        },
    ];
    result.splice(0, 1);

    return result;
})();

export const optionByValue: any = (value: string) =>
    opensPickerOptions.find((option) => option.value === value) ||
    closesPickerOptions.find((option) => option.value === value);

export const formatTimeTo12hour = (time: string): string =>
    optionByValue(time)?.label || '';
