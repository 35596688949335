import { createReducer, Reducer } from '@reduxjs/toolkit';

import * as previewActions from './preview.actions';
import { IPreviewTypes } from './preview.types';

export const initialState: IPreviewTypes = {
    connected: '',
    status: '',
    businessPreview: {},
    isLoaded: true,
    isError: false,
    isAccountAutoCreated: false,
};

export const previewReducer: Reducer<IPreviewTypes> = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(previewActions.getPreview.pending, (state) => {
                state.isLoaded = true;
            })
            .addCase(previewActions.getPreview.fulfilled, (state, action: any) => {
                state.connected = action.payload.connected;
                state.status = action.payload.status;
                state.businessPreview = action.payload.businessPreview;
                state.isLoaded = false;
                state.isError = false;
            })
            .addCase(previewActions.getPreview.rejected, (state) => {
                state.isLoaded = false;
                state.isError = true;
            })
            .addCase(previewActions.createAccount.pending, (state) => {
                state.isLoaded = true;
                state.isAccountAutoCreated = true;
            })
            .addCase(previewActions.createAccount.fulfilled, (state) => {
                state.isLoaded = false;
                state.isAccountAutoCreated = true;
            })
            .addCase(previewActions.createAccount.rejected, (state) => {
                state.isLoaded = false;
                state.isAccountAutoCreated = false;
            })
            .addDefaultCase((state) => {
                return state;
            });
    },
);

export default previewReducer;
