import React from 'react';
import { Text } from 'spoton-lib';

import styles from './ImageContainer.module.scss';

interface IProps {
    days?: string;
    image?: any;
    onClick?: () => void;
}

export function ImageContainer({ days, image, onClick }: IProps): JSX.Element {
    return (
        <div className={styles.ImageContainer} aria-hidden="true" onClick={onClick}>
            <img alt={image} src={image} className={styles.Image} />
            <Text className={styles.ImageTextDays}>{days}</Text>
        </div>
    );
}
