import React, { useState, BaseSyntheticEvent, useEffect } from 'react';
import { useNavigation } from 'react-navi';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Input, Button } from 'spoton-lib';

import { RootState } from 'reduxConfig';
import { showDetailedError } from 'utils/messages';
import { getPathnameWithQueryParams } from 'app/components/App/App.utils';

import { selectVerification } from 'features/account/redux/verification/verification.selectors';

import { VerificationApi } from 'features/account/api';

import styles from './VerificationCode.module.scss';
import { paths } from 'app/routes/app.routes';

interface IVerificationCodeProps {
    showOptions: () => void;
    showCodeError: () => void;
}

export default function VerificationCode({
    showOptions,
}: //showCodeError,
IVerificationCodeProps): JSX.Element {
    const { t } = useTranslation();
    const navigation = useNavigation();

    const verification = useSelector((state: RootState) =>
        selectVerification(state),
    );

    const [inputValue, setInputValue] = useState<string>('');
    const [isInputValid, setIsInputValid] = useState<boolean>(true);

    const collectInput = (event: BaseSyntheticEvent) => {
        setInputValue(event.target.value);
        setIsInputValid(true);
    };

    // TODO: refactor to redux actions
    const sendCode = () => {
        if (!inputValue) {
            setIsInputValid(false);
            return;
        } else {
            VerificationApi.postCompleteVerification(
                inputValue,
                verification.currentVerification.name,
            )
                .then(() => {
                    navigation.navigate(getPathnameWithQueryParams(paths.dashboard));
                })
                .catch((error) => {
                    showDetailedError({
                        message: t('errors.connect.sendVerificationCode'),
                        error: error.resonse?.data,
                    });
                });
        }
    };

    useEffect(() => {
        const abortController = new AbortController();
        if ('OTPCredential' in window) {
            const o = {
                otp: { transport: ['sms'] },
                signal: abortController.signal,
            } as CredentialRequestOptions;

            navigator.credentials.get(o).then((data) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const code = data?.code;
                if (code) {
                    setInputValue(code);
                }
            });
        }
        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <>
            <div className={styles.Header}>{t('connect.sendCodeHeader')}</div>
            <div className={styles.Help}>
                {t(
                    `connect.sendCodeHelp.${verification.currentVerification.method}`,
                )}
            </div>
            <Input
                className={styles.CodeInput}
                value={inputValue}
                onChange={collectInput}
                isValid={isInputValid}
                label={t('connect.sendCodeInput.label')}
                placeholder={t('connect.sendCodeInput.placeholder')}
                primaryCondition={t('connect.sendCodeInput.condition')}
                secondaryCondition={
                    !isInputValid ? t('connect.sendCodeInput.error') : ''
                }
                autoComplete="one-time-code"
                inputMode="numeric"
            />
            <div className={styles.ResendCode}>{t('connect.resendCode')}</div>
            <div className={styles.Footer}>
                <div className={styles.ButtonWrapper}>
                    <Button className={styles.Button} onClick={sendCode}>
                        {t('connect.sendCodeButton')}
                    </Button>
                </div>
                <div
                    aria-hidden="true"
                    onClick={showOptions}
                    className={styles.ChangeMethod}
                >
                    {t('connect.changeMethod')}
                </div>
            </div>
        </>
    );
}
