import { TVerificationMethod } from 'features/account/redux/verification/verification.types';

/* eslint-disable @typescript-eslint/naming-convention */
export const verificationFieldValues: Record<
    TVerificationMethod,
    'phoneNumber' | 'emailData' | 'addressData'
> = {
    PHONE_CALL: 'phoneNumber',
    SMS: 'phoneNumber',
    EMAIL: 'emailData',
    ADDRESS: 'addressData',
};
