/**
 * Prefixes react router routes if the app is being loaded as an MFE in Dashboard.
 */
export function getRoutePrefix(): string {
    if (!window.applicationBase) {
        return '';
    }
    const appUrl = new URL(window.applicationBase);
    return appUrl.pathname.replace(/\/$/, '') + '/gbp';
}

export function isMfe(): boolean {
    return !!window.applicationBase;
}
