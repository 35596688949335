import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
    //setMerchantLocationIdHeader,
    getConfigVar,
} from 'features/common/utils';

//import { MerchantLocationsApi } from 'features/settings/api';

export const logoutUserAction = createAction('auth/logout', function prepare() {
    window.location.href = `${getConfigVar('REACT_APP_API_BASE_URL')}/api/logout`;
    return { payload: {} };
});

export const resetStore = createAction('auth/resetStore');

export const setMerchantLocationId = createAction<number>(
    'auth/setMerchantLocationId',
);

export const setMerchantLocation = createAsyncThunk(
    'auth/setMerchantLocation',
    async (id: number, { dispatch }) => {
        dispatch(setMerchantLocationId(id));
        dispatch(resetStore());
    },
);

/*export const retrieveMerchantLocationId = createAsyncThunk(
    'auth/retrieveMerchantLocationId',
    async (_, { dispatch }) => {
        const cookieData = readCookieData();

        if (!cookieData?.merchantLocationId) {
            const allMerchantLocations = await MerchantLocationsApi.merchantLocationsGet(
                { page: 1 },
            );

            // We select the first location as the default one
            dispatch(setMerchantLocationId(allMerchantLocations.data.results[0].id));

            return;
        }

        dispatch(setMerchantLocationId(Number(cookieData.merchantLocationId)));
    },
);*/
