import React, { useState, BaseSyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Radio, Button, Input } from 'spoton-lib';

import { selectVerification } from 'features/account/redux/verification/verification.selectors';
import { postVerification } from 'features/account/redux/verification/verification.actions';
import { IVerificationOption } from 'features/account/redux/verification/verification.types';
import { selectStatus } from 'features/common/redux/status/status.selectors';
import { Status } from 'features/common/redux/status/status.types';

import { verificationFieldValues } from './config';

import styles from './VerificationOptions.module.scss';

interface IVerificationOptionsProps {
    showCode: () => void;
    isError: boolean;
}

export default function VerificationOptions({
    showCode,
    isError,
}: IVerificationOptionsProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { options: verificationOptions } = useSelector(selectVerification);
    const status = useSelector(selectStatus);

    const [selectedOption, setSelectedOption] = useState<IVerificationOption>(
        verificationOptions.length === 1 ? verificationOptions[0] : '',
    );
    const [inputValue, setInputValue] = useState<string>(
        generateItemValue(selectedOption, status),
    );
    const [isInputValid, setIsInputValid] = useState<boolean>(true);

    const selectOption = (item: IVerificationOption) => {
        setInputValue(generateItemValue(item, status));
        setSelectedOption(item);
        setIsInputValid(true);
    };

    const collectInput = (event: BaseSyntheticEvent) => {
        setInputValue(event.target.value);
    };

    const processOption = () => {
        if (!inputValue) {
            setIsInputValid(false);
            return;
        } else {
            dispatch(
                postVerification({
                    input: inputValue,
                    method: selectedOption.verificationMethod,
                    showCode,
                }),
            );
        }
    };

    return (
        <>
            <div className={styles.Header}>
                {t('connect.selectVerificationHeader')}
            </div>
            <div className={styles.Help}>{t('connect.selectVerificationHelp')}</div>
            <div className={styles.RadioList}>
                {verificationOptions.map(
                    (item: IVerificationOption, index: number) => {
                        const { verificationMethod } = item;
                        const isChecked =
                            selectedOption.verificationMethod ===
                            item.verificationMethod;
                        return (
                            <div key={index}>
                                <Radio
                                    className={styles.Radio}
                                    checked={isChecked}
                                    onChange={() => selectOption(item)}
                                    value={selectedOption.verificationMethod}
                                    label={t(
                                        `connect.selectVerificationOptions.${verificationMethod}.option`,
                                    )}
                                />
                                {isChecked && (
                                    <>
                                        <div className={styles.RadioDescription}>
                                            {t(
                                                `connect.selectVerificationOptions.${verificationMethod}.description`,
                                                '',
                                                { returnObjects: true },
                                            )}
                                            {extendDescription(item)}
                                        </div>
                                        <div className={styles.RadioWarning}>
                                            {t(
                                                `connect.selectVerificationOptions.${verificationMethod}.warning`,
                                                '',
                                                {
                                                    returnObjects: true,
                                                    numberDays:
                                                        item?.addressData
                                                            ?.expectedDeliveryDaysRegion,
                                                },
                                            )}
                                        </div>
                                        <Input
                                            className={styles.RadioInput}
                                            value={inputValue}
                                            onChange={collectInput}
                                            placeholder={t(
                                                `connect.selectVerificationOptions.${verificationMethod}.placeholder`,
                                            )}
                                            isValid={isInputValid}
                                            secondaryCondition={
                                                !isInputValid
                                                    ? t(
                                                          `connect.selectVerificationOptions.${verificationMethod}.error`,
                                                      )
                                                    : ''
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        );
                    },
                )}
            </div>
            <div className={styles.ButtonWrapper}>
                <Button
                    className={styles.Button}
                    onClick={processOption}
                    disabled={!selectedOption}
                >
                    {t('connect.selectVerificationButton')}
                </Button>
            </div>
            {isError && <div className={styles.ServerError}>{t('serverError')}</div>}
        </>
    );
}

function extendDescription(item: IVerificationOption): string {
    let description = '';
    if (item.verificationMethod === 'ADDRESS') {
        const address = item.addressData?.address;
        if (address) {
            description = ` - ${address?.addressLines
                .filter((item) => !!item)
                .join(', ')}, ${address?.locality}, ${address?.administrativeArea} ${
                address?.postalCode
            }, ${address?.regionCode}`;
        }
    }
    return description;
}

function generateItemValue(item: IVerificationOption, statusData: Status): string {
    const valueName =
        verificationFieldValues[item.verificationMethod] ||
        verificationFieldValues.SMS;

    if (item.verificationMethod === 'ADDRESS') {
        return `${statusData.firstName} ${statusData.lastName}`;
    }

    if (item.verificationMethod === 'EMAIL') {
        const verificationValue = `${item.emailData?.user}@${item.emailData?.domain}`;
        return verificationValue;
    }

    return item[valueName] as string;
}
