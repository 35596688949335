import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import {
    Button,
    colors,
    IconButton,
    Input,
    LoadingIndicator,
    Text,
} from 'spoton-lib';

import { Widget } from 'features/common/components';
import {
    fetchMenuLinks,
    selectMenuLinksData,
    selectMenuLinksLoadingStatus,
    updateMenuLinks,
} from 'features/dashboard/redux/menu';

import styles from './WebsiteMenus.module.scss';

export default function WebsiteMenus(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const [websiteUrl, setWebsiteUrl] = useState('');
    const reg =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
    const isValid = reg.test(websiteUrl) || websiteUrl === '';

    const menuLinksData = useSelector(selectMenuLinksData);
    const siteUrl = get(menuLinksData, 'url_menu[0].uri', '');
    const hasSiteUrl = siteUrl !== '';
    const isSameUrl = siteUrl === websiteUrl;
    const isLoading = useSelector(selectMenuLinksLoadingStatus);

    useEffect(() => {
        dispatch(fetchMenuLinks());
    }, [dispatch]);

    const handleCloseEditMode = useCallback(() => {
        setIsEdit(false);
    }, []);

    const onAddLink = useCallback(() => {
        const data = websiteUrl !== '' ? [{ uri: websiteUrl }] : [];
        dispatch(
            updateMenuLinks({
                url_menu: data,
            }),
        );

        handleCloseEditMode();
    }, [dispatch, websiteUrl]);

    const onHandleShowEdit = useCallback(() => {
        setIsEdit(true);
        setWebsiteUrl(siteUrl);
    }, [siteUrl]);

    return (
        <>
            <Widget className={styles.WebsiteMenu}>
                {hasSiteUrl && (
                    <>
                        {!isEdit ? (
                            <IconButton
                                alt="pencil"
                                className={styles.EditIcon}
                                buttonSize="regular"
                                color={colors.black}
                                disableBorder
                                name="PencilOutline"
                                onClick={onHandleShowEdit}
                                selectedName="PencilOutline"
                                size={60}
                                variant="secondary"
                            />
                        ) : (
                            <IconButton
                                alt="CloseIcon"
                                className={styles.EditIcon}
                                buttonSize="regular"
                                color={colors.black}
                                disableBorder
                                name="CloseIcon"
                                onClick={handleCloseEditMode}
                                selectedName="CloseIcon"
                                size={60}
                                variant="secondary"
                            />
                        )}
                    </>
                )}
                <div className={styles.CardTitle}>
                    {t('menus.websiteMenu.title')}
                </div>
                <Text type="p">{t('menus.websiteMenu.subTitle')}</Text>
                {isLoading ? (
                    <div className={styles.SpinnerWrapper}>
                        <LoadingIndicator />
                    </div>
                ) : (
                    <>
                        {!hasSiteUrl || isEdit ? (
                            <div className={styles.InputsWrapper}>
                                <Input
                                    className={styles.Input}
                                    isValid={isValid}
                                    secondaryCondition={
                                        isValid
                                            ? ''
                                            : t('menus.websiteMenu.validation')
                                    }
                                    label={
                                        siteUrl
                                            ? t('menus.websiteMenu.saveUrl')
                                            : t('menus.websiteMenu.addUrl')
                                    }
                                    value={websiteUrl}
                                    onChange={(e) => setWebsiteUrl(e.target.value)}
                                    placeholder="www.mysite.com/menu"
                                />
                                <Button
                                    className={styles.AddButton}
                                    onClick={onAddLink}
                                    disabled={!isValid || isSameUrl}
                                >
                                    {hasSiteUrl
                                        ? t('save')
                                        : t('menus.websiteMenu.addLink')}
                                </Button>
                            </div>
                        ) : (
                            <div className={styles.WebsiteMenuUrlWrapper}>
                                {menuLinksData?.url_menu &&
                                    menuLinksData?.url_menu.map((uri) => (
                                        <a
                                            key={uri.uri}
                                            className={styles.WebsiteMenuUrl}
                                            href={uri.uri}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {uri.uri}
                                        </a>
                                    ))}
                            </div>
                        )}
                    </>
                )}
            </Widget>
        </>
    );
}
