import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { MediaFullScreen } from '../MediaFullScreen';
import { selectMenuPhotosData } from 'features/dashboard/redux';
import { ModalHeader } from 'features/common/components/Modal';
import { ImageContainer } from '../ImageContainer/ImageContainer.component';

import styles from './ViewAllContent.module.scss';

export function ViewAllContent({
    closeModal,
}: {
    closeModal: () => void;
}): JSX.Element {
    const { t } = useTranslation();

    const menuPhotos = useSelector(selectMenuPhotosData);

    const [fullScreenImage, setFullScreenImage] = useState<any>(null);
    const [isOpenFullScreenImage, setIsOpenFullScreenImage] =
        useState<boolean>(false);

    const openFullScreenImage = (data: any, index: number) =>
        useCallback(() => {
            setFullScreenImage({ ...data, index });
            setIsOpenFullScreenImage(true);
        }, [data, index]);

    const closeFullScreenImage = () => {
        setFullScreenImage(null);
        setIsOpenFullScreenImage(false);
    };

    return (
        <>
            <ModalHeader
                title={t('menuPhotos.menuImages')}
                closeModal={closeModal}
                className={styles.ModalHeader}
            />
            <div className={styles.Body}>
                <div className={styles.ViewAllWrapper}>
                    {menuPhotos.mediaItems.length &&
                        menuPhotos.mediaItems.map((item: any, index) => (
                            <ImageContainer
                                key={item?.name}
                                image={item?.thumbnailUrl}
                                days={item?.hrCreateTime}
                                onClick={openFullScreenImage(item, index)}
                            />
                        ))}
                </div>
            </div>

            {isOpenFullScreenImage && (
                <MediaFullScreen
                    imageData={fullScreenImage}
                    imagesData={menuPhotos?.mediaItems}
                    totalMediaCount={menuPhotos?.mediaItems?.length}
                    pageToken={menuPhotos?.nextPageToken}
                    currentFilter={'0'}
                    closeModal={closeFullScreenImage}
                />
            )}
        </>
    );
}
