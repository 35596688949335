import React from 'react';

import { BusinessInfo } from '../BusinessInfo';
import { BusinessHours } from '../BusinessHours';

import styles from './ProfileScreen.module.scss';
import { ServiceArea } from '../ServiceArea';

export default function ProfileScreen(): JSX.Element {
    return (
        <div className={styles.Wrapper}>
            <div className={styles.HalfSectionWrapper}>
                <div className={styles.LeftSideWrapper}>
                    <BusinessInfo />
                    <div className={styles.AppointmentLinksWrapper}>
                        <ServiceArea />
                    </div>
                </div>
                <div className={styles.RightSideWrapper}>
                    <BusinessHours />
                </div>
            </div>
        </div>
    );
}
