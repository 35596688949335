import React from 'react';
import {
    mount,
    withView,
    route,
    compose,
    createBrowserNavigation,
    map,
    redirect,
    Matcher,
} from 'navi';

import i18n from 'i18n.config';
import App from 'app/components/App';

import { Preview } from 'features/account/components/Preview';
import { Connect } from 'features/account/components/Connect';

import { PendingAccess } from 'features/account/components/PendingAccess';
import { PendingVerification } from 'features/account/components/PendingVerification';
import { PendingConnection } from 'features/account/components/PendingConnection';

import { ServiceUnavailable } from 'features/account/components/ServiceUnavailable';
import { LoginRedirect } from 'features/common/components';
import { Disconnect } from 'features/disconnect/Disconnect.componnent';
import { Sublocation } from 'features/account/components/Sublocation';
import { checkAuth } from 'features/common/utils/auth.utils';
import { getConfigFlag } from 'features';
import dashboardRoutes from 'features/dashboard/routes/dashboard.routes';
import { getRoutePrefix, isMfe } from 'utils/router';

const routePrefix = getRoutePrefix();

export const paths = {
    preview: `${routePrefix}/`,
    connect: `${routePrefix}/connect`,
    sublocationSelect: `${routePrefix}/sublocation-select`,
    pendingAccess: `${routePrefix}/pending-access`,
    pendingVerification: `${routePrefix}/pending-verification`,
    pendingConnection: `${routePrefix}/pending-connection`,
    error: `${routePrefix}/error`,
    healthz: `${routePrefix}/healthz`,
    login: `${routePrefix}/login`,
    dashboard: `${routePrefix}/dashboard`,
    disconnect: `${routePrefix}/disconnect`,
};

const routesToMount = {
    [paths.preview]: route({
        title: `${i18n.t('applicationShortName')} ${i18n.t(
            'navigationApps.titles.preview',
        )}`,
        view: <Preview />,
    }),
    [paths.connect]: route({
        title: `${i18n.t('applicationShortName')} ${i18n.t(
            'navigationApps.titles.connect',
        )}`,
        view: <Connect />,
    }),
    [paths.sublocationSelect]: route({
        title: `${i18n.t('applicationShortName')} ${i18n.t(
            'navigationApps.titles.sublocationSelect',
        )}`,
        view: <Sublocation />,
    }),
    [paths.pendingAccess]: route({
        title: `${i18n.t('applicationShortName')} ${i18n.t(
            'navigationApps.titles.pendingAccess',
        )}`,
        view: <PendingAccess />,
    }),
    [paths.pendingVerification]: route({
        title: `${i18n.t('applicationShortName')} ${i18n.t(
            'navigationApps.titles.pendingVerification',
        )}`,
        view: <PendingVerification />,
    }),
    [paths.pendingConnection]: route({
        title: `${i18n.t('applicationShortName')} ${i18n.t(
            'navigationApps.titles.pendingConnection',
        )}`,
        view: <PendingConnection />,
    }),
    [paths.error]: route({
        title: `${i18n.t('applicationShortName')} ${i18n.t(
            'navigationApps.titles.serviceUnavailable',
        )}`,
        view: <ServiceUnavailable />,
    }),
    [paths.dashboard]: dashboardRoutes,
    // NOTES: Apparently webpack is really unhappy with lazy loading the routes which kept failing.
    // If you can find a way around it please do. If you comment out the line above and uncomment the line below you will see the previous state which fails
    // '/dashboard': lazy(
    //     () => import('features/dashboard/routes/dashboard.routes'),
    // ),
    [paths.healthz]: route({
        title: `${i18n.t('applicationShortName')} ${i18n.t(
            'navigationApps.titles.healthCheck',
        )}`,
        view: <span>GBP-web app is Ok</span>,
    }),
    [paths.login]: route({
        view: <LoginRedirect />,
    }),
};

const protectedRoutes = isMfe()
    ? mount(routesToMount)
    : compose(withView(<App />), mount(routesToMount));

const redirectTo = (originalUrl: string): Matcher<any> => {
    return redirect(`${paths.login}?next=${originalUrl}`, { exact: false });
};

const isSSOEnabled = getConfigFlag('REACT_APP_SSO_ENABLED');

export const routes = mount({
    [paths.login]: route({
        view: <LoginRedirect />,
    }),
    [paths.disconnect]: route({
        title: `${i18n.t('applicationShortName')} ${i18n.t(
            'navigationApps.titles.disconnect',
        )}`,
        view: <Disconnect />,
    }),
    '*': map((request: any) => {
        if (!isSSOEnabled) {
            return protectedRoutes;
        }
        const isAuthenticated = checkAuth();
        return isAuthenticated ? protectedRoutes : redirectTo(request.originalUrl);
    }),
});

/**
 * Use this navigation-object when you can not use useNavigation, for ex. : outside of React component.
 * Actually, that is workaround. there is ts-error if to pass 'navigation' to redux-action
 */

export const navigation = createBrowserNavigation({
    routes: routes,
});
