// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectCategory_CategoryContainer__luXBi{width:100%;margin-bottom:15px}.SelectCategory_CategoryInput__4lnHm{margin-bottom:10px;font-size:14px;line-height:22px}.SelectCategory_CategoryDropdown__3uh_1{font-size:14px;line-height:22px}\n", "",{"version":3,"sources":["webpack://src/features/account/components/Connect/SelectCategory.module.scss"],"names":[],"mappings":"AAAA,yCACI,UAAW,CACX,kBAAmB,CACtB,qCAGG,kBAAmB,CACnB,cAAe,CACf,gBAAiB,CACpB,wCAGG,cAAe,CACf,gBAAiB","sourcesContent":[".CategoryContainer {\n    width: 100%;\n    margin-bottom: 15px;\n}\n\n.CategoryInput {\n    margin-bottom: 10px;\n    font-size: 14px;\n    line-height: 22px;\n}\n\n.CategoryDropdown {\n    font-size: 14px;\n    line-height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CategoryContainer": "SelectCategory_CategoryContainer__luXBi",
	"CategoryInput": "SelectCategory_CategoryInput__4lnHm",
	"CategoryDropdown": "SelectCategory_CategoryDropdown__3uh_1"
};
export default ___CSS_LOADER_EXPORT___;
