// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiSelectDropdown_MultiSelect__1ffbd{width:100%}.MultiSelectDropdown_ClearIndicator__QT_Os{margin-right:0.75rem;cursor:pointer}.MultiSelectDropdown_DropdownIcon__iD8ch{margin:0 0.75rem;cursor:pointer}.MultiSelectDropdown_Block__3a3tk{display:block}.MultiSelectDropdown_TagTooltip__5iqu3{z-index:99999}.MultiSelectDropdown_Label__2L-Ir{margin-bottom:0.25rem}\n", "",{"version":3,"sources":["webpack://src/features/common/components/MultiSelectDropdown/MultiSelectDropdown.module.scss"],"names":[],"mappings":"AAEA,wCACI,UAAW,CACd,2CAGG,oBAAqB,CACrB,cAAe,CAClB,yCAGG,gBAAiB,CACjB,cAAe,CAClB,kCAGG,aAAc,CACjB,uCAGG,aAAc,CACjB,kCAGG,qBAAsB","sourcesContent":["@import '~spoton-lib/styles/color-variables';\n\n.MultiSelect {\n    width: 100%;\n}\n\n.ClearIndicator {\n    margin-right: 0.75rem;\n    cursor: pointer;\n}\n\n.DropdownIcon {\n    margin: 0 0.75rem;\n    cursor: pointer;\n}\n\n.Block {\n    display: block;\n}\n\n.TagTooltip {\n    z-index: 99999;\n}\n\n.Label {\n    margin-bottom: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary10": "var(--primary10)",
	"primary20": "var(--primary20)",
	"primary30": "var(--primary30)",
	"primary50": "var(--primary50)",
	"primary70": "var(--primary70)",
	"primary80": "var(--primary80)",
	"primary90": "var(--primary90)",
	"base10": "var(--base10)",
	"base20": "var(--base20)",
	"base30": "var(--base30)",
	"base50": "var(--base50)",
	"base70": "var(--base70)",
	"base80": "var(--base80)",
	"base90": "var(--base90)",
	"highlightBase20": "var(--#f7f8f9)",
	"white": "var(--white)",
	"black": "var(--black)",
	"shadow": "var(--shadow)",
	"informative10": "var(--informative10)",
	"informative20": "var(--informative20)",
	"informative30": "var(--informative30)",
	"informative50": "var(--informative50)",
	"informative70": "var(--informative70)",
	"informative80": "var(--informative80)",
	"informative90": "var(--informative90)",
	"success10": "var(--success10)",
	"success20": "var(--success20)",
	"success30": "var(--success30)",
	"success50": "var(--success50)",
	"success70": "var(--success70)",
	"success80": "var(--success80)",
	"success90": "var(--success90)",
	"warning10": "var(--warning10)",
	"warning20": "var(--warning20)",
	"warning30": "var(--warning30)",
	"warning50": "var(--warning50)",
	"warning70": "var(--warning70)",
	"warning80": "var(--warning80)",
	"warning90": "var(--warning90)",
	"danger10": "var(--danger10)",
	"danger20": "var(--danger20)",
	"danger30": "var(--danger30)",
	"danger50": "var(--danger50)",
	"danger70": "var(--danger70)",
	"danger80": "var(--danger80)",
	"danger90": "var(--danger90)",
	"loyalty10": "var(--loyalty10)",
	"loyalty20": "var(--loyalty20)",
	"loyalty30": "var(--loyalty30)",
	"loyalty50": "var(--loyalty50)",
	"loyalty70": "var(--loyalty70)",
	"loyalty80": "var(--loyalty80)",
	"loyalty90": "var(--loyalty90)",
	"loyaltyGradient": "var(--loyaltyGradient)",
	"MultiSelect": "MultiSelectDropdown_MultiSelect__1ffbd",
	"ClearIndicator": "MultiSelectDropdown_ClearIndicator__QT_Os",
	"DropdownIcon": "MultiSelectDropdown_DropdownIcon__iD8ch",
	"Block": "MultiSelectDropdown_Block__3a3tk",
	"TagTooltip": "MultiSelectDropdown_TagTooltip__5iqu3",
	"Label": "MultiSelectDropdown_Label__2L-Ir"
};
export default ___CSS_LOADER_EXPORT___;
