import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import { IPostMenuLinks } from './Post.types';

export function getMenuItems(readMask?: string): Promise<AxiosResponse<any>> {
    const q = readMask ? `?readMask=${readMask}` : '';
    return axios.get<any>(`/api/menu/${q}`);
}

export function getMenuLinks(): Promise<AxiosResponse<any>> {
    return axios.get<any>(`/api/menu/links`);
}

export function updateMenuLinks(
    data: IPostMenuLinks,
): Promise<AxiosResponse<IPostMenuLinks>> {
    return axios.patch<IPostMenuLinks>(`/api/menu/links`, data, {
        headers: {
            'content-type': 'application/json',
        },
    });
}
