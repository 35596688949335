import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, LoadingIndicator } from 'spoton-lib';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import Review, { ReviewEmpty } from './Review.component';
import { IReview } from 'features/dashboard/api/Review.types';
import { selectReviewModalActiveTab } from 'features/dashboard/redux/modals';
import { ReviewTabs } from './ReviewsTabs.types';
import styles from './ReviewsSection.module.scss';

interface IReviewSectionProps {
    reviews: Array<IReview>;
    nextPageToken?: string;
    isError: boolean;
    isFullView: boolean;
    getReviews: any;
    isReviewFetchInProgress: any;
    isModal?: boolean;
}

function LoaderIndicator() {
    return (
        <div
            key={0}
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <LoadingIndicator />
        </div>
    );
}

export default function ReviewsTabs({
    reviews,
    nextPageToken,
    isFullView,
    getReviews,
    isReviewFetchInProgress,
    isModal,
    isError,
}: IReviewSectionProps): JSX.Element {
    const [activeTab, setActiveTab] = useState(ReviewTabs.all);
    const { t } = useTranslation();
    const maxItemsNumber = 2;

    const initialActiveTab = useSelector(selectReviewModalActiveTab);

    useEffect(() => {
        if (initialActiveTab && isModal) {
            setActiveTab(initialActiveTab);
        }
    }, [initialActiveTab, isModal]);

    const onTabSelect = (name: string) => {
        setActiveTab(name as ReviewTabs);
    };

    let content;
    const hasReviews = reviews && reviews.length > 0;
    const repliedReviews: Array<IReview> = [],
        notRepliedReviews: Array<IReview> = [];
    if (reviews?.length) {
        reviews.forEach((review) => {
            if (review.reviewReply) {
                repliedReviews.push(review);
            } else {
                notRepliedReviews.push(review);
            }
        });
    }

    if (!isFullView) {
        [reviews, repliedReviews, notRepliedReviews].forEach((reviewSet) => {
            if (reviewSet && reviewSet.length > maxItemsNumber) {
                reviewSet.length = maxItemsNumber;
            }
        });
    }

    switch (activeTab) {
        case ReviewTabs.all:
            content = hasReviews ? (
                <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={getReviews}
                    hasMore={!!nextPageToken}
                    isReverse={false}
                    threshold={400}
                    useWindow={false}
                    loader={<LoaderIndicator />}
                >
                    {reviews.map((review) => (
                        <Review
                            key={
                                review.reviewId +
                                review.createTime +
                                review.updateTime
                            }
                            review={review}
                        />
                    ))}
                </InfiniteScroll>
            ) : (
                <div className={styles.Loader}>
                    {!isReviewFetchInProgress ? (
                        <ReviewEmpty
                            isError={isError}
                            activeTab={activeTab}
                            hasReviews={hasReviews}
                        />
                    ) : (
                        <LoadingIndicator />
                    )}
                </div>
            );
            break;
        case ReviewTabs.replied:
            content =
                reviews && repliedReviews.length ? (
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        loadMore={getReviews}
                        hasMore={!!nextPageToken}
                        isReverse={false}
                        threshold={400}
                        useWindow={false}
                        loader={<LoaderIndicator />}
                    >
                        {repliedReviews.map((review) => (
                            <Review
                                key={
                                    review.reviewId +
                                    review.createTime +
                                    review.updateTime
                                }
                                review={review}
                            />
                        ))}
                    </InfiniteScroll>
                ) : (
                    <div className={styles.Loader}>
                        <ReviewEmpty
                            isError={isError}
                            activeTab={activeTab}
                            hasReviews={hasReviews}
                        />
                    </div>
                );
            break;
        case ReviewTabs.omitted:
            content =
                reviews && notRepliedReviews.length ? (
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        loadMore={getReviews}
                        hasMore={!!nextPageToken}
                        isReverse={false}
                        threshold={400}
                        useWindow={false}
                        loader={<LoaderIndicator />}
                    >
                        {notRepliedReviews.map((review) => (
                            <Review
                                key={
                                    review.reviewId +
                                    review.createTime +
                                    review.updateTime
                                }
                                review={review}
                            />
                        ))}
                    </InfiniteScroll>
                ) : (
                    <div className={styles.Loader}>
                        <ReviewEmpty
                            isError={isError}
                            activeTab={activeTab}
                            hasReviews={hasReviews}
                        />
                    </div>
                );
            break;
        default:
            content = '';
    }

    return (
        <>
            <Tabs active={activeTab} onSelect={onTabSelect} className={styles.Tabs}>
                <Tabs.Tab name={ReviewTabs.all}>{t('all')}</Tabs.Tab>
                <Tabs.Tab name={ReviewTabs.replied}>
                    {t('dashboard.replied')}
                </Tabs.Tab>
                <Tabs.Tab name={ReviewTabs.omitted}>
                    {t('dashboard.notReplied')}
                </Tabs.Tab>
            </Tabs>
            <div className={styles.TabContent}>{content}</div>
        </>
    );
}
