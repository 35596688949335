import React from 'react';

import { BadgeLevel } from 'features/dashboard/redux/advisor/advisor.types';

import badgesSprite from 'features/dashboard/assets/badges-sprite.svg';

import styles from './Badge.module.scss';

export interface IBadgeProps {
    type?: string;
    level?: string;
    width?: number;
    height?: number;
}

export default function Badge({
    type = 'Post',
    level = 'Disabled',
    width = 90,
    height = 90,
}: IBadgeProps): JSX.Element {
    return (
        <>
            <svg width={width} height={height} className={styles.Badge}>
                <use
                    href={`${badgesSprite}#${type}${
                        Object.values(BadgeLevel).includes(level)
                            ? level
                            : 'Disabled'
                    }`}
                />
            </svg>
        </>
    );
}
