import React, { useEffect, useMemo, useState } from 'react';
import { event } from 'react-fullstory';
import { Modal } from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from 'react-navi';

import { Card, LoadingIndicator, Icon, Button, Checkbox } from 'spoton-lib';
import MobilePreview from './MobilePreview.component';

import { RootState } from 'reduxConfig';

import { MERCHANT_TYPE } from 'features/common/redux/status/status.types';
import {
    getPreview,
    createAccount,
} from 'features/account/redux/preview/preview.actions';
import {
    selectPreview,
    selectLoadingStatus,
} from 'features/account/redux/preview/preview.selectors';
import {
    selectFsIdentifiers,
    selectStatus,
} from 'features/common/redux/status/status.selectors';
import { Terms } from './Terms';

import styles from './Preview.module.scss';
import { getPathnameWithQueryParams } from 'app/components/App/App.utils';
import { paths } from 'app/routes/app.routes';

export default function Preview(): JSX.Element {
    const { t } = useTranslation();
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const [shouldAutoCreate, setShouldAutoCreate] = useState(false);
    const [isShowTermsModal, setShowTermsModal] = useState(false);
    const status = useSelector(selectStatus);

    useEffect(() => {
        if (status.merchantInfo.type !== MERCHANT_TYPE.ROOT) {
            dispatch(getPreview());
        }

        // https://spotonteam.atlassian.net/browse/MC-2776
        const params = new URLSearchParams(location.search);
        const redirectTo = params.get('redirectTo');
        if (redirectTo) {
            sessionStorage.setItem('redirectTo', redirectTo);
        }
    }, []);

    const fsIdentifiers = useSelector(selectFsIdentifiers);
    const isLoading = useSelector(selectLoadingStatus);
    const preview = useSelector((state: RootState) => selectPreview(state));

    const action = preview.status === 'CLAIMED' ? 'CONNECT' : 'CREATE';

    const advantages: Array<any> = t(`connect.advantages.${action}`, {
        returnObjects: true,
    });

    const previewStatuses = useMemo(() => {
        switch (preview.status) {
            case 'CLAIMED':
                return 'CONNECT';
            case 'NOT_CLAIMED':
                return 'CLAIM';
            case 'MULTIPLE_RESULTS':
                return 'CREATE';
            case 'NOT_FOUND':
                return 'CREATE';
            default:
                return preview.status;
        }
    }, [preview]);

    const goToConnect = () => {
        event('preview_start', {
            ...fsIdentifiers,
            preview_status: previewStatuses,
        } as { [property: string]: string });
        navigation.navigate(getPathnameWithQueryParams(paths.connect));
    };

    const autoCreateAccount = () => {
        dispatch(createAccount());
    };

    const toggleAutoCreation = () => {
        setShouldAutoCreate((current) => !current);
    };

    const onCloseModal = () => {
        setShowTermsModal(false);
    };

    return (
        <>
            <Card className={styles.Wrapper}>
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <div className={styles.LeftWrapper}>
                            <div className={styles.Title}>
                                {preview.status
                                    ? t(`connect.previewTitles.${preview.status}`)
                                    : t(`connect.previewTitles.NOT_FOUND`)}
                            </div>
                            <div className={styles.Advantages}>
                                {action === 'CONNECT' && t(`connect.spotOnDesc`)}
                                {advantages.map((advantage, index) => (
                                    <div key={index}>
                                        <div className={styles.Advantage}>
                                            <div className={styles.AdvantageIcon}>
                                                <Icon
                                                    name={advantage.icon}
                                                    alt="advantage icon"
                                                    size={22}
                                                />
                                            </div>
                                            {advantage.text}
                                        </div>
                                        {advantage.details && (
                                            <ul className={styles.DetailList}>
                                                {advantage.details.map(
                                                    (
                                                        item: string,
                                                        index: number,
                                                    ) => (
                                                        <li
                                                            key={index}
                                                            className={styles.Detail}
                                                        >
                                                            {item}
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        )}
                                    </div>
                                ))}
                            </div>
                            {(previewStatuses === 'CLAIMED' ||
                                previewStatuses === 'CREATE') && (
                                <div className={styles.AutoCreateWrapper}>
                                    <Checkbox
                                        label={t('connect.autoCreate')}
                                        checked={shouldAutoCreate}
                                        onChange={toggleAutoCreation}
                                    />
                                    <div
                                        className={styles.Terms}
                                        onClick={() => setShowTermsModal(true)}
                                        tabIndex={0}
                                        role="button"
                                        aria-hidden="true"
                                    >
                                        {t('connect.terms')}
                                    </div>
                                </div>
                            )}

                            <div className={styles.ButtonWrapper}>
                                <Button
                                    onClick={
                                        shouldAutoCreate
                                            ? autoCreateAccount
                                            : goToConnect
                                    }
                                    className={styles.ConnectButton}
                                >
                                    {preview.status
                                        ? t(
                                              `connect.previewButtons.${preview.status}`,
                                          )
                                        : t(`connect.previewButtons.NOT_FOUND`)}
                                </Button>
                            </div>
                        </div>
                        <div className={styles.RightWrapper}>
                            {!preview.isError && (
                                <MobilePreview
                                    businessPreview={preview.businessPreview}
                                />
                            )}
                        </div>
                    </>
                )}
            </Card>
            <Modal
                ariaHideApp={false}
                isOpen={isShowTermsModal}
                onRequestClose={onCloseModal}
                className={styles.TermsModal}
            >
                <Terms onCloseModal={onCloseModal} />
            </Modal>
        </>
    );
}
