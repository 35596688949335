// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputAction_ActionWrapper__3TQdh{position:relative;display:flex;align-items:center;justify-content:space-between;height:70px;margin-bottom:15px;font-size:14px}.InputAction_ActionLabel__1Bsu_{position:absolute;top:2px;left:2px;font-size:12px}.InputAction_ActionDropdown__cSsDL{width:calc(50% - 10px);padding:2px 0 0}@media screen and (max-width: 1024px){.InputAction_ActionDropdown__cSsDL{width:100%}}.InputAction_ActionDropdown__cSsDL div{color:#646e8a !important}.InputAction_ActionUrl__riV0f{width:calc(50% - 10px)}.InputAction_ActionUrl__riV0f input{box-sizing:border-box;height:44px}\n", "",{"version":3,"sources":["webpack://src/features/dashboard/home/PostSection/SmallModal/InputAction.module.scss"],"names":[],"mappings":"AAGA,kCACI,iBAAkB,CAClB,YAAa,CACb,kBAAmB,CACnB,6BAA8B,CAC9B,WAAY,CACZ,kBAAmB,CACnB,cAAe,CAClB,gCAGG,iBAAkB,CAClB,OAAQ,CACR,QAAS,CACT,cAAe,CAClB,mCAGG,sBAAuB,CACvB,eAAgB,CASnB,sCAXD,mCAKQ,UAAW,CAMlB,CAXD,uCASQ,wBAAyB,CAC5B,8BAID,sBAAuB,CAD3B,oCAIQ,qBAAsB,CACtB,WAAY","sourcesContent":["@import 'spoton-lib/styles/color-variables';\n@import 'features/common/styles/breakpoints';\n\n.ActionWrapper {\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: 70px;\n    margin-bottom: 15px;\n    font-size: 14px;\n}\n\n.ActionLabel {\n    position: absolute;\n    top: 2px;\n    left: 2px;\n    font-size: 12px;\n}\n\n.ActionDropdown {\n    width: calc(50% - 10px);\n    padding: 2px 0 0;\n\n    @include notDesktop() {\n        width: 100%;\n    }\n\n    div {\n        color: $base70 !important;\n    }\n}\n\n.ActionUrl {\n    width: calc(50% - 10px);\n\n    input {\n        box-sizing: border-box;\n        height: 44px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary10": "var(--primary10)",
	"primary20": "var(--primary20)",
	"primary30": "var(--primary30)",
	"primary50": "var(--primary50)",
	"primary70": "var(--primary70)",
	"primary80": "var(--primary80)",
	"primary90": "var(--primary90)",
	"base10": "var(--base10)",
	"base20": "var(--base20)",
	"base30": "var(--base30)",
	"base50": "var(--base50)",
	"base70": "var(--base70)",
	"base80": "var(--base80)",
	"base90": "var(--base90)",
	"highlightBase20": "var(--#f7f8f9)",
	"white": "var(--white)",
	"black": "var(--black)",
	"shadow": "var(--shadow)",
	"informative10": "var(--informative10)",
	"informative20": "var(--informative20)",
	"informative30": "var(--informative30)",
	"informative50": "var(--informative50)",
	"informative70": "var(--informative70)",
	"informative80": "var(--informative80)",
	"informative90": "var(--informative90)",
	"success10": "var(--success10)",
	"success20": "var(--success20)",
	"success30": "var(--success30)",
	"success50": "var(--success50)",
	"success70": "var(--success70)",
	"success80": "var(--success80)",
	"success90": "var(--success90)",
	"warning10": "var(--warning10)",
	"warning20": "var(--warning20)",
	"warning30": "var(--warning30)",
	"warning50": "var(--warning50)",
	"warning70": "var(--warning70)",
	"warning80": "var(--warning80)",
	"warning90": "var(--warning90)",
	"danger10": "var(--danger10)",
	"danger20": "var(--danger20)",
	"danger30": "var(--danger30)",
	"danger50": "var(--danger50)",
	"danger70": "var(--danger70)",
	"danger80": "var(--danger80)",
	"danger90": "var(--danger90)",
	"loyalty10": "var(--loyalty10)",
	"loyalty20": "var(--loyalty20)",
	"loyalty30": "var(--loyalty30)",
	"loyalty50": "var(--loyalty50)",
	"loyalty70": "var(--loyalty70)",
	"loyalty80": "var(--loyalty80)",
	"loyalty90": "var(--loyalty90)",
	"loyaltyGradient": "var(--loyaltyGradient)",
	"ActionWrapper": "InputAction_ActionWrapper__3TQdh",
	"ActionLabel": "InputAction_ActionLabel__1Bsu_",
	"ActionDropdown": "InputAction_ActionDropdown__cSsDL",
	"ActionUrl": "InputAction_ActionUrl__riV0f"
};
export default ___CSS_LOADER_EXPORT___;
