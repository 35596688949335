import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IconButton, Button, Icon } from 'spoton-lib';

import { useBreakpoint } from 'utils/customHooks';

import { showMenuPhotosModal } from 'features/dashboard/redux';

import Example1 from '../../../assets/example_1.png';
import Example2 from '../../../assets/example_2.png';
import Example3 from '../../../assets/example_3.png';

import styles from './PhotoExample.module.scss';

interface IProps {
    closeModal: () => void;
}

export function PhotoExample({ closeModal }: IProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [photoIndex, setPhotoIndex] = useState(0);
    const [imagePosition, setImagePosition] = useState('100%');

    const photos = [
        { name: Example1, position: '100%' },
        { name: Example2, position: '0%' },
        { name: Example3, position: '-100%' },
    ];

    const isMobile = useBreakpoint() === 'mobile';

    const onTouchImage = useCallback(
        (imageIndex, position) => () => {
            if (isMobile) {
                setPhotoIndex(imageIndex);
                setImagePosition(position);
            }
        },
        [isMobile],
    );

    const onClickAddPhoto = useCallback(() => {
        closeModal();
        dispatch(showMenuPhotosModal());
    }, []);

    return (
        <>
            <IconButton
                name="CloseIcon"
                alt={t('close')}
                buttonSize="regular"
                size={36}
                variant="secondary"
                disableBorder
                className={styles.CloseIcon}
                onClick={closeModal}
            />
            <div className={styles.Body}>
                <div className={styles.Examples}>
                    <div className={styles.ExamplesHeader}>
                        <div className={styles.ImageNavigation}>
                            {photos.map((item, index) => (
                                <div
                                    key={item.name}
                                    className={
                                        photoIndex === index
                                            ? styles.ImageNavigationItemActive
                                            : styles.ImageNavigationItem
                                    }
                                ></div>
                            ))}
                        </div>
                        <span className={styles.ExamplesHeaderTitle}>
                            {t('menuPhotos.examplesTitle')}
                        </span>
                        <span className={styles.ExamplesHeaderSubTitle}>
                            {t('menuPhotos.examplesSubTitle')}
                        </span>
                    </div>
                    <div className={styles.ExamplesPhoto}>
                        <div
                            className={styles.ExamplesPhotoWrapper}
                            style={{
                                left: isMobile ? imagePosition : 0,
                            }}
                        >
                            {photos.map((item, index) => (
                                <img
                                    aria-hidden="true"
                                    key={index}
                                    src={item.name}
                                    alt={item.name}
                                    onTouchStart={onTouchImage(index, item.position)}
                                    className={styles.ExamplesPhotoItem}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <footer className={styles.Footer}>
                <Button
                    className={styles.ButtonSubmit}
                    variant="primary"
                    onClick={onClickAddPhoto}
                >
                    <>
                        <Icon name="PhotoUploadIcon" alt="Search" size={20} />
                        {t('menuPhotos.addPhoto')}
                    </>
                </Button>
                <Button
                    onClick={closeModal}
                    variant="tertiary"
                    className={styles.ButtonClose}
                >
                    Go back
                </Button>
            </footer>
        </>
    );
}
