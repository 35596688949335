import React from 'react';
import { Icon, colors } from 'spoton-lib';
import { useTranslation } from 'react-i18next';

import { RegularHoursPeriod } from 'features/dashboard/types';

import styles from './TimePeriodRow.module.scss';
import { formatTimeTo12hour } from 'features/common/components/TimePicker';
import { MIDNIGHT, MIDNIGHT_24, daysOfWeek } from 'utils/periods.utils';

interface IProps {
    dayKey: string;
    periods: RegularHoursPeriod[];
}

export function TimePeriodRow({ dayKey, periods }: IProps): JSX.Element {
    const { t } = useTranslation();
    const day = daysOfWeek[dayKey];

    const period = periods.find((item) => {
        return item.openDay.toLowerCase() === day.toLowerCase();
    });

    const isFullDay =
        period &&
        period.combinedOpenTime === MIDNIGHT &&
        period.combinedCloseTime === MIDNIGHT_24;

    return (
        <div className={styles.TimePeriodRow}>
            <div className={styles.RowCellDay}>{t(dayKey)}</div>

            <div className={styles.HoursWrapper}>
                {period ? (
                    <>
                        {isFullDay ? (
                            <>
                                <div className={styles.RowCellTime}>
                                    {t('profile.businessInfo.fullDay')}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.RowCellTime}>
                                    {formatTimeTo12hour(period.combinedOpenTime)}
                                </div>
                                <div>
                                    <Icon
                                        alt="arrow"
                                        color={colors.base30}
                                        name="ArrowRightIcon"
                                        size={24}
                                    />
                                </div>
                                <div className={styles.RowCellTime}>
                                    {formatTimeTo12hour(period.combinedCloseTime)}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className={styles.RowCellClosed}>
                        {t('profile.businessHours.closed')}
                    </div>
                )}
            </div>
        </div>
    );
}

export default TimePeriodRow;
