import React from 'react';

import { Button } from 'spoton-lib';

import { useTranslation } from 'react-i18next';
import styles from './VerificationError.module.scss';

interface IVerificationErrorProps {
    showOptions: () => void;
}

export default function VerificationError({
    showOptions,
}: IVerificationErrorProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.Header}>{t('connect.codeErrorHeader')}</div>
            <div className={styles.Help}>{t('connect.codeErrorHelp')}</div>
            <div className={styles.ButtonWrapper}>
                <Button className={styles.Button} onClick={showOptions}>
                    {t('connect.codeErrorButton')}
                </Button>
            </div>
        </>
    );
}
