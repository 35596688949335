import { getConfigFlag } from './config.utils';

/**
 * We need to prefix the cookie with QA in only the QA environment or else SSO will not work
 */
function getCookieName(name: string): string {
    const cookiePrefix = getConfigFlag('REACT_APP_IS_QA') ? 'QA' : '';
    return cookiePrefix + name;
}

export function getCookieValue(name: string): string | undefined {
    const cookie = getCookieName(name);
    const decodedCookie = decodeURIComponent(document.cookie);
    const b = decodedCookie.match('(^|;)\\s*' + cookie + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : undefined;
}

export function setCookie(name: string, value: string, days = 0): void {
    const cookie = getCookieName(name);
    let expire;

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expire = '; expires=' + date.toUTCString();
    } else {
        expire = '';
    }

    document.cookie = cookie + '=' + value + expire + '; path=/';
}
