import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { MediaApi } from 'features/dashboard/api/index';
import { hideMediaSectionSmallModal } from '../modals';

import { showDetailedError, showSuccess } from '../../../../utils/messages';

import i18n from 'i18n.config';

export const homeUploadMedia: any = createAsyncThunk(
    'dashboard/homeUploadMedia',
    async (image: File, thunkAPI) => {
        try {
            const response = await MediaApi.postImages(image);
            showSuccess({
                title: '',
                message: i18n.t('dashboard.uploadMediaSuccess'),
            });
            return response.data;
        } catch (error) {
            showDetailedError({
                template: 'errors.dashboard.mediaUpload',
                error: (error as AxiosError<any, any>).response?.data,
            });
        } finally {
            thunkAPI.dispatch(hideMediaSectionSmallModal());
        }
    },
);
