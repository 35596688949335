import React from 'react';

import { Icon, Tabs } from 'spoton-lib';

import { useTranslation } from 'react-i18next';
import styles from './MobilePreview.module.scss';

import { useBreakpoint } from 'utils/customHooks';

import { ReactComponent as MobilePhone } from 'features/account/assets/MobilePhone.svg';
import { ReactComponent as ActionCall } from 'features/account/assets/ActionCall.svg';
import { ReactComponent as ActionDirections } from 'features/account/assets/ActionDirections.svg';
import { ReactComponent as ActionMessage } from 'features/account/assets/ActionMessage.svg';
import { ReactComponent as ActionWebsite } from 'features/account/assets/ActionWebsite.svg';
import { ReactComponent as MapMarker } from 'features/account/assets/MapMarker.svg';

import { IBusinessPreview } from 'features/account/api/Preview.types';

//TODO optimize props usage
//TODO optimize code / split into different components

interface IMobilePreviewProps {
    businessPreview: IBusinessPreview;
    isExampleProfile?: boolean;
}

export default function MobilePreview({
    businessPreview,
    isExampleProfile = false,
}: IMobilePreviewProps): JSX.Element {
    const { t } = useTranslation();

    const isMobile = useBreakpoint() !== 'desktop';

    const starRating = (rating: number) => {
        const roundedRating = Math.round(rating);
        return [1, 2, 3, 4, 5].map((item: number) => (
            <Icon
                key={item}
                className={styles.Star}
                name={item <= roundedRating ? 'RatingSolidIcon' : 'RatingIcon'}
                alt="rating icon"
                size={isMobile ? 4 : 7}
            />
        ));
    };

    const chartLength = [5, 30, 40, 20, 60];
    const mobileChartLength = [3, 15, 30, 7, 45];

    const reviewChart = [5, 4, 3, 2, 1].map((item: number) => (
        <div key={item} className={styles.Rating}>
            <div className={styles.ReviewChartRating}>{item}</div>
            <Icon
                className={styles.ReviewRatingIcon}
                name="RatingSolidIcon"
                alt="rating icon"
                size={isMobile ? 6 : 9}
            />
            <div
                className={styles.ReviewRatingBar}
                style={{
                    width: `${
                        isMobile
                            ? mobileChartLength[item - 1]
                            : chartLength[item - 1]
                    }px`,
                }}
            ></div>
        </div>
    ));

    const reviews = (reviews: Array<any>) => {
        const reviewList = reviews.slice(0, 4);

        return reviewList.map((review: any, index) => (
            <div key={index} className={styles.Review}>
                <img
                    className={styles.AuthorPhoto}
                    src={review.authorAvatar}
                    alt=""
                />
                <div className={styles.ReviewDetails}>
                    <div className={styles.AuthorName}>{review.authorName}</div>
                    <div className={styles.ReviewRating}>
                        {starRating(review.rating)}
                        <div className={styles.RelativeTime}>
                            {review.relativeTimeDescription}
                        </div>
                    </div>
                    <div className={styles.ReviewText}>{review.text}</div>
                </div>
            </div>
        ));
    };

    return (
        <div className={styles.Wrapper}>
            <div
                className={styles.Header}
                style={{
                    backgroundImage: `url(${businessPreview.imageMobile})`,
                }}
            ></div>
            <div
                className={styles.ImageMerchant}
                style={{ backgroundImage: `url(${businessPreview.image})` }}
            />
            <div className={styles.Phones}>
                <div className={styles.LeftMobile}>
                    <div className={styles.PhoneWrapper}>
                        <MobilePhone className={styles.MobilePhone} />
                        <div className={styles.MobileScreen}>
                            <div
                                className={styles.SmallImage}
                                style={{
                                    backgroundImage: `url(${businessPreview.imageSmall})`,
                                }}
                            ></div>

                            <div className={styles.Overview}>
                                <div className={styles.Name}>
                                    {businessPreview.name}
                                </div>
                                <div className={styles.Rating}>
                                    {businessPreview.rating}
                                    {starRating(businessPreview.rating)}
                                </div>
                                <div className={styles.Category}>
                                    {businessPreview.category}
                                    <b className={styles.Open}>{t('open')}</b>
                                </div>
                                <Tabs
                                    active="overview"
                                    onSelect={() => null}
                                    className={styles.Tabs}
                                >
                                    <Tabs.Tab className={styles.Tab} name="overview">
                                        {t('overview')}
                                    </Tabs.Tab>
                                    <Tabs.Tab className={styles.Tab} name="reviews">
                                        {t('reviews')}
                                    </Tabs.Tab>
                                    <Tabs.Tab className={styles.Tab} name="photos">
                                        {t('photos')}
                                    </Tabs.Tab>
                                    <Tabs.Tab className={styles.Tab} name="about">
                                        {t('about')}
                                    </Tabs.Tab>
                                </Tabs>
                                <div className={styles.Actions}>
                                    <div className={styles.Action}>
                                        <ActionCall />
                                        {t('connect.previewMobileActions.call')}
                                    </div>
                                    <div className={styles.Action}>
                                        <ActionDirections />
                                        {t(
                                            'connect.previewMobileActions.directions',
                                        )}
                                    </div>
                                    <div className={styles.Action}>
                                        <ActionMessage />
                                        {t('connect.previewMobileActions.message')}
                                    </div>
                                    <div className={styles.Action}>
                                        <ActionWebsite />
                                        {t('connect.previewMobileActions.website')}
                                    </div>
                                </div>
                                <div className={styles.About}>
                                    {businessPreview.about}
                                    <Icon
                                        className={styles.ChevronIcon}
                                        name="ChevronRightIcon"
                                        alt="right chevron"
                                        size={10}
                                    />
                                </div>
                                <div className={styles.Location}>
                                    <div className={styles.Address}>
                                        <MapMarker className={styles.MapMarker} />
                                        {businessPreview.address}
                                    </div>
                                    <div
                                        className={styles.Map}
                                        style={{
                                            backgroundImage: `url(${businessPreview.map})`,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.RightMobile}>
                    <div className={styles.PhoneWrapper}>
                        <MobilePhone className={styles.MobilePhone} />
                        <div className={styles.MobileScreen}>
                            <div className={styles.Reviews}>
                                <div className={styles.Name}>
                                    <Icon
                                        className={styles.ArrowIcon}
                                        name="ArrowLeftIcon"
                                        size={isMobile ? 8 : 11}
                                        alt="arrow left"
                                    />
                                    {businessPreview.name}
                                </div>
                                <Tabs
                                    active="reviews"
                                    onSelect={() => null}
                                    className={styles.Tabs}
                                >
                                    <Tabs.Tab className={styles.Tab} name="overview">
                                        {t('overview')}
                                    </Tabs.Tab>
                                    <Tabs.Tab className={styles.Tab} name="reviews">
                                        {t('reviews')}
                                    </Tabs.Tab>
                                    <Tabs.Tab className={styles.Tab} name="photos">
                                        {t('photos')}
                                    </Tabs.Tab>
                                    <Tabs.Tab className={styles.Tab} name="about">
                                        {t('about')}
                                    </Tabs.Tab>
                                </Tabs>
                                <div className={styles.RatingSummary}>
                                    <div className={styles.RatingChart}>
                                        {reviewChart}
                                    </div>
                                    <div className={styles.RatingTotal}>
                                        <div className={styles.RatingValue}>
                                            {businessPreview.rating}
                                        </div>
                                        {starRating(businessPreview.rating)}
                                        <div className={styles.ReviewsCount}>
                                            {businessPreview.userRatingsTotal}
                                            &nbsp;
                                            {t('reviews')}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.ReviewsList}>
                                    {reviews(businessPreview.reviews)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isExampleProfile && (
                    <div className={styles.ExampleProfile}>
                        {t('connect.sublocationSelect.exampleProfile')}
                    </div>
                )}
            </div>
        </div>
    );
}
