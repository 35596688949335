import { createReducer, Reducer } from '@reduxjs/toolkit';

import * as locationsActions from './locations.actions';
import { ILocationTypes } from './locations.types';

export const initialState: ILocationTypes = {
    locations: [],
    spoton: {},
    googleCategory: {},
    isLoading: true,
    isError: false,
    isLoadingSyncLocation: true,
};

export const locationsReducer: Reducer<ILocationTypes> = createReducer(
    initialState,
    (builder) => {
        builder
            .addCase(
                locationsActions.getLocations.fulfilled,
                (state, action: any) => {
                    state.locations = action.payload.locations;
                    state.spoton = action.payload.spoton;
                    state.googleCategory = action.payload.googleCategory;
                    state.isLoading = false;
                    state.isError = false;
                },
            )
            .addCase(locationsActions.getLocations.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(locationsActions.getLocations.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(locationsActions.syncLocation.pending, (state) => {
                state.isLoadingSyncLocation = true;
            })
            .addCase(locationsActions.syncLocation.fulfilled, (state) => {
                state.isLoadingSyncLocation = false;
            })
            .addCase(locationsActions.syncLocation.rejected, (state) => {
                state.isLoadingSyncLocation = false;
            })
            .addDefaultCase((state) => {
                return state;
            });
    },
);

export default locationsReducer;
