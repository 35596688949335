import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

const getDeviceType = (width: number) => {
    if (width < 768) {
        return 'mobile';
    } else if (width >= 768 && width < 1024) {
        return 'tablet';
    } else if (width >= 1024) {
        return 'desktop';
    }
};

export const useBreakpoint = (): any => {
    const [brkPnt, setBrkPnt] = useState(() => getDeviceType(window.innerWidth));

    useEffect(() => {
        const calcInnerWidth = throttle(function () {
            setBrkPnt(getDeviceType(window.innerWidth));
        }, 200);
        window.addEventListener('resize', calcInnerWidth);
        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return brkPnt;
};
