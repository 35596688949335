import { axios, AxiosResponse } from 'features/common/utils/axios.utils';

import {
    IGetVerification,
    IPostVerification,
    IPostCompleteVerification,
} from './Verification.types';

export function getVerification(): Promise<AxiosResponse<IGetVerification>> {
    return axios.get<IGetVerification>('/api/location/verification');
}

export function postVerification(
    method: string,
    input: string,
): Promise<AxiosResponse<any>> {
    return axios.post<IPostVerification>('/api/location/verification', {
        method,
        input,
    });
}

export function postCompleteVerification(
    pin: string,
    name: string,
): Promise<AxiosResponse<any>> {
    return axios.post<IPostCompleteVerification>(
        '/api/location/verification/complete',
        { pin, name },
    );
}
