import React, { Suspense } from 'react';
import { NotFoundBoundary, Router } from 'react-navi';
import HelmetProvider from 'react-navi-helmet-async';
import { Text } from 'spoton-lib';
import { Provider } from 'react-redux';

import { ErrorBoundaryGeneric } from 'features/common/components';
import { fetchAdvisorStatus } from 'features/dashboard/redux/advisor/advisor.actions';
import initialState from 'reduxConfig/initialState';
import configureStore from 'reduxConfig/config';
import { navigation } from 'app/routes';
import { App } from './App';
import { axios } from './features';

const store = configureStore(initialState);

// Interceptor for updating Advisor todo list
axios.interceptors.response.use(
    (response) => {
        const urlListToTrack = [
            'api/profile/info',
            'api/location/media',
            'api/location/local-posts',
            'api/menu/media',
            'api/location/reviews',
        ];
        const trackableMethods = ['post', 'patch', 'put'];

        const url: string = response.config.url as string;
        const method: string = response.config.method as string;

        const isTrackableUrl = urlListToTrack.some((u) => url.includes(u));
        const isTrackableMethods = trackableMethods.includes(method);
        const shouldUpdateAdvisor = isTrackableUrl && isTrackableMethods;

        if (shouldUpdateAdvisor) {
            store.dispatch(fetchAdvisorStatus());
        }
        return response;
    },
    (error) => Promise.reject(error),
);

function renderNotFound(): JSX.Element {
    return <Text type="h2">Oops! Page not found</Text>;
}
/**
 * This coomponent serves as a common entry point for the application, regardless of runing as MFE or standalone app.
 * It wraps the App component with all necessary providers and context.
 * For MFE entry point, see src/singleSpaRoot.tsx
 * For standalone app entry point, see src/index.tsx
 */
const Common: React.VFC = () => {
    return (
        <Provider store={store}>
            <HelmetProvider>
                <Router navigation={navigation}>
                    <Suspense fallback={null}>
                        <ErrorBoundaryGeneric>
                            <NotFoundBoundary render={renderNotFound}>
                                <App />
                            </NotFoundBoundary>
                        </ErrorBoundaryGeneric>
                    </Suspense>
                </Router>
            </HelmetProvider>
        </Provider>
    );
};

export default Common;
