import React, { useEffect, useCallback } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useSelector, useDispatch } from 'react-redux';
import { Card, LoadingIndicator, Text, showToast } from 'spoton-lib';
import { useCurrentRoute } from 'react-navi';
import { useTranslation } from 'react-i18next';
import colors from 'spoton-lib/styles/color-variables.module.scss';

import {
    getAuthConfig,
    getLocationStatus,
    disconnectAccount,
} from 'features/disconnect/actions';
import { selectDisconnectState } from './selectors';

import SignInButton from 'features/account/components/Connect/components/SignInButton/SignInButton.component';

import LogoPoweredBy from 'assets/images/LogoPoweredBy.svg';
import SuccessLogo from './assets/Success.svg';
import GoogleKiosk from 'features/account/assets/images/googleKiosk.png';

import styles from './Disconnect.module.scss';

export function Disconnect(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    /**
     * There is hardcoded query parameter ?key=test on backend side to test the flow
     */
    const { url } = useCurrentRoute();
    const {
        locationAddress,
        locationName,
        clientId,
        scope,
        isLoading,
        isLocationStatus,
        isLocationDisconnected,
        isStatusError,
    } = useSelector(selectDisconnectState);

    useEffect(() => {
        dispatch(getLocationStatus({ key: url?.query?.key || '' }));
        dispatch(getAuthConfig());
    }, [url]);

    const disconnectLocationAfterLogin = useCallback(
        (google) => {
            dispatch(
                disconnectAccount({
                    authorizationCode: google.code,
                    key: url?.query?.key || '',
                }),
            );
        },
        [dispatch],
    );

    const showErrorToLogInGoogle = (message: any) => {
        console.warn('Log in Google: ', message);
        showToast({
            variant: 'danger',
            title: t('error'),
            content: message?.details,
            autoClose: 4000,
        });
    };

    const isShowGoogleLogin =
        !isLoading && isLocationStatus && clientId && !isLocationDisconnected;

    const isShowSuccessSection = !isLoading && isLocationDisconnected;

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <div className={styles.Wrapper}>
                <div className={styles.Logo}>
                    <img
                        src={LogoPoweredBy}
                        alt="Spoton logo"
                        width="147"
                        height="44"
                    />
                </div>
                <Card elevation={1} className={styles.Card}>
                    {isLoading && <LoadingIndicator />}
                    {isStatusError && (
                        <Text type="h2" color={colors.warning70}>
                            {t('disconnect.linkExp')}
                        </Text>
                    )}
                    {isShowGoogleLogin && (
                        <>
                            <img
                                className={styles.ImageKiosk}
                                src={GoogleKiosk}
                                alt="google kiosk"
                                width="101"
                                height="88"
                            />
                            <Text type="h5" className={styles.Title}>
                                {t('disconnect.firstTitle')}
                            </Text>
                            <Text type="sub2">{locationName}</Text>
                            <Text type="sub2">{locationAddress}</Text>
                            <SignInButton
                                type="primary"
                                scope={scope}
                                onSuccess={disconnectLocationAfterLogin}
                                onError={showErrorToLogInGoogle}
                            />
                            <Text
                                type="label"
                                color="#0e1d47"
                                className={styles.SubTitle}
                            >
                                {t('disconnect.firstSubTitle')}
                            </Text>
                        </>
                    )}
                    {isShowSuccessSection && (
                        <>
                            <img
                                className={styles.ImageKiosk}
                                src={SuccessLogo}
                                alt="success"
                                width="207"
                                height="193"
                            />
                            <Text type="h3" className={styles.Title}>
                                {t('disconnect.secondTitle')}
                            </Text>
                            <Text
                                type="label"
                                color="#0e1d47"
                                className={styles.SubTitle}
                            >
                                {t('disconnect.secondSubTitle')}
                            </Text>
                        </>
                    )}
                </Card>
            </div>
        </GoogleOAuthProvider>
    );
}
