import React from 'react';
import { IconButton } from 'spoton-lib';

import styles from './TransparentButton.module.scss';

type TransparentButtonProps = {
    onClick(): void;
    name?: string;
    alt?: string;
};

export default function TransparentButton({
    onClick,
    ...otherProps
}: TransparentButtonProps): JSX.Element {
    return (
        <IconButton
            variant="secondary"
            onClick={onClick}
            disableBorder
            className={styles.Icon}
            {...otherProps}
        />
    );
}
