import React from 'react';
import { useTranslation } from 'react-i18next';
import { event } from 'react-fullstory';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, IconButton, Button } from 'spoton-lib';

import { getConfigVar } from 'features/common/utils/config.utils';

import { getLocations } from 'features/account/redux/locations/locations.actions';
import { selectFsIdentifiers } from 'features/common/redux/status/status.selectors';

import styles from './SmallModal.module.scss';

interface IPostSmallModalProps {
    title: string;
    closeModal: () => void;
    locationsState: string;
    createLocation: () => void;
}

export default function SmallModal({
    title,
    closeModal,
    locationsState,
    createLocation,
}: IPostSmallModalProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const profileUrl = getConfigVar('REACT_APP_SETTINGS_ACCOUNT') as string;
    const supportPhoneNumber = getConfigVar(
        'REACT_APP_SUPPORT_PHONE_NUMBER',
    ) as string;

    const fsIdentifiers = useSelector(selectFsIdentifiers);

    const goToProfile = () => {
        event('go_to_profile', fsIdentifiers as { [property: string]: string });
        window.open(profileUrl, '_blank');
    };

    const searchAgain = () => {
        event('search_again', fsIdentifiers as { [property: string]: string });
        dispatch(getLocations());
        closeModal();
    };

    const createLocationState = () => {
        createLocation();
        closeModal();
    };

    return (
        <Modal
            ariaHideApp={false}
            isOpen={true}
            className={styles.Modal}
            onRequestClose={closeModal}
            overlayClassName={styles.Overlay}
        >
            <div className={styles.Header}>
                <div className={styles.HeaderText}>{title}</div>
                <IconButton
                    className={styles.CloseIcon}
                    name="CloseIcon"
                    variant="secondary"
                    disableBorder={true}
                    alt="close icon"
                    onClick={closeModal}
                />
            </div>
            <div className={styles.Body}>
                <div className={styles.Explanation}>
                    {t('connect.dontRecognizeExplanation')}
                </div>
                <div className={styles.Recommendation}>
                    {t('connect.dontRecognizeRecommendation')}
                </div>
                <div className={styles.Footer}>
                    <div className={styles.ButtonWrapper}>
                        <Button className={styles.Button} onClick={goToProfile}>
                            {t('connect.goToProfile')}
                        </Button>
                        <Button onClick={searchAgain} variant={'secondary'}>
                            {t('connect.searchAgain')}
                        </Button>
                        {locationsState === 'MULTIPLE' && (
                            <Button
                                className={styles.CreateButton}
                                onClick={createLocationState}
                                variant={'tertiary'}
                                danger={true}
                            >
                                {t('connect.createLocation')}
                            </Button>
                        )}
                    </div>
                    <div className={styles.Help}>
                        {t('connect.dontRecognizeHelpStart')}
                        &nbsp;
                        <a
                            className={styles.PhoneNumber}
                            href={`tel:${supportPhoneNumber}`}
                        >
                            {supportPhoneNumber}, {t('connect.option')}
                        </a>
                        &nbsp;
                        {t('connect.dontRecognizeHelpEnd')}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
