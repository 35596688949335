import { useRef, useEffect, RefObject } from 'react';

export default function useOuterClick<Element extends HTMLElement>(
    callback: (e: any) => void,
): RefObject<Element> {
    const callbackRef: { current: any } = useRef();
    const innerRef = useRef<Element>(null);

    useEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        function handleClick(e: any) {
            if (
                innerRef.current &&
                callbackRef.current &&
                !innerRef.current.contains(e.target)
            )
                callbackRef.current(e);
        }
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []);

    return innerRef;
}

/**
 * How use:
 *
 * const innerRef = useOuterClick(ev => {event handler code on outer click});
 * return <div ref={innerRef} className="wrapper"> Inside </div>
 */
