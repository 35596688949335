import { RootState } from 'reduxConfig';

export const selectLocations = (state: RootState): any => state.locations;

export const selectLocationsGMB = (state: RootState): any =>
    state.locations.locations;

export const selectLocationSpotOn = (state: RootState): any =>
    state.locations.spoton;

export const selectLocationsCategory = (state: RootState): any =>
    state.locations.googleCategory;

export const selectLocationsStatus = (state: RootState): any => ({
    isLoading: state.locations.isLoading,
    isError: state.locations.isError,
});

export const selectSyncLocationsStatus = (state: RootState): boolean =>
    state.locations.isLoadingSyncLocation;
