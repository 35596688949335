import React, { useState, useEffect } from 'react';
import { Button, Icon, IconButton } from 'spoton-lib';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-navi';

import { InfoTooltip } from 'features/common';
import PostSmallModal from './SmallModal/SmallModal.component';
import Menu from 'features/common/components/Menu';
import { useBreakpoint } from 'utils/customHooks';
import useNoEnabledPost from 'features/dashboard/hooks/useNoEnabledPost';

import styles from './PostsHeading.module.scss';

interface IHeadingProps {
    isModal: boolean;
    isCreateMode?: boolean;
    closeModal?: any;
    refreshTab: (arg: string) => void;
}

export default function PostHeading({
    isModal,
    isCreateMode,
    closeModal,
    refreshTab,
}: IHeadingProps): JSX.Element {
    const isMobile = useBreakpoint() !== 'desktop';
    const { t } = useTranslation();
    const [isShowingPostCreateMenu, setIsShowingPostCreateMenu] =
        useState<boolean>(false);

    const { isPostsEnabled, notifyNoPost } = useNoEnabledPost();

    const params = new URL(location.href).searchParams;
    const queryCreatePost = params.get('wind');

    const navigation = useNavigation();

    const togglePostCreateMenu = () => {
        if (isPostsEnabled) {
            setIsShowingPostCreateMenu(!isShowingPostCreateMenu);
        } else {
            notifyNoPost();
        }
    };
    const closePostCreateMenu = () => {
        setIsShowingPostCreateMenu(false);
    };

    const [topicType, setTopicType] = useState<string>('');

    const [isShowingSmallModal, setIsShowingSmallModal] = useState<boolean>(false);

    const openSmallModal = (topicType: string) => {
        setTopicType(topicType);
        setIsShowingSmallModal(true);
    };

    const closeSmallModal = () => {
        setIsShowingSmallModal(false);
    };

    const createPost = (topicType: string) => {
        openSmallModal(topicType);
        closePostCreateMenu();
    };

    useEffect(() => {
        if (isMobile && isCreateMode) {
            setIsShowingPostCreateMenu(true);
        }
    }, [isModal, isMobile]);

    useEffect(() => {
        if (queryCreatePost === 'create-post') {
            createPost('STANDARD');
            params.delete('wind');
            navigation.navigate(`${location.pathname}?${params.toString()}`, {
                replace: true,
            });
        }
    }, [queryCreatePost]);

    const mobileHeader = (isModal: boolean) => (
        <div
            className={isModal ? styles.MobileHeading : styles.Heading}
            onMouseLeave={closePostCreateMenu}
        >
            <div className={styles.HeadingText}>
                {t('dashboard.postTitle')}
                <InfoTooltip
                    tooltipText={t('dashboard.tooltips.postManagementTitle')}
                    className={styles.TooltipContainer}
                    tooltipClassName={styles.TooltipContent}
                    iconClassName={styles.ToolTipIcon}
                />
            </div>
            <div
                className={styles.HeadingButtonContainer}
                style={isModal && !isMobile ? { right: '40px' } : {}}
            >
                {isModal && (
                    <IconButton
                        className={styles.AddButton}
                        name="AddIcon"
                        variant="tertiary"
                        onClick={togglePostCreateMenu}
                        alt="add icon"
                        size={24}
                    />
                )}
                {isShowingPostCreateMenu && isPostsEnabled && (
                    <Menu className={styles.Menu}>
                        <Menu.item onClick={() => createPost('STANDARD')}>
                            {t('update')}
                        </Menu.item>
                        <Menu.item onClick={() => createPost('OFFER')}>
                            {t('offer')}
                        </Menu.item>
                        <Menu.item onClick={() => createPost('EVENT')}>
                            {t('event')}
                        </Menu.item>
                    </Menu>
                )}
            </div>
            {isModal && (
                <IconButton
                    className={
                        isMobile && isModal
                            ? styles.MobileCloseIcon
                            : styles.CloseIcon
                    }
                    name={`${isMobile ? 'ArrowLeftIcon' : 'CloseIcon'}`}
                    alt="close button"
                    buttonSize="large"
                    size={60}
                    variant="secondary"
                    disableBorder={true}
                    onClick={closeModal}
                />
            )}
        </div>
    );

    const desktopHeader = () => (
        <div className={styles.Heading} onMouseLeave={closePostCreateMenu}>
            <div className={styles.HeadingText}>
                {t('dashboard.postTitle')}
                <InfoTooltip
                    tooltipText={t('dashboard.tooltips.postManagementTitle')}
                    className={styles.TooltipContainer}
                    tooltipClassName={styles.TooltipContent}
                    iconClassName={styles.ToolTipIcon}
                />
            </div>
            <div
                className={styles.HeadingButtonContainer}
                style={isModal ? { right: '40px' } : {}}
            >
                <Button
                    className={styles.HeadingButton}
                    onClick={togglePostCreateMenu}
                    variant="secondary"
                >
                    <>
                        <Icon
                            className={styles.AddButton}
                            name="AddIcon"
                            alt="add icon"
                            size={24}
                        />
                        {t('dashboard.postCreate')}
                    </>
                </Button>
                {isShowingPostCreateMenu && isPostsEnabled && (
                    <Menu className={styles.Menu}>
                        <Menu.item onClick={() => createPost('STANDARD')}>
                            {t('update')}
                        </Menu.item>
                        <Menu.item onClick={() => createPost('OFFER')}>
                            {t('offer')}
                        </Menu.item>
                        <Menu.item onClick={() => createPost('EVENT')}>
                            {t('event')}
                        </Menu.item>
                    </Menu>
                )}
            </div>
            {isModal && (
                <IconButton
                    className={styles.CloseIcon}
                    name="CloseIcon"
                    alt="close button"
                    variant="secondary"
                    disableBorder={true}
                    onClick={closeModal}
                />
            )}
        </div>
    );

    return (
        <>
            {isMobile && mobileHeader(isModal)}
            {!isMobile && desktopHeader()}
            {isShowingSmallModal && (
                <PostSmallModal
                    post={{
                        topicType,
                    }}
                    closeModal={closeSmallModal}
                    refreshTab={refreshTab}
                />
            )}
        </>
    );
}
