import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator, Text, Button, Modal } from 'spoton-lib';

import { Widget } from 'features/common/components';
import {
    fetchMenuItems,
    selectMenuItemsData,
    selectMenuItemsErrorStatus,
    selectMenuItemsLoadingStatus,
} from 'features/dashboard/redux/menu';
import { MenuItemsList } from './components/MenuItemsList';
import { ModalHeader } from 'features/common/components/Modal/Modal.component';
import { mockMenu } from './mockMenu';

import styles from './UserMenuItems.module.scss';

import serverErrorImage from '../../assets/serverError.png';
import { InfoTooltip } from '../../../common';

export default function UserMenuItems(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const menusData = useSelector(selectMenuItemsData);
    const isLoading = useSelector(selectMenuItemsLoadingStatus);
    const isError = useSelector(selectMenuItemsErrorStatus);
    const menus = menusData?.menus || [];
    const isNoMenus = !isLoading && !menus?.length;

    useEffect(() => {
        dispatch(fetchMenuItems({}));
    }, [dispatch]);

    const showModal = useCallback(() => {
        setIsModalVisible(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalVisible(false);
    }, []);

    return (
        <>
            <Widget className={styles.MenuItems}>
                <div className={styles.CardHeader}>
                    <div className={styles.CardTitle}>
                        {t('menus.title')}
                        <InfoTooltip
                            tooltipText={t(`menus.titleTooltip`)}
                            className={styles.TooltipContainer}
                            tooltipClassName={styles.TooltipContent}
                            iconClassName={styles.ToolTipIcon}
                        />
                    </div>

                    <Text type="p">{t('menus.subTitle')}</Text>
                </div>
                {isNoMenus && !isError && (
                    <>
                        <div className={styles.MenuExampleImageWrapper}>
                            <MenuItemsList menus={mockMenu} />
                        </div>

                        <div className={styles.MenuExampleOverlay}>
                            <div className={styles.MenuExampleWrapper}>
                                <Text type="h5" className={styles.CardModalTitle}>
                                    {t('menus.joinSpotonTitle')}
                                </Text>
                                <Text type="p">{t('menus.joinSpotonText')}</Text>
                                <Text type="p" className={styles.CallSpoton}>
                                    {t('menus.callNow')} -
                                    <span className={styles.CallSpotonNumberWrapper}>
                                        <a
                                            className={styles.CallSpotonNumber}
                                            href={`tel:${t('menus.callNumber')}`}
                                        >
                                            {t('menus.callNumber')}
                                        </a>
                                    </span>
                                </Text>
                            </div>
                        </div>
                    </>
                )}
                {isLoading && !isError && (
                    <div className={styles.Loader}>
                        <LoadingIndicator />
                    </div>
                )}
                {isNoMenus && isError && (
                    <div className={styles.MenuItemsContentCenter}>
                        <div className={styles.MenuItemsErrorWrapper}>
                            <img src={serverErrorImage} alt="error icon" />
                            <p className={styles.MenuItemsErrorText}>
                                {t('errors.server')}
                            </p>
                        </div>
                    </div>
                )}
                {!isLoading && !isNoMenus && (
                    <>
                        <div className={styles.CardBody}>
                            <MenuItemsList menus={menus} />
                        </div>
                        <div className={styles.CardFooter}>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={showModal}
                                className={styles.ViewMoreButton}
                            >
                                View more
                            </Button>
                        </div>
                    </>
                )}
            </Widget>
            <Modal
                ariaHideApp={false}
                isOpen={isModalVisible}
                onRequestClose={closeModal}
                className={styles.Modal}
            >
                <ModalHeader
                    title={t('menus.title')}
                    closeModal={closeModal}
                    className={styles.ModalHeader}
                />
                <div className={styles.ModalViewport}>
                    <div className={styles.ModalContainer}>
                        <MenuItemsList menus={menus} />
                    </div>
                </div>
            </Modal>
        </>
    );
}
