export enum SortValues {
    'updateTime desc' = 'updateTime desc',
    'rating desc' = 'rating desc',
    'rating' = 'rating',
}

export enum QuestionTabs {
    all = 'all',
    replied = 'replied',
    notReplied = 'notReplied',
}

export interface IQuestionsPayload {
    answersPerQuestion: number;
    ignoreAnswered?: boolean;
    orderBy?: SortValues;
    pageSize?: number;
    pageToken?: string;
}

export interface ICreateAnswer {
    id: string;
    text: string;
    sortOrder: any;
}

export interface IDeleteQuestion {
    questionId: string;
    order: any;
}

interface IAuthor {
    current_user: boolean;
    displayName: string;
    profilePhotoUrl: string;
    type: string;
}

interface IAnswer {
    name: string;
    author: IAuthor;
    upvoteCount: number;
    text: string;
    createTime: string;
    updateTime: string;
}

export interface IQuestion {
    name: string;
    questionId: string;
    author: IAuthor;
    upvoteCount: number;
    text: string;
    createTime: string;
    updateTime: string;
    topAnswers: IAnswer[];
    totalAnswerCount: number;
}

export interface IQuestions {
    questions: IQuestion[];
    totalSize: number;
    nextPageToken: string;
}
