import React, { useState, BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, StarRating, TextArea } from 'spoton-lib';
import formatDistance from 'date-fns/formatDistance';

import { Widget } from 'features/common/components';
import { IReview } from 'features/dashboard/api/Review.types';
import { ReviewApi } from 'features/dashboard/api';
import styles from './ReviewsSection.module.scss';
import NoItemsImageSorted from 'features/common/assets/noItems.png';
import NoItemsImage from 'features/dashboard/assets/noPosts.png';
import ServerErrorImage from 'features/dashboard/assets/serverError.png';
import { showDetailedError } from 'utils/messages';

interface IReviewProps {
    review: IReview;
}
const stars = ['', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE'];

// eslint-disable-next-line
export default function Review(this: any, { review }: IReviewProps): JSX.Element {
    const { t } = useTranslation();
    const [isReplying, setIsReplying] = useState(false);
    const [replyText, setReplyText] = useState('');

    const leaveReply = (reviewId: string) => {
        if (!replyText) return;
        ReviewApi.postReviewReply(reviewId, replyText)
            .then(() => {
                review.reviewReply = { comment: replyText };
                setIsReplying(false);
            })
            .catch((error) => {
                showDetailedError({
                    error: error.response?.data,
                    template: 'errors.dashboard.reviewsReplyPost',
                });
            });
    };
    const deleteReply = (reviewId: string) => {
        ReviewApi.deleteReviewReply({ reviewId })
            .then(() => {
                delete review.reviewReply;
                setReplyText('forceUpdate');
            })
            .catch(() => {
                showDetailedError({
                    template: 'errors.dashboard.reviewsReplyDelete',
                });
            });
    };
    const discardReply = () => {
        setIsReplying(false);
        setReplyText('');
    };

    return (
        <Widget className={styles.ReviewCard}>
            <div className={styles.ReviewPhoto}>
                <img src={review.reviewer.profilePhotoUrl} width="36" alt="" />
            </div>
            <div className={styles.Reviewer}>{review.reviewer.displayName}</div>
            <div className={styles.Stars}>
                <StarRating stars={stars.indexOf(review.starRating)} />
            </div>
            <div className={styles.ReviewDate}>
                {formatDistance(new Date(review.updateTime), new Date(), {
                    addSuffix: true,
                })}
            </div>
            <div className={styles.ReviewText}>
                {review.comment}
                {review.reviewReply && (
                    <div className={styles.ReviewReply}>
                        <strong>{t('dashboard.yourResponse')}</strong>
                        <br />
                        {review.reviewReply.comment}
                    </div>
                )}
            </div>
            {isReplying && (
                <>
                    <div className={styles.ReviewReply}>{t('owner')}</div>
                    <TextArea
                        className={styles.ReviewReplying}
                        placeholder={t('dashboard.replyPlaceholder')}
                        onChange={(e: BaseSyntheticEvent) =>
                            setReplyText(e.target.value)
                        }
                    />
                    <div className={styles.ReplyButtons}>
                        <Button variant="tertiary" onClick={discardReply}>
                            {t('discard')}
                        </Button>
                        <Button
                            variant="tertiary"
                            onClick={leaveReply.bind(this, review.reviewId)}
                        >
                            {t('reply')}
                        </Button>
                    </div>
                </>
            )}
            {!isReplying && review.reviewReply && (
                <div className={styles.ReplyButtons}>
                    <Button
                        variant="tertiary"
                        onClick={deleteReply.bind(this, review.reviewId)}
                    >
                        {`${t('remove')} ${t('reply')}`}
                    </Button>
                </div>
            )}
            {!isReplying && !review.reviewReply && (
                <div className={styles.ReplyButtons}>
                    <Button
                        variant="tertiary"
                        onClick={setIsReplying.bind(this, true)}
                    >
                        {t('reply')}
                    </Button>
                </div>
            )}
        </Widget>
    );
}
export interface IReviewEmpty {
    activeTab: string;
    hasReviews?: boolean;
    isError: boolean;
}

export function ReviewEmpty({
    activeTab,
    hasReviews,
    isError,
}: IReviewEmpty): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className={styles.Rating_noItem}>
            {!isError && (
                <img src={hasReviews ? NoItemsImageSorted : NoItemsImage} alt="" />
            )}
            {isError && <img src={ServerErrorImage} alt="Server error" />}
            <div className={styles.Rating_noItemText}>
                {isError && t('errors.server')}
                {activeTab === 'all' && !isError && t('dashboard.noReviews')}
                {activeTab === 'replied' &&
                    !isError &&
                    (hasReviews
                        ? t('dashboard.noRepliedReviews')
                        : t('dashboard.noReviews'))}
                {activeTab === 'omitted' &&
                    !isError &&
                    (hasReviews
                        ? t('dashboard.noReviewsToAnswer')
                        : t('dashboard.noReviews'))}
            </div>
        </div>
    );
}
