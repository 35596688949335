import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { LocationsApi } from 'features/account/api';
import { showDetailedError } from 'utils/messages';

export const getLocations = createAsyncThunk(
    'locations/getLocations',
    async (_, { rejectWithValue }) => {
        try {
            const response = await LocationsApi.getLocations();
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                template: 'errors.connect.locations',
            });
            return rejectWithValue({
                data,
                status,
            });
        }
    },
);

export const syncLocation = createAsyncThunk(
    'locations/syncLocation',
    async (_, { rejectWithValue }) => {
        try {
            const response = await LocationsApi.syncLocation();
            return response.data;
        } catch (error) {
            const data = (error as AxiosError<any, any>).response?.data;
            const status = (error as AxiosError<any, any>).response?.status;
            showDetailedError({
                message: 'Server error',
            });
            return rejectWithValue({
                data,
                status,
            });
        }
    },
);
