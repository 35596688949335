import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, Button, colors, LoadingIndicator } from 'spoton-lib';

import { Widget } from 'features/common/components';
import {
    selectBusinessInfoData,
    selectBusinessInfoErrorStatus,
    selectBusinessInfoLoadingStatus,
    showBusinessHoursModal,
} from 'features/dashboard/redux';

import serverErrorImage from 'features/dashboard/assets/serverError.png';
import { TimePeriodRow } from './components';
import styles from './BusinessHours.module.scss';
import { daysOfWeekKeys, normalizeCombineNightHours } from 'utils/periods.utils';
import { RegularHoursPeriod } from 'features/dashboard/types';

export default function BusinessHours(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const info = useSelector(selectBusinessInfoData);
    const [periods, setPeriods] = useState<RegularHoursPeriod[]>([]);
    const isError = useSelector(selectBusinessInfoErrorStatus);
    const isLoading = useSelector(selectBusinessInfoLoadingStatus);
    const isRegularHours = useMemo(
        () => info?.regularHours?.periods?.length > 0,
        [info],
    );

    useEffect(() => {
        setPeriods(normalizeCombineNightHours(info.regularHours?.periods));
    }, [info]);

    const handleEditBusinessHours = useCallback(() => {
        dispatch(showBusinessHoursModal());
    }, [dispatch]);

    const renderContent = useCallback(() => {
        return isRegularHours ? (
            daysOfWeekKeys.map((key: string) => (
                <TimePeriodRow dayKey={key} periods={periods} key={key} />
            ))
        ) : (
            <>
                <div className={styles.NoHours}>
                    <Icon
                        alt="time"
                        name="TimeIcon"
                        size={18}
                        color={colors.primary50}
                    />
                    <span className={styles.Label}>
                        {t('profile.businessHours.letCustomersKnow')}
                    </span>
                </div>
                <Button
                    className={styles.SetButton}
                    onClick={handleEditBusinessHours}
                    variant="primary"
                >
                    {t('profile.businessHours.businessHours')}
                </Button>
            </>
        );
    }, [periods]);

    const shouldShowLoading = isLoading && !isError;
    const shouldShowContent = !isLoading && !isError;

    return (
        <Widget className={styles.BusinessHours}>
            {shouldShowContent && (
                <div className={styles.BusinessHoursBox}>
                    <div className={styles.Header}>
                        <div className={styles.HeaderText}>
                            {t('profile.businessHours.businessHours')}
                        </div>
                        {isRegularHours && (
                            <button
                                onClick={handleEditBusinessHours}
                                className={styles.EditButton}
                            >
                                <Icon alt="edit" name="PencilOutline" size={20} />
                            </button>
                        )}
                    </div>
                    <div className={styles.Content}>{renderContent()}</div>
                </div>
            )}

            {shouldShowLoading && (
                <div className={styles.Loader}>
                    <LoadingIndicator />
                </div>
            )}

            {isError && (
                <div className={styles.BusinessHoursContentCenter}>
                    <div className={styles.BusinessHoursErrorWrapper}>
                        <img src={serverErrorImage} alt="good work icon" />
                        <p className={styles.BusinessHoursErrorText}>
                            {t('errors.server')}
                        </p>
                    </div>
                </div>
            )}
        </Widget>
    );
}
